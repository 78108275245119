import React, { useEffect, useState } from "react";
import { Button, Container } from "reactstrap";
import NoInternetComp from "../../../InternetConnetction/NoInternetComp";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import AdminPaymentGatewayModal from "../../Modal/PaymentGateway/AdminPaymentGatewayModal";
import toast from "react-hot-toast";
import {
  getServices,
  postServices,
  removeServices,
  updateServices,
} from "../../../../../apiServices/apiServices";
import { MdOutlineDelete, MdOutlineEdit } from "react-icons/md";

function ManagePaymentsView() {
  const [value, setValue] = useState("rp");
  const navigate = useNavigate();
  const [modal, setModal] = useState({
    rp_add_modal: false,
    rp_update_modal: false,
    pp_add_modal: false,
    pp_update_modal: false,
  });
  const [data, setData] = useState({ razorpay: [], phonepe: [] });
  const access_token = sessionStorage.getItem("access-token");
  const [fieldsValue, setFieldsValue] = useState({
    payment_method: "",
    razorpaykey_id: "",
    razorpay_secret: "",
    merchant_id: "",
    merchant_userid: "",
    merchant_mobno: "",
    saltkey: "",
  });

  useEffect(() => {
    fetchPaymentGatewayList();
  }, []);

  const fetchPaymentGatewayList = async () => {
    try {
      const res = await getServices(`/api/credential/list`, access_token);
      if (res.responseCode === 200) {
        setData({
          ...data,
          razorpay: res.responseData.razorpayData,
          phonepe: res.responseData.phonepeData,
        });
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handlePhonePeAddModal = () => {
    setModal({ ...modal, pp_add_modal: true });
    setFieldsValue({
      ...fieldsValue,
      payment_method: "",
      razorpaykey_id: "",
      razorpay_secret: "",
      merchant_id: "",
      merchant_userid: "",
      merchant_mobno: "",
      saltkey: "",
    });
  };
  const handleUpdatePhonePeModal = (keyData) => {
    setModal({ ...modal, pp_update_modal: true });
    setFieldsValue({
      ...fieldsValue,
      merchant_id: keyData.merchant_id,
      merchant_mobno: keyData.merchant_mobno,
      merchant_userid: keyData.merchant_userid,
      pp_id: keyData.pp_id,
      isdisabled: keyData.isdisabled,
    });
  };

  const handlePhonePeAddSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await postServices(`/api/credential/add`, access_token, {
        payment_method: "pp",
        merchant_id: fieldsValue.merchant_id,
        merchant_userid: fieldsValue.merchant_userid,
        merchant_mobno: fieldsValue.merchant_mobno,
        saltkey: fieldsValue.saltkey,
      });
      if (res.responseCode === 200) {
        toast.success("Key added.");
        fetchPaymentGatewayList();
        setModal({ ...modal, pp_add_modal: false });
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleRazorpayAddModal = () => {
    setModal({ ...modal, rp_add_modal: true });
    setFieldsValue({
      ...fieldsValue,
      payment_method: "",
      razorpaykey_id: "",
      razorpay_secret: "",
      merchant_id: "",
      merchant_userid: "",
      merchant_mobno: "",
      saltkey: "",
    });
  };
  const handleUpdateRazorpayModal = (keyData) => {
    setModal({ ...modal, rp_update_modal: true });
    setFieldsValue({
      ...fieldsValue,
      razorpaykey_id: keyData.razorpaykey_id,
      rp_id: keyData.rp_id,
      isdisabled: keyData.isdisabled,
    });
  };

  const handlePhonePeUpdateSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await updateServices(
        `/api/credential/update?pp_id=${fieldsValue.pp_id}`,
        access_token,
        {
          isdisabled: fieldsValue.isdisabled,
        }
      );
      if (res.responseCode === 200) {
        toast.success("Key status updated.");
        fetchPaymentGatewayList();
        setModal({ ...modal, pp_update_modal: false });
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleRazorpayAddSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await postServices(`/api/credential/add`, access_token, {
        payment_method: "rp",
        razorpaykey_id: fieldsValue.razorpaykey_id,
        razorpay_secret: fieldsValue.razorpay_secret,
      });
      if (res.responseCode === 200) {
        toast.success("Key added.");
        fetchPaymentGatewayList();
        setModal({ ...modal, rp_add_modal: false });
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleRazorpayUpdateSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await updateServices(
        `/api/credential/update?rp_id=${fieldsValue.rp_id}`,
        access_token,
        {
          isdisabled: fieldsValue.isdisabled,
        }
      );
      if (res.responseCode === 200) {
        toast.success("Key status updated.");
        fetchPaymentGatewayList();
        setModal({ ...modal, rp_update_modal: false });
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleDeleteRazorpay = async (keyData) => {
    if (window.confirm("Do you want delete this key?")) {
      try {
        const res = await removeServices(
          `/api/credential/delete?rp_id=${keyData.rp_id}`,
          access_token
        );
        if (res.responseCode === 200) {
          toast.success("Key deleted.");
          fetchPaymentGatewayList();
        } else {
          toast.error(res.responseMessage);
        }
      } catch (error) {
        toast.error(error);
      }
    }
  };

  const handleDeletePhonePe = async (keyData) => {
    if (window.confirm("Do you want delete this key?")) {
      try {
        const res = await removeServices(
          `/api/credential/delete?pp_id=${keyData.pp_id}`,
          access_token
        );
        if (res.responseCode === 200) {
          toast.success("Key deleted.");
          fetchPaymentGatewayList();
        } else {
          toast.error(res.responseMessage);
        }
      } catch (error) {
        toast.error(error);
      }
    }
  };

  return (
    <>
      {navigator.onLine ? (
        <Container>
          <Container className="flex flex-col sm:flex-row justify-between mx-8 py-5">
            <p className="text-lg sm:text-2xl font-semibold flex items-center gap-3">
              <IoIosArrowBack
                className="cursor-pointer"
                onClick={() => navigate(-1)}
              />
              Manage Payment Gateways
            </p>
          </Container>

          <Container className="mx-10 mt-5 sm:mt-0">
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleTabChange}
                  aria-label="lab API tabs example"
                  variant="scrollable"
                  sx={{
                    "& .MuiTab-root": {
                      fontFamily: "Poppins",
                    },
                    "& .MuiTab-root.Mui-selected": {
                      color: "#000",
                    },
                    "& .MuiTabs-indicator": {
                      backgroundColor: "#000",
                    },
                  }}
                >
                  <Tab label={"Razorpay"} value="rp" />
                  <Tab label={"PhonePe"} value="pp" />
                </TabList>
              </Box>
              <TabPanel value="rp" sx={{ padding: "24px 0" }}>
                {/* Razorpay */}
                <Container>
                  <Container className="flex justify-end items-center">
                    <Button
                      onClick={handleRazorpayAddModal}
                      className="bg-slate-800 flex items-center px-7 py-2 text-white font-semibold rounded-xl outline-none border-none active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out"
                    >
                      Add
                    </Button>
                  </Container>
                </Container>
                <Container className="grid grid-cols-1 md:grid-cols-2 mt-3 gap-3">
                  {data.razorpay.map((item, i) => (
                    <Container
                      key={i}
                      className="bg-white rounded-md p-2 flex flex-col gap-2 drop-shadow-md"
                    >
                      <Container
                        className={`flex ${
                          item.isdisabled === 1
                            ? "justify-between"
                            : "justify-end"
                        } items-center`}
                      >
                        {item.isdisabled === 1 && (
                          <p className="text-sm bg-green-200 text-green-700 px-2 rounded-sm">
                            Active
                          </p>
                        )}
                        <Container className="flex items-center gap-3">
                          <MdOutlineEdit
                            onClick={() => handleUpdateRazorpayModal(item)}
                            className="rounded-full w-5 h-5 cursor-pointer"
                          />
                          {item.isdisabled !== 1 && (
                            <MdOutlineDelete
                              onClick={() => handleDeleteRazorpay(item)}
                              className="h-5 w-5 rounded-full cursor-pointer"
                            />
                          )}
                        </Container>
                      </Container>
                      <p>
                        <span className="font-semibold">Key Id:</span>{" "}
                        {item.razorpaykey_id}
                      </p>
                    </Container>
                  ))}
                </Container>
              </TabPanel>
              <TabPanel value="pp" sx={{ padding: "24px 0" }}>
                {/* PhonePe */}
                <Container>
                  <Container className="flex justify-end items-center">
                    <Button
                      onClick={handlePhonePeAddModal}
                      className="bg-slate-800 flex items-center px-7 py-2 text-white font-semibold rounded-xl outline-none border-none active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out"
                    >
                      Add
                    </Button>
                  </Container>
                </Container>
                <Container className="grid grid-cols-1 md:grid-cols-2 mt-3 gap-3">
                  {data.phonepe.map((item, i) => (
                    <Container
                      key={i}
                      className="bg-white rounded-md p-2 flex flex-col gap-2 drop-shadow-md"
                    >
                      <Container
                        className={`flex ${
                          item.isdisabled === 1
                            ? "justify-between"
                            : "justify-end"
                        } items-center`}
                      >
                        {item.isdisabled === 1 && (
                          <p className="text-sm bg-green-200 text-green-700 px-2 rounded-sm">
                            Active
                          </p>
                        )}
                        <Container className="flex items-center gap-3">
                          <MdOutlineEdit
                            onClick={() => handleUpdatePhonePeModal(item)}
                            className="rounded-full w-5 h-5 cursor-pointer"
                          />
                          {item.isdisabled !== 1 && (
                            <MdOutlineDelete
                              onClick={() => handleDeletePhonePe(item)}
                              className="h-5 w-5 rounded-full cursor-pointer"
                            />
                          )}
                        </Container>
                      </Container>
                      <p>
                        <span className="font-semibold">Merchant UserId:</span>{" "}
                        {item.merchant_userid}
                      </p>
                      <p>
                        <span className="font-semibold">Merchant Id:</span>{" "}
                        {item.merchant_id}
                      </p>
                      <p>
                        <span className="font-semibold">
                          Merchant Mobile No.:
                        </span>{" "}
                        {item.merchant_mobno}
                      </p>
                    </Container>
                  ))}
                </Container>
              </TabPanel>
            </TabContext>
          </Container>
        </Container>
      ) : (
        <Container className="min-h-[65vh] flex flex-col justify-center items-center">
          <NoInternetComp />
        </Container>
      )}

      {/* razorpay */}
      <AdminPaymentGatewayModal
        open={modal.rp_add_modal}
        onClose={() => setModal({ ...modal, rp_add_modal: false })}
        modal={modal}
        handleRazorpayAddSubmit={handleRazorpayAddSubmit}
        fieldsValue={fieldsValue}
        setFieldsValue={setFieldsValue}
      />
      <AdminPaymentGatewayModal
        open={modal.rp_update_modal}
        onClose={() => setModal({ ...modal, rp_update_modal: false })}
        modal={modal}
        fieldsValue={fieldsValue}
        setFieldsValue={setFieldsValue}
        handleRazorpayUpdateSubmit={handleRazorpayUpdateSubmit}
      />

      {/* phonepe */}
      <AdminPaymentGatewayModal
        open={modal.pp_add_modal}
        onClose={() => setModal({ ...modal, pp_add_modal: false })}
        modal={modal}
        fieldsValue={fieldsValue}
        setFieldsValue={setFieldsValue}
        handlePhonePeAddSubmit={handlePhonePeAddSubmit}
      />
      <AdminPaymentGatewayModal
        open={modal.pp_update_modal}
        onClose={() => setModal({ ...modal, pp_update_modal: false })}
        modal={modal}
        fieldsValue={fieldsValue}
        setFieldsValue={setFieldsValue}
        handlePhonePeUpdateSubmit={handlePhonePeUpdateSubmit}
      />
    </>
  );
}

export default ManagePaymentsView;
