import React, { useState } from "react";
import classes from "./style.module.css";
import { LuEye, LuEyeOff } from "react-icons/lu";
import { mailPattern, nonstrongPwd } from "../../variables/constants";
import { Link, useNavigate } from "react-router-dom";
import { Button, Container, Form, Input } from "reactstrap";
import { forgotPasswordService, login } from "../../apiServices/authServices";
import CustomBottomSheet from "../common/Admin/common/CustomBottomSheet";
import PhoneInput from "react-phone-input-2";
import { isValidPhoneNumber } from "react-phone-number-input";
import toast from "react-hot-toast";

function LoginForm() {
  const [data, setData] = useState({ email: "", password: "" });
  const [isValid, setIsValid] = useState({
    isEmailValid: false,
    isPasswordValid: false,
  });
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const naviagte = useNavigate();
  const [isBottomNav, setIsBottomNav] = useState(false);
  const [forgotData, setForgotData] = useState({ forgot_phone: "" });

  const handleChange = (e) => {
    if (e.target.name === "email") {
      if (mailPattern.test(e.target.value)) {
        setIsValid({ ...isValid, isEmailValid: true });
      } else {
        setIsValid({ ...isValid, isEmailValid: false });
      }
    } else if (e.target.name === "password") {
      if (nonstrongPwd.test(e.target.value)) {
        setIsValid({ ...isValid, isPasswordValid: true });
      } else {
        setIsValid({ ...isValid, isPasswordValid: false });
      }
    }
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await login(data);
      if (res.responseCode === 200) {
        if (res.responseData.role === 2) {
          sessionStorage.setItem("access-token", res.responseData.access_token);
          naviagte("/admin/dashboard");
        } else if (res.responseData.role === 1) {
          localStorage.setItem(
            "user-access-token",
            res.responseData.access_token
          );
          naviagte("/");
        }
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleForgotPasswordSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await forgotPasswordService(forgotData);
      if (res.responseCode === 200) {
        naviagte("/forgot_password", {
          state: {
            forgot_phone: forgotData.forgot_phone,
            session_id: res.responseData.session_id,
          },
        });
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <Container className={`${classes.loginForm} overflow-hidden`}>
      <h2 className="text-4xl font-bold mb-6" style={{ color: "#1E2219" }}>
        Hello Again!
      </h2>
      <Form className="flex flex-col" onSubmit={handleSubmit}>
        <Container className="mb-6">
          <Input
            type="email"
            name="email"
            placeholder="Email *"
            onChange={handleChange}
            value={data.email}
            required
          />
          {!isValid.isEmailValid && data.email && (
            <p className={`${classes.errorMsg} mt-2 ml-4 text-rose-600`}>
              Please enter valid email address
            </p>
          )}
        </Container>
        <Container className="mb-2">
          <Container className="flex items-center">
            <Input
              type={isPasswordVisible ? "text" : "password"}
              name="password"
              placeholder="Password *"
              onChange={handleChange}
              value={data.password}
              required
            />
            {isPasswordVisible ? (
              <LuEye className=" -ml-7" onClick={togglePasswordVisibility} />
            ) : (
              <LuEyeOff className=" -ml-7" onClick={togglePasswordVisibility} />
            )}
          </Container>
          {!isValid.isPasswordValid && data.password && (
            <p className={`${classes.errorMsg} mt-2 ml-4 text-rose-600`}>
              Password must have atleast 1 uppercase,
              <br /> lowercase, number, special characters
              <br /> and minimum 8 characters.
            </p>
          )}
        </Container>
        <Container className="flex justify-end items-center mb-5 active:scale-[.99] active:duration-75 transition-all ease-in-out">
          <Button
            type="button"
            className="border-none outline-none"
            onClick={() => setIsBottomNav(true)}
          >
            Forgot password
          </Button>
        </Container>
        <Input
          type="submit"
          value="Login"
          className="active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out"
          disabled={
            !isValid.isEmailValid || !isValid.isPasswordValid ? true : false
          }
        />
      </Form>

      <Container className="flex justify-center my-4">
        <p>
          Don't have account?{" "}
          <span className="font-semibold cursor-pointer">
            <Link to="/register">Register</Link>
          </span>
          ,{" "}
          <span className="font-semibold cursor-pointer">
            <Link to="/">Guest</Link>
          </span>
        </p>
      </Container>

      {/* bottom sheet for forgot password  */}
      <CustomBottomSheet
        isBottomNav={isBottomNav}
        onClose={() => setIsBottomNav(false)}
      >
        <Container className="mx-10 my-10">
          <h2 className="font-bold text-xl lg:text-2xl mb-5">
            Forgot password
          </h2>
          <p className="mb-7">
            Enter your Phone number and we'll send a OTP on your phone number to
            reset your password
          </p>
          <Form
            className="flex flex-col justify-center items-center"
            onSubmit={handleForgotPasswordSubmit}
          >
            <Container className="mb-6 flex justify-center flex-col">
              <PhoneInput
                country={"in"}
                onChange={(e) =>
                  setForgotData({ ...forgotData, forgot_phone: "+" + e })
                }
                inputStyle={{
                  paddingTop: "1.8rem",
                  paddingBottom: "1.8rem",
                  height: "calc(1.5em + 1.25 rem+ 2px )",
                  width: "100%",
                  fontWeight: 400,
                  lineHeight: 1.5,
                  color: "#000",
                  backgroundClip: "padding-box",
                  borderRadius: "1rem",
                  border: "none",
                  fontFamily: "Poppins",
                  fontSize: "17px",
                }}
                inputClass={classes.phoneInput}
                dropdownStyle={{ fontFamily: "Poppins" }}
                buttonStyle={{
                  background: "#fff",
                  borderTopLeftRadius: "2rem",
                  borderBottomLeftRadius: "2rem",
                  borderLeft: "none",
                  borderTop: "none",
                  borderBottom: "none",
                  display: "block",
                }}
                searchStyle={{
                  width: "85%",
                }}
                containerStyle={{ marginBottom: "25px" }}
                enableSearch
                isValid={(value, country) => {
                  if (value === country.dialCode) {
                    return true;
                  }
                  return isValidPhoneNumber("+" + value);
                }}
                defaultErrorMessage="Please enter valid number"
              />
              <Input
                type="submit"
                value="Send OTP"
                className="active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out p-3 bg-[#352210] w-80 md:w-96 text-white text-xl font-semibold rounded-xl disabled:cursor-not-allowed disabled:bg-[#4d5157]"
                disabled={
                  !isValidPhoneNumber(forgotData.forgot_phone) ? true : false
                }
              />
            </Container>
          </Form>
        </Container>
      </CustomBottomSheet>
    </Container>
  );
}

export default LoginForm;
