import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Button, Container, Form } from "reactstrap";
import ReactSelect from "react-select";
import { TextField } from "@mui/material";

const options = [
  { value: "defective product", label: "Defective Products" },
  {
    value: "The product size is incorrect",
    label: "The product size is incorrect",
  },
];

function Modal(props) {
  const [selectedReturnOption, setSelectedReturnOption] = useState(null);

  const colorStyles = {
    control: (styles) => ({
      ...styles,
      //   backgroundColor: "#c5d3e380",
      border: "1px solid #352210",
      marginBottom: "0.75rem",
      marginTop: "0.25rem",
      outline: "none",
      padding: "0.5rem",
      boxShadow: "none",
      borderRadius: "10px",
      "&:hover": {
        border: "1px solid #352210",
      },
    }),
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    props.onClose();
  };

  return (
    <Transition.Root show={props.isModalShow} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[9999]"
        // initialFocus={cancelButtonRef}
        onClose={() => {}}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <Container
          className="fixed inset-0 z-10 overflow-y-auto mt-0"
          // onClick={onClose}
        >
          <div className="flex items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-[#fffaef] text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg">
                <Container className="flex justify-between items-center px-5">
                  <p className="font-semibold">Return Product</p>
                  <Dialog.Title
                    as="button"
                    onClick={props.onClose}
                    className="text-lg font-medium leading-6 text-gray-900 text-right py-5 outline-none"
                  >
                    <AiOutlineCloseCircle />
                  </Dialog.Title>
                </Container>
                <Form onSubmit={handleSubmit}>
                  <Container className="px-4 pb-4 flex flex-col gap-2">
                    <ReactSelect
                      options={options}
                      onChange={setSelectedReturnOption}
                      defaultValue={selectedReturnOption}
                      placeholder="Reason for Return"
                      styles={colorStyles}
                      className="w-full mr-4"
                      required
                    />
                    <p>Upload Image</p>
                    <input
                      type="file"
                      accept="image/png, image/jpg, image/jpeg, image/heic"
                    />
                    <p>Remarks</p>
                    <TextField
                      fullWidth
                      label="Sub Description"
                      id="fullWidth"
                      multiline
                      minRows={3}
                      maxRows={3}
                      sx={{
                        "& .MuiInputLabel-root": {
                          fontFamily: "Poppins",
                          zIndex: 0,
                        },
                        "& .MuiOutlinedInput-root": {
                          fontFamily: "Poppins",
                          borderRadius: "12px",
                        },
                        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#352210",
                          },
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#352210",
                          },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #352210",
                        },
                        "& .MuiInputLabel-root.Mui-focused": {
                          color: "#352210",
                        },
                      }}
                      // value={data.sub_desc}
                      // onChange={(e) =>
                      //   setData({ ...data, sub_desc: e.target.value })
                      // }
                      // inputProps={{ maxLength: characterLimit }}
                      required
                    />
                    <Button
                      type="submit"
                      className="bg-[#352210] py-3 mt-2 rounded-lg text-white capitalize font-semibold active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out"
                    >
                      Send return request
                    </Button>
                  </Container>
                </Form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Container>
      </Dialog>
    </Transition.Root>
  );
}

export default Modal;
