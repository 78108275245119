import React from "react";
import { Container } from "reactstrap";

function CheckConnection(props) {
  return (
    <>
      {navigator.onLine ? (
        props.children
      ) : (
        <Container
          className="overflow-hidden pointer-events-none"
          style={{ filter: "grayscale(1)" }}
        >
          {props.children}
        </Container>
      )}
    </>
  );
}

export default CheckConnection;
