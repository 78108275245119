import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { IoChevronBackOutline } from "react-icons/io5";
import { Button, Container, Table } from "reactstrap";
import SimpleBar from "simplebar-react";
import {
  getServices,
  postServices,
} from "../../../../../apiServices/apiServices";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingSpinner from "../../common/LoadingSpinner";
import {
  IconButton,
  Menu,
  MenuItem,
  Step,
  StepContent,
  StepLabel,
  Stepper,
} from "@mui/material";
import { MdOutlineMoreVert } from "react-icons/md";
import NoInternetComp from "../../../InternetConnetction/NoInternetComp";

function getSteps() {
  return ["Generate a AWB", "Request for Pickup", "Generate Manifest"];
}
function getMoreSteps() {
  return ["Generate Label", "Generate Invoice"];
}

function ShipRocketOrderDetails() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation().state;
  const [activeStep, setActiveStep] = useState();
  const [moreActiveStep, setMoreActiveStep] = useState();
  const steps = getSteps();
  const moreSteps = getMoreSteps();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (navigator.onLine) {
      fetchOrderDetails();
    }
  }, [navigator]);

  const fetchOrderDetails = async () => {
    setLoading(true);
    try {
      const res = await getServices(
        `/api/shiprocket/orders_details?order_id=${location.orderDetail.id}`,
        null,
        location.shipRocketAccessToken
      );
      setLoading(false);
      if (res.responseCode === 200) {
        setData(res.responseData.data);
        setActiveStep(
          res.responseData.data.status === "NEW"
            ? 0
            : res.responseData.data.status === "READY TO SHIP"
            ? 1
            : 2
        );
        setMoreActiveStep(
          res.responseData.data.status === "PICKUP SCHEDULED" ? 0 : 1
        );
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleBackNavigate = () => {
    navigate(-1);
  };

  const handleGenerateAwb = async () => {
    setBtnLoading(true);
    try {
      const res = await postServices(
        `/api/shiprocket/generate_awb`,
        null,
        {
          shipment_id: data.shipments?.id.toString(),
        },
        location.shipRocketAccessToken
      );
      setBtnLoading(false);
      if (res.responseCode === 200) {
        fetchOrderDetails();
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleRequesrForPickup = async () => {
    setBtnLoading(true);
    try {
      const res = await postServices(
        `/api/shiprocket/pickup_request`,
        null,
        {
          shipment_id: data.shipments?.id.toString(),
        },
        location.shipRocketAccessToken
      );
      setBtnLoading(false);
      if (res.responseCode === 200) {
        fetchOrderDetails();
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleGenerateManifest = async () => {
    setBtnLoading(true);
    try {
      const res = await postServices(
        `/api/shiprocket/generate_manifest`,
        null,
        { shipment_id: data.shipments?.id.toString() },
        location.shipRocketAccessToken
      );
      setBtnLoading(false);
      if (res.responseCode === 200) {
        window.open(res.responseData.manifest_url);
        fetchOrderDetails();
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleGenerateLabel = async () => {
    setBtnLoading(true);
    try {
      const res = await postServices(
        `/api/shiprocket/generate_label`,
        null,
        {
          shipment_id: data.shipments?.id.toString(),
        },
        location.shipRocketAccessToken
      );
      setBtnLoading(false);
      if (res.responseCode === 200) {
        window.open(res.responseData.label_url);
        setMoreActiveStep((prevMoreActiveStep) => prevMoreActiveStep + 1);
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleGenerateInvoice = async () => {
    setBtnLoading(true);
    try {
      const res = await postServices(
        `/api/shiprocket/generate_invoice`,
        null,
        {
          order_id: location.orderDetail.id,
        },
        location.shipRocketAccessToken
      );
      setBtnLoading(false);
      if (res.responseCode === 200) {
        window.open(res.responseData.invoice_url);
        setMoreActiveStep((prevMoreActiveStep) => prevMoreActiveStep - 1);
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleOrderTrack = () => {
    window.open(`https://shiprocket.co/tracking/${data.awb_data?.awb}`);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCancelOrder = async () => {
    setLoading(true);
    setAnchorEl(null);
    if (window.confirm("Are you sure you want to cancel this order?")) {
      try {
        const res = await postServices(
          `/api/shiprocket/cancel_order`,
          null,
          {
            order_id: data.shipments.order_id,
          },
          location.shipRocketAccessToken
        );
        setLoading(false);
        if (res.responseCode === 200) {
          fetchOrderDetails();
          toast.success("Order Cancelled...");
        } else {
          toast.error(res.responseMessage);
        }
      } catch (error) {
        toast.error(error);
      }
    } else {
      return;
    }
  };

  const handleCancelShipment = async () => {
    setAnchorEl(null);
    setLoading(true);
    if (window.confirm("Are you sure you want to cancel this shipment?")) {
      try {
        const res = await postServices(
          `/api/shiprocket/cancel_shipment`,
          null,
          { awb_code: data.awb_data?.awb },
          location.shipRocketAccessToken
        );
        setLoading(false);
        if (res.responseCode === 200) {
          fetchOrderDetails();
          toast.success("Shipment has been cancelled...");
        } else {
          toast.error(res.responseMessage);
        }
      } catch (error) {
        toast.error(error);
      }
    } else {
      return;
    }
  };

  return (
    <>
      {navigator.onLine ? (
        <Container>
          {loading ? (
            <Container className="flex justify-center items-center my-64">
              <LoadingSpinner />
            </Container>
          ) : (
            <Container className="mx-0 px-4 md:px-8 py-8">
              <Container className="flex justify-between items-center">
                <Container className="flex items-center justify-between w-full">
                  <Container className="flex gap-2 items-center">
                    <IoChevronBackOutline
                      onClick={handleBackNavigate}
                      className="text-2xl cursor-pointer"
                    />
                    <p className="text-lg sm:text-xl font-semibold">
                      #{location.orderDetail.channel_order_id}
                    </p>
                    <p className="text-xs text-[#008e27] bg-[#e2f5e8] px-3 py-1 rounded border-[0.5px] border-solid border-[#008e27]">
                      {data.status}
                    </p>
                  </Container>
                  {data.status !== "CANCELED" &&
                    data.status !== "CANCELLATION REQUESTED" && (
                      <Container>
                        <MdOutlineMoreVert
                          className="p-2 h-10 w-10 rounded-full mx-2 text-black bg-[#ffe1a1] cursor-pointer"
                          onClick={handleClick}
                          id="demo-positioned-button"
                          aria-controls={
                            open ? "demo-positioned-menu" : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                        />
                        <Menu
                          id="long-menu"
                          MenuListProps={{
                            "aria-labelledby": "long-button",
                          }}
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          PaperProps={{
                            style: {
                              maxHeight: 48 * 4.5,
                              width: "20ch",
                            },
                          }}
                        >
                          <MenuItem
                            selected={"Cancel Order" === "Pyxis"}
                            onClick={handleCancelOrder}
                            sx={{
                              fontFamily: "Poppins",
                              color: data.awb_data?.awb === "" ? "red" : "",
                            }}
                          >
                            Cancel Order
                          </MenuItem>
                          {data.awb_data?.awb !== "" && (
                            <MenuItem
                              selected={"Cancel Shipment" === "Pyxis"}
                              onClick={handleCancelShipment}
                              sx={{
                                fontFamily: "Poppins",
                                color: "red",
                              }}
                            >
                              Cancel Shipment
                            </MenuItem>
                          )}
                        </Menu>
                      </Container>
                    )}
                </Container>
              </Container>
              <Container className="grid md:grid-cols-3 gap-4 my-5">
                <Container className="md:col-span-2 h-fit">
                  <Container className="flex flex-col gap-2 bg-white p-2 rounded-md shadow mb-3">
                    <Container className="flex gap-3 items-center">
                      <img
                        src="https://app.shiprocket.in/seller//assets/images/icons/orderdetails.svg"
                        alt="navarang smaranika"
                      />
                      <p className="font-semibold">Order Details</p>
                    </Container>
                    <hr className="border border-[#f4f4f4]" />
                    <SimpleBar>
                      <Container>
                        <Container className="grid grid-cols-4 min-w-[640px] px-3 gap-5">
                          <Container className="col-span-1 flex items-center gap-4">
                            <Container className="flex flex-col gap-1">
                              <p className="text-[#888] text-xs">
                                Order created on channel
                              </p>
                              <p className="text-sm">
                                {data.channel_created_at}
                              </p>
                              <p className="text-[#888] text-xs mt-3">
                                Order created on system
                              </p>
                              <p className="text-sm">{data.created_at}</p>
                            </Container>
                          </Container>
                          <Container className="flex flex-col gap-1">
                            <p className="text-[#888] text-xs">Channels</p>
                            <Container className="flex items-center gap-2">
                              <img
                                src="https://app.shiprocket.in/seller//assets/images/orders/CS.png"
                                alt="navarang smaranika"
                                className="h-5 w-5"
                              />
                              <p className="text-sm">{data.channel_name}</p>
                            </Container>
                          </Container>
                          <Container className="flex flex-col gap-1">
                            <p className="text-[#888] text-xs">
                              Pickup Address
                            </p>
                            <p className="text-sm border-b border-dashed w-fit border-[#7f7f7f]">
                              {data.pickup_address?.pickup_code}
                            </p>
                          </Container>
                          <Container className="flex flex-col gap-1">
                            <p className="text-[#888] text-xs">Payment</p>
                            <p className="text-sm w-fit border-[#7f7f7f] flex gap-3 items-center">
                              ₹{data.total}{" "}
                              <span className="text-xs bg-[#e5e5e5] w-fit px-2 py-1 rounded-md text-[#008e27]">
                                {data.payment_method}
                              </span>
                            </p>
                          </Container>
                        </Container>
                      </Container>
                    </SimpleBar>
                  </Container>
                  {data.awb_data?.awb !== "" && (
                    <Container className="flex flex-col gap-2 bg-white p-2 rounded-md shadow mb-3">
                      <Container className="flex gap-3 items-center">
                        <img
                          src="https://app.shiprocket.in/seller//assets/images/icons/packagedetails.svg"
                          alt="navarang smaranika"
                        />
                        <p className="font-semibold">Shipping Details</p>
                      </Container>
                      <hr className="border border-[#f4f4f4]" />
                      <SimpleBar>
                        <Container>
                          <Container className="grid grid-cols-3 min-w-[640px] px-3 gap-5">
                            <Container className="col-span-1 flex items-center gap-4">
                              <Container className="flex flex-col gap-1">
                                <p className="text-[#888] text-xs">Courier</p>
                                <p className="text-sm">
                                  {data.shipments?.courier}
                                </p>
                              </Container>
                            </Container>
                            <Container className="flex flex-col gap-1">
                              <p className="text-[#888] text-xs">AWB No.</p>
                              <p className="text-sm">{data.shipments?.awb}</p>
                            </Container>
                            <Container className="flex flex-col gap-1">
                              <p className="text-[#888] text-xs">
                                E Way Bill No.
                              </p>
                              <p className="text-sm w-fit border-[#7f7f7f] flex gap-3 items-center">
                                {data.shipments?.eway_bill_number}
                              </p>
                            </Container>
                          </Container>
                        </Container>
                      </SimpleBar>
                    </Container>
                  )}
                  <Container className="flex flex-col gap-2 bg-white p-2 rounded-md shadow mb-3">
                    <Container className="flex gap-3 items-center">
                      <img
                        src="https://app.shiprocket.in/seller//assets/images/icons/packagedetails.svg"
                        alt="navarang smaranika"
                      />
                      <p className="font-semibold">Package Details</p>
                    </Container>
                    <hr className="border border-[#f4f4f4]" />
                    <SimpleBar>
                      <Container>
                        <Container className="grid grid-cols-4 min-w-[640px] px-3 gap-5">
                          <Container className="col-span-1 flex items-center gap-4">
                            <Container className="flex flex-col gap-1">
                              <p className="text-[#888] text-xs">
                                Dead Weight (in Kg)
                              </p>
                              <p className="text-sm">
                                {data.shipments?.weight}
                              </p>
                            </Container>
                          </Container>
                          <Container className="flex flex-col gap-1">
                            <p className="text-[#888] text-xs">Dimensions</p>
                            <p className="text-sm">
                              {data.shipments?.dimensions
                                .split("x")
                                .join(" x ")}
                            </p>
                          </Container>
                          <Container className="flex flex-col gap-1">
                            <p className="text-[#888] text-xs">
                              Volumetric Weight (in Kg)
                            </p>
                            <p className="text-sm w-fit">
                              {data.shipments?.volumetric_weight}
                            </p>
                          </Container>
                          <Container className="flex flex-col gap-1">
                            <p className="text-[#888] text-xs">
                              Applied Weight (in Kg)
                            </p>
                            <p className="text-sm w-fit border-[#7f7f7f] flex gap-3 items-center">
                              {data.shipments?.weight}
                            </p>
                          </Container>
                        </Container>
                      </Container>
                    </SimpleBar>
                  </Container>
                  <Container className="flex flex-col gap-2 bg-white p-2 rounded-md shadow mb-3">
                    <Container className="flex gap-3 items-center">
                      <img
                        src="https://app.shiprocket.in/seller//assets/images/icons/customerdetails.svg"
                        alt="navarang smaranika"
                      />
                      <p className="font-semibold">Customer Details</p>
                    </Container>
                    <hr className="border border-[#f4f4f4]" />
                    <SimpleBar>
                      <Container>
                        <Container className="grid grid-cols-4 min-w-[640px] px-3 gap-5">
                          <Container className="col-span-1 flex gap-4">
                            <Container className="flex flex-col gap-1">
                              <p className="text-[#888] text-xs">Name</p>
                              <p className="text-sm">{data.customer_name}</p>
                            </Container>
                          </Container>
                          <Container className="flex flex-col gap-1">
                            <p className="text-[#888] text-xs">Contact No.</p>
                            <p className="text-sm">{data.customer_phone}</p>
                          </Container>
                          <Container className="flex flex-col gap-1">
                            <p className="text-[#888] text-xs">Email</p>
                            <p className="text-sm w-fit">
                              {data.customer_email}
                            </p>
                          </Container>
                          <Container className="flex flex-col gap-1">
                            <p className="text-[#888] text-xs">Address</p>
                            <p className="text-sm w-fit border-[#7f7f7f] flex gap-3 items-center">
                              {data.customer_address}
                              <br />
                              {data.customer_city}, {data.customer_state} <br />
                              {data.customer_pincode}, {data.customer_country}
                            </p>
                          </Container>
                        </Container>
                      </Container>
                    </SimpleBar>
                  </Container>
                  <Container className="flex flex-col gap-2 bg-white p-2 rounded-md shadow mb-3">
                    <Container className="flex gap-3 items-center">
                      <img
                        src="https://app.shiprocket.in/seller//assets/images/icons/productdetails.svg"
                        alt="navarang smaranika"
                      />
                      <p className="font-semibold">Product Details</p>
                    </Container>
                    <hr className="border border-[#f4f4f4]" />
                    <SimpleBar>
                      <Container>
                        <Container className="grid grid-cols-1 min-w-[640px] px-3 gap-1">
                          <Table className="w-full">
                            <thead
                              className="text-left text-sm bg-[#fafafa] rounded-lg border-[#efefef]"
                              style={{
                                boxShadow: "0 1px 1px rgba(0,0,0,.102)",
                              }}
                            >
                              <tr>
                                <th className="p-2">Name</th>
                                <th>Category</th>
                                <th>SKU</th>
                                <th>Qty</th>
                                <th>Unit price</th>
                                <th>Total</th>
                              </tr>
                            </thead>
                            {data.products?.map((item, i) => (
                              <tbody key={i} className="text-sm">
                                <tr>
                                  <td className="p-2">{item.name}</td>
                                  <td>{item.channel_category}</td>
                                  <td>{item.sku}</td>
                                  <td>{item.quantity}</td>
                                  <td>₹{item.price}</td>
                                  <td>₹{item.net_total}.00</td>
                                </tr>
                              </tbody>
                            ))}
                          </Table>
                          <Container className="flex flex-col gap-4 items-end p-2">
                            <Container className="flex flex-row font-semibold">
                              <p className="mr-14">Order Total</p>
                              <p className="w-28 text-center">
                                ₹{data.total?.toLocaleString("en-in")}
                              </p>
                            </Container>
                          </Container>
                        </Container>
                      </Container>
                    </SimpleBar>
                  </Container>
                </Container>
                <Container className="flex flex-col gap-4">
                  <Container className="bg-white h-fit p-3 rounded-md shadow">
                    {(data.status === "NEW" ||
                      data.status === "READY TO SHIP" ||
                      ((data.status === "PICKUP SCHEDULED" ||
                        data.status === "OUT FOR PICKUP") &&
                        data.shipments?.manifest_id === "")) && (
                      <Container>
                        <Stepper activeStep={activeStep} orientation="vertical">
                          {steps.map((label, index) => (
                            <Step key={label}>
                              <StepLabel
                                sx={{
                                  "& .MuiStepLabel-label.Mui-active, & .MuiStepLabel-label, & .MuiStepIcon-text":
                                    {
                                      fontFamily: "Poppins",
                                    },
                                }}
                              >
                                {label}
                              </StepLabel>
                              <StepContent>
                                <div>
                                  <div>
                                    {btnLoading ? (
                                      <Button className="bg-[#8881a7] py-1 px-4 text-white rounded-lg cursor-not-allowed">
                                        Loading...
                                      </Button>
                                    ) : (
                                      <Button
                                        className="bg-[#745be7] py-1 px-4 text-white rounded-lg"
                                        onClick={
                                          activeStep === 0
                                            ? handleGenerateAwb
                                            : activeStep === 1
                                            ? handleRequesrForPickup
                                            : handleGenerateManifest
                                        }
                                      >
                                        {activeStep === 0
                                          ? "Generate AWB"
                                          : activeStep === 1
                                          ? "Request for Pickup"
                                          : "Generate Manifest"}
                                      </Button>
                                    )}
                                  </div>
                                </div>
                              </StepContent>
                            </Step>
                          ))}
                        </Stepper>
                      </Container>
                    )}

                    {(data.status === "PICKUP SCHEDULED" ||
                      data.status === "OUT FOR PICKUP" ||
                      data.status === "INVOICED") &&
                      data.shipments?.manifest_id !== "" && (
                        <Container>
                          <Stepper
                            activeStep={moreActiveStep}
                            orientation="vertical"
                          >
                            {moreSteps.map((label, index) => (
                              <Step key={label}>
                                <StepLabel
                                  sx={{
                                    "& .MuiStepLabel-label.Mui-active, & .MuiStepLabel-label, & .MuiStepIcon-text":
                                      {
                                        fontFamily: "Poppins",
                                      },
                                  }}
                                >
                                  {label}
                                </StepLabel>
                                <StepContent>
                                  <div>
                                    <div>
                                      {btnLoading ? (
                                        <Button className="bg-[#8881a7] py-1 px-4 text-white rounded-lg cursor-not-allowed">
                                          Loading...
                                        </Button>
                                      ) : (
                                        <Button
                                          className="bg-[#745be7] py-1 px-4 text-white rounded-lg"
                                          onClick={
                                            moreActiveStep === 0
                                              ? handleGenerateLabel
                                              : handleGenerateInvoice
                                          }
                                        >
                                          {moreActiveStep === 0
                                            ? "Generate Label"
                                            : "Generate Invoice"}
                                        </Button>
                                      )}
                                    </div>
                                  </div>
                                </StepContent>
                              </Step>
                            ))}
                          </Stepper>
                        </Container>
                      )}

                    {(data.status === "CANCELED" ||
                      data.status === "CANCELLATION REQUESTED" ||
                      data.status === "INVOICED") && (
                      <Container>
                        <p>No data found..!</p>
                      </Container>
                    )}
                  </Container>
                  {data.shipments?.manifest_id !== "" && (
                    <Container className="bg-white h-fit p-3 rounded-md shadow flex flex-col gap-3">
                      <Container className="flex justify-end">
                        <Button
                          className="text-sm text-[#745be7] underline"
                          onClick={handleOrderTrack}
                        >
                          View more
                        </Button>
                      </Container>
                      <iframe
                        src={`https://shiprocket.co/tracking/${data.awb_data?.awb}`}
                        className="w-full h-[470px]"
                        title="Track order"
                      />
                    </Container>
                  )}
                </Container>
              </Container>
            </Container>
          )}
        </Container>
      ) : (
        <Container className="min-h-[65vh] flex flex-col justify-center items-center">
          <NoInternetComp />
        </Container>
      )}
    </>
  );
}

export default ShipRocketOrderDetails;
