import React from "react";
import { Container } from "reactstrap";
import { Typography, Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";

function ContactUsPage() {
  return (
    <>
      <Container className="flex justify-center px-4 pt-2">
        <Container className="max-w-7xl w-full mb-3 flex flex-col gap-3">
          <Breadcrumbs
            separator=">"
            aria-label="breadcrumb"
            sx={{
              fontFamily: "Poppins",
            }}
          >
            <Link underline="hover" color="inherit" to="/profile">
              Profile
            </Link>
            <Typography
              color="text.primary"
              sx={{
                fontFamily: "Poppins",
              }}
            >
              Contact us
            </Typography>
          </Breadcrumbs>

          <h1 className="font-semibold text-2xl">Contact us</h1>
          <hr className="w-[5%] border-black border-2" />
          <p className="font-semibold text-xl text-gray-600">
            Last updated on Apr 1st 2024
          </p>
          <p>You may contact us using the information below:</p>
          <div>
            <p>Merchant Legal entity name: NAVARANG SMARANIKA</p>
            <p>
              Registered Address: 3-4/5, NAVARANG SMARANIKA Panemangaluru
              KARNATAKA 574231
            </p>
            <p>
              Operational Address: 3-4/5, NAVARANG SMARANIKA Panemangaluru
              KARNATAKA 574231
            </p>
            <p>Telephone No: 9343899767</p>
            <p>E-Mail ID: navarangsmaranika@gmail.com</p>
          </div>

          <p className="italic mt-4">
            Disclaimer: The above content is created at NAVARANG SMARANIKA's
            sole discretion.
          </p>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.484658569937!2d75.04543267491664!3d12.876526987430182!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba4a65bc5519659%3A0xeb3f1f797a9d1017!2sNavarang%20Smaranika!5e0!3m2!1sen!2sin!4v1719980937784!5m2!1sen!2sin"
            className="w-full h-96"
            allowFullScreen={true}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </Container>
      </Container>
    </>
  );
}

export default ContactUsPage;
