import React, { useState, useEffect } from "react";
import { HiPlus } from "react-icons/hi";
import { Button, Container } from "reactstrap";
import AddBannerModal from "../Modal/Banner/AddBannerModal";
import EditBannerModal from "../Modal/Banner/EditBannerModal";
import config from "../../../../config.json";
import "./style.css";
import BannerMoreButton from "../common/BannerMoreButton";
import DeleteModal from "../common/DeleteModal";
import {
  getServices,
  removeServices,
} from "../../../../apiServices/apiServices";
import toast from "react-hot-toast";
import NoInternetComp from "../../InternetConnetction/NoInternetComp";

function ManageBannerView() {
  const [isModalShow, setIsModalShow] = useState({
    addModal: false,
    editModal: false,
  });
  const [data, setData] = useState([]);
  const url = config.url;
  const access_token = sessionStorage.getItem("access-token");
  const [deleteModal, setDeleteModal] = useState({ isOpen: false, data: null });
  const [updateBannerData, setUpdateBannerData] = useState([]);

  useEffect(() => {
    if (navigator.onLine) {
      fetchBannerData();
    }
  }, [navigator]);

  const fetchBannerData = async () => {
    try {
      const res = await getServices(`/api/banner/list_banner`);
      if (res.responseCode === 200) {
        setData([...res.responseData]);
      } else if (res.responseCode === 206) {
        setData([]);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleAddModal = () => {
    setIsModalShow({ ...isModalShow, addModal: true });
  };

  const handleUpdateModal = (data) => {
    setIsModalShow({ ...isModalShow, editModal: true });
    setUpdateBannerData(data);
  };

  const handleDeleteModal = (data) => {
    setDeleteModal({ ...deleteModal, isOpen: !deleteModal.isOpen, data: data });
  };

  const handleDelete = async (id) => {
    try {
      const res = await removeServices(
        `/api/banner/delete_banner/${id}`,
        access_token
      );
      if (res.responseCode === 200) {
        toast.success("Banner deleted...");
        fetchBannerData();
        setDeleteModal({ ...deleteModal, isOpen: !deleteModal.isOpen });
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <>
      {navigator.onLine ? (
        <Container>
          <Container className="flex justify-between items-center mx-0 p-8">
            <p className="text-xl sm:text-2xl font-semibold">Manage Banner</p>
            <Button
              onClick={() => handleAddModal()}
              className="bg-slate-800 flex items-center px-2 sm:px-7 py-2 text-white font-semibold rounded-xl outline-none border-none active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out"
            >
              <HiPlus className="mr-2" /> Add Banner
            </Button>
          </Container>
          {data.length >= 1 ? (
            <Container className="mx-8 flex flex-wrap justify-center sm:justify-normal gap-6 mb-10">
              {data.map((item, i) => (
                <Container
                  key={i}
                  className="card-allignment relative shadow-lg rounded-lg"
                >
                  <img
                    src={url + item.image}
                    alt="banner"
                    className="h-[150px] object-cover rounded-lg"
                  />

                  <Container className="absolute top-3 right-0">
                    <BannerMoreButton
                      handleUpdate={() => handleUpdateModal(item)}
                      handleDelete={() => handleDeleteModal(item)}
                    />
                  </Container>
                </Container>
              ))}
            </Container>
          ) : (
            <Container className="flex justify-center font-medium">
              <p>No banners found...</p>
            </Container>
          )}

          <AddBannerModal
            isModalShow={isModalShow.addModal}
            onClose={() => setIsModalShow({ ...isModalShow, addModal: false })}
            fetchBannerData={fetchBannerData}
          />
          <EditBannerModal
            isModalShow={isModalShow.editModal}
            onClose={() => setIsModalShow({ ...isModalShow, editModal: false })}
            fetchBannerData={fetchBannerData}
            updateBannerData={updateBannerData}
          />
          <DeleteModal
            isOpen={deleteModal}
            // name={deleteModal?.data?.product_name}
            id={deleteModal?.data?.banner_id}
            handleDeleteModal={handleDeleteModal}
            title={"Banner"}
            handleDelete={handleDelete}
          />
        </Container>
      ) : (
        <Container className="min-h-[65vh] flex flex-col justify-center items-center">
          <NoInternetComp />
        </Container>
      )}
    </>
  );
}

export default ManageBannerView;
