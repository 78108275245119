import { TextField } from "@mui/material";
import React from "react";

function CustomInputField(props) {
  return (
    // <div>
    <TextField
      fullWidth
      // autoFocus
      name={props.name}
      label={props.label}
      type={props.type}
      id="fullWidth"
      sx={{
        "& .MuiInputLabel-root": {
          fontFamily: "Poppins",
          zIndex: 0,
        },
        "& .MuiOutlinedInput-root": {
          fontFamily: "Poppins",
          borderRadius: "12px",
        },
        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: "#352210",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
          {
            borderColor: "#352210",
          },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "1px solid #352210",
        },
        "& .MuiInputLabel-root.Mui-focused": {
          color: "#352210",
        },
      }}
      value={props.value}
      onChange={props.onChange}
      required={props.required}
      InputProps={{ inputProps: { min: props.min } }}
    />
    // </div>
  );
}

export default CustomInputField;
