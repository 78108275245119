import { Breadcrumbs, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";
import AboutImg from "../../components/assets/about_img.webp";

function AboutUsPage() {
  return (
    <>
      <Container className="flex justify-center px-4 pt-2">
        <Container className="max-w-7xl w-full mb-3 flex flex-col gap-3">
          <Breadcrumbs
            separator=">"
            aria-label="breadcrumb"
            sx={{
              fontFamily: "Poppins",
            }}
          >
            <Link underline="hover" color="inherit" to="/profile">
              Profile
            </Link>
            <Typography
              color="text.primary"
              sx={{
                fontFamily: "Poppins",
              }}
            >
              About us
            </Typography>
          </Breadcrumbs>

          <h1 className="font-semibold text-2xl">About us</h1>
          <hr className="w-[5%] border-black border-2" />
          <div className="flex flex-col md:flex-row items-center gap-4">
            <img src={AboutImg} alt="" className="md:w-1/2 rounded-md" />
            <p className="text-center">
              We, Navarang Smaranika, situated at panemangalore, B. C. Road,
              bantwal taluk, Mangalore, karnataka, are recognized as one of the
              best customised creative resin moulded manufacturing company. We
              design elegant, traditional and modern trophies made from a
              selection of resin moulded, that the clients can take their pick
              from. We are committed to deliver quality products to our
              customers with our creative approach in design and with the help
              of our dedicated and experienced team.
            </p>
          </div>
        </Container>
      </Container>
    </>
  );
}

export default AboutUsPage;
