import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container } from "reactstrap";
import Sidebar from "../../components/common/Admin/common/Sidebar";
import Navbar from "../../components/common/Admin/common/Navbar";
import { FaBars } from "react-icons/fa";
import ImageEditorView from "../../components/common/Admin/views/ImageEditorView";

const ImageEditor = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleCollapsedChange = () => {
    setCollapsed(!collapsed);
  };

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  useEffect(() => {
    if (!sessionStorage.getItem("access-token")) {
      navigate("/login");
    }
  }, []);

  return (
    <Container className={`app ${toggled ? "toggled" : ""} bg-zinc-100`}>
      <Sidebar
        collapsed={collapsed}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
        handleCollapsedChange={handleCollapsedChange}
      />
      <main>
        <Navbar>
          <FaBars
            className="btn-toggle"
            onClick={() => handleToggleSidebar(true)}
          />
        </Navbar>
        <ImageEditorView image={location.state?.img} />
      </main>
    </Container>
  );
};

export default ImageEditor;
