import { TextField } from "@mui/material";
import React from "react";

function CustomInput(props) {
  return (
    <>
      <TextField
        fullWidth
        name={props.name}
        label={props.label}
        // id="fullWidth"
        type={props.type}
        sx={{
          "& .MuiInputLabel-root": {
            fontFamily: "Poppins",
            zIndex: 0,
          },
          "& .MuiOutlinedInput-root": {
            fontFamily: "Poppins",
            borderRadius: "12px",
          },
          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#352210",
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderColor: "#352210",
            },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #352210",
          },
          "& .MuiInputLabel-root.Mui-focused": {
            color: "#352210",
          },
          backgroundColor: "white",
        }}
        required
        value={props.value}
        onChange={props.onChange}
        inputProps={{ maxLength: props.maxLength }}
      />
    </>
  );
}

export default CustomInput;
