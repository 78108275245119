import { Backdrop, Box, Fade, Modal, TextField } from "@mui/material";
import React from "react";
import { IoClose } from "react-icons/io5";
import { Button, Container, Form } from "reactstrap";
import { PiWarningFill } from "react-icons/pi";

function UserGSTModal(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    padding: "15px 20px",
    outline: "none",
    borderRadius: "15px",
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        // onClose={props.onClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={props.open}>
          <Box sx={style}>
            <div className="flex justify-between items-center mb-2">
              <p className="font-semibold">Your GST Information</p>
              <IoClose
                className="cursor-pointer border border-[#f46565] rounded-full"
                onClick={props.onClose}
              />
            </div>
            <Form
              onSubmit={props.handleGstDataSubmit}
              className="mt-3 w-[300px] sm:w-[400px] flex flex-col gap-3"
            >
              <Container className="flex flex-col gap-3">
                <TextField
                  fullWidth
                  name="gstin"
                  label="GSTIN"
                  type="text"
                  required={true}
                  value={props.gstData.gstin}
                  onChange={props.handleGstDataChange}
                  inputProps={{ maxLength: 15 }}
                  sx={{
                    "& .MuiInputLabel-root": {
                      fontFamily: "Poppins",
                    },
                    "& .MuiOutlinedInput-root": {
                      fontFamily: "Poppins",
                      borderRadius: "12px",
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#352210",
                      },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#352210",
                      },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #352210",
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#352210",
                    },
                    "& .MuiInputBase-input-MuiOutlinedInput-input, .MuiOutlinedInput-input":
                      {
                        textTransform: "uppercase",
                      },
                  }}
                />
                {/* <CustomInputField
                  name="business_name"
                  label="Business Name"
                  type="text"
                  required={true}
                  value={props.gstData.business_name}
                  onChange={props.handleGstDataChange}
                /> */}
                <Container className="flex items-center gap-2 pl-4 text-red-600">
                  <PiWarningFill className="text-base" />
                  <p className="text-xs">
                    Incorrect GSTIN details will lead to order cancellation.
                  </p>
                </Container>
              </Container>
              <Container className="flex justify-end items-center">
                {/* {loading ? (
                  <Button
                    type="button"
                    className="bg-slate-600 text-white text-lg font-semibold py-2 px-4 rounded-xl cursor-not-allowed"
                  >
                    Loading...
                  </Button>
                ) : ( */}
                <Button
                  type="submit"
                  className="active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out bg-slate-800 text-white text-lg font-semibold py-2 px-6 rounded-xl cursor-pointer"
                >
                  Save
                </Button>
                {/* )} */}
              </Container>
            </Form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default UserGSTModal;
