import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Badge, Box, Tab } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Container } from "reactstrap";
import OrdersAll from "../../tables/OrdersAll";
import { getServices } from "../../../../apiServices/apiServices";
import toast from "react-hot-toast";
import NoInternetComp from "../../InternetConnetction/NoInternetComp";

function OrdersView() {
  const [value, setValue] = useState("0");
  const [allOrders, setAllOrders] = useState([]);
  const [processingOrders, setProcessingOrders] = useState([]);
  const [shipRocketOrders, setShiRocketOrders] = useState([]);
  const [cancelledOrders, setCancelledOrders] = useState([]);
  const access_token = sessionStorage.getItem("access-token");

  useEffect(() => {
    if (navigator.onLine) {
      fetchAllOrdersData();
      // fetchPendingOrdersData();
      fetchProcessingOrdersData();
      fetchShipRocketOrdersData();
      fetchCancelledOrdersData();
    }
  }, [navigator]);

  const fetchAllOrdersData = async () => {
    try {
      const res = await getServices(
        `/api/orders/list_all_orders?filter_type=`,
        access_token
      );
      setAllOrders(res.responseData.rows);
    } catch (error) {
      toast.error(error);
    }
  };
  const fetchProcessingOrdersData = async () => {
    try {
      const res = await getServices(
        `/api/orders/list_all_orders?filter_type=2`,
        access_token
      );
      setProcessingOrders(res.responseData.rows);
    } catch (error) {
      toast.error(error);
    }
  };
  const fetchShipRocketOrdersData = async () => {
    try {
      const res = await getServices(
        `/api/orders/list_all_orders?filter_type=8`,
        access_token
      );
      setShiRocketOrders(res.responseData.rows);
    } catch (error) {
      toast.error(error);
    }
  };
  const fetchCancelledOrdersData = async () => {
    try {
      const res = await getServices(
        `/api/orders/list_all_orders?filter_type=6`,
        access_token
      );
      setCancelledOrders(res.responseData.rows);
    } catch (error) {
      toast.error(error);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {navigator.onLine ? (
        <Container>
          <Container className="flex justify-between items-center mx-0 px-4 md:px-8 py-8">
            <p className="text-xl sm:text-2xl font-semibold">Orders</p>
          </Container>
          <Container className="px-4 md:px-8">
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  variant="scrollable"
                  sx={{
                    "& .MuiTab-root": {
                      fontFamily: "Poppins",
                    },
                    "& .MuiTab-root.Mui-selected": {
                      color: "#000",
                    },
                    "& .MuiTabs-indicator": {
                      backgroundColor: "#000",
                    },
                  }}
                >
                  <Tab
                    label={
                      <Container className="flex">
                        <p>All</p>
                        {allOrders?.length > 0 && (
                          <Badge
                            badgeContent={allOrders.length}
                            color="secondary"
                            sx={{
                              position: "unset",
                              "& .MuiBadge-badge": {
                                position: "unset",
                                height: "unset",
                                transform: "unset",
                                margin: "0 0 0 8px",
                                borderRadius: "4px",
                                fontFamily: "Poppins",
                                backgroundColor: "#080808de",
                              },
                            }}
                          />
                        )}
                      </Container>
                    }
                    value="0"
                  />
                  <Tab
                    label={
                      <Container className="flex">
                        <p>Processing</p>
                        {processingOrders?.length > 0 && (
                          <Badge
                            badgeContent={processingOrders.length}
                            color="secondary"
                            sx={{
                              position: "unset",
                              "& .MuiBadge-badge": {
                                position: "unset",
                                height: "unset",
                                transform: "unset",
                                margin: "0 0 0 8px",
                                borderRadius: "4px",
                                fontFamily: "Poppins",
                                color: "#000000b3",
                                backgroundColor: "#fda693d6",
                              },
                            }}
                          />
                        )}
                      </Container>
                    }
                    value="2"
                  />
                  <Tab
                    label={
                      <Container className="flex">
                        <p>Initiated to ShipRocket</p>
                        {shipRocketOrders?.length > 0 && (
                          <Badge
                            badgeContent={shipRocketOrders.length}
                            color="secondary"
                            sx={{
                              position: "unset",
                              "& .MuiBadge-badge": {
                                position: "unset",
                                height: "unset",
                                transform: "unset",
                                margin: "0 0 0 8px",
                                borderRadius: "4px",
                                fontFamily: "Poppins",
                                backgroundColor: "#6149cd",
                              },
                            }}
                          />
                        )}
                      </Container>
                    }
                    value="8"
                  />
                  <Tab
                    label={
                      <Container className="flex">
                        <p>Cancelled</p>
                        {cancelledOrders?.length > 0 && (
                          <Badge
                            badgeContent={cancelledOrders.length}
                            color="secondary"
                            sx={{
                              position: "unset",
                              "& .MuiBadge-badge": {
                                position: "unset",
                                height: "unset",
                                transform: "unset",
                                margin: "0 0 0 8px",
                                borderRadius: "4px",
                                fontFamily: "Poppins",
                                backgroundColor: "#ffab0029",
                                color: "#b76e00",
                              },
                            }}
                          />
                        )}
                      </Container>
                    }
                    value="6"
                  />
                </TabList>
              </Box>
              <TabPanel value="0" sx={{ padding: "24px 0" }}>
                {allOrders?.length >= 1 ? (
                  <OrdersAll
                    data={allOrders}
                    fetchOrdersData={fetchAllOrdersData}
                    fetchProcessingOrdersData={fetchProcessingOrdersData}
                    fetchShipRocketOrdersData={fetchShipRocketOrdersData}
                  />
                ) : (
                  <Container className="text-center font-semibold">
                    <p>No data found!!!</p>
                  </Container>
                )}
              </TabPanel>
              <TabPanel value="2" sx={{ padding: "24px 0" }}>
                {processingOrders?.length >= 1 ? (
                  <OrdersAll
                    data={processingOrders}
                    fetchOrdersData={fetchAllOrdersData}
                    fetchProcessingOrdersData={fetchProcessingOrdersData}
                    fetchShipRocketOrdersData={fetchShipRocketOrdersData}
                  />
                ) : (
                  <Container className="text-center font-semibold">
                    <p>No data found!!!</p>
                  </Container>
                )}
              </TabPanel>
              <TabPanel value="8" sx={{ padding: "24px 0" }}>
                {shipRocketOrders?.length >= 1 ? (
                  <OrdersAll
                    data={shipRocketOrders}
                    fetchOrdersData={fetchAllOrdersData}
                    fetchProcessingOrdersData={fetchProcessingOrdersData}
                    fetchShipRocketOrdersData={fetchShipRocketOrdersData}
                  />
                ) : (
                  <Container className="text-center font-semibold">
                    <p>No data found!!!</p>
                  </Container>
                )}
              </TabPanel>
              <TabPanel value="6" sx={{ padding: "24px 0" }}>
                {cancelledOrders?.length >= 1 ? (
                  <OrdersAll
                    data={cancelledOrders}
                    fetchOrdersData={fetchAllOrdersData}
                    fetchProcessingOrdersData={fetchProcessingOrdersData}
                    fetchShipRocketOrdersData={fetchShipRocketOrdersData}
                  />
                ) : (
                  <Container className="text-center font-semibold">
                    <p>No data found!!!</p>
                  </Container>
                )}
              </TabPanel>
            </TabContext>
          </Container>
        </Container>
      ) : (
        <Container className="min-h-[65vh] flex flex-col justify-center items-center">
          <NoInternetComp />
        </Container>
      )}
    </>
  );
}

export default OrdersView;
