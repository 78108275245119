import React, { useEffect, useState, createContext } from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "reactstrap";
import UserNavbar from "../common/Admin/common/UserNavbar";
import UserFooter from "../common/Admin/common/UserFooter";
import { getServices, postServices } from "../../apiServices/apiServices";
import toast from "react-hot-toast";
import { FaWhatsapp } from "react-icons/fa";

export const GlobalContext = createContext();

function UserLayout(props) {
  const navigate = useNavigate();
  const access_token = localStorage.getItem("user-access-token");
  const ship_rocket_access_token = localStorage.getItem(
    "user-shiprocket-access-token"
  );
  const [newNotificationCount, setNewNotificationCount] = useState(0);
  const [cartCount, setCartCount] = useState(0);

  useEffect(() => {
    if (access_token && navigator.onLine) {
      fetchNotificationCount();
      fetchCartData();
      authShipRocket();
    }
  }, [navigate]);

  const fetchNotificationCount = async () => {
    try {
      const res = await getServices(
        `/api/notifications/notification_count`,
        access_token
      );
      if (res.responseCode === 200) {
        setNewNotificationCount(res.responseData.new_notification);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const authShipRocket = async () => {
    try {
      const res = await postServices(`/api/shiprocket/login`, access_token);
      localStorage.setItem(
        "user-shiprocket-access-token",
        res.responseData.token
      );
      fetchCartData(res.responseData.token);
    } catch (error) {
      toast.error(error);
    }
  };

  const fetchCartData = async (shipAccessToken) => {
    try {
      const res = await getServices(
        `/api/cart/list_cart`,
        access_token,
        shipAccessToken || ship_rocket_access_token
      );
      if (res.responseCode === 200) {
        setCartCount(res.responseData.count);
      } else if (res.responseCode === 402) {
        return;
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleWhatsappClick = () => {
    const url = `https://wa.me/9343899767`;
    window.open(url, "_blank");
  };

  return (
    <GlobalContext.Provider
      value={{
        newNotificationCount: newNotificationCount,
        fetchCartData: fetchCartData,
        cartCount: cartCount,
      }}
    >
      <Container>
        <UserNavbar />
        <Container className="mt-[81px] sm:mt-[97px]" />
        {props.children}
        <UserFooter />
        <Container className="h-16 sm:h-0 bg-[#352210]" />
        <Container className="fixed bottom-20 sm:bottom-3 right-3">
          <FaWhatsapp
            className="text-5xl sm:text-5xl bg-green-600 text-white p-2 cursor-pointer drop-shadow-lg rounded-full"
            title="WhatsApp Support"
            onClick={handleWhatsappClick}
          />
        </Container>
      </Container>
    </GlobalContext.Provider>
  );
}

export default UserLayout;
