import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Container } from "reactstrap";
import { FaGift } from "react-icons/fa";
import UserProductSliderCard from "../../components/common/Admin/UserProductSliderCard";
import { BiSolidCategoryAlt, BiSolidOffer } from "react-icons/bi";
import "./style.css";
import { useNavigate } from "react-router-dom";
import config from "../../config.json";
import LoadingSpinner from "../../components/common/Admin/common/LoadingSpinner";
import { getServices } from "../../apiServices/apiServices";
import toast from "react-hot-toast";
import NoInternetComp from "../../components/common/InternetConnetction/NoInternetComp";

function HomePage() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
  };

  const navigate = useNavigate();
  const [recommededData, setRecommededData] = useState([]);
  const [topOffersData, setTopOffersData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [bannerImgs, setBannerImgs] = useState([]);
  const url = config.url;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (navigator.onLine) {
      fetchRecommendedProducts();
      fetchCategoryList();
      fetchTopOffersProducts();
      fetchBannerList();
    }

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [navigator]);

  const fetchRecommendedProducts = async () => {
    setIsLoading(true);
    try {
      const res = await getServices(`/api/products/recommended`);
      setIsLoading(false);
      if (res.responseCode === 200) {
        setRecommededData(res.responseData);
      } else if (res.responseCode === 500) {
        return;
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error);
    }
  };

  const fetchTopOffersProducts = async () => {
    setIsLoading(true);
    try {
      const res = await getServices(`/api/products/offers`);
      setIsLoading(false);
      setTopOffersData(res.responseData);
    } catch (error) {
      setIsLoading(false);
      toast.error(error);
    }
  };

  const fetchCategoryList = async () => {
    setIsLoading(true);
    try {
      const res = await getServices(`/api/category/list_category`);
      setIsLoading(false);
      if (res.responseCode === 200) {
        setCategoryData(res.responseData);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error);
    }
  };

  const fetchBannerList = async () => {
    setIsLoading(true);
    try {
      const res = await getServices(`/api/banner/list_banner`);
      setIsLoading(false);
      if (res.responseCode === 200) {
        setBannerImgs(res.responseData);
      } else {
        setBannerImgs([]);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error);
    }
  };

  return (
    <>
      <Container className="pt-2">
        {navigator.onLine ? (
          <Container>
            {isLoading ? (
              <Container className="flex justify-center items-center my-[300px] sm:my-[370px]">
                <LoadingSpinner />
              </Container>
            ) : (
              <Container className="min-h-[55vh]">
                {bannerImgs.length > 0 && (
                  <Slider {...settings} className="w-full outline-none">
                    {bannerImgs.map((item, i) => (
                      <div key={i} className="outline-none">
                        <img
                          src={url + item.image}
                          alt="sliders"
                          className="outline-none rounded-[30px] px-2 object-cover w-full h-[25vh] sm:h-[35vh] md:h-[45vh] lg:h-[55vh] xl:h-[65vh]"
                          style={{ aspectRatio: "3700*1400" }}
                        />
                      </div>
                    ))}
                  </Slider>
                )}
                <Container className="mx-2 sm:mx-20">
                  {recommededData.length > 0 && (
                    <Container>
                      <Container className="flex items-center gap-5 font-semibold my-3">
                        <FaGift className="text-3xl text-[#352210]" />
                        <p>Recommeded for You</p>
                      </Container>
                      <UserProductSliderCard data={recommededData} />
                    </Container>
                  )}

                  {categoryData.length > 0 && (
                    <Container>
                      <Container className="flex items-center gap-5 font-semibold my-3">
                        <BiSolidCategoryAlt className="text-3xl text-[#352210]" />
                        <p>Categories</p>
                      </Container>
                      <Container
                        className={`cards mb-4 ${
                          categoryData.length >= 7 ? "justify-center" : ""
                        }`}
                      >
                        {categoryData.map((item, i) => (
                          <Container
                            key={i}
                            alt="categories"
                            className="card cursor-pointer"
                            onClick={() =>
                              navigate("/products_category", {
                                state: { id: item.category_id },
                              })
                            }
                          >
                            <img src={url + item.image} alt="category" />
                            <p
                              className="px-2 pb-1 text-sm font-medium capitalize"
                              style={{ overflowWrap: "anywhere" }}
                            >
                              {item.category_name}
                            </p>
                          </Container>
                        ))}
                      </Container>
                    </Container>
                  )}

                  {topOffersData.length > 0 && (
                    <Container className="pb-5">
                      <Container className="flex items-center gap-5 font-semibold my-3">
                        <BiSolidOffer className="text-3xl text-[#352210]" />
                        <p>Top Offers</p>
                      </Container>
                      <UserProductSliderCard data={topOffersData} />
                    </Container>
                  )}
                </Container>
              </Container>
            )}
          </Container>
        ) : (
          <Container className="min-h-[65vh] flex flex-col justify-center items-center">
            <NoInternetComp />
          </Container>
        )}
      </Container>
    </>
  );
}

export default HomePage;
