import React, { useContext } from "react";
import { Container } from "reactstrap";
import {
  MdOutlineNotificationsNone,
  MdOutlinePersonOutline,
  MdOutlinePhotoLibrary,
  MdOutlineShoppingCart,
} from "react-icons/md";
import { ImSearch } from "react-icons/im";
import { PiHeartBold } from "react-icons/pi";
import { BiHomeAlt } from "react-icons/bi";
import { Link, useLocation } from "react-router-dom";
import "./style.css";
import { GlobalContext } from "../../../layouts/UserLayout";
import SiteLogo from "../../../assets/site_logo.png";

function UserNavbar() {
  const global_context = useContext(GlobalContext);
  const location = useLocation();

  return (
    <>
      <Container className="block">
        <Container className="sm:h-24 h-20 bg-[#352210] fixed top-0 w-full z-[100] flex justify-between items-center px-5 md:px-10 text-white font-semibold text-lg rounded-b-3xl nav-bar">
          <Container className="flex items-center">
            <img src={SiteLogo} alt="site logo" width="150px" />
            {/* <p>Navarag Smaranika</p> */}
          </Container>
          <Container className={`nav-links`}>
            <ul className="flex flex-col sm:flex-row gap-5">
              <li>
                <Link
                  to="/"
                  className={`${
                    location.pathname === "/"
                      ? "border-b-[3px] border-white"
                      : ""
                  }`}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/favorite_products"
                  className={`${
                    location.pathname === "/favorite_products"
                      ? "border-b-[3px] border-white"
                      : ""
                  }`}
                >
                  Wishlist
                </Link>
              </li>
              <li className="relative">
                <Link
                  to="/notification"
                  className={`${
                    location.pathname === "/notification"
                      ? "border-b-[3px] border-white"
                      : ""
                  }`}
                >
                  Notifications
                </Link>
                {global_context.newNotificationCount > 0 && (
                  <p className="absolute -top-1 bg-red-600 py-[2px] px-[6px] text-xs rounded-full -right-3">
                    {global_context.newNotificationCount}
                  </p>
                )}
              </li>
              <li>
                <Link
                  to="/gallery"
                  className={`${
                    location.pathname === "/gallery"
                      ? "border-b-[3px] border-white"
                      : ""
                  }`}
                >
                  Gallery
                </Link>
              </li>
              <li>
                <Link
                  to="/about_us"
                  className={`${
                    location.pathname === "/about_us"
                      ? "border-b-[3px] border-white"
                      : ""
                  }`}
                >
                  About us
                </Link>
              </li>
            </ul>
          </Container>

          <Container className="flex items-center gap-5">
            <Link to="/cart" className="relative outline-none">
              <MdOutlineShoppingCart
                className="text-3xl cursor-pointer"
                title="Cart"
              />
              {global_context.cartCount > 0 && (
                <p className="absolute -top-1 bg-red-600 py-[2px] px-[6px] text-xs rounded-full -right-2">
                  {global_context.cartCount}
                </p>
              )}
            </Link>
            <Link to="/search" className="outline-none">
              <ImSearch className="text-2xl cursor-pointer" title="Search" />
            </Link>
            <Link to="/profile" className="hidden sm:block outline-none">
              <MdOutlinePersonOutline
                className="text-3xl cursor-pointer"
                title="Profile"
              />
            </Link>
          </Container>
        </Container>
      </Container>
      <Container className="flex justify-around items-center bg-[#352210] fixed bottom-0 w-full text-white font-semibold py-5 text-2xl sm:hidden rounded-t-2xl z-10">
        <Link to="/">
          <BiHomeAlt />
        </Link>
        <Link to="/favorite_products">
          <PiHeartBold />
        </Link>
        <Link to="/notification" className="relative">
          <MdOutlineNotificationsNone />
          {global_context.newNotificationCount > 0 && (
            <p className="absolute -top-1 bg-red-600 py-[1px] px-[5px] text-xs rounded-full -right-2">
              {global_context.newNotificationCount}
            </p>
          )}
        </Link>
        <Link to="/gallery">
          <MdOutlinePhotoLibrary />
        </Link>
        <Link to="/profile">
          <MdOutlinePersonOutline />
        </Link>
      </Container>
    </>
  );
}

export default UserNavbar;
