import React, { useEffect, useState } from "react";
import { Button, Container } from "reactstrap";
import "./style.css";
import { Link, useLocation } from "react-router-dom";
import config from "../../config.json";
import { IoMdHeart } from "react-icons/io";
import LoadingSpinner from "../../components/common/Admin/common/LoadingSpinner";
import VariationCustomBottomSheet from "../../components/common/Admin/common/VariationCustomBottomSheet";
import { getServices } from "../../apiServices/apiServices";
import { nNumber } from "../../variables/constants";
import toast from "react-hot-toast";
import NoInternetComp from "../../components/common/InternetConnetction/NoInternetComp";

function ProductsCategory() {
  const [selectedTab, setSelectedTab] = useState(1);
  const [selectedAllTab, setSelectedAllTab] = useState(0);
  const { id } = useLocation().state;
  const access_token = localStorage.getItem("user-access-token");
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [allCategoryProduct, setAllCategoryProduct] = useState([]);
  const [listSubCategoryProducts, setListSubCategoryProducts] = useState([]);
  const url = config.url;
  const [isLoading, setIsLoading] = useState(false);
  const [isBottomSheet, setIsBottomSheet] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    if (navigator.onLine) {
      fetchSubCategory();
      fetchProductByCategory();
    }

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [navigator]);

  const handleAllCategory = () => {
    setSelectedTab(0);
    setSelectedAllTab(0);
    fetchProductByCategory();
  };
  const handleTabClick = async (tabNumber) => {
    setSelectedTab(tabNumber);
    setSelectedAllTab(null);
    setIsLoading(true);

    try {
      const res = await getServices(
        `/api/products/list_by_subcat/${tabNumber}`
      );
      setIsLoading(false);
      if (res.responseCode === 200) {
        setListSubCategoryProducts(res.responseData[0]);
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error);
    }
  };

  const fetchProductByCategory = async () => {
    setIsLoading(true);
    try {
      const res = await getServices(
        `/api/products/list_by_category/${id}?limit=${nNumber}`
      );
      setIsLoading(false);
      if (res.responseCode === 200) {
        setAllCategoryProduct(res.responseData[0]);
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error);
    }
  };

  const fetchSubCategory = async () => {
    setIsLoading(true);
    try {
      const res = await getServices(
        `/api/subcat/list_subcat/${id}`,
        access_token
      );
      setIsLoading(false);
      if (res.responseCode === 200) {
        setSubCategoryData(res.responseData[0].sub_categories);
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error);
    }
  };

  const handleAddWishlistData = async (data) => {
    setIsBottomSheet(true);
    setData(data);
  };

  return (
    <>
      <Container className="pt-2">
        {navigator.onLine ? (
          <Container>
            <Container className="mx-2 sm:mx-20">
              {allCategoryProduct.length > 0 ? (
                <Container className="min-h-[65vh]">
                  {subCategoryData.length >= 1 ||
                  allCategoryProduct.length >= 1 ||
                  isLoading ? (
                    <Container className="flex gap-4 overflow-x-scroll subCategoryScroll">
                      <Button
                        className={`${
                          selectedAllTab === 0
                            ? "bg-[#352210] text-white"
                            : "bg-[#FFFAEF] text-black"
                        } px-4 py-2 border border-[#352210] rounded capitalize outline-none`}
                        onClick={handleAllCategory}
                      >
                        All
                      </Button>
                      {subCategoryData.map((item, i) => (
                        <Container key={i}>
                          <Button
                            className={`${
                              selectedTab === item.subcat_id
                                ? "bg-[#352210] text-white"
                                : "bg-[#FFFAEF] text-black"
                            } px-4 py-2 border border-[#352210] rounded capitalize outline-none whitespace-nowrap`}
                            onClick={() => handleTabClick(item.subcat_id)}
                          >
                            {item.subcat_name}
                          </Button>
                        </Container>
                      ))}
                    </Container>
                  ) : (
                    <Container className="text-center capitalize font-semibold text-lg mt-[370px]">
                      no products found..!
                    </Container>
                  )}
                  {isLoading ? (
                    <Container className="flex items-center justify-center my-52">
                      <LoadingSpinner />
                    </Container>
                  ) : (
                    <Container
                      className={`product-category-cards ${
                        selectedAllTab === 0 ? "my-4" : ""
                      }`}
                    >
                      {selectedAllTab === 0 &&
                        allCategoryProduct.map((item, i) => (
                          <Container
                            className="product-category-card relative"
                            key={i}
                          >
                            <Link
                              to="/product_details"
                              state={{ product_id: item.product_id }}
                            >
                              <img
                                src={
                                  url +
                                  item.productColorInfo[0].productDataInfo[0]
                                    .image[0]
                                }
                                alt="product with category all"
                                className="h-3/4 w-full"
                              />
                              <Container className="flex items-center justify-between px-4">
                                <Container>
                                  <p className="text-base font-semibold">
                                    {item.product_name.length >= 13
                                      ? item.product_name.substring(0, 12) +
                                        "..."
                                      : item.product_name}
                                  </p>
                                  <Container className="flex gap-4 text-sm sm:text-base">
                                    <p className="text-[#757575]">
                                      {
                                        item.productColorInfo[0]
                                          .productDataInfo[0].variation
                                      }{" "}
                                      inch
                                    </p>
                                    <p className="font-semibold">
                                      ₹{" "}
                                      {item.productColorInfo[0]
                                        .productDataInfo[0].sales_price <= 0
                                        ? item.productColorInfo[0]
                                            .productDataInfo[0].price
                                        : item.productColorInfo[0]
                                            .productDataInfo[0].sales_price}
                                    </p>
                                  </Container>
                                </Container>
                              </Container>
                            </Link>
                            <Container
                              className="cursor-pointer absolute bottom-6 right-3 sm:right-5"
                              onClick={() => handleAddWishlistData(item)}
                            >
                              <IoMdHeart className="text-white p-2 text-4xl bg-[#352210] rounded-full absolute bottom-0 right-0" />
                            </Container>
                          </Container>
                        ))}
                    </Container>
                  )}
                  {!isLoading && (
                    <Container className="my-4">
                      {subCategoryData.map((item, i) => (
                        <Container key={i} className="product-category-cards">
                          {selectedTab === item.subcat_id &&
                            listSubCategoryProducts.length >= 1 &&
                            listSubCategoryProducts.map((item, i) => (
                              <Container
                                className="product-category-card relative"
                                key={i}
                              >
                                <Link
                                  to="/product_details"
                                  state={{ product_id: item.product_id }}
                                >
                                  <img
                                    src={
                                      url +
                                      item.productColorInfo[0]
                                        .productDataInfo[0].image[0]
                                    }
                                    alt="product with category all"
                                  />
                                  <Container className="flex items-center justify-between px-4 pb-4">
                                    <Container>
                                      <p className="text-base font-semibold">
                                        {item.product_name.length >= 13
                                          ? item.product_name.substring(0, 12) +
                                            "..."
                                          : item.product_name}
                                      </p>
                                      <Container className="flex gap-4">
                                        <p className="text-[#757575]">
                                          {
                                            item.productColorInfo[0]
                                              .productDataInfo[0].variation
                                          }{" "}
                                          inch
                                        </p>
                                        <p className="font-semibold">
                                          ₹{" "}
                                          {item.productColorInfo[0]
                                            .productDataInfo[0].sales_price <= 0
                                            ? item.productColorInfo[0]
                                                .productDataInfo[0].price
                                            : item.productColorInfo[0]
                                                .productDataInfo[0].sales_price}
                                        </p>
                                      </Container>
                                    </Container>
                                  </Container>
                                </Link>
                                <Container
                                  className="cursor-pointer absolute bottom-6 right-3 sm:right-3"
                                  onClick={() => handleAddWishlistData(item)}
                                >
                                  <IoMdHeart className="text-white p-2 text-4xl bg-[#352210] rounded-full absolute bottom-0 right-0" />
                                </Container>
                              </Container>
                            ))}
                        </Container>
                      ))}
                    </Container>
                  )}

                  {subCategoryData.map((item, i) => (
                    <Container key={i}>
                      {selectedTab === item.subcat_id &&
                        listSubCategoryProducts.length <= 0 && (
                          <Container>
                            {isLoading ? null : (
                              <p className="capitalize text-center mt-24 font-semibold text-lg">
                                no products found..!
                              </p>
                            )}
                          </Container>
                        )}
                    </Container>
                  ))}
                </Container>
              ) : (
                <Container className="text-center capitalize font-semibold text-lg mt-[370px] mb-[300px]">
                  no products found..!
                </Container>
              )}
            </Container>

            <VariationCustomBottomSheet
              isBottomNav={isBottomSheet}
              setIsBottomNav={() => setIsBottomSheet(false)}
              data={data}
            />
          </Container>
        ) : (
          <Container className="min-h-[65vh] flex flex-col justify-center items-center">
            <NoInternetComp />
          </Container>
        )}
      </Container>
    </>
  );
}

export default ProductsCategory;
