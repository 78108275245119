import React, { useState } from "react";
import { LuEye, LuEyeOff } from "react-icons/lu";
import { Link, useNavigate } from "react-router-dom";
import { Container, Form, Input } from "reactstrap";
import classes from "./style.module.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Checkbox } from "@mui/material";
import { isValidPhoneNumber } from "react-phone-number-input";
import { mailPattern, nonstrongPwd } from "../../variables/constants";
import { phoneVerify } from "../../apiServices/authServices";
import toast from "react-hot-toast";

function RegisterForm() {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);
  const [data, setData] = useState({
    name: "",
    email: "",
    password: "",
    phone: "",
    confirmPassword: "",
  });
  const [isValid, setIsValid] = useState({
    isEmailValid: false,
    isPasswordValid: false,
    isConfirmPasswordValid: false,
  });
  const [isCheckboxClicked, setIsCheckboxClicked] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };
  const toggleConfirmPasswordVisibility = () => {
    setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
  };

  const handleChange = (e) => {
    if (e.target.name === "email") {
      if (mailPattern.test(e.target.value)) {
        setIsValid({ ...isValid, isEmailValid: true });
      } else {
        setIsValid({ ...isValid, isEmailValid: false });
      }
    } else if (e.target.name === "password") {
      if (nonstrongPwd.test(e.target.value)) {
        setIsValid({ ...isValid, isPasswordValid: true });
      } else {
        setIsValid({ ...isValid, isPasswordValid: false });
      }
    } else if (e.target.name === "confirmPassword") {
      if (
        data.password === e.target.value &&
        e.target.value === data.password
      ) {
        setIsValid({ ...isValid, isConfirmPasswordValid: true });
      } else {
        setIsValid({ ...isValid, isConfirmPasswordValid: false });
      }
    }
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (data.password !== data.confirmPassword) {
      toast.error("Passwords must be same.");
    } else {
      try {
        const res = await phoneVerify(data.phone);
        if (res.responseCode === 200) {
          navigate("/verification", {
            state: { data: data, session_id: res.responseData.session_id },
          });
        } else {
          toast.error(res.responseMessage);
        }
      } catch (error) {
        toast.error(error);
      }
    }
  };

  return (
    <Container className={`${classes.loginForm} overflow-hidden`}>
      <h2 className="text-4xl font-bold mb-6" style={{ color: "#1E2219" }}>
        Create Account.
      </h2>
      <Form className="flex flex-col" onSubmit={handleSubmit}>
        <Container className="mb-6">
          <Input
            type="text"
            name="name"
            placeholder="Name *"
            onChange={handleChange}
            value={data.name}
            required
          />
        </Container>
        <Container className="mb-6">
          <Input
            type="email"
            name="email"
            placeholder="Email *"
            onChange={handleChange}
            value={data.email}
            required
          />
          {!isValid.isEmailValid && data.email && (
            <p className={`${classes.errorMsg} mt-2 ml-4 text-rose-600`}>
              Please enter valid email address
            </p>
          )}
        </Container>
        <Container className="mb-6">
          <PhoneInput
            country={"in"}
            onChange={(e) => setData({ ...data, phone: "+" + e })}
            inputStyle={{
              paddingTop: "1.8rem",
              paddingBottom: "1.8rem",
              height: "calc(1.5em + 1.25 rem+ 2px )",
              width: "100%",
              fontWeight: 400,
              lineHeight: 1.5,
              color: "#000",
              backgroundClip: "padding-box",
              borderRadius: "1rem",
              border: "none",
              fontFamily: "Poppins",
              fontSize: "17px",
            }}
            inputClass={classes.phoneInput}
            dropdownStyle={{ fontFamily: "Poppins" }}
            buttonStyle={{
              background: "#fff",
              borderTopLeftRadius: "2rem",
              borderBottomLeftRadius: "2rem",
              borderLeft: "none",
              borderTop: "none",
              borderBottom: "none",
              display: "block",
            }}
            searchStyle={{
              width: "85%",
            }}
            enableSearch
            isValid={(value, country) => {
              if (value === country.dialCode) {
                return true;
              }
              return isValidPhoneNumber("+" + value);
            }}
            defaultErrorMessage="Please enter valid number"
          />
        </Container>
        <Container className="mb-6">
          <Container className="flex items-center">
            <Input
              type={isPasswordVisible ? "text" : "password"}
              name="password"
              placeholder="Password *"
              onChange={handleChange}
              value={data.password}
              required
            />
            {isPasswordVisible ? (
              <LuEye className="-ml-7" onClick={togglePasswordVisibility} />
            ) : (
              <LuEyeOff className="-ml-7" onClick={togglePasswordVisibility} />
            )}
          </Container>
          {!isValid.isPasswordValid && data.password && (
            <p className={`${classes.errorMsg} mt-2 ml-4 text-rose-600`}>
              Password must have atleast 1 uppercase,
              <br /> lowercase, number, special characters
              <br /> and minimum 8 characters.
            </p>
          )}
        </Container>
        <Container className="mb-2">
          <Container className="flex items-center">
            <Input
              type={isConfirmPasswordVisible ? "text" : "password"}
              name="confirmPassword"
              placeholder="Confirm Password *"
              onChange={handleChange}
              value={data.confirmPassword}
              required
            />
            {isConfirmPasswordVisible ? (
              <LuEye
                className="-ml-7"
                onClick={toggleConfirmPasswordVisibility}
              />
            ) : (
              <LuEyeOff
                className="-ml-7"
                onClick={toggleConfirmPasswordVisibility}
              />
            )}
          </Container>
          {!isValid.isConfirmPasswordValid && data.confirmPassword && (
            <p className={`${classes.errorMsg} mt-2 ml-4 text-rose-600`}>
              Passwords must be same.
            </p>
          )}
        </Container>
        <Container className="flex justify-start items-center mb-5">
          <Checkbox
            defaultChecked={false}
            sx={{
              color: "#352210",
              pointerEvents: "auto",
              "&.Mui-checked": {
                color: "#352210",
              },
            }}
            onChange={(e) => setIsCheckboxClicked(e.target.checked)}
            required
          />
          <p className="w-[255px] md:w-full">
            I accept all{" "}
            <span className="text-[#352210] font-semibold cursor-pointer">
              Terms of Services
            </span>{" "}
            &{" "}
            <span className="text-[#352210] font-semibold cursor-pointer">
              Privacy policy
            </span>
            *
          </p>
        </Container>
        <input
          type="submit"
          value="Register"
          className="active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out"
          disabled={
            !isValid.isEmailValid ||
            !isValid.isPasswordValid ||
            !isValidPhoneNumber(data.phone) ||
            !isValid.isConfirmPasswordValid ||
            !isCheckboxClicked
              ? true
              : false
          }
        />
      </Form>

      <Container className="flex justify-center my-4">
        <p>
          Already a user?{" "}
          <span className="font-semibold cursor-pointer">
            <Link to="/login">Login</Link>
          </span>
        </p>
      </Container>
    </Container>
  );
}

export default RegisterForm;
