import * as React from "react";
import { Box } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { TableHead } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { MdOutlineDelete, MdOutlineEdit } from "react-icons/md";
import { Container } from "reactstrap";
import { useState } from "react";
import SubCategoryEditModal from "../../common/Admin/Modal/SubCategory/SubCategoryEditModal";
import DeleteModal from "../Admin/common/DeleteModal";
import { removeServices } from "../../../apiServices/apiServices";
import toast from "react-hot-toast";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator() {
  return (a, b) => -descendingComparator(a, b);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "sub-category_name",
    numeric: false,
    disablePadding: true,
    label: "Sub Category Name",
  },
  {
    id: "parent_category_name",
    numeric: true,
    disablePadding: false,
    label: "Parent Category Name",
  },
  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    label: "Actions",
  },
];

function EnhancedTableHead() {
  return (
    <TableHead sx={{ backgroundColor: "#f7e1a3" }}>
      <TableRow>
        <TableCell padding="checkbox" />
        {headCells.map((headCell) => (
          <TableCell
            sx={{
              fontFamily: "poppins",
            }}
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            <TableSortLabel>{headCell.label}</TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function EnhancedTable({
  isSubCategoryData,
  parentCategoryName,
  handleViewSubCategory,
}) {
  const [order] = React.useState("asc");
  const [orderBy] = React.useState("id");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isModalShow, setIsModalShow] = useState({
    edit: false,
    delete: false,
  });
  const [isEditData, setIsEditData] = useState({ id: "", name: "" });
  const access_token = sessionStorage.getItem("access-token");
  const [deleteModal, setDeleteModal] = useState({ isOpen: false, data: null });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEditModal = (id, name) => {
    setIsModalShow({ ...isModalShow, edit: true });
    setIsEditData({ ...isEditData, id: id, name: name });
  };

  const handleDeleteModal = (data) => {
    setDeleteModal({ ...deleteModal, isOpen: !deleteModal.isOpen, data: data });
  };

  const handleDelete = async (id) => {
    try {
      const res = await removeServices(
        `/api/subcat/delete_subcat/${id}`,
        access_token
      );
      if (res.responseCode === 200) {
        toast.success("Sub-Category deleted...");
        setDeleteModal({ ...deleteModal, isOpen: !deleteModal.isOpen });
        handleViewSubCategory();
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      {isSubCategoryData ? (
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <EnhancedTableHead />
              <TableBody>
                {isSubCategoryData &&
                stableSort(isSubCategoryData, getComparator(order, orderBy)) &&
                Array.isArray(
                  stableSort(isSubCategoryData, getComparator(order, orderBy))
                )
                  ? stableSort(isSubCategoryData, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.subcat_id}
                            sx={{ cursor: "pointer" }}
                          >
                            <TableCell padding="checkbox" />
                            <TableCell
                              component="th"
                              scope="row"
                              padding="none"
                              sx={{
                                fontFamily: "poppins",
                              }}
                            >
                              {row.subcat_name}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontFamily: "poppins",
                              }}
                              align="right"
                            >
                              {parentCategoryName}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontFamily: "poppins",
                              }}
                              align="right"
                            >
                              <Container className="flex justify-end items-center">
                                <MdOutlineEdit
                                  className="p-2 h-9 w-9 rounded-full mx-2"
                                  style={{ backgroundColor: "#ffe1a1" }}
                                  onClick={() =>
                                    handleEditModal(
                                      row.subcat_id,
                                      row.subcat_name
                                    )
                                  }
                                />
                                <MdOutlineDelete
                                  className="p-2 h-9 w-9 rounded-full mx-2"
                                  style={{ backgroundColor: "#ffe1a1" }}
                                  onClick={() => handleDeleteModal(row)}
                                />
                              </Container>
                            </TableCell>
                          </TableRow>
                        );
                      })
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={isSubCategoryData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              "& .MuiTablePagination-selectLabel": {
                fontFamily: "Poppins",
              },
              "& .MuiTablePagination-select": {
                fontFamily: "Poppins",
              },
              "& .MuiTablePagination-displayedRows": {
                fontFamily: "Poppins",
              },
            }}
          />
        </Paper>
      ) : (
        <Container className="flex justify-center font-semibold">
          <p>No sub-categories found...</p>
        </Container>
      )}
      <SubCategoryEditModal
        isModalShow={isModalShow.edit}
        onClose={() => setIsModalShow({ ...isModalShow, edit: false })}
        handleViewSubCategory={handleViewSubCategory}
        isEditData={isEditData}
      />
      <DeleteModal
        isOpen={deleteModal}
        name={deleteModal?.data?.subcat_name}
        id={deleteModal?.data?.subcat_id}
        handleDeleteModal={handleDeleteModal}
        title={"Sub-Category"}
        handleDelete={handleDelete}
      />
    </Box>
  );
}
