import React, { useState, useEffect } from "react";
import { RiErrorWarningFill } from "react-icons/ri";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Container } from "reactstrap";
import config from "../../config.json";
import LoadingSpinner from "../../components/common/Admin/common/LoadingSpinner";
import { BiSolidBox } from "react-icons/bi";
import { FaFileInvoice, FaTruck } from "react-icons/fa";
import { BsFillGiftFill, BsFillPatchCheckFill } from "react-icons/bs";
import { MdCancel } from "react-icons/md";
import { FaRegMoneyBill1 } from "react-icons/fa6";
import AddRatingsModal from "../../components/common/Admin/Modal/Ratings/AddRatingsModal";
import RetrunProductModal from "../../components/common/Admin/Modal/ReturnProductModal";
import { getServices, postServices } from "../../apiServices/apiServices";
import toast from "react-hot-toast";
import NoInternetComp from "../../components/common/InternetConnetction/NoInternetComp";
import { IoIosArrowForward } from "react-icons/io";
import { Typography, Breadcrumbs } from "@mui/material";

function ManageOrderDetails() {
  const location = useLocation().state;
  const access_token = localStorage.getItem("user-access-token");
  const [data, setData] = useState([]);
  const url = config.url;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  //   const [modal, setModal] = useState({ addModal: false, editModal: false });
  const [modal, setModal] = useState(false);
  const [ratingsData, setRatingsData] = useState({
    data: "",
    title: "",
    cartData: {},
    review_id: "",
  });
  const [returnModal, setReturnModal] = useState(false);
  const [trackingData, setTrackingData] = useState({});
  const [shipRocketAccessToken, setShipRocketAccessToken] = useState("");

  useEffect(() => {
    if (navigator.onLine) {
      fetchOrderDetails();
      // authShipRocket();
    }

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [navigator]);

  const fetchOrderDetails = async () => {
    setLoading(true);
    try {
      const res = await getServices(
        `/api/orders/order_details/${location.track_id}`,
        access_token
      );
      setLoading(false);
      if (res.responseCode === 200) {
        setData(res.responseData);
        authShipRocket(res.responseData[0].order_details.awb_code);
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  const authShipRocket = async (awb_code) => {
    setLoading(true);
    try {
      const res = await postServices(`/api/shiprocket/login`, access_token);
      setLoading(false);
      if (res.responseCode === 200) {
        fetchTrackOrder(res.responseData.token, awb_code);
        setShipRocketAccessToken(res.responseData.token);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const fetchTrackOrder = async (token, awb_code) => {
    try {
      const res = await getServices(
        `/api/shiprocket/track?awb_code=${awb_code}`,
        null,
        token
      );
      if (res.responseCode === 200) {
        setTrackingData(res.responseData.tracking_data);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleNavigatetoProductDetails = (data) => {
    navigate("/product_details", {
      state: { product_id: data.cartInfo.productInfo.product_id },
    });
  };

  const handleAddRatings = async (data, i) => {
    try {
      const res = await getServices(
        `/api/review/isrivewed/${data.data[0].cartInfo?.productInfo?.product_id}`,
        access_token
      );
      if (res.responseCode === 200) {
        setModal(true);
        setRatingsData({
          ...ratingsData,
          data: res.responseData.reviews,
          title: "Update Review",
          cartData: data,
          review_id: res.responseData.reviews[0].review_id,
        });
      } else if (res.responseCode === 206) {
        setModal(true);
        setRatingsData({
          ...ratingsData,
          data: [],
          title: "Add Review",
          cartData: data,
          productInfo: data.data[i].cartInfo.productInfo,
        });
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleTrackOrder = () => {
    window.open(
      `https://shiprocket.co/tracking/${data[0]?.order_details?.awb_code}`
    );
  };

  const handleReturnProduct = () => {
    setReturnModal(true);
  };

  const handleDownloadInvoice = async () => {
    try {
      const res = await postServices(
        `/api/shiprocket/generate_invoice`,
        null,
        {
          order_id: data[0]?.order_details?.shiprocket_order_id,
        },
        shipRocketAccessToken
      );
      if (res.responseCode === 200) {
        window.open(res.responseData.invoice_url);
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <>
      {navigator.onLine ? (
        <Container>
          {loading ? (
            <Container className="flex items-center justify-center my-80">
              <LoadingSpinner />
            </Container>
          ) : (
            <>
              <Container className="flex justify-center">
                <Container className="w-full sm:w-[70vw] py-2 px-3 sm:px-0">
                  <Breadcrumbs
                    separator=">"
                    aria-label="breadcrumb"
                    sx={{
                      fontFamily: "Poppins",
                    }}
                  >
                    <Link underline="hover" color="inherit" to="/profile">
                      Profile
                    </Link>
                    <Link underline="hover" color="inherit" to="/manage_orders">
                      My Orders
                    </Link>
                    <Typography
                      color="text.primary"
                      sx={{
                        fontFamily: "Poppins",
                      }}
                    >
                      {data[0]?.order_details?.track_id}
                    </Typography>
                  </Breadcrumbs>
                </Container>
              </Container>
              {data.length > 0 ? (
                <Container className="flex justify-center flex-col gap-3 items-center px-2 sm:px-0 mb-4">
                  {data.map((item, i) => (
                    <Container className="w-full sm:w-[70vw]" key={i}>
                      <Container className="flex gap-4 text-2xl">
                        <BiSolidBox />
                        <Container className="text-gray-500 text-sm sm:text-base">
                          <p className="text-black font-semibold">
                            {trackingData?.track_status > 0
                              ? trackingData?.shipment_track[0]?.current_status
                              : data[0].order_details.orderStatus === 2
                              ? "Proccessing"
                              : data[0].order_details.orderStatus === 6
                              ? "Cancelled"
                              : data[0].order_details.orderStatus === 8
                              ? "Order Confirmed"
                              : "Order Confirmed"}
                          </p>
                          <p>Order ID - {item.order_details.track_id}</p>
                          <p>
                            Ordered Date -{" "}
                            {item.order_details.orderd_date +
                              " " +
                              item.order_details.orderd_time}
                          </p>
                          <p>
                            {/* Estimated Date of Delivery - in{" "}
                            {item.order_details.estd_del_days} days */}
                            Estimated Delivery time is between 10-15 working
                            days
                          </p>
                          {data[0]?.order_details?.awb_code !== null && (
                            <Button
                              onClick={handleTrackOrder}
                              className="bg-[#352210] mt-2 text-white px-4 py-1 rounded outline-none font-semibold active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out"
                            >
                              Track Order
                            </Button>
                          )}
                        </Container>
                      </Container>
                      {data[0]?.order_details?.shiprocket_order_id !== null && (
                        <Container
                          onClick={handleDownloadInvoice}
                          className="bg-[#fffaef] mt-4 p-2 sm:p-4 font-semibold text-[#352210] flex justify-between items-center cursor-pointer"
                        >
                          <Container className="flex items-center gap-2">
                            <FaFileInvoice className="text-lg" />
                            <p>Download Invoice</p>
                          </Container>
                          <IoIosArrowForward className="text-xl" />
                        </Container>
                      )}
                      <Container className="flex flex-col gap-4 my-4">
                        {item.data.map((item2, i) => (
                          <Container
                            className="bg-[#fffaef] p-2 sm:p-4"
                            key={i}
                          >
                            <Container
                              className="flex items-center gap-4 cursor-pointer"
                              onClick={() =>
                                handleNavigatetoProductDetails(item2)
                              }
                            >
                              <img
                                src={
                                  url +
                                  item2.cartInfo.productInfo.variationInfo
                                    .image[0]
                                }
                                alt="Ordered product preview"
                                className="w-20 sm:w-32 h-20 sm:h-32 object-cover"
                              />
                              <Container className="text-sm sm:text-base flex flex-col gap-1">
                                <p className="font-semibold">
                                  {item2.cartInfo.productInfo.product_name}
                                </p>
                                <p className="text-gray-500 text-sm">
                                  {item2.cartInfo.productInfo.colorInfo.color}
                                </p>
                                <p className="text-gray-500 text-sm">
                                  {
                                    item2.cartInfo.productInfo.variationInfo
                                      .variation
                                  }{" "}
                                  inch
                                </p>
                                <Container className="flex justify-between gap-10">
                                  <p className="font-semibold">
                                    ₹{item2.cartInfo.totalAmount}
                                  </p>
                                  <p className="text-gray-500">
                                    Quantity: {item2.cartInfo.product_qty}
                                  </p>
                                </Container>
                              </Container>
                            </Container>
                            <Container className="flex justify-between">
                              <p
                                className="mt-3 font-semibold text-sm sm:text-base cursor-pointer"
                                onClick={() => handleAddRatings(item, i)}
                              >
                                Rate and Review the Product
                              </p>
                              {item.order_details?.orderStatus === 5 && (
                                <p
                                  className="mt-3 font-semibold text-sm sm:text-base cursor-pointer"
                                  onClick={() => handleReturnProduct()}
                                >
                                  Return Product
                                </p>
                              )}
                            </Container>
                          </Container>
                        ))}
                        <p className="font-medium text-sm sm:text-base">
                          Total Amount Paid: ₹{item.order_details.totalAmount}
                        </p>
                        <Container>
                          <p className="text-sm sm:text-base font-semibold mb-3">
                            Shipping Address
                          </p>
                          <Container className="flex justify-between flex-col gap-2 bg-[#fffaef] p-2 sm:p-4">
                            <Container className="flex justify-between items-center text-sm sm:text-base">
                              <p className="font-semibold capitalize">
                                {item.order_details.addressInfo.name}
                              </p>
                              <p className="px-3 py-1 border-2 border-[#352210] rounded-full uppercase text-xs sm:text-sm">
                                {item.order_details.addressInfo.save_address}
                              </p>
                            </Container>
                            <Container className="capitalize text-slate-500 font-medium text-sm sm:text-base">
                              <p>{item.order_details.addressInfo.area}</p>
                              <p>{item.order_details.addressInfo.town}</p>
                              <p>
                                {item.order_details.addressInfo.city},{" "}
                                {item.order_details.addressInfo.state} -{" "}
                                {item.order_details.addressInfo.pincode}
                              </p>
                              <Container className="flex justify-between items-center">
                                <p>
                                  Mobile:{" "}
                                  <span className="text-black">
                                    +91{" "}
                                    {item.order_details.addressInfo.phone_no}
                                  </span>
                                </p>
                              </Container>
                              {item.order_details.cust_gstin && (
                                <Container className="flex justify-between items-center">
                                  <p>
                                    GSTIN:{" "}
                                    <span className="text-black">
                                      {item.order_details.cust_gstin}
                                    </span>
                                  </p>
                                </Container>
                              )}
                              {item.order_details.cust_business_name && (
                                <Container className="flex justify-between items-center">
                                  <p>
                                    Business Name:{" "}
                                    <span className="text-black">
                                      {item.order_details.cust_business_name}
                                    </span>
                                  </p>
                                </Container>
                              )}
                            </Container>
                          </Container>
                        </Container>
                      </Container>
                    </Container>
                  ))}
                </Container>
              ) : (
                <Container className="flex justify-center items-center h-[65vh]">
                  <p className="font-semibold text-lg">No Data found...</p>
                </Container>
              )}
            </>
          )}

          <AddRatingsModal
            isModalShow={modal}
            ratingsData={ratingsData}
            onClose={() => setModal(false)}
          />
          <RetrunProductModal
            isModalShow={returnModal}
            onClose={() => setReturnModal(false)}
          />
        </Container>
      ) : (
        <Container className="min-h-[65vh] flex flex-col justify-center items-center">
          <NoInternetComp />
        </Container>
      )}
    </>
  );
}

export default ManageOrderDetails;
