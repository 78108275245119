import React, { useState } from "react";
import { Button, Container, Form } from "reactstrap";
import CustomInput from "../../components/common/Admin/common/CustomInput";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { updateServices } from "../../apiServices/apiServices";
import toast from "react-hot-toast";
import axios from "axios";
import ReactSelect from "react-select";
import { useEffect } from "react";
import NoInternetComp from "../../components/common/InternetConnetction/NoInternetComp";

function EditAddressPage() {
  const { editData, is_cart } = useLocation().state;
  const [data, setData] = useState({
    name: editData.name,
    phone_no: editData.phone_no,
    area: editData.area,
    town: editData.town,
    city: editData.city,
    state: editData.state,
    pincode: editData.pincode,
    save_address: editData.save_address_as,
  });
  const access_token = localStorage.getItem("user-access-token");
  const navigate = useNavigate();
  const [pinCodeData, setPinCodeData] = useState([]);
  const [selectedCity, setSelectedCity] = useState({});
  const [valid, setValid] = useState({ pincode: false });

  const colorStyles = {
    control: (styles) => ({
      ...styles,
      border: "1px solid #352210",
      marginBottom: "0",
      marginTop: "0",
      outline: "none",
      padding: "0.5rem",
      borderRadius: "10px",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #352210",
      },
    }),
  };

  useEffect(() => {
    if (navigator.onLine) {
      fetchPincode(editData.pincode);
    }
  }, [navigator]);

  const handlePincodeChange = async (e) => {
    setData({ ...data, pincode: e.target.value, city: "", state: "" });
    setPinCodeData([]);
    setSelectedCity({});
    if (e.target.value.length >= 6) {
      // setLoading(true);
      setValid({ ...valid, pincode: false });
      fetchPincode(e.target.value);
    } else {
      setValid({ ...valid, pincode: true });
    }
  };

  const fetchPincode = async (value) => {
    try {
      const res = await axios.get(
        `https://api.postalpincode.in/pincode/${value}`
      );
      // setLoading(false);
      if (res.data[0].PostOffice !== null) {
        setData({
          ...data,
          city: res.data[0].PostOffice[0].District,
          state: res.data[0].PostOffice[0].State,
          pincode: res.data[0].PostOffice[0].Pincode,
        });
        setPinCodeData(res.data[0].PostOffice);
      } else {
        setData({
          ...data,
          city: "",
          state: "",
          pincode: value,
        });
        setPinCodeData([]);
        setSelectedCity([]);
        setValid({ ...valid, pincode: true });
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await updateServices(
        `/api/address/update_address/${editData.address_id}`,
        access_token,
        {
          name: data.name,
          phone_no: data.phone_no,
          pincode: data.pincode,
          area: data.area,
          town: selectedCity.label || data.town,
          city: data.city,
          state: data.state,
          save_address: data.save_address,
        }
      );
      if (res.responseCode === 200) {
        navigate("/manage_address", { state: { is_cart: is_cart } });
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <>
      <Container className="pt-2">
        {navigator.onLine ? (
          <Container className="w-full sm:w-3/4 flex flex-col mx-auto mb-4 px-2 sm:px-0">
            <Form onSubmit={handleSubmit}>
              <p className="font-semibold capitalize">Contact details</p>
              <Container className="flex flex-col sm:flex-row w-full gap-4 bg-[#FFFAEF] mt-2 py-5 px-2 rounded-lg">
                <CustomInput
                  label="Name"
                  type="text"
                  name="name"
                  value={data.name}
                  onChange={(e) => setData({ ...data, name: e.target.value })}
                />
                <CustomInput
                  label="Mobile no."
                  type="tel"
                  name="mobile"
                  value={data.phone_no}
                  onChange={(e) =>
                    setData({ ...data, phone_no: e.target.value })
                  }
                  maxLength={10}
                />
              </Container>
              <p className="capitalize mt-5 font-semibold">Address</p>
              <Container className="flex flex-col sm:flex-row w-full gap-4 bg-[#FFFAEF] mt-2 py-5 px-2 rounded-t-lg rounded-l-lg">
                <CustomInput
                  label="Pin Code"
                  type="tel"
                  name="pincode"
                  value={data.pincode}
                  onChange={handlePincodeChange}
                  // onChange={(e) => setData({ ...data, pincode: e.target.value })}
                  maxLength={6}
                />
                <CustomInput
                  label="Address (House no, Building, Street, Area)"
                  type="text"
                  name="address"
                  value={data.area}
                  onChange={(e) => setData({ ...data, area: e.target.value })}
                />
              </Container>
              <Container className="flex flex-row w-full gap-4 bg-[#FFFAEF] pb-5 px-2 ">
                <ReactSelect
                  options={pinCodeData.map((category) => {
                    return {
                      value: category.Name,
                      label: category.Name,
                    };
                  })}
                  onChange={(e) => setSelectedCity(e)}
                  placeholder="Locality/Town *"
                  styles={colorStyles}
                  className="w-full m-0"
                  defaultInputValue={data.town}
                  // required
                />
                <CustomInput
                  label="City/District"
                  type="text"
                  name="city"
                  value={data.city}
                  // onChange={(e) => setData({ ...data, city: e.target.value })}
                />
              </Container>
              <Container className="bg-[#FFFAEF] pb-5 px-2 rounded-b-lg w-full sm:w-1/2">
                <CustomInput
                  label="State"
                  type="text"
                  name="state"
                  value={data.state}
                  // onChange={(e) => setData({ ...data, state: e.target.value })}
                />
              </Container>
              <p className="capitalize mt-5 font-semibold">Save Address As</p>
              <Container className="flex gap-5 mt-2 mb-4 px-2">
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={data.save_address}
                    onChange={(e) =>
                      setData({ ...data, save_address: e.target.value })
                    }
                  >
                    <FormControlLabel
                      value="home"
                      control={
                        <Radio
                          sx={{
                            "&, &.Mui-checked": {
                              color: "#352210",
                            },
                          }}
                        />
                      }
                      label="Home"
                      sx={{
                        ".MuiFormControlLabel-root .MuiFormControlLabel-label, .MuiTypography-root":
                          { fontFamily: "Poppins" },
                      }}
                    />
                    <FormControlLabel
                      value="office"
                      control={
                        <Radio
                          sx={{
                            "&, &.Mui-checked": {
                              color: "#352210",
                            },
                          }}
                        />
                      }
                      label="Office"
                      sx={{
                        ".MuiFormControlLabel-root .MuiFormControlLabel-label, .MuiTypography-root":
                          { fontFamily: "Poppins" },
                      }}
                    />
                  </RadioGroup>
                </FormControl>
              </Container>
              <Container className="flex justify-center">
                <Button
                  type="submit"
                  className="w-full sm:w-1/2 bg-[#352210] outline-none py-3 text-white font-semibold active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out rounded-xl disabled:cursor-not-allowed disabled:bg-gray-500"
                  disabled={
                    data.phone_no.length === 10 &&
                    data.pincode.length === 6 &&
                    !valid.pincode
                      ? false
                      : true
                  }
                >
                  Save Address
                </Button>
              </Container>
            </Form>
          </Container>
        ) : (
          <Container className="min-h-[65vh] flex flex-col justify-center items-center">
            <NoInternetComp />
          </Container>
        )}
      </Container>
    </>
  );
}

export default EditAddressPage;
