import { DialogContent, Slider, Typography } from "@mui/material";
import React, { useState } from "react";
import Cropper from "react-easy-crop";
import { Button, Container } from "reactstrap";
import getCroppedImg from "../common/utils/cropImage";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { postServices } from "../../../../apiServices/apiServices";
import toast from "react-hot-toast";

const CROP_AREA_ASPECT = 370 / 159;

const Output = ({ croppedArea, isCroppedImage }) => {
  const scale = 100 / croppedArea.width;
  const transform = {
    x: `${-croppedArea.x * scale}%`,
    y: `${-croppedArea.y * scale}%`,
    scale,
    width: "calc(100% + 0.5px)",
    height: "auto",
  };

  const imageStyle = {
    transform: `translate3d(${transform.x}, ${transform.y}, 0) scale3d(${transform.scale},${transform.scale},1)`,
    width: transform.width,
    height: transform.height,
  };

  return (
    <div
    // className="output"
    // style={{ paddingBottom: `${100 / CROP_AREA_ASPECT}%` }}
    >
      <img src={isCroppedImage?.url} alt="" />
    </div>
  );
};

function ImageEditorView(props) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const access_token = sessionStorage.getItem("access-token");
  const [isCroppedImage, setIsCroppedImage] = useState();
  const navigate = useNavigate();

  const cropComplete = async (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);

    const croppedImage = await getCroppedImg(
      props.image,
      croppedAreaPixels,
      rotation
    );
    setIsCroppedImage(croppedImage);
  };

  const saveCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        props.image,
        croppedAreaPixels,
        rotation
      );

      const formData = new FormData();
      formData.append("image", croppedImage);

      try {
        const res = await postServices(
          `/api/banner/add_banner`,
          access_token,
          formData
        );
        if (res.responseCode === 200) {
          toast.success("Banner added...");
          navigate("/admin/manage_banner");
        } else {
          toast.error(res.responseMessage);
        }
      } catch (error) {
        toast.error(error);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <Container className="mb-10">
      <p className="p-8 text-2xl font-semibold">Crop image</p>
      <Container className="lg:flex mx-8 mb-8 justify-center">
        <Container className="lg:w-2/4 mx-1 p-2 border border-slate-400 rounded-md relative">
          <DialogContent
            dividers
            sx={{
              background: "transparent",
              position: "relative",
              height: 500,
              width: "auto",
              //   minWidth: { sm: 500 },
            }}
          >
            <Cropper
              image={props.image}
              crop={crop}
              zoom={zoom}
              rotation={rotation}
              aspect={CROP_AREA_ASPECT}
              onZoomChange={setZoom}
              onRotationChange={setRotation}
              onCropChange={setCrop}
              onCropComplete={cropComplete}
              // onCropAreaChange={(croppedArea) => {
              //   setCroppedAreaPixels(croppedArea);
              // }}
            />
          </DialogContent>
        </Container>
        <Container className="lg:w-2/4 mx-1 mt-3 lg:mt-0 p-2 flex justify-center items-center border border-slate-400 rounded-md">
          <Container className="bg-[#00000066] h-full flex justify-center items-center">
            {/* <h1>Preview</h1> */}
            <Container className="viewer">
              {croppedAreaPixels && (
                <Output
                  croppedArea={croppedAreaPixels}
                  isCroppedImage={isCroppedImage}
                />
              )}
            </Container>
          </Container>
        </Container>
      </Container>
      <Container className="flex justify-center items-center flex-col mb-5 gap-2">
        <Container className="grid w-80 sm:w-96 items-center gap-5 grid-cols-3">
          <Typography
            className="whitespace-nowrap col-span-1"
            sx={{ fontFamily: "Poppins" }}
          >
            Zoom :
          </Typography>
          <Slider
            valueLabelDisplay="auto"
            valueLabelFormat={zoomPercent}
            min={1}
            max={3}
            step={0.001}
            value={zoom}
            className="col-span-2"
            onChange={(e, zoom) => setZoom(zoom)}
          />
        </Container>
        <Container className="grid items-center gap-5 w-80 sm:w-96 grid-cols-3">
          <Typography
            className="whitespace-nowrap col-span-1"
            sx={{ fontFamily: "Poppins" }}
          >
            Rotation :
          </Typography>
          <Slider
            valueLabelDisplay="auto"
            min={1}
            max={360}
            value={rotation}
            onChange={(e, rotation) => setRotation(rotation)}
            className="col-span-2"
          />
        </Container>
      </Container>
      <Container className="flex justify-center">
        <Button
          onClick={saveCroppedImage}
          className="bg-slate-800 flex items-center px-2 sm:px-7 py-2 text-white font-semibold rounded-xl outline-none border-none active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out"
        >
          Save
        </Button>
      </Container>
    </Container>
  );
}

export default ImageEditorView;

const zoomPercent = (value) => {
  return `${Math.round(value * 100)}%`;
};
