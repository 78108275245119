import React, { useState, useEffect } from "react";
import { Button, Container } from "reactstrap";
import "./style.css";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingSpinner from "../../components/common/Admin/common/LoadingSpinner";
import PhonePePaymentModal from "../../components/common/Admin/Modal/PhonePePaymentModal";
import { getServices, postServices } from "../../apiServices/apiServices";
import toast from "react-hot-toast";
import UserGSTModal from "../../components/common/Admin/Modal/UserGst/UserGSTModal";
import { gstinPattern } from "../../variables/constants";
import { CiEdit } from "react-icons/ci";
import { MdDeleteOutline } from "react-icons/md";
import NoInternetComp from "../../components/common/InternetConnetction/NoInternetComp";
// import axios from "axios";
import Logo from "../../components/assets/site_logo2.png";
import { jwtDecode } from "jwt-decode";

function PaymentSelectionPage() {
  const { address_id } = useLocation().state;
  const [selectedPayment, setSelectedPayment] = useState("2");
  const [data, setData] = useState([]);
  const access_token = localStorage.getItem("user-access-token");
  const ship_rocket_access_token = localStorage.getItem(
    "user-shiprocket-access-token"
  );
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [paymentUrl, setPaymentUrl] = useState("");
  const [gstModal, setGSTModal] = useState(false);
  const [gstCheckbox, setGSTCheckbox] = useState(false);
  const [gstData, setGstData] = useState({ gstin: "", business_name: "" });
  const [gstDataValid, setGstDataValid] = useState({
    gstin: false,
    business_name: false,
  });
  const [savedGstData, setSavedGstData] = useState({
    gstin: "",
    business_name: "",
  });
  const decoded = jwtDecode(access_token);

  useEffect(() => {
    if (navigator.onLine) {
      fetchCartData();
    }
  }, [navigator]);

  const fetchCartData = async () => {
    setLoading(true);
    try {
      const res = await getServices(
        `/api/cart/list_cart?addy_id=${address_id}`,
        access_token,
        ship_rocket_access_token
      );
      setLoading(false);
      if (res.responseCode === 200) {
        setData(res.responseData);
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  const handleSelectPaymentChange = (e) => {
    setSelectedPayment(e.target.value);
  };

  const handleMakeOrder = async () => {
    if (selectedPayment === "1") {
      // try {
      //   const res = await postServices(
      //     `/api/orders/make_order`,
      //     access_token,
      //     {
      //       payment_method: selectedPayment,
      //       address_id: address_id,
      //     },
      //     ship_rocket_access_token
      //   );
      //   if (res.responseCode === 200) {
      //     navigate("/manage_cart/order_placed_successful");
      //   } else {
      //     toast.error(res.responseMessage);
      //   }
      // } catch (error) {
      //   toast.error(error);
      // }
    } else if (selectedPayment === "2") {
      try {
        const res = await postServices(
          `/api/orders/make_order`,
          access_token,
          {
            payment_method: selectedPayment,
            address_id: address_id,
            cust_gstin: gstData.gstin.toUpperCase(),
            cust_business_name: gstData.business_name,
          },
          ship_rocket_access_token
        );
        if (res.responseCode === 200) {
          if (res.responseData.payment_gateway === "rp") {
            payOnline(res.responseData.track_id);
          } else if (res.responseData.payment_gateway === "pp") {
            try {
              const res2 = await postServices(
                `/api/payment/pay?track_id=${res.responseData.track_id}`,
                access_token
              );
              console.log(res2);
              if (res2.responseCode === 200) {
                window.location.href =
                  res2.responseData.data.instrumentResponse.redirectInfo.url;
                // setModal(true);
                // setPaymentUrl(
                //   res2.responseData.data.instrumentResponse.redirectInfo.url
                // );
                const id = setInterval(() => {
                  checkPhonepePaymentVerification(
                    res.responseData.payment_id,
                    id
                  );
                }, 5000);
                return () => {
                  clearInterval(id);
                };
              } else {
                alert(res2.responseData.message);
              }
            } catch (error) {
              toast.error(error);
            }
            // navigate("/manage_cart/online_payment", {
            //   state: { track_id: res.responseData.track_id },
            // });
          }
        } else {
          toast.error(res.responseMessage);
        }
      } catch (error) {
        toast.error(error);
      }
    }
  };

  const payOnline = async (track_id) => {
    try {
      const res = await postServices(
        `/api/razorpay/payment/create?track_id=${track_id}`,
        access_token
      );
      if (res.responseCode === 200) {
        var options = {
          key: res.responseData.razorpaykey, // Enter the Key ID generated from the Dashboard
          amount: res.responseData.order.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          currency: "INR",
          name: "Navarang Smaranika", //your business name
          description: "Order payment",
          image: Logo,
          order_id: res.responseData.order.id,
          callback_url: "",
          redirect: false,
          handler: function (response) {
            validatePayment(response);
          },
          prefill: {
            name: decoded.name, //your customer's name
            email: decoded.email,
            contact: decoded.phone,
          },
          // notes: {
          //   address: "Razorpay Corporate Office",
          // },
          timeout: 5 * 60,
          theme: {
            color: "#352210",
          },
        };

        var rzp1 = new window.Razorpay(options);
        rzp1.on("payment.failed", function (response) {
          console.log(response.error.code);
          console.log(response.error.description);
          console.log(response.error.source);
          console.log(response.error.step);
          console.log(response.error.reason);
          alert("Payment failed");
          // alert(response.error.metadata.order_id);
          // alert(response.error.metadata.payment_id);
        });
        rzp1.open();
      } else {
        toast.error(res.responseMessage);
      }
      // if (res.responseCode === 200) {
      //   setModal(true);
      //   setPaymentUrl(
      //     res.responseData.data.instrumentResponse.redirectInfo.url
      //   );
      // window.location.href =
      //   res.responseData.data.instrumentResponse.redirectInfo.url;
      // window.open(res.responseData.data.instrumentResponse.redirectInfo.url);
      //   const id = setInterval(() => {
      //     // checkPhonepePaymentVerification(res.responseData.payment_id, id);
      //     checkPhonepePaymentVerification(
      //       res.responseData.data.merchantId,
      //       res.responseData.data.merchantTransactionId,
      //       id
      //     );
      //   }, 5000);

      //   return () => {
      //     clearInterval(id);
      //   };
      // } else {
      //   toast.error(res.responseMessage);
      // }
    } catch (error) {
      toast.error(error);
    }
  };

  const validatePayment = async (payment_data) => {
    try {
      const res = await postServices(
        `/api/razorpay/payment/verify/signature`,
        null,
        payment_data
      );
      if (res.responseCode === 200) {
        navigate("/manage_cart/order_placed_successful");
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const checkPhonepePaymentVerification = async (
    merchantId,
    merchantTransactionId,
    timeoutId
  ) => {
    try {
      const res = await getServices(
        `/api/payment/verify?merchantTransactionId=${merchantTransactionId}`
      );
      console.log(res);

      // if (res.responseCode === 208 || res.responseCode === 210) {
      //   setTimeout(() => {
      //     clearInterval(timeoutId);
      //   });
      //   navigate("/manage_cart/order_placed_successful");
      // } else if (res.responseCode === 209) {
      //   setTimeout(() => {
      //     clearInterval(timeoutId);
      //   });
      //   alert("Payment failed...");
      //   navigate("/");
      // }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleUseGSTChange = (e) => {
    if (e.target.checked) {
      setGSTModal(true);
      setGSTCheckbox(true);
    } else {
      setGSTCheckbox(false);
      setGSTModal(false);
    }
  };

  const handleGstDataChange = async (e) => {
    if (e.target.name === "gstin") {
      if (gstinPattern.test(e.target.value)) {
        setGstDataValid({ ...gstDataValid, gstin: true });
        setGstData({ ...gstData, gstin: e.target.value });
        try {
          const res = await getServices(
            `/api/orders/validate/gstin?gstin=${e.target.value.toUpperCase()}`
            // {
            //   headers: {
            //     Authorization: "0ab31ef7392227173c6e8d34195e86d5eb0da1e9",
            //     client_id: "JarZChUcsytSBbnkpt",
            //   },
            // }
          );
          if (res.responseCode === 200) {
            if (!res.responseData.error) {
              setGstData({
                ...gstData,
                business_name: res.responseData.data.lgnm,
              });
              setSavedGstData({
                ...savedGstData,
                business_name: res.responseData.data.lgnm,
              });
            } else {
              alert("Invalid GSTIN");
              setGstDataValid({ ...gstDataValid, gstin: false });
              setSavedGstData({
                ...savedGstData,
                business_name: "",
              });
            }
          } else {
            toast.error(res.responseMessage);
          }
        } catch (error) {
          toast.error(error);
        }
      } else {
        setGstDataValid({ ...gstDataValid, gstin: false });
      }
    }
    setGstData({ ...gstData, [e.target.name]: e.target.value });
  };

  const handleGstDataSubmit = (e) => {
    e.preventDefault();

    if (gstDataValid.gstin) {
      setSavedGstData({
        ...savedGstData,
        gstin: gstData.gstin,
        // business_name: gstData.business_name,
      });
      setGSTModal(false);
    } else {
      alert("Invalid GST number...");
    }
  };

  const handleUpdateGstData = () => {
    setGSTModal(true);
  };

  const handleDeleteGstData = () => {
    if (window.confirm("Do you want to delete GST Invoice?")) {
      setGstData({ ...gstData, gstin: "", business_name: "" });
      setSavedGstData({ ...savedGstData, gstin: "", business_name: "" });
      setGSTCheckbox(false);
    }
  };

  return (
    <>
      {navigator.onLine ? (
        <Container>
          {loading ? (
            <Container className="flex items-center justify-center min-h-[65vh]">
              <LoadingSpinner />
            </Container>
          ) : (
            <Container className="payment-selection-cards min-h-[65vh]">
              <Container className="payment-selection-first-card sm:pr-2">
                <Container className="p-2 sm:p-5 bg-white rounded-xl shadow-lg sticky top-28 bottom-0">
                  <p className="text-base capitalize font-semibold text-gray-500">
                    Recommended payment option
                  </p>
                  <Container className="flex flex-col gap-4 items-end">
                    <Container className="bg-[#FFFAEF] w-full sm:col-span-2 px-4 py-2 flex gap-2 items-center relative rounded-md">
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={selectedPayment}
                          onChange={handleSelectPaymentChange}
                        >
                          {/* <FormControlLabel
                        value="1"
                        control={
                          <Radio
                            sx={{
                              "&, &.Mui-checked": {
                                color: "#352210",
                              },
                            }}
                          />
                        }
                        label={
                          <p className="text-sm sm:text-base font-medium capitalize">
                            Cash on delivery
                          </p>
                        }
                        sx={{
                          "& .MuiTypography-root": { fontFamily: "Poppins" },
                        }}
                      /> */}
                          <FormControlLabel
                            value="2"
                            control={
                              <Radio
                                sx={{
                                  "&, &.Mui-checked": {
                                    color: "#352210",
                                  },
                                }}
                              />
                            }
                            label={
                              <p className="text-sm sm:text-base font-medium capitalize">
                                Online payment
                              </p>
                            }
                            sx={{
                              "& .MuiTypography-root": {
                                fontFamily: "Poppins",
                              },
                            }}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Container>
                  </Container>

                  <Container className="pl-4">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{
                              "&.Mui-checked": {
                                color: "#352210",
                              },
                              "&.Mui-disabled": {
                                color: "#352210 !important",
                              },
                            }}
                            onChange={handleUseGSTChange}
                            checked={gstCheckbox}
                            disabled={savedGstData.gstin ? true : false}
                          />
                        }
                        label="Use GST Invoice"
                        sx={{
                          ".MuiTypography-root": {
                            fontFamily: "Poppins",
                            fontWeight: "500",
                          },
                          "&.Mui-disabled, .MuiFormControlLabel-root .MuiFormControlLabel-label.Mui-disabled":
                            {
                              color: "#352210 !important", // Change this to your desired color
                            },
                        }}
                      />
                    </FormGroup>
                  </Container>

                  {savedGstData.gstin && (
                    <Container className="bg-[#fffaef] px-5 py-2 flex items-center gap-4 justify-between">
                      <Container className="flex items-center gap-4">
                        <p className="uppercase">{savedGstData.gstin}</p>
                        <hr className="h-[20px] border border-black" />
                        <p>{savedGstData.business_name}</p>
                      </Container>
                      <Container className="flex items-center gap-3 text-2xl">
                        <CiEdit
                          className="cursor-pointer"
                          onClick={handleUpdateGstData}
                        />
                        <MdDeleteOutline
                          className="cursor-pointer"
                          onClick={handleDeleteGstData}
                        />
                      </Container>
                    </Container>
                  )}
                </Container>
              </Container>
              <Container className="payment-selection-second-card">
                <Container className="lg:mt-0 p-5 shadow-lg mb-6 sm:mb-3 bg-[#FFFAEF] mt-4 sm:col-span-1 w-full px-4 py-2 flex flex-col gap-2 rounded-md sticky top-28 bottom-0">
                  <p className="font-semibold text-base mb-3">Price Details</p>
                  <Container className="flex justify-between text-sm">
                    <p>Total MRP</p>
                    <p className="font-semibold">₹ {data?.totalMRP}</p>
                  </Container>
                  <Container className="flex justify-between text-sm">
                    <p>Discount MRP</p>
                    <p className="font-semibold">-₹ {data.discountAmt}</p>
                  </Container>
                  <Container className="flex justify-between text-sm">
                    <p>Shipping Fee</p>
                    <p className="font-semibold">₹ {data.shippingFees}</p>
                  </Container>
                  <hr className="border border-[#352210] my-2" />
                  <Container className="flex justify-between font-semibold">
                    <p>Total Amount</p>
                    <p>₹ {data.totalPayableAmt}</p>
                  </Container>
                  <Button
                    className="w-full bg-[#352210] outline-none my-2 py-2 rounded-xl uppercase text-white font-semibold active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out"
                    onClick={handleMakeOrder}
                  >
                    {selectedPayment === "1" ? "Place order" : "Pay Now"}
                  </Button>
                </Container>
              </Container>
            </Container>
          )}

          <PhonePePaymentModal
            isModalShow={modal}
            onClose={() => setModal(false)}
            paymentUrl={paymentUrl}
            // stripeVerification={stripeVerification}
          />

          <UserGSTModal
            open={gstModal}
            onClose={() => {
              setGSTModal(false);
              setGSTCheckbox(false);
            }}
            setGstData={setGstData}
            gstData={gstData}
            handleGstDataChange={handleGstDataChange}
            handleGstDataSubmit={handleGstDataSubmit}
          />
        </Container>
      ) : (
        <Container className="min-h-[65vh] flex flex-col justify-center items-center">
          <NoInternetComp />
        </Container>
      )}
    </>
  );
}

export default PaymentSelectionPage;
