import React, { useRef, useState } from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";

function CustomBottomSheet(props) {
  return (
    <div>
      {/* <button onClick={() => setOpen(true)} ref={focusRef}>
        Open
      </button> */}
      <BottomSheet
        className="z-[9999999] relative"
        open={props.isBottomNav}
        onDismiss={props.onClose}
      >
        {props.children}
      </BottomSheet>
    </div>
  );
}

export default CustomBottomSheet;
