import React, { useState, useEffect } from "react";
import { Button, Container, Input } from "reactstrap";
import { HiPlus } from "react-icons/hi";
import { Link } from "react-router-dom";
import ProductsTable from "../../tables/ProductsTable";
import { ImSearch } from "react-icons/im";
import LoadingSpinner from "../common/LoadingSpinner";
import { getServices } from "../../../../apiServices/apiServices";
import { nNumber } from "../../../../variables/constants";
import toast from "react-hot-toast";
import NoInternetComp from "../../InternetConnetction/NoInternetComp";

function ProductsView() {
  const [data, setData] = useState([]);
  const access_token = sessionStorage.getItem("access-token");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (navigator.onLine) {
      fetchProductData();
    }
  }, [navigator]);

  const fetchProductData = async () => {
    setLoading(true);
    try {
      const res = await getServices(
        `/api/products/list_products/?page=1&limit=${nNumber}&pending=false`,
        access_token
      );
      setData(res.responseData.rows);
      setLoading(false);
    } catch (error) {
      toast.error(error);
      setLoading(false);
    }
  };

  const handleProductSearch = async (e) => {
    if (e.target.value === "") {
      fetchProductData();
    } else {
      try {
        const res = await getServices(
          `/api/products/search/${e.target.value}?limit=${nNumber}`,
          access_token
        );
        if (res.responseCode === 200) {
          setData(res.responseData.rows);
        } else if (res.responseCode === 206) {
          setData([]);
        } else {
          toast.error(res.responseMessage);
        }
      } catch (error) {
        toast.error(error);
      }
    }
  };

  return (
    <>
      {navigator.onLine ? (
        <Container>
          {loading ? (
            <Container className="flex items-center justify-center mt-52">
              <LoadingSpinner />
            </Container>
          ) : (
            <Container>
              <Container className="flex justify-between items-center mx-0 md:mx-0 p-8">
                <p className="text-xl sm:text-2xl font-semibold">Products</p>
                <Link to="/admin/add_product">
                  <Button className="bg-slate-800 flex items-center px-2 sm:px-7 py-2 text-white font-semibold rounded-xl outline-none border-none active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out">
                    <HiPlus className="mr-2" /> Add Products
                  </Button>
                </Link>
              </Container>
              <Container className="flex justify-end items-center mx-0 md:mx-0 px-8">
                <Container className="flex items-center w-80 bg-white px-4 rounded border border-slate-500">
                  <ImSearch />
                  <Input
                    type="text"
                    className=" bg-white py-2 pl-4 pr-2 w-full outline-none border-none"
                    placeholder="Search"
                    onChange={handleProductSearch}
                    autoFocus
                    // onChange={(e) => setSearchText(e.target.value)}
                  />
                </Container>
              </Container>

              {data?.length > 0 ? (
                <Container className="mx-8 mt-5 sm:mt-5">
                  <ProductsTable
                    data={data}
                    fetchProductData={fetchProductData}
                  />
                </Container>
              ) : (
                <Container className="text-center mx-8 mt-5 sm:mt-5">
                  <p className="font-semibold">No products found!!!</p>
                </Container>
              )}
            </Container>
          )}
        </Container>
      ) : (
        <Container className="min-h-[65vh] flex flex-col justify-center items-center">
          <NoInternetComp />
        </Container>
      )}
    </>
  );
}

export default ProductsView;
