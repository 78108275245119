import React, { useState } from "react";
import ImageUploader from "../../common/ImageUploader";
import { Container } from "reactstrap";
import config from "../../../../../config.json";
import { updateServices } from "../../../../../apiServices/apiServices";
import toast from "react-hot-toast";

function EditBannerInner(props) {
  const url = config.url;
  const [selectedImages, setSelectedImages] = useState([
    url + props.updateBannerData.image,
  ]);
  const access_token = sessionStorage.getItem("access-token");

  const handleDrop = async (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    handleImageUpload(files);

    // navigate("/admin/manage_banner/image/editor", {
    //   state: { img: URL.createObjectURL(files[0]) },
    // });
    // const croppedImage = await getCroppedImg(
    //   URL.createObjectURL(files[0]),
    //   croppedAreaPixels,
    //   rotation
    // );

    updateApiService(files[0]);
  };

  const handleFileSelect = async (event) => {
    const files = Array.from(event.target.files);
    handleImageUpload(files);
    // setIsImg(event.target.files[0]);

    // navigate("/admin/manage_banner/image/editor", {
    //   state: { img: URL.createObjectURL(event.target.files[0]) },
    // });

    updateApiService(files[0]);
  };

  const updateApiService = async (file) => {
    const formData = new FormData();
    formData.append("image", file);

    try {
      const res = await updateServices(
        `/api/banner/update_banner/${props.updateBannerData.banner_id}`,
        access_token,
        formData
      );
      if (res.responseCode === 200) {
        props.onClose();
        props.fetchBannerData();
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleImageUpload = (files) => {
    // Perform any necessary image upload logic here
    const selected = [...selectedImages];

    for (const file of files) {
      selected.push(URL.createObjectURL(file));
    }

    setSelectedImages(selected);
  };

  const handleRemoveImage = (index) => {
    setSelectedImages((prevImages) => {
      const updatedImages = prevImages.filter((_, i) => i !== index);
      return updatedImages;
    });
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  return (
    <Container className="mx-8 mb-8">
      <ImageUploader
        handleDrop={handleDrop}
        handleDragOver={handleDragOver}
        handleFileSelect={handleFileSelect}
        handleRemoveImage={handleRemoveImage}
        selectedImages={selectedImages}
        multiple={false}
      />
    </Container>
  );
}

export default EditBannerInner;
