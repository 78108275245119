import React, { useState, useEffect } from "react";
import { Container } from "reactstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import classes from "../style.module.css";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import { Rating } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import ProductDetailTabs from "./ProductDetailTabs";
import { useLocation } from "react-router-dom";
import config from "../../../../config.json";
import toast from "react-hot-toast";
import { getServices } from "../../../../apiServices/apiServices";
import NoInternetComp from "../../InternetConnetction/NoInternetComp";

function ProductDetailView() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [sliderRef, setSliderRef] = useState(null);
  const [data, setData] = useState();
  const [ratingsData, setRatingsData] = useState();
  const location = useLocation();
  const { product_id } = location.state;
  const url = config.url;
  const access_token = sessionStorage.getItem("access-token");
  const [variation, setVariation] = useState();
  const [sizeSelection, setSizeSelection] = useState();
  const [colorId, setColorId] = useState();
  const [sizeId, setSizeId] = useState();

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow:
      sizeSelection?.image.length > 4 ? 4 : sizeSelection?.image.length,
    slidesToScroll: 1,
    arrows: false,
    beforeChange: (current, next) => setCurrentImageIndex(next),
  };

  useEffect(() => {
    if (navigator.onLine) {
      fetchDeatils();
      fetchRatings();
    }
  }, [navigator]);

  const fetchDeatils = async () => {
    try {
      const res = await getServices(`/api/products/details/${product_id}`);
      if (res.responseCode === 200) {
        setData(res.responseData);
        setVariation(res.responseData.productColorInfo[0]);
        setSizeSelection(
          res.responseData.productColorInfo[0].productDataInfo[0]
        );
        setColorId(res.responseData.productColorInfo[0].product_color_id);
        setSizeId(
          res.responseData.productColorInfo[0].productDataInfo[0]
            .product_data_id
        );
        // setIsApiQuantity(res.responseData.quantity);
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const fetchRatings = async () => {
    try {
      const res = await getServices(
        `/api/review/list_review/${product_id}`,
        access_token
      );
      if (res.responseCode === 200) {
        setRatingsData(res.responseData);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleVariationClick = (data) => {
    setVariation(data);
    setSizeSelection(data.productDataInfo[0]);
    setColorId(data.product_color_id);
    setSizeId(data.productDataInfo[0].product_data_id);
    setCurrentImageIndex(0);
  };

  const handleSizeClick = (size) => {
    setSizeSelection(size);
    setSizeId(size.product_data_id);
    setCurrentImageIndex(0);
  };

  // const handleQuantityIncrease = () => {
  //   setIsQuantity((prevQuantity) => prevQuantity + 1);
  // };
  // const handleQuantityDecrease = () => {
  //   setIsQuantity((prevQuantity) => prevQuantity - 1);
  // };

  return (
    <>
      {navigator.onLine ? (
        <Container>
          <p className="p-8 text-2xl font-semibold">Product Detail</p>
          <Container className="bg-white mx-4 py-8 mb-8 rounded-3xl shadow-2xl">
            <Container className="flex flex-col md:flex-row ">
              <Container className="w-full md:w-2/4 relative md:mx-5">
                <Container className="outline-none border-none rounded mb-3 mx-3 ">
                  {data && (
                    <img
                      src={url + sizeSelection?.image[currentImageIndex]}
                      alt="preview"
                      className="rounded-2xl object-cove w-full h-72 sm:h-[450px]"
                    />
                  )}
                </Container>
                <div
                  style={{
                    backgroundColor: "#161c247a",
                    color: "#ffffff",
                    display: "inline-flex",
                    alignItems: "center",
                    borderRadius: "8px",
                    padding: "2px",
                    position: "absolute",
                    // bottom: "130px",
                    // right: "16px",
                    top: "25px",
                    left: "40px",
                    // zIndex: "9999",
                  }}
                >
                  <button
                    onClick={sliderRef?.slickPrev}
                    className="px-2 outline-none border-none"
                  >
                    <MdArrowBackIos />
                  </button>
                  {currentImageIndex + 1} /{" "}
                  {sizeSelection ? sizeSelection?.image.length : null}
                  <button
                    onClick={sliderRef?.slickNext}
                    className="px-2 outline-none border-none"
                  >
                    <MdArrowForwardIos />
                  </button>
                </div>
                <Slider
                  {...settings}
                  ref={setSliderRef}
                  className="mb-10 max-w-xs mx-auto"
                >
                  {sizeSelection?.image.map((item, index) => (
                    <div
                      key={index}
                      className={`${classes.thumbnail} outline-none w-full`}
                      onClick={() => setCurrentImageIndex(index)}
                    >
                      <img
                        src={url + item}
                        alt={`Thumbnail ${index + 1}`}
                        // width={"100%"}
                        className={`${
                          currentImageIndex === index ? classes.active : ""
                        } object-cover`}
                        style={{
                          height: "60px",
                          width: "80px",
                          borderRadius: "16px",
                        }}
                      />
                    </div>
                  ))}
                </Slider>
              </Container>
              <Container className="w-4/5 md:w-2/4 mx-8 my-4">
                <Container className="flex flex-col gap-4 items-start">
                  {/* <h3
                    className="text-green-500 font-semibold"
                    style={{
                      color: data?.is_stock === 1 ? "#22C55E" : "#ff5630",
                    }}
                  >
                    {data?.is_stock === 1
                      ? "In Stock"
                      : data?.is_stock === 0
                      ? "Out of Stock"
                      : null}
                  </h3> */}
                  {data && (
                    <h1 className="font-bold text-xl capitalize">
                      {data?.product_name}
                    </h1>
                  )}
                  {ratingsData?.noOfRatings > 0 && (
                    <Container className="flex items-center">
                      <Rating
                        name="half-rating-read"
                        value={ratingsData ? ratingsData.totalRatings : 0}
                        precision={0.1}
                        readOnly
                        sx={{ color: "#faaf00" }}
                        emptyIcon={<StarIcon style={{ opacity: 0.55 }} />}
                        className="mr-3"
                      />
                      <p style={{ color: "#919eab" }} className="text-sm">
                        ({ratingsData?.noOfRatings} reviews)
                      </p>
                    </Container>
                  )}
                  <Container className="flex items-end">
                    {data && (
                      <h1
                        className="text-3xl font-bold"
                        style={{
                          marginRight:
                            sizeSelection?.sales_price == 0 ? "0" : "1rem",
                        }}
                      >
                        {sizeSelection?.sales_price == 0
                          ? null
                          : "₹ " + sizeSelection?.sales_price}
                      </h1>
                    )}
                    {data && (
                      <h1
                        className="text-xl font-bold"
                        style={{
                          color:
                            sizeSelection?.sales_price == 0
                              ? "#353535"
                              : "#64748B",
                          fontSize:
                            sizeSelection?.sales_price == 0
                              ? "1.875rem"
                              : "1.25rem",
                          lineHeight: sizeSelection?.sales_price
                            ? "1.75rem"
                            : "2.25rem",
                        }}
                      >
                        {sizeSelection?.sales_price == 0 ? (
                          "₹ " + sizeSelection?.price
                        ) : (
                          <del>{"₹ " + sizeSelection?.price}</del>
                        )}
                      </h1>
                    )}
                  </Container>
                  <p className="text-slate-500 w-full text-justify">
                    {data ? data.description : null}
                  </p>
                  <hr
                    className="m-0 w-full"
                    style={{
                      borderWidth: "0px 0px thin",
                      borderColor: "#00000033",
                      borderStyle: "dashed",
                    }}
                  />

                  <Container className="flex justify-between w-full items-center">
                    {data && <h2 className="font-semibold">Category</h2>}
                    <p>{data?.categoryInfo?.category_name}</p>
                  </Container>
                  <Container className="flex justify-between w-full items-center">
                    {data?.subcatInfo?.subcat_name && (
                      <h2 className="font-semibold">Sub-Category</h2>
                    )}
                    <p>{data?.subcatInfo?.subcat_name}</p>
                  </Container>
                  <Container className="flex justify-between w-full items-center">
                    <h2 className="font-semibold w-1/3">Colors</h2>
                    <Container className="flex gap-2 flex-wrap items-center text-center">
                      {data?.productColorInfo?.map((item, i) => (
                        <Container
                          key={i}
                          onClick={
                            colorId === item.product_color_id
                              ? null
                              : () => handleVariationClick(item)
                          }
                          className={`text-center cursor-pointer capitalize rounded-md ${
                            colorId === item.product_color_id
                              ? "border-2 border-slate-500 opacity-100"
                              : "opacity-50"
                          }`}
                        >
                          <img
                            src={url + item.productDataInfo[0]?.image[0]}
                            alt={item.productDataInfo[0].variation}
                            className="w-16 rounded-md h-10 object-cover"
                          />
                          <p className="text-xs">{item.colors}</p>
                        </Container>
                      ))}
                    </Container>
                  </Container>
                  <Container className="flex justify-between w-full items-center">
                    <h2 className="font-semibold">Select Sizes</h2>
                    <Container className="flex gap-2 items-center text-center">
                      {variation?.productDataInfo?.map((size, i) => (
                        <Container
                          key={i}
                          onClick={() => handleSizeClick(size)}
                          className={`${
                            sizeId === size.product_data_id
                              ? "border-2 border-slate-800 rounded-lg"
                              : ""
                          }`}
                        >
                          <p className="cursor-pointer bg-slate-400 text-white px-2 rounded-md">
                            {size.variation} inch
                          </p>
                        </Container>
                      ))}
                    </Container>
                  </Container>
                  <Container className="flex justify-between w-full items-center">
                    {data && <h2 className="font-semibold">Weight(approx.)</h2>}
                    <Container>
                      {data && (
                        <p className="my-2">
                          {data && sizeSelection.weight} kg
                        </p>
                      )}
                    </Container>
                  </Container>
                  <Container className="flex justify-between w-full items-center">
                    {data && (
                      <h2 className="font-semibold">Length X Breadth (cm)</h2>
                    )}
                    <Container>
                      {data && (
                        <p className="my-2">
                          {data && sizeSelection.length} X{" "}
                          {data && sizeSelection.breadth}
                        </p>
                      )}
                    </Container>
                  </Container>
                  <Container className="flex justify-between w-full items-center">
                    {data && <h2 className="font-semibold">Product SKU</h2>}
                    <Container>
                      {data && (
                        <p className="my-2">
                          {data && sizeSelection.product_sku}
                        </p>
                      )}
                    </Container>
                  </Container>
                  {/* <Container className="flex justify-between w-full items-center">
                    {data && <h2 className="font-semibold">Product HSN</h2>}
                    <Container>
                      {data && (
                        <p className="my-2">
                          {data && sizeSelection.product_hsn}
                        </p>
                      )}
                    </Container>
                  </Container> */}
                  {/* <Container className="flex justify-between w-full items-center">
                    {data && <h2 className="font-semibold">Quantity</h2>}
                    <Container> */}
                  {/* <Container
                    className="flex items-center p-1 justify-between rounded-lg"
                    style={{
                      border: "1px solid #6a747f69",
                      fontFamily: "Poppins",
                    }}
                  >
                    <Button
                      className="inline-flex items-center justify-center border-none outline-none text-center p-1 text-lg disabled:text-gray-400"
                      disabled={isQuantity <= 1 ? true : false}
                      onClick={handleQuantityDecrease}
                    >
                      <HiOutlineMinus />
                    </Button>
                    <p className="py-1 px-3">{isQuantity}</p>
                    <Button
                      className="inline-flex items-center justify-center border-none outline-none text-center p-1 text-lg disabled:text-gray-400"
                      onClick={handleQuantityIncrease}
                      disabled={isQuantity >= isApiQuantity ? true : false}
                    >
                      <HiOutlinePlus />
                    </Button>
                  </Container> */}
                  {/* {data && (
                        <p className="my-2">
                          Available: {sizeSelection && sizeSelection.quantity}
                        </p>
                      )}
                    </Container> */}
                  {/* </Container> */}
                  <hr
                    className="m-0 w-full"
                    style={{
                      borderWidth: "0px 0px thin",
                      borderColor: "#00000033",
                      borderStyle: "dashed",
                    }}
                  />
                </Container>
              </Container>
            </Container>
            <Container className="mx-8">
              <ProductDetailTabs data={data} ratingsData={ratingsData} />
            </Container>
          </Container>
        </Container>
      ) : (
        <Container className="min-h-[65vh] flex flex-col justify-center items-center">
          <NoInternetComp />
        </Container>
      )}
    </>
  );
}

export default ProductDetailView;
