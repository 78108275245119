import React from "react";
import { Container } from "reactstrap";
import { Typography, Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";

function CancellationAndRefund() {
  return (
    <>
      <Container className="flex justify-center px-4 pt-2">
        <Container className="max-w-7xl w-full mb-3 flex flex-col gap-3">
          <Breadcrumbs
            separator=">"
            aria-label="breadcrumb"
            sx={{
              fontFamily: "Poppins",
            }}
          >
            <Link underline="hover" color="inherit" to="/profile">
              Profile
            </Link>
            <Typography
              color="text.primary"
              sx={{
                fontFamily: "Poppins",
              }}
            >
              Cancellation & Refund Policy
            </Typography>
          </Breadcrumbs>

          <h1 className="font-semibold text-2xl">
            Cancellation & Refund Policy
          </h1>
          <hr className="w-[5%] border-black border-2" />
          <p className="font-semibold text-xl text-gray-600">
            Last updated on Apr 1st 2024
          </p>
          <p>
            NAVARANG SMARANIKA believes in helping its customers as far as
            possible, and has therefore a liberal cancellation policy. Under
            this policy:
          </p>
          <ul
            className="list-disc flex flex-col gap-2"
            style={{ padding: "revert" }}
          >
            <li>
              Cancellations will be considered only if the request is made
              within 7 days of placing the order. However, the cancellation
              request may not be entertained if the orders have been
              communicated to the vendors/merchants and they have initiated the
              process of shipping them.
            </li>
            <li>
              NAVARANG SMARANIKA does not accept cancellation requests for and
              refunds. However, refund/replacement can be made if the customer
              establishes that the quality of product delivered is not good.
            </li>
            <li>
              In case of receipt of damaged or defective items please report the
              same to our Customer Service team. The request will, however, be
              entertained once the merchant has checked and determined the same
              at his own end. This should be reported within 7 days of receipt
              of the products.
            </li>
            <li>
              In case you feel that the product received is not as shown on the
              site or as per your expectations, you must bring it to the notice
              of our customer service within 7 days of receiving the product.
              The Customer Service Team after looking into your complaint will
              take an appropriate decision.
            </li>
            <li>
              In case of complaints regarding products that come with a warranty
              from manufacturers, please refer the issue to them.
            </li>
            <li>
              In case of any Refunds approved by the NAVARANG SMARANIKA, it'll
              take 6-8 days for the refund to be processed to the end customer.
            </li>
          </ul>

          <p className="italic mt-4">
            Disclaimer: The above content is created at NAVARANG SMARANIKA's
            sole discretion.
          </p>
        </Container>
      </Container>
    </>
  );
}

export default CancellationAndRefund;
