import React from "react";
import { Container } from "reactstrap";
import { Typography, Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";

function ShippingAndDelivery() {
  return (
    <>
      <Container className="flex justify-center px-4 pt-2">
        <Container className="max-w-7xl w-full mb-3 flex flex-col gap-3">
          <Breadcrumbs
            separator=">"
            aria-label="breadcrumb"
            sx={{
              fontFamily: "Poppins",
            }}
          >
            <Link underline="hover" color="inherit" to="/profile">
              Profile
            </Link>
            <Typography
              color="text.primary"
              sx={{
                fontFamily: "Poppins",
              }}
            >
              Shipping & Delivery Policy
            </Typography>
          </Breadcrumbs>

          <h1 className="font-semibold text-2xl">Shipping & Delivery Policy</h1>
          <hr className="w-[5%] border-black border-2" />
          <p className="font-semibold text-xl text-gray-600">
            Last updated on Apr 1st 2024
          </p>
          <p>
            For International buyers, orders are shipped and delivered through
            registered international courier companies and/or International
            speed post only. For domestic buyers, orders are shipped through
            registered domestic courier companies and /or speed post only.
            Orders are shipped within 0-7 days or as per the delivery date
            agreed at the time of order confirmation and delivering of the
            shipment subject to Courier Company / post office norms. NAVARANG
            SMARANIKA is not liable for any delay in delivery by the courier
            company / postal authorities and only guarantees to hand over the
            consignment to the courier company or postal authorities within 0-7
            days rom the date of the order and payment or as per the delivery
            date agreed at the time of order confirmation. Delivery of all
            orders will be to the address provided by the buyer. Delivery of our
            services will be confirmed on your mail ID as specified during
            registration. For any issues in utilizing our services you may
            contact our helpdesk on or
          </p>

          <p className="italic mt-4">
            Disclaimer: The above content is created at NAVARANG SMARANIKA's
            sole discretion.
          </p>
        </Container>
      </Container>
    </>
  );
}

export default ShippingAndDelivery;
