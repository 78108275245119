import React from "react";
import classes from "../style.module.css";
import { LuLogOut } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { Button, Container } from "reactstrap";

function Navbar(props) {
  const navigate = useNavigate();

  const handleLogout = () => {
    sessionStorage.removeItem("access-token");
    sessionStorage.removeItem("activeMenuItem");
    navigate("/login");
  };

  return (
    <Container className={classes.navbarBg}>
      <Container className="w-2/4">{props.children}</Container>
      <Container className={`w-2/4 flex items-center justify-end p-4`}>
        <Button
          className={`${classes.logoutBtn} flex items-center font-semibold active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out`}
          onClick={handleLogout}
        >
          <LuLogOut className="mr-2 font-bold" />
          LOGOUT
        </Button>
      </Container>
    </Container>
  );
}

export default Navbar;
