import { Backdrop, Box, Fade, Modal } from "@mui/material";
import React from "react";
import { IoClose } from "react-icons/io5";
import { Container, Form, Input } from "reactstrap";
import ImageUploader from "../../common/ImageUploader";

function ManageGalleryModal(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    padding: "15px 20px",
    outline: "none",
    borderRadius: "15px",
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        // onClose={props.onClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={props.open}>
          <Box sx={style}>
            <div className="flex justify-between items-center">
              <p className="font-semibold">
                Add {props.modal.add_album ? "Album" : "Video"}
              </p>
              <IoClose
                className="cursor-pointer border border-[#000] rounded-full"
                onClick={props.onClose}
              />
            </div>

            <Form
              className="flex flex-col gap-4 mt-3"
              onSubmit={props.handleMediaSubmit}
            >
              <Input
                type="file"
                className="border"
                accept={props.modal.add_album ? "image/*" : "video/*"}
                onChange={props.handleFileChange}
                required
              />

              {props.previewUrl && (
                <div>
                  {props.file && props.file.type.startsWith("image/") ? (
                    <img
                      src={props.previewUrl}
                      alt="Preview"
                      className="w-24 rounded border"
                    />
                  ) : (
                    <video
                      controls
                      style={{ maxWidth: "100%", maxHeight: "200px" }}
                    >
                      <source src={props.previewUrl} type={props.file.type} />
                      Your browser does not support the video tag.
                    </video>
                  )}
                </div>
              )}

              <Container className="flex justify-end items-center">
                <Input
                  disabled={props.loading}
                  type="submit"
                  value={!props.loading ? "Submit" : "Loading"}
                  className="px-8 py-2 bg-slate-800 text-white font-semibold rounded-lg active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out disabled:cursor-not-allowed disabled:bg-slate-500"
                />
              </Container>
            </Form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default ManageGalleryModal;
