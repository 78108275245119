import { Box, TextField } from "@mui/material";
import React from "react";
import { useState } from "react";
import { Button, Container, Form } from "reactstrap";
import ImageUploader from "../../common/ImageUploader";
import config from "../../../../../config.json";
import { updateServices } from "../../../../../apiServices/apiServices";
import toast from "react-hot-toast";

function CategoryEditForm({ isEditData, onClose, fetchCategoryList }) {
  const url = config.url;
  const [selectedImages, setSelectedImages] = useState([
    url + isEditData.image,
  ]);
  const [data, setData] = useState({
    name: isEditData.name,
    image: isEditData.image,
  });
  const access_token = sessionStorage.getItem("access-token");

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    handleImageUpload(files);
  };

  const handleFileSelect = (event) => {
    const files = event.target.files;
    // const files = Array.from(event.target.files);
    handleImageUpload(files);
    setData({ ...data, image: event.target.files[0] });
  };

  const handleImageUpload = (files) => {
    // Perform any necessary image upload logic here
    const selected = [...selectedImages];

    for (const file of files) {
      selected.push({
        file,
        preview: URL.createObjectURL(file),
      });
    }

    setSelectedImages(selected);
  };

  const handleRemoveImage = (index) => {
    setSelectedImages((prevImages) => {
      const updatedImages = prevImages.filter((_, i) => i !== index);
      return updatedImages;
    });
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("category_name", data.name);
    formData.append("image", data.image);

    try {
      const res = await updateServices(
        `/api/category/update_category/${isEditData.id}`,
        access_token,
        formData
      );
      if (res.responseCode === 200) {
        onClose();
        toast.success("Category updated successfully...");
        fetchCategoryList();
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Box
          sx={{
            maxWidth: "100%",
          }}
          className="mx-8"
        >
          <TextField
            fullWidth
            name="category_name"
            label="Category Name"
            id="fullWidth"
            sx={{
              "& .MuiInputLabel-root": {
                fontFamily: "Poppins",
              },
              "& .MuiInputLabel-root": {
                fontFamily: "Poppins",
              },
              "& .MuiOutlinedInput-root": {
                fontFamily: "Poppins",
                borderRadius: "12px",
              },
              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#352210",
                },
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#352210",
                },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #352210",
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "#352210",
              },
              marginBottom: "20px",
            }}
            value={data.name}
            onChange={(e) => setData({ ...data, name: e.target.value })}
            required
          />
          <Container className="mb-5">
            <ImageUploader
              handleDrop={handleDrop}
              handleDragOver={handleDragOver}
              handleFileSelect={handleFileSelect}
              handleRemoveImage={handleRemoveImage}
              selectedImages={selectedImages}
              multiple={false}
            />
          </Container>
          <Container className="flex justify-end items-center mb-5">
            <Button
              type="submit"
              className="px-8 py-2 bg-slate-800 text-white font-semibold rounded-lg active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out disabled:cursor-not-allowed disabled:bg-slate-500"
              disabled={
                data.name.length < 1 || selectedImages.length !== 1
                  ? true
                  : false
              }
            >
              Save Changes
            </Button>
          </Container>
        </Box>
      </Form>
    </Container>
  );
}

export default CategoryEditForm;
