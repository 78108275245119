import React, { useState, useEffect } from "react";
import { Button, Container } from "reactstrap";
import SubCategoryAddModal from "../Modal/SubCategory/SubCategoryAddModal";
import ReactSelect from "react-select";
import SubCategoryTable from "../../tables/SubCategoryTable";
import { HiPlus } from "react-icons/hi";
import { getServices } from "../../../../apiServices/apiServices";
import toast from "react-hot-toast";
import NoInternetComp from "../../InternetConnetction/NoInternetComp";

function ManageSubCategoryView() {
  const [statusSelectedOption, setStatusSelectedOption] = useState();
  const [isModalShow, setIsModalShow] = useState(false);
  const [isSubCategoryShow, setIsSubCategoryShow] = useState(false);
  const [isCategoryList, setIsCategoryList] = useState([]);
  const [isSubCategoryData, setIsSubCategoryData] = useState([]);
  const [isParentName, setIsParentName] = useState("");
  const access_token = sessionStorage.getItem("access-token");

  useEffect(() => {
    if (navigator.onLine) {
      fetchCategoryList();
    }
  }, [navigator]);

  const colorStyles = {
    control: (styles) => ({
      ...styles,
      border: "1px solid #7d7d7d",
      marginBottom: "0.75rem",
      marginTop: "0.25rem",
      outline: "none",
      padding: "0.5rem",
      borderRadius: "10px",
    }),
  };

  const fetchCategoryList = async () => {
    try {
      const res = await getServices(`/api/category/list_category`);
      setIsCategoryList(res.responseData);
    } catch (error) {
      toast.error(error);
    }
  };

  const handleAddModal = () => {
    setIsModalShow(true);
  };

  const handleViewSubCategory = async () => {
    if (statusSelectedOption === undefined) {
      toast.error("Please select valid category!");
    } else {
      setIsSubCategoryShow(true);
      try {
        const res = await getServices(
          `/api/subcat/list_subcat/${statusSelectedOption.value}`,
          access_token
        );
        setIsSubCategoryData(res.responseData[0].sub_categories);
        setIsParentName(res.responseData[0].category_name);
      } catch (error) {
        toast.error(error);
      }
    }
  };

  return (
    <>
      {navigator.onLine ? (
        <Container>
          <Container className="flex justify-between items-center mx-0 md:mx-0 p-8">
            <p className="text-xl sm:text-2xl font-semibold">
              Manage Sub-Category
            </p>
            <Button
              onClick={handleAddModal}
              className="bg-slate-800 flex items-center px-2 sm:px-7 py-2 text-white font-semibold rounded-xl outline-none border-none active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out"
            >
              <HiPlus className="mr-2" />
              Add Sub-Category
            </Button>
          </Container>
          <Container className="flex flex-col sm:flex-row items-center px-8">
            <ReactSelect
              options={isCategoryList.map((category) => {
                return {
                  value: category.category_id,
                  label: category.category_name,
                };
              })}
              onChange={setStatusSelectedOption}
              placeholder="Select the category"
              styles={colorStyles}
              className="w-full"
            />
            <Button
              onClick={handleViewSubCategory}
              className="bg-amber-500 h-auto sm:h-16 lg:h-16 xl:h-12 w-auto sm:w-72 mb-2 ml-4 px-7 py-2 text-white font-semibold rounded-xl outline-none border-none active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out"
            >
              View Sub-Categories
            </Button>
          </Container>
          {isSubCategoryShow ? (
            <Container className="mx-8 mt-5 sm:mt-5">
              {isSubCategoryData.length >= 1 ? (
                <SubCategoryTable
                  isSubCategoryData={isSubCategoryData}
                  parentCategoryName={isParentName}
                  handleViewSubCategory={handleViewSubCategory}
                />
              ) : (
                <Container className="text-center">
                  <p className="font-semibold">No data found!!!</p>
                </Container>
              )}
            </Container>
          ) : (
            <Container className="text-center mt-4">
              <p className="font-semibold">Please select any category...</p>
            </Container>
          )}

          <SubCategoryAddModal
            isModalShow={isModalShow}
            onClose={() => setIsModalShow(false)}
            // handleViewSubCategory={handleViewSubCategory}
          />
        </Container>
      ) : (
        <Container className="min-h-[65vh] flex flex-col justify-center items-center">
          <NoInternetComp />
        </Container>
      )}
    </>
  );
}

export default ManageSubCategoryView;
