import React, { useEffect, useState, useContext } from "react";
import { Button, Container, Form, Input } from "reactstrap";
import "./style.css";
import Slider from "react-slick";
import { useLocation, useNavigate } from "react-router-dom";
import config from "../../config.json";
import classes from "../../components/common/Admin/style.module.css";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import {
  Box,
  FormControl,
  FormControlLabel,
  LinearProgress,
  Modal,
  Radio,
  RadioGroup,
  Rating,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import LoadingSpinner from "../../components/common/Admin/common/LoadingSpinner";
import { GlobalContext } from "../../components/layouts/UserLayout";
import { SlArrowLeft } from "react-icons/sl";
import { getServices, postServices } from "../../apiServices/apiServices";
import toast from "react-hot-toast";
import { IoChevronBackOutline } from "react-icons/io5";
import NoInternetComp from "../../components/common/InternetConnetction/NoInternetComp";

function ProductDetails() {
  const location = useLocation();
  const [data, setData] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const url = config.url;
  const [sliderRef, setSliderRef] = useState(null);
  const [ratingsData, setRatingsData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const access_token = localStorage.getItem("user-access-token");
  const global_context = useContext(GlobalContext);
  const [variationData, setVariationData] = useState();
  const navigate = useNavigate();
  const [loginModal, setIsLoginModal] = useState(false);
  const [sizeSelection, setSizeSelection] = useState();
  const [colorId, setColorId] = useState();

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow:
      sizeSelection?.image.length > 4 ? 4 : sizeSelection?.image.length,
    slidesToScroll: 1,
    // centerMode: true,
    arrows: false,
    beforeChange: (current, next) => setCurrentImageIndex(next),
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    padding: "20px 14px",
    outline: "none",
    borderRadius: "10px",
  };

  useEffect(() => {
    if (navigator.onLine) {
      fetchSingleProductDetails();
      fetchRatings();
    }

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [navigator]);

  const fetchSingleProductDetails = async () => {
    setIsLoading(true);
    try {
      const res = await getServices(
        `/api/products/details/${location.state.product_id}`
      );
      setIsLoading(false);
      if (res.responseCode === 200) {
        setData([res.responseData]);
        setVariationData(res.responseData.productColorInfo[0]);
        setSizeSelection(
          res.responseData.productColorInfo[0].productDataInfo[0]
        );
        setColorId(res.responseData.productColorInfo[0].product_color_id);
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error);
    }
  };

  const fetchRatings = async () => {
    setIsLoading(true);
    try {
      const res = await getServices(
        `/api/review/list_review/${location.state.product_id}`
      );
      setIsLoading(false);
      if (res.responseCode === 200) {
        setRatingsData(res.responseData);
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error);
    }
  };

  const handleAddToCart = async () => {
    try {
      const res = await postServices(`/api/cart/add_cart`, access_token, {
        product_id: data[0].product_id,
        color_id: colorId,
        variation_id: sizeSelection.product_data_id,
      });
      if (res.responseCode === 200) {
        toast.success("Product added to Cart successfully...");
        global_context.fetchCartData();
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleVariationClick = (data) => {
    setVariationData(data);
    setSizeSelection(data.productDataInfo[0]);
    setColorId(data.product_color_id);
    setCurrentImageIndex(0);
  };

  const handleSizeClick = (e, size) => {
    setSizeSelection(size);
    setCurrentImageIndex(0);
  };

  const handleBackButton = () => {
    navigate(-1);
  };

  const handleGuestModeCart = () => {
    setIsLoginModal(true);
  };

  const handleLoginPage = () => {
    navigate("/login");
  };

  const handleCheckAvailability = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <Container className="pt-2">
        {navigator.onLine ? (
          <Container>
            {data.length <= 0 ? (
              <Container className="flex justify-center items-center h-[65vh] flex-col">
                <p className="font-semibold">No Data Found...</p>
              </Container>
            ) : (
              <Container className="pb-4 px-4 sm:px-8">
                {isLoading ? (
                  <Container className="flex items-center justify-center mt-52">
                    <LoadingSpinner />
                  </Container>
                ) : (
                  <Container>
                    <Container className="relative z-10">
                      <Button
                        className="py-1 px-4 rounded-full flex items-center gap-2"
                        onClick={handleBackButton}
                      >
                        <IoChevronBackOutline className="text-2xl cursor-pointer" />
                        {/* <SlArrowLeft className="text-lg font-extrabold" /> */}
                        <p className="font-semibold text-lg">Product Details</p>
                        {/* Back */}
                      </Button>
                    </Container>
                    <Container className="product-details-cards">
                      <Container className="product-details-first-card pr-2">
                        <Container className="p-5 bg-white rounded-xl shadow-lg sticky top-28 bottom-0">
                          <Container className="relative">
                            {data && (
                              <img
                                src={
                                  url + sizeSelection?.image[currentImageIndex]
                                }
                                alt="preview"
                                className="rounded-2xl object-contain w-full h-72 sm:h-[450px]"
                              />
                            )}
                            <div
                              style={{
                                backgroundColor: "#161c247a",
                                color: "#ffffff",
                                display: "inline-flex",
                                alignItems: "center",
                                borderRadius: "8px",
                                padding: "2px",
                                position: "absolute",
                                top: "25px",
                                left: "40px",
                              }}
                            >
                              <button
                                onClick={sliderRef?.slickPrev}
                                className="px-2 outline-none border-none"
                              >
                                <MdArrowBackIos />
                              </button>
                              {currentImageIndex + 1} /{" "}
                              {sizeSelection
                                ? sizeSelection?.image.length
                                : null}
                              <button
                                onClick={sliderRef?.slickNext}
                                className="px-2 outline-none border-none"
                              >
                                <MdArrowForwardIos />
                              </button>
                            </div>
                            <hr className="border-dashed" />
                          </Container>
                          <Container className="flex flex-col gap-4 items-end my-6 text-right pl-2">
                            <Slider
                              {...settings}
                              ref={setSliderRef}
                              className="max-w-xs mx-auto w-96"
                            >
                              {sizeSelection?.image?.map((item, index) => (
                                <div
                                  key={index}
                                  className={`${classes.thumbnail} outline-none`}
                                  onClick={() => setCurrentImageIndex(index)}
                                >
                                  <img
                                    src={url + item}
                                    alt={`Thumbnail ${index + 1}`}
                                    // width={"100%"}
                                    className={`${
                                      currentImageIndex === index
                                        ? classes.active
                                        : ""
                                    } object-cover`}
                                    style={{
                                      height: "60px",
                                      width: "80px",
                                      borderRadius: "16px",
                                    }}
                                  />
                                </div>
                              ))}
                            </Slider>
                          </Container>
                        </Container>
                      </Container>
                      <Container className="product-details-second-card">
                        <Container className="mt-6 lg:mt-0 p-5 bg-white rounded-xl shadow-lg mb-6 sm:mb-3">
                          {data.map((item, i) => (
                            <Container key={i}>
                              {/* <h3
                                className="text-green-500 font-semibold mb-3"
                                style={{
                                  color:
                                    data[0]?.is_stock === 1
                                      ? "#22C55E"
                                      : "#ff5630",
                                }}
                              >
                                {data[0]?.is_stock === 1
                                  ? "In Stock"
                                  : data[0]?.is_stock === 0
                                  ? "Out of Stock"
                                  : null}
                              </h3> */}
                              <p className="font-semibold capitalize text-xl mb-3">
                                {item.product_name}
                              </p>
                              {ratingsData?.noOfRatings > 0 && (
                                <Container className="flex items-center mb-3">
                                  <Rating
                                    name="half-rating-read"
                                    defaultValue={
                                      ratingsData ? ratingsData.totalRatings : 0
                                    }
                                    value={
                                      ratingsData ? ratingsData.totalRatings : 0
                                    }
                                    precision={0.1}
                                    readOnly
                                    sx={{ color: "#faaf00" }}
                                    emptyIcon={
                                      <StarIcon style={{ opacity: 0.55 }} />
                                    }
                                    className="mr-3"
                                  />
                                  <p
                                    style={{ color: "#919eab" }}
                                    className="text-sm"
                                  >
                                    ({ratingsData?.noOfRatings} reviews)
                                  </p>
                                </Container>
                              )}

                              <Container className="flex items-end mb-3">
                                {data && (
                                  <h1
                                    className="text-3xl font-bold"
                                    style={{
                                      marginRight:
                                        sizeSelection?.sales_price == 0
                                          ? "0"
                                          : "1rem",
                                      color:
                                        sizeSelection?.sales_price == 0
                                          ? ""
                                          : "red",
                                    }}
                                  >
                                    {sizeSelection?.sales_price == 0
                                      ? null
                                      : "₹ " + sizeSelection?.sales_price}
                                  </h1>
                                )}
                                {data && (
                                  <h1
                                    className="text-xl font-bold"
                                    style={{
                                      color:
                                        sizeSelection?.sales_price == 0
                                          ? "red"
                                          : "#64748B",
                                      fontSize:
                                        sizeSelection?.sales_price == 0
                                          ? "1.875rem"
                                          : "1.25rem",
                                      lineHeight: sizeSelection?.sales_price
                                        ? "1.75rem"
                                        : "2.25rem",
                                    }}
                                  >
                                    {sizeSelection?.sales_price == 0 ? (
                                      "₹ " + sizeSelection?.price
                                    ) : (
                                      <del>{"₹ " + sizeSelection?.price}</del>
                                    )}
                                  </h1>
                                )}
                                {data && (
                                  <p className="ml-3 text-md font-semibold text-green-600">
                                    {sizeSelection.offer_value > 0 &&
                                      sizeSelection.offer_value + "% OFF"}
                                  </p>
                                )}
                              </Container>
                              <p className="min-w-[160px] text-[#637381e3] mb-3 text-justify">
                                {item?.description}
                              </p>

                              <Container className="bg-[#fff5de] p-4 mb-3 rounded">
                                <p className="font-semibold text-sm mb-2">
                                  Select Size
                                </p>
                                <Container className="flex gap-2 flex-wrap items-center text-center">
                                  {data[0]?.productColorInfo?.map((item, i) => (
                                    <Container
                                      key={i}
                                      onClick={
                                        colorId === item.product_color_id
                                          ? null
                                          : () => handleVariationClick(item)
                                      }
                                      className={`text-center cursor-pointer capitalize rounded-md ${
                                        colorId === item.product_color_id
                                          ? "border-2 border-slate-500 opacity-100"
                                          : "opacity-50"
                                      }`}
                                    >
                                      <img
                                        src={
                                          url +
                                          item.productDataInfo[0]?.image[0]
                                        }
                                        alt={item.productDataInfo[0].variation}
                                        className="w-16 rounded-md h-10 object-cover"
                                      />
                                      <p className="text-xs">{item.colors}</p>
                                    </Container>
                                  ))}
                                </Container>
                                <FormControl>
                                  {variationData?.productDataInfo?.map(
                                    (varia, i) => (
                                      <RadioGroup
                                        key={i}
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={sizeSelection.product_data_id}
                                        onChange={(e) =>
                                          handleSizeClick(e, varia)
                                        }
                                      >
                                        <FormControlLabel
                                          value={varia.product_data_id}
                                          control={
                                            <Radio
                                              sx={{
                                                "&, &.Mui-checked": {
                                                  color: "#352210",
                                                },
                                              }}
                                            />
                                          }
                                          sx={{
                                            fontFamily: "Poppins",
                                            ".css-pi3i9q-MuiFormControlLabel-root .MuiFormControlLabel-label":
                                              { fontFamily: "Poppins" },
                                          }}
                                          label={
                                            <span
                                              style={{
                                                fontFamily: "Poppins",
                                                fontSize: "14px",
                                              }}
                                            >
                                              {varia.variation} inch
                                            </span>
                                          }
                                        />
                                      </RadioGroup>
                                    )
                                  )}
                                </FormControl>
                              </Container>
                              {/* {data[0]?.is_stock === 1 && ( */}
                              <Container className="mb-3 flex justify-between">
                                <Button
                                  className="bg-[#352210] outline-none text-white font-semibold py-3 px-8 rounded-xl active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out"
                                  onClick={
                                    access_token
                                      ? () => handleAddToCart()
                                      : () => handleGuestModeCart()
                                  }
                                >
                                  Add to Cart
                                </Button>
                              </Container>
                              {/* )} */}
                              <hr className="border-dashed border-[#352210] mb-3" />
                              {/* <Container className="bg-[#fff5de] mb-3 py-2">
                          <p className="font-semibold px-4 text-sm">
                            Check Availability
                          </p>
                          <Container className="flex flex-col sm:flex-row mt-2">
                            <Form onSubmit={handleCheckAvailability}>
                              <Input
                                className="mx-4 outline-none rounded-lg px-3 py-2 w-[90%] sm:w-auto"
                                type="tel"
                                maxLength={6}
                                placeholder="Enter Delivery Pincode"
                              />
                              <Button
                                type="submit"
                                className="bg-[#352210] font-semibold text-white px-4 rounded-lg active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out mt-2 sm:mt-0 py-2 mx-4 sm:mx-0 w-[90%] sm:w-auto"
                              >
                                Check
                              </Button>
                            </Form>
                          </Container>
                        </Container>
                        <hr className="border-dashed border-[#352210] mb-3" /> */}

                              <h2 className="font-semibold mb-4">
                                Product Info
                              </h2>
                              <Container className="flex justify-between w-full text-sm items-center mb-3">
                                <h2 className="font-semibold">Category</h2>
                                <p className="capitalize">
                                  {item.categoryInfo?.category_name}
                                </p>
                              </Container>
                              {item.subcatInfo?.subcat_name && (
                                <Container className="flex text-sm justify-between w-full items-center mb-3">
                                  <h2 className="font-semibold">
                                    Sub-Category
                                  </h2>
                                  <p>{item.subcatInfo?.subcat_name}</p>
                                </Container>
                              )}
                              {sizeSelection.weight && (
                                <Container className="flex text-sm justify-between w-full items-center mb-3">
                                  <h2 className="font-semibold">
                                    Weight(approx.)
                                  </h2>
                                  <p>{sizeSelection.weight} kg</p>
                                </Container>
                              )}
                              {sizeSelection.length && (
                                <Container className="flex text-sm justify-between w-full items-center mb-3">
                                  <h2 className="font-semibold">
                                    Length X Breadth (cm)
                                  </h2>
                                  <p>
                                    {sizeSelection.length} X{" "}
                                    {sizeSelection.breadth}
                                  </p>
                                </Container>
                              )}
                              <hr className="border-dashed border-[#352210] mb-3" />

                              <h2 className="font-semibold mb-4">
                                Product Description
                              </h2>
                              <p
                                className="text-xs text-justify mb-3"
                                dangerouslySetInnerHTML={{
                                  __html: item.product_feature,
                                }}
                              />
                            </Container>
                          ))}
                          <hr className="border-dashed border-[#352210] mb-4" />

                          {ratingsData?.noOfRatings > 0 && (
                            <Container>
                              <p className="font-semibold">Ratings</p>
                              <Container className="grid grid-cols-3">
                                <Container className="flex flex-col col-span-1 items-center justify-center gap-2 sm:gap-4">
                                  <h6 className="font-semibold text-sm sm:text-base">
                                    Average Rating
                                  </h6>
                                  <h2 className="text-xl sm:text-3xl font-bold">
                                    {ratingsData
                                      ? ratingsData.totalRatings
                                      : ""}
                                    /5
                                  </h2>
                                  <Rating
                                    name="half-rating-read"
                                    value={
                                      ratingsData ? ratingsData.totalRatings : 0
                                    }
                                    precision={0.1}
                                    readOnly
                                    sx={{ color: "#faaf00" }}
                                    emptyIcon={
                                      <StarIcon style={{ opacity: 0.55 }} />
                                    }
                                  />
                                  <p className="text-zinc-400">
                                    ({ratingsData ? ratingsData.noOfRatings : 0}{" "}
                                    reviews)
                                  </p>
                                </Container>
                                <Container className="flex flex-col col-span-2 ml-4 gap-2 px-0 md:px-10 py-5 md:border-dashed md:border-[#352210] md:border-l">
                                  <Container className="flex items-center flex-row">
                                    <span className="m-0 font-semibold w-24 md:w-16 flex items-center justify-center">
                                      5 <StarIcon sx={{ fontSize: "15px" }} />
                                    </span>
                                    <LinearProgress
                                      variant="determinate"
                                      value={
                                        (ratingsData?.ratingCounts[5] /
                                          ratingsData?.noOfRatings) *
                                        100
                                      }
                                      sx={{
                                        width: "100%",
                                        maxWidth: "500px",
                                        borderRadius: "4px",
                                        backgroundColor: "#388e3c4a",
                                        "& .MuiLinearProgress-bar": {
                                          backgroundColor: "#388e3c",
                                        },
                                      }}
                                      className="mx-2"
                                    />
                                    <span className="m-0 text-zinc-500">
                                      {ratingsData
                                        ? ratingsData.ratingCounts[5]
                                        : 0}
                                    </span>
                                  </Container>
                                  <Container className="flex items-center flex-row">
                                    <span className="m-0 font-semibold w-24 md:w-16 flex items-center justify-center">
                                      4 <StarIcon sx={{ fontSize: "15px" }} />
                                    </span>
                                    <LinearProgress
                                      variant="determinate"
                                      value={
                                        (ratingsData?.ratingCounts[4] /
                                          ratingsData?.noOfRatings) *
                                        100
                                      }
                                      sx={{
                                        width: "100%",
                                        maxWidth: "500px",
                                        borderRadius: "4px",
                                        backgroundColor: "#388e3c4a",
                                        "& .MuiLinearProgress-bar": {
                                          backgroundColor: "#388e3c",
                                        },
                                      }}
                                      className="mx-2"
                                    />
                                    <span className="m-0 text-zinc-500">
                                      {ratingsData
                                        ? ratingsData.ratingCounts[4]
                                        : 0}
                                    </span>
                                  </Container>
                                  <Container className="flex items-center flex-row">
                                    <span className="m-0 font-semibold w-24 md:w-16 flex items-center justify-center">
                                      3 <StarIcon sx={{ fontSize: "15px" }} />
                                    </span>
                                    <LinearProgress
                                      variant="determinate"
                                      value={
                                        (ratingsData?.ratingCounts[3] /
                                          ratingsData?.noOfRatings) *
                                        100
                                      }
                                      sx={{
                                        width: "100%",
                                        maxWidth: "500px",
                                        borderRadius: "4px",
                                        backgroundColor: "#eea3004a",
                                        "& .MuiLinearProgress-bar": {
                                          backgroundColor: "#eea300",
                                        },
                                      }}
                                      className="mx-2"
                                    />
                                    <span className="m-0 text-zinc-500">
                                      {ratingsData
                                        ? ratingsData.ratingCounts[3]
                                        : 0}
                                    </span>
                                  </Container>
                                  <Container className="flex items-center flex-row">
                                    <span className="m-0 font-semibold w-24 md:w-16 flex items-center justify-center">
                                      2 <StarIcon sx={{ fontSize: "15px" }} />
                                    </span>
                                    <LinearProgress
                                      variant="determinate"
                                      value={
                                        (ratingsData?.ratingCounts[2] /
                                          ratingsData?.noOfRatings) *
                                        100
                                      }
                                      sx={{
                                        width: "100%",
                                        maxWidth: "500px",
                                        borderRadius: "4px",
                                        backgroundColor: "#ff61614a",
                                        "& .MuiLinearProgress-bar": {
                                          backgroundColor: "#ff6161",
                                        },
                                      }}
                                      className="mx-2"
                                    />
                                    <span className="m-0 text-zinc-500">
                                      {ratingsData
                                        ? ratingsData.ratingCounts[2]
                                        : 0}
                                    </span>
                                  </Container>
                                  <Container className="flex items-center flex-row">
                                    <span className="m-0 font-semibold w-24 md:w-16 flex items-center justify-center">
                                      1 <StarIcon sx={{ fontSize: "15px" }} />
                                    </span>
                                    <LinearProgress
                                      variant="determinate"
                                      value={
                                        (ratingsData?.ratingCounts[1] /
                                          ratingsData?.noOfRatings) *
                                        100
                                      }
                                      sx={{
                                        width: "100%",
                                        maxWidth: "500px",
                                        borderRadius: "4px",
                                        backgroundColor: "#ff00004a",
                                        "& .MuiLinearProgress-bar": {
                                          backgroundColor: "#ff0000",
                                        },
                                      }}
                                      className="mx-2"
                                    />
                                    <span className="m-0 text-zinc-500">
                                      {ratingsData
                                        ? ratingsData.ratingCounts[1]
                                        : 0}
                                    </span>
                                  </Container>
                                </Container>
                              </Container>

                              <hr className="border-dashed border-[#352210] mb-3" />
                              <Container>
                                <p className="font-semibold mb-3">Reviews</p>
                                {ratingsData?.reviews.map((item, i) => (
                                  <Container className="mb-4" key={i}>
                                    <Container className="flex items-center">
                                      <span
                                        className={`px-2 py-1 text-xs sm:text-sm text-white rounded mb-2 ${
                                          item.star === 1
                                            ? "bg-[#ff0000]"
                                            : item.star === 2
                                            ? "bg-[#ff6161]"
                                            : item.star === 3
                                            ? "bg-[eea300]"
                                            : item.star === 4
                                            ? "bg-[#388e3c]"
                                            : "bg-[#388e3c]"
                                        }`}
                                      >
                                        {item.star}{" "}
                                        <StarIcon sx={{ fontSize: "12px" }} />
                                      </span>
                                    </Container>
                                    <p className="text-base font-semibold mb-1">
                                      {item.review}
                                    </p>
                                    <p className="text-sm font-medium">
                                      {item.userInfo?.email}
                                    </p>
                                    <p className="text-sm text-slate-400">
                                      {item.date}
                                    </p>
                                    <hr className="border-dashed mt-1" />
                                  </Container>
                                ))}
                              </Container>
                            </Container>
                          )}
                        </Container>
                      </Container>
                    </Container>
                  </Container>
                )}
              </Container>
            )}

            <Modal
              open={loginModal}
              onClose={() => setIsLoginModal(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <p id="modal-modal-description">Please login to continue.</p>
                <Container className="flex justify-end">
                  <Button
                    onClick={handleLoginPage}
                    className="bg-[#352210] px-6 py-1 rounded-lg text-white font-semibold active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out"
                  >
                    Login
                  </Button>
                </Container>
              </Box>
            </Modal>
          </Container>
        ) : (
          <Container className="min-h-[65vh] flex flex-col justify-center items-center">
            <NoInternetComp />
          </Container>
        )}
      </Container>
    </>
  );
}

export default ProductDetails;
