import React, { useState, useEffect } from "react";
import { MdOutlineDeleteOutline, MdOutlineShoppingCart } from "react-icons/md";
import { Button, Container } from "reactstrap";
import { Link } from "react-router-dom";
import config from "../../config.json";
import LoadingSpinner from "../../components/common/Admin/common/LoadingSpinner";
import { useContext } from "react";
import { GlobalContext } from "../../components/layouts/UserLayout";
import {
  getServices,
  postServices,
  removeServices,
} from "../../apiServices/apiServices";
import toast from "react-hot-toast";
import NoInternetComp from "../../components/common/InternetConnetction/NoInternetComp";

function FavoritesPage() {
  const access_token = localStorage.getItem("user-access-token");
  const [wishlistData, setWishlistData] = useState([]);
  const url = config.url;
  const [isLoading, setIsLoading] = useState(false);
  const global_context = useContext(GlobalContext);

  useEffect(() => {
    if (access_token && navigator.onLine) {
      fetchWishlist();
    }

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [navigator]);

  const fetchWishlist = async () => {
    setIsLoading(true);
    try {
      const res = await getServices(
        `/api/wishlist/list_wishlist`,
        access_token
      );
      setIsLoading(false);
      if (res.responseCode === 200) {
        setWishlistData(res.responseData);
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleDeleteWishlist = async (id) => {
    if (window.confirm("Are you sure want to remove this product?")) {
      try {
        const res = await removeServices(
          `/api/wishlist/remove_wishlist/${id}`,
          access_token
        );
        if (res.responseCode === 200) {
          fetchWishlist();
        } else {
          toast.error(res.responseMessage);
        }
      } catch (error) {
        toast.error(error);
      }
    }
  };

  const handleAddtoCart = async (data) => {
    try {
      const res = await postServices(`/api/cart/add_cart`, access_token, {
        product_id: data.product.product_id,
        color_id: data.product.colorInfo.product_color_id,
        variation_id: data.product.variationInfo.variation_id,
      });
      if (res.responseCode === 200) {
        toast.success("Product added to Cart successfully...");
        global_context.fetchCartData();
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <>
      <Container className="pt-2">
        {navigator.onLine ? (
          <Container>
            {access_token ? (
              <Container>
                {isLoading ? (
                  <Container className="flex justify-center items-center min-h-[65vh]">
                    <LoadingSpinner />
                  </Container>
                ) : (
                  <Container className="mb-3 min-h-[65vh]">
                    {wishlistData.length <= 0 ? (
                      <Container className="flex justify-center items-center h-[65vh] flex-col">
                        <p className="font-semibold mb-4">
                          Your Wishlist is empty
                        </p>
                        <Link to="/">
                          <Button className="bg-[#352210] font-semibold text-white py-2 px-10 rounded active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out">
                            Shop Now
                          </Button>
                        </Link>
                      </Container>
                    ) : (
                      <Container className="flex justify-center flex-col gap-3 items-center px-2 sm:px-0">
                        {wishlistData.map((item, i) => (
                          <Container
                            className="bg-[#FFFAEF] w-full sm:w-3/4 px-4 py-2 rounded-md relative"
                            key={i}
                          >
                            <Link
                              to="/product_details"
                              state={{ product_id: item.product?.product_id }}
                            >
                              <Container className="flex gap-2 items-center">
                                <img
                                  src={
                                    url + item.product.variationInfo.image[0]
                                  }
                                  className="aspect-square w-16 sm:w-24 object-cover"
                                  alt="wishlist porduct preview"
                                />
                                <Container>
                                  <p className="font-semibold text-sm sm:text-base capitalize">
                                    {item.product?.product_name}
                                  </p>
                                  <p className="text-xs sm:text-sm text-[#626262]">
                                    {item.product.colorInfo.color}
                                  </p>
                                  <p className="text-xs sm:text-sm text-[#868686]">
                                    {item.product.variationInfo.variation} inch
                                  </p>
                                  <Container className="flex gap-2 text-sm sm:text-base items-start">
                                    <p className="font-semibold">
                                      ₹{" "}
                                      {item.product?.variationInfo
                                        ?.sales_price > 0
                                        ? item.product?.variationInfo
                                            ?.sales_price
                                        : item.product?.variationInfo?.price}
                                    </p>
                                    {item.product?.variationInfo?.sales_price >
                                      0 && (
                                      <p className="line-through text-xs text-[#757575]">
                                        ₹{" "}
                                        {item.product?.variationInfo
                                          ?.sales_price > 0
                                          ? item.product?.variationInfo?.price
                                          : null}
                                      </p>
                                    )}
                                    {item.product.variationInfo.offer_value >
                                      0 && (
                                      <p className="font-medium">
                                        {item.product.variationInfo
                                          .offer_value + "% OFF"}
                                      </p>
                                    )}
                                  </Container>
                                </Container>
                              </Container>
                            </Link>

                            <Container className="absolute right-3 bottom-3 flex gap-3">
                              <MdOutlineShoppingCart
                                className="text-3xl sm:text-4xl bg-[#352210] text-white p-1 rounded-full cursor-pointer"
                                onClick={() => handleAddtoCart(item)}
                              />
                              <MdOutlineDeleteOutline
                                className="text-3xl sm:text-4xl bg-[#352210] text-white p-1 rounded-full cursor-pointer"
                                onClick={() =>
                                  handleDeleteWishlist(item.wishlist_id)
                                }
                              />
                            </Container>
                          </Container>
                        ))}
                      </Container>
                    )}
                  </Container>
                )}
              </Container>
            ) : (
              <Container className="flex justify-center items-center h-[65vh] flex-col">
                <p className="font-semibold mb-4">Please login to continue.</p>
                <Link to="/login">
                  <Button className="bg-[#352210] font-semibold text-white py-2 px-10 rounded active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out">
                    Login
                  </Button>
                </Link>
              </Container>
            )}
          </Container>
        ) : (
          <Container className="min-h-[65vh] flex flex-col justify-center items-center">
            <NoInternetComp />
          </Container>
        )}
      </Container>
    </>
  );
}

export default FavoritesPage;
