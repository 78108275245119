import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import NoInternetComp from "../../components/common/InternetConnetction/NoInternetComp";
import LoadingSpinner from "../../components/common/Admin/common/LoadingSpinner";
import { Box, Breadcrumbs, Tab, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import { LuRotateCw, LuZoomIn, LuZoomOut } from "react-icons/lu";
import ReactPlayer from "react-player";
import { getServices } from "../../apiServices/apiServices";
import toast from "react-hot-toast";
import config from "../../config.json";

function GalleryPage() {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("1");
  const [imageData, setImageData] = useState([]);
  const [videoData, setVideoData] = useState([]);
  const url = config.url;

  useEffect(() => {
    if (navigator.onLine) {
      fetchGalleryData();
    }
  }, [navigator]);

  const fetchGalleryData = async () => {
    try {
      const res = await getServices(`/api/gallery/file/view`);
      if (res.responseCode === 200) {
        setImageData(
          res.responseData.filter((item) =>
            item.gallery_img.match(/\.(jpeg|jpg|gif|png)$/i)
          )
        );
        setVideoData(
          res.responseData.filter((item) =>
            item.gallery_img.match(/\.(mp4|webm|ogg)$/i)
          )
        );
      } else {
        setImageData([]);
        setVideoData([]);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {navigator.onLine ? (
        <Container>
          {loading ? (
            <Container className="flex items-center justify-center min-h-[65vh]">
              <LoadingSpinner />
            </Container>
          ) : (
            <Container className="min-h-[65vh]">
              <Container className="flex justify-center">
                <Container className="w-full sm:w-3/4 py-2 flex justify-between items-center px-3 sm:px-0">
                  <Breadcrumbs
                    separator=">"
                    aria-label="breadcrumb"
                    sx={{
                      fontFamily: "Poppins",
                    }}
                  >
                    <Link underline="hover" color="inherit" to="/profile">
                      Profile
                    </Link>
                    <Typography
                      color="text.primary"
                      sx={{
                        fontFamily: "Poppins",
                      }}
                    >
                      Gallery
                    </Typography>
                  </Breadcrumbs>
                </Container>
              </Container>

              <Container className="flex justify-center flex-col gap-3 items-center px-2 sm:px-0 mb-4">
                <Container className="w-full sm:w-3/4">
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                        sx={{
                          "& .MuiTab-root": {
                            fontFamily: "Poppins",
                          },
                          "& .MuiTab-root.Mui-selected": {
                            color: "#000",
                          },
                          "& .MuiTabs-indicator": {
                            backgroundColor: "#000",
                          },
                        }}
                      >
                        <Tab label="Albums" value="1" />
                        <Tab label="Videos" value="2" />
                      </TabList>
                    </Box>
                    <TabPanel value="1" sx={{ padding: "14px 0" }}>
                      {imageData.length > 0 ? (
                        <PhotoProvider
                          toolbarRender={({
                            onScale,
                            scale,
                            rotate,
                            onRotate,
                          }) => {
                            return (
                              <div className="flex gap-4">
                                <LuZoomIn
                                  onClick={() => onScale(scale + 1)}
                                  className="cursor-pointer"
                                />
                                <LuZoomOut
                                  onClick={() => onScale(scale - 1)}
                                  className="cursor-pointer"
                                />
                                <LuRotateCw
                                  onClick={() => onRotate(rotate + 90)}
                                  className="cursor-pointer"
                                />
                              </div>
                            );
                          }}
                          maskOpacity={0.8}
                        >
                          <div className="image-gallery">
                            {imageData.map((image, i) => (
                              <div className="image-box" key={i}>
                                <PhotoView src={url + image.gallery_img}>
                                  <img src={url + image.gallery_img} alt="" />
                                </PhotoView>
                              </div>
                            ))}
                          </div>
                        </PhotoProvider>
                      ) : (
                        <div>
                          <p className="text-center font-semibold">
                            No Images Found...
                          </p>
                        </div>
                      )}
                    </TabPanel>
                    <TabPanel value="2" sx={{ padding: "14px 0" }}>
                      {videoData.length > 0 ? (
                        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5">
                          {videoData.map((video, i) => (
                            <div key={i}>
                              <ReactPlayer
                                url={url + video.gallery_img}
                                controls
                                width={"100%"}
                                height="100%"
                              />
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div>
                          <p className="text-center font-semibold">
                            No Videos Found...
                          </p>
                        </div>
                      )}
                    </TabPanel>
                  </TabContext>
                </Container>
              </Container>

              {/* {data.length > 0 ? (
                <Container className="flex justify-center flex-col gap-3 items-center px-2 sm:px-0 mb-4">
                  {data.map((item, i) => (
                    <Container
                      className="bg-[#FFFAEF] w-full sm:w-3/4 px-4 py-2 flex flex-col gap-2 items-start relative rounded-md"
                      key={i}
                    ></Container>
                  ))}
                </Container>
              ) : (
                <Container className="flex justify-center items-center h-[65vh]">
                  <p className="font-semibold text-lg">No Orders found...</p>
                </Container>
              )} */}
            </Container>
          )}
        </Container>
      ) : (
        <Container className="min-h-[65vh] flex flex-col justify-center items-center">
          <NoInternetComp />
        </Container>
      )}
    </>
  );
}

export default GalleryPage;
