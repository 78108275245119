import React from "react";
import { Button, Container } from "reactstrap";
import successImg from "../../components/assets/order_confirmed2.svg";
import { useNavigate } from "react-router-dom";

function OrderPlacedSuccessful() {
  const navigate = useNavigate();

  const handleGoToHomePage = () => {
    navigate("/");
  };

  return (
    <Container className="flex flex-col justify-center items-center text-center h-[100vh]">
      <img src={successImg} className="w-2/3 sm:w-1/3" />
      <p className="text-2xl font-semibold">
        Your Order has been placed successfully!
      </p>
      <Container className="mt-7 text-gray-500">
        <p>Your order has been successfully completed.</p>
        <p>Within moments you will receive a notification.</p>
      </Container>
      <Button
        onClick={handleGoToHomePage}
        className="outline-none mt-5 bg-[#352210] w-2/3 sm:w-1/4 text-white font-semibold py-3 uppercase rounded-xl active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out"
      >
        Continue Shopping
      </Button>
    </Container>
  );
}

export default OrderPlacedSuccessful;
