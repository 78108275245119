import React, { useState, useEffect } from "react";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { Button, Container } from "reactstrap";
import config from "../../config.json";
import { HiOutlineMinus, HiOutlinePlus } from "react-icons/hi";
import "./style.css";
import LoadingSpinner from "../../components/common/Admin/common/LoadingSpinner";
import { Link, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { GlobalContext } from "../../components/layouts/UserLayout";
import {
  getServices,
  removeServices,
  updateServices,
} from "../../apiServices/apiServices";
import toast from "react-hot-toast";
import NoInternetComp from "../../components/common/InternetConnetction/NoInternetComp";

function CartPage() {
  const access_token = localStorage.getItem("user-access-token");
  const ship_rocket_access_token = localStorage.getItem(
    "user-shiprocket-access-token"
  );
  const [data, setData] = useState([]);
  const url = config.url;
  const [isLoading, setIsLoading] = useState(false);
  const global_context = useContext(GlobalContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (access_token && navigator.onLine) {
      fetchCartData(true);
    }

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [navigator]);

  const fetchCartData = async (load) => {
    setIsLoading(load);
    try {
      const res = await getServices(
        `/api/cart/list_cart`,
        access_token,
        ship_rocket_access_token
      );
      setIsLoading(false);
      if (res.responseCode === 200) {
        setData(res.responseData);
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error);
    }
  };

  const handleQuantityIncrease = async (data) => {
    const increasedQuantity = data.product_qty + 1;

    try {
      const res = await updateServices(
        `/api/cart/update_cart/${data.cart_id}`,
        access_token,
        { product_qty: increasedQuantity.toString() }
      );
      if (res.responseCode === 200) {
        fetchCartData(false);
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleQuantityDecrease = async (data) => {
    const decreasedQuantity = data.product_qty - 1;

    try {
      const res = await updateServices(
        `/api/cart/update_cart/${data.cart_id}`,
        access_token,
        { product_qty: decreasedQuantity.toString() }
      );
      if (res.responseCode === 200) {
        fetchCartData(false);
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleCartDelete = async (cart_id) => {
    if (window.confirm("Are you sure you want to remove this item?")) {
      try {
        const res = await removeServices(
          `/api/cart/remove_cart/${cart_id}`,
          access_token
        );
        if (res.responseCode === 200) {
          fetchCartData(true);
          global_context.fetchCartData();
        } else {
          toast.error(res.responseMessage);
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  const handlePlaceOrder = () => {
    navigate("/manage_address", { state: { is_cart: 1 } });
  };

  return (
    <>
      <Container className="pt-2">
        {navigator.onLine ? (
          <Container>
            {access_token ? (
              <Container>
                {isLoading ? (
                  <Container className="flex items-center justify-center h-[66vh]">
                    <LoadingSpinner />
                  </Container>
                ) : (
                  <Container className="p-4 sm:px-8">
                    {data.count <= 0 ? (
                      <Container className="flex justify-center items-center h-[65vh] flex-col">
                        <p className="font-semibold mb-4">Yout Cart is empty</p>
                        <Link to="/">
                          <Button className="bg-[#352210] font-semibold text-white py-2 px-10 rounded active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out">
                            Shop Now
                          </Button>
                        </Link>
                      </Container>
                    ) : (
                      <Container className="cart-details-cards min-h-[55vh]">
                        <Container className="cart-details-first-card sm:pr-2 mb-4">
                          <Container className="p-2 sm:p-5 bg-white rounded-xl shadow-lg sticky top-28 bottom-0">
                            <Container className="flex flex-col gap-4 items-end">
                              {data.data?.map((item, i) => (
                                <Container
                                  className="bg-[#FFFAEF] w-full sm:col-span-2 px-4 py-2 flex gap-2 items-center relative rounded-md"
                                  key={i}
                                >
                                  <Link
                                    to="/product_details"
                                    state={{
                                      product_id: item.product.product_id,
                                    }}
                                  >
                                    <img
                                      src={
                                        url +
                                        item.product.variationInfo.image[0]
                                      }
                                      alt="cart product preview"
                                      className="aspect-square w-20 sm:w-32 object-cover"
                                    />
                                  </Link>
                                  <Container>
                                    <Link
                                      to="/product_details"
                                      state={{
                                        product_id: item.product.product_id,
                                      }}
                                    >
                                      <p className="font-semibold text-sm sm:text-base capitalize">
                                        {item.product.product_name}
                                      </p>
                                      <p className="text-xs sm:text-sm text-[#626262]">
                                        {item.product.colorInfo.color}
                                      </p>
                                      <p className="text-xs sm:text-sm text-[#868686]">
                                        {item.product.variationInfo.variation}{" "}
                                        inch
                                      </p>
                                    </Link>
                                    <Container className="text-sm sm:text-base flex">
                                      <Container
                                        className="flex items-center p-1 justify-between rounded-lg"
                                        style={{
                                          fontFamily: "Poppins",
                                        }}
                                      >
                                        <Button
                                          className="inline-flex items-center justify-center border-none outline-none text-center p-1 text-lg disabled:text-gray-400 disabled:cursor-not-allowed"
                                          disabled={
                                            item.product_qty <= 1 ? true : false
                                          }
                                          onClick={() =>
                                            handleQuantityDecrease(item)
                                          }
                                        >
                                          <HiOutlineMinus />
                                        </Button>
                                        <p className="py-1 px-3">
                                          {item.product_qty}
                                        </p>
                                        <Button
                                          className="inline-flex items-center justify-center border-none outline-none text-center p-1 text-lg disabled:text-gray-400 disabled:cursor-not-allowed"
                                          onClick={() =>
                                            handleQuantityIncrease(item)
                                          }
                                        >
                                          <HiOutlinePlus />
                                        </Button>
                                      </Container>
                                    </Container>
                                    <Container className="flex gap-2 text-sm sm:text-base items-start">
                                      <p className="font-semibold">
                                        ₹{" "}
                                        {item.product?.variationInfo
                                          ?.sales_price > 0
                                          ? item.product?.variationInfo
                                              ?.sales_price
                                          : item.product?.variationInfo?.price}
                                      </p>
                                      {item.product?.variationInfo
                                        ?.sales_price > 0 && (
                                        <p className="line-through text-xs text-[#757575]">
                                          ₹{" "}
                                          {item.product?.variationInfo
                                            ?.sales_price > 0
                                            ? item.product?.variationInfo?.price
                                            : null}
                                        </p>
                                      )}
                                      {item.product.variationInfo.offer_value >
                                        0 && (
                                        <p className="font-medium">
                                          {item.product.variationInfo
                                            .offer_value + "% OFF"}
                                        </p>
                                      )}
                                    </Container>
                                  </Container>

                                  <Container className="absolute right-3 bottom-3 flex gap-3">
                                    <MdOutlineDeleteOutline
                                      className="text-3xl sm:text-4xl bg-[#352210] text-white p-1 rounded-full cursor-pointer"
                                      onClick={() =>
                                        handleCartDelete(item.cart_id)
                                      }
                                    />
                                  </Container>
                                </Container>
                              ))}
                            </Container>
                          </Container>
                        </Container>
                        <Container className="cart-details-second-card">
                          <Container className="lg:mt-0 p-5 shadow-lg mb-6 sm:mb-3 bg-[#FFFAEF] mt-4 sm:col-span-1 w-full px-4 py-2 flex flex-col gap-2 rounded-md sticky top-28 bottom-0">
                            <p className="font-semibold text-base mb-3">
                              Price Details
                            </p>
                            <Container className="flex justify-between text-sm">
                              <p>Total MRP</p>
                              <p className="font-semibold">
                                ₹ {data?.totalMRP}
                              </p>
                            </Container>
                            <Container className="flex justify-between text-sm">
                              <p>Discount MRP</p>
                              <p className="font-semibold">
                                -₹ {data.discountAmt}
                              </p>
                            </Container>
                            <Container className="flex justify-between text-sm">
                              <p>Shipping Fee</p>
                              <p className="font-semibold">
                                Calculated at next step
                              </p>
                            </Container>
                            <hr className="border border-[#352210] my-2" />
                            <Container className="flex justify-between font-semibold">
                              <p>Total Amount</p>
                              <p>₹ {data.totalPayableAmt}</p>
                            </Container>
                            <Button
                              className="w-full bg-[#352210] my-2 py-2 rounded-xl uppercase text-white font-semibold active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out"
                              onClick={handlePlaceOrder}
                            >
                              Place order
                            </Button>
                          </Container>
                        </Container>
                      </Container>
                    )}
                  </Container>
                )}
              </Container>
            ) : (
              <Container className="flex justify-center items-center h-[65vh] flex-col">
                <p className="font-semibold mb-4">Please login to continue.</p>
                <Link to="/login">
                  <Button className="bg-[#352210] font-semibold text-white py-2 px-10 rounded active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out">
                    Login
                  </Button>
                </Link>
              </Container>
            )}
          </Container>
        ) : (
          <Container className="min-h-[65vh] flex flex-col justify-center items-center">
            <NoInternetComp />
          </Container>
        )}
      </Container>
    </>
  );
}

export default CartPage;
