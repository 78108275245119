import { Box, TextField } from "@mui/material";
import React from "react";
import { Button, Container, Form } from "reactstrap";
import ImageUploader from "../../common/ImageUploader";

function CategoryFrom(props) {
  return (
    <Container>
      <Form onSubmit={props.handleSubmit}>
        <Box
          sx={{
            maxWidth: "100%",
          }}
          className="mx-8"
        >
          <TextField
            fullWidth
            name="category_name"
            label="Category Name"
            id="fullWidth"
            sx={{
              "& .MuiInputLabel-root": {
                fontFamily: "Poppins",
              },
              "& .MuiInputLabel-root": {
                fontFamily: "Poppins",
              },
              "& .MuiOutlinedInput-root": {
                fontFamily: "Poppins",
                borderRadius: "12px",
              },
              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#352210",
                },
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#352210",
                },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #352210",
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "#352210",
              },
              marginBottom: "20px",
            }}
            onChange={props.handleChange}
            value={props.data?.name}
            required
          />
          <Container className="mb-5">
            <ImageUploader
              handleDrop={props.handleDrop}
              handleDragOver={props.handleDragOver}
              handleFileSelect={props.handleFileSelect}
              handleRemoveImage={props.handleRemoveImage}
              selectedImages={props.selectedImages}
              multiple={false}
            />
          </Container>
          <Container className="flex justify-end items-center mb-5">
            <Button
              type="submit"
              className="px-8 py-2 bg-slate-800 text-white font-semibold rounded-lg active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out disabled:cursor-not-allowed disabled:bg-slate-500"
              disabled={
                props.data.name.length < 1 || props.selectedImages.length !== 1
                  ? true
                  : false
              }
            >
              Save
            </Button>
          </Container>
        </Box>
      </Form>
    </Container>
  );
}

export default CategoryFrom;
