import React, { useState, useEffect } from "react";
import { Container, Input } from "reactstrap";
import { BiSearchAlt } from "react-icons/bi";
import { IoMdCloseCircleOutline, IoMdHeart } from "react-icons/io";
import config from "../../config.json";
import { Link } from "react-router-dom";
import VariationCustomBottomSheet from "../../components/common/Admin/common/VariationCustomBottomSheet";
import { nNumber } from "../../variables/constants";
import { getServices } from "../../apiServices/apiServices";
import toast from "react-hot-toast";
import NoInternetComp from "../../components/common/InternetConnetction/NoInternetComp";

function SearchPage() {
  const [data, setData] = useState({ search: "" });
  const [searchData, setSearchData] = useState([]);
  const url = config.url;
  const [isBottomSheet, setIsBottomSheet] = useState(false);
  const [variationData, setVariationData] = useState();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const handleChange = async (e) => {
    setData({ ...data, search: e.target.value });

    if (e.target.value === "") {
      setSearchData([]);
    } else {
      try {
        const res = await getServices(
          `/api/products/search/${e.target.value}?page=1&limit=${nNumber}`
        );
        if (res.responseCode === 200) {
          setSearchData(res.responseData.rows);
        } else if (res.responseCode === 206) {
          setSearchData([]);
        } else {
          toast.error(res.responseMessage);
        }
      } catch (error) {
        toast.error(error);
      }
    }
  };

  const handleAddWishlistData = async (data) => {
    setIsBottomSheet(true);
    setVariationData(data);
  };

  return (
    <>
      <Container className="pt-2">
        {navigator.onLine ? (
          <Container>
            <Container className="flex justify-center mx-4 items-center">
              <Input
                className="border-[#352210] border-2 rounded-full outline-none px-5 py-3 w-80 sm:w-96 relative bg-[#FFFAEF]"
                placeholder="Search"
                value={data.search}
                onChange={handleChange}
                autoFocus
              />
              {data.search.length > 0 ? (
                <IoMdCloseCircleOutline
                  className="absolute -mr-[280px] sm:-mr-[330px] text-[#757575] cursor-pointer"
                  onClick={() => {
                    setData({ ...data, search: "" });
                    setSearchData([]);
                  }}
                />
              ) : (
                <BiSearchAlt className="absolute -mr-[280px] sm:-mr-[330px] text-[#757575]" />
              )}
            </Container>
            {searchData.length >= 1 ? (
              <Container className="search-cards my-5 px-2 sm:px-5 pb-10 min-h-[40vh]">
                {searchData.map((item, i) => (
                  <Container className="search-card relative" key={i}>
                    <Link
                      to="/product_details"
                      state={{ product_id: item.product_id }}
                    >
                      <img
                        src={
                          url +
                          item.productColorInfo[0].productDataInfo[0].image[0]
                        }
                        alt="product"
                      />
                      <Container className="flex items-center justify-between px-4 pb-4">
                        <Container>
                          <p className="text-sm capitalize font-semibold">
                            {item.product_name}
                          </p>
                          <Container className="flex items-center gap-4">
                            <p className="text-[#757575] text-sm">
                              {
                                item.productColorInfo[0].productDataInfo[0]
                                  .variation
                              }{" "}
                              inch
                            </p>
                            <p className="font-semibold text-sm">
                              ₹{" "}
                              {
                                item.productColorInfo[0].productDataInfo[0]
                                  .price
                              }
                            </p>
                          </Container>
                        </Container>
                      </Container>
                    </Link>
                    <Container className="absolute bottom-4 right-3">
                      <IoMdHeart
                        className="text-white p-2 text-4xl bg-[#352210] rounded-full cursor-pointer"
                        onClick={() => handleAddWishlistData(item)}
                      />
                    </Container>
                  </Container>
                ))}
              </Container>
            ) : (
              <Container className="flex justify-center items-center my-5 text-lg font-semibold min-h-[55vh]">
                No data found...
              </Container>
            )}

            <VariationCustomBottomSheet
              isBottomNav={isBottomSheet}
              setIsBottomNav={() => setIsBottomSheet(false)}
              data={variationData}
            />
          </Container>
        ) : (
          <Container className="min-h-[65vh] flex flex-col justify-center items-center">
            <NoInternetComp />
          </Container>
        )}
      </Container>
    </>
  );
}

export default SearchPage;
