import React, { useEffect, useState } from "react";
import { FiUsers } from "react-icons/fi";
import { TbTruckDelivery } from "react-icons/tb";
import { MdOutlineLocalGroceryStore } from "react-icons/md";
import { GiReceiveMoney } from "react-icons/gi";
import { BiBuildings } from "react-icons/bi";
import ReactApexChart from "react-apexcharts";
import { lineChartOptions } from "../../../../variables/charts";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { Container } from "reactstrap";
import { getServices } from "../../../../apiServices/apiServices";
import { nNumber } from "../../../../variables/constants";
import toast from "react-hot-toast";
import NoInternetComp from "../../InternetConnetction/NoInternetComp";

function DashboardView() {
  const [isChartData, setIsChartData] = useState([]);
  const [isPieChartData, setIsPieChartData] = useState([]);
  const [isChartOptions, setIsChartOptions] = useState({});
  const [isOrdersLength, setIsOrdersLength] = useState(0);
  const [isProductsLength, setIsProductsLength] = useState(0);
  const [isUsersLength, setIsUsersLength] = useState(0);
  const access_token = sessionStorage.getItem("access-token");
  const date = new Date();

  ChartJS.register(ArcElement, Tooltip, Legend);

  const pieChartData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Orders",
        data: isPieChartData,
        backgroundColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
          "#29b7d7",
          "#20a772",
          "#fcac2c",
          "#fb5937",
          "#8e2df9",
          "#1063bc",
        ],
      },
    ],
  };

  useEffect(() => {
    setIsChartOptions(lineChartOptions);

    if (navigator.onLine) {
      fetchOrderData();
      fetchProductData();
      fetchusersData();
      fetchSalesAnalytics();
      fetchOrdersAnalytics();
    }
  }, [navigator]);

  const fetchSalesAnalytics = async () => {
    try {
      const res = await getServices("/api/analysis/revenue", access_token);
      if (res.responseCode === 200) {
        setIsChartData(res?.responseData);
      } else {
        setIsChartData([]);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const fetchOrdersAnalytics = async () => {
    try {
      const res = await getServices(`/api/analysis/order`, access_token);
      if (res.responseCode === 200) {
        setIsPieChartData(res.responseData?.data);
      } else {
        setIsPieChartData([]);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const fetchOrderData = async () => {
    try {
      const res = await getServices(
        `/api/orders/list_all_orders?filter_type=`,
        access_token
      );
      if (res.responseCode === 200) {
        setIsOrdersLength(res.responseData.rows.length);
      } else {
        setIsOrdersLength(0);
      }
    } catch (error) {
      toast.error(error);
    }
  };
  const fetchProductData = async () => {
    try {
      const res = await getServices(
        `/api/products/list_products?limit=${nNumber}&pending=false`,
        access_token
      );
      if (res.responseCode === 200) {
        setIsProductsLength(res.responseData.count);
      } else {
        setIsProductsLength(0);
      }
    } catch (error) {
      toast.error(error);
    }
  };
  const fetchusersData = async () => {
    try {
      const res = await getServices(`/api/user/user_details`, access_token);
      if (res.responseCode === 200) {
        setIsUsersLength(res.responseData.count);
      } else {
        setIsUsersLength(0);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <>
      {navigator.onLine ? (
        <Container>
          <p className="p-8 text-2xl font-semibold">Dashboard</p>
          <Container
            className={`grid sm:grid-cols-2 md:grid-cols-5 lg:grid-cols-5 gap-4 mx-4`}
          >
            <Container className="md:col-span-2 lg:col-span-1 xl:col-span-1">
              <Container className="bg-white shadow rounded-md w-full p-4 relative">
                <Container className="flex justify-between xl:gap-x-2 items-center">
                  <Container className="relative overflow-hidden text-transparent -m-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-hexagon h-20 w-20 fill-slate-500/10 group-hover:fill-white/10 mx-auto rotate-90 relative top-1"
                    >
                      <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                    </svg>
                    <Container className="absolute top-2/4 -translate-y-2/4 left-0 right-0 mx-auto text-slate-600 rounded-xl group-hover:text-white transition-all duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
                      <FiUsers />
                    </Container>
                  </Container>
                  <Container className="self-center ml-auto">
                    <h3 className="my-1 font-semibold text-2xl text-right">
                      {isUsersLength > 0 ? isUsersLength : 0}
                    </h3>
                    <p className="text-gray-400 mb-0 font-medium text-end">
                      Users
                    </p>
                  </Container>
                </Container>
              </Container>
            </Container>

            <Container className="md:col-span-2 lg:col-span-1 xl:col-span-1">
              <Container className="bg-white shadow rounded-md w-full p-4 relative">
                <Container className="flex justify-between xl:gap-x-2 items-center">
                  <Container className="relative overflow-hidden text-transparent -m-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-hexagon h-20 w-20 fill-slate-500/10 group-hover:fill-white/10 mx-auto rotate-90 relative top-1"
                    >
                      <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                    </svg>
                    <Container className="absolute top-2/4 -translate-y-2/4 left-0 right-0 mx-auto text-slate-600 rounded-xl group-hover:text-white transition-all duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
                      <TbTruckDelivery />
                    </Container>
                  </Container>
                  <Container className="self-center ml-auto">
                    <h3 className="my-1 font-semibold text-2xl text-right">
                      {isOrdersLength > 0 ? isOrdersLength : 0}
                    </h3>
                    <p className="text-gray-400 mb-0 font-medium text-end">
                      Total orders
                    </p>
                  </Container>
                </Container>
              </Container>
            </Container>

            <Container className="md:col-span-2 lg:col-span-1 xl:col-span-1">
              <Container className="bg-white shadow rounded-md w-full p-4 relative">
                <Container className="flex justify-between xl:gap-x-2 items-center">
                  <Container className="relative overflow-hidden text-transparent -m-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-hexagon h-20 w-20 fill-slate-500/10 group-hover:fill-white/10 mx-auto rotate-90 relative top-1"
                    >
                      <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                    </svg>
                    <Container className="absolute top-2/4 -translate-y-2/4 left-0 right-0 mx-auto text-slate-600 rounded-xl group-hover:text-white transition-all duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
                      <MdOutlineLocalGroceryStore />
                    </Container>
                  </Container>
                  <Container className="self-center ml-auto">
                    <h3 className="my-1 font-semibold text-2xl text-right">
                      {isProductsLength > 0 ? isProductsLength : 0}
                    </h3>
                    <p className="text-gray-400 mb-0 font-medium text-end">
                      Total products
                    </p>
                  </Container>
                </Container>
              </Container>
            </Container>

            <Container className="md:col-span-2 lg:col-span-1 xl:col-span-1">
              <Container className="bg-white shadow rounded-md w-full p-4 relative">
                <Container className="flex justify-between xl:gap-x-2 items-center">
                  <Container className="relative overflow-hidden text-transparent -m-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-hexagon h-20 w-20 fill-slate-500/10 group-hover:fill-white/10 mx-auto rotate-90 relative top-1"
                    >
                      <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                    </svg>
                    <Container className="absolute top-2/4 -translate-y-2/4 left-0 right-0 mx-auto text-slate-600 rounded-xl group-hover:text-white transition-all duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
                      <GiReceiveMoney />
                    </Container>
                  </Container>
                  <Container className="self-center ml-auto">
                    <h3 className="my-1 font-semibold text-2xl text-right">
                      1M+
                    </h3>
                    <p className="text-gray-400 mb-0 font-medium text-end">
                      Total revenue
                    </p>
                  </Container>
                </Container>
              </Container>
            </Container>

            <Container className="md:col-span-2 lg:col-span-1 xl:col-span-1">
              <Container className="bg-white shadow rounded-md w-full p-4 relative">
                <Container className="flex justify-between xl:gap-x-2 items-center">
                  <Container className="relative overflow-hidden text-transparent -m-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-hexagon h-20 w-20 fill-slate-500/10 group-hover:fill-white/10 mx-auto rotate-90 relative top-1"
                    >
                      <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                    </svg>
                    <Container className="absolute top-2/4 -translate-y-2/4 left-0 right-0 mx-auto text-slate-600 rounded-xl group-hover:text-white transition-all duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
                      <BiBuildings />
                    </Container>
                  </Container>
                  <Container className="self-center ml-auto">
                    <h3 className="my-1 font-semibold text-2xl text-right">
                      10M+
                    </h3>
                    <p className="text-gray-400 mb-0 font-medium text-end">
                      Total assets
                    </p>
                  </Container>
                </Container>
              </Container>
            </Container>
          </Container>

          <Container className="grid sm:grid-cols-2 lg:grid-cols-3 grid-cols-1">
            <Container className="bg-white m-4 md:mx-4 my-4 md:my-10 rounded-lg shadow col-span-2 md:col-span-3 lg:col-span-3 xl:col-span-2">
              <Container className="pt-10 pb-5 px-5 text-zinc-700">
                <h3 className="text-xl font-bold">Sales Overview</h3>
                <h5 className="text-lg">
                  <span className="text-green-500">(+5) more</span> in{" "}
                  {date.getFullYear() + " - " + (date.getFullYear() + 1)}
                </h5>
              </Container>
              <ReactApexChart
                options={isChartOptions}
                series={isChartData}
                type="area"
                width="100%"
                height="440px"
              />
            </Container>
            <Container className="m-4 md:my-10 rounded-lg shadow col-span-2 md:col-span-3 lg:col-span-3 xl:col-span-1 bg-white">
              <h2 className="p-6 text-lg font-bold">Orders overview</h2>
              <Pie data={pieChartData} />
            </Container>
          </Container>
        </Container>
      ) : (
        <Container className="min-h-[65vh] flex flex-col justify-center items-center">
          <NoInternetComp />
        </Container>
      )}
    </>
  );
}

export default DashboardView;
