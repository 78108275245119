export const lineChartData = [
  {
    name: "Total Income",
    data: [10, 41, 35, 51, 49, 62, 69, 91, 148, 35, 51, 49],
  },
  {
    name: "Total Expenses",
    data: [10, 34, 13, 56, 77, 88, 99, 77, 45, 13, 56, 77],
  },
];

export const lineChartOptions = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  tooltip: {
    theme: "light",
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
    width: 2,
  },
  xaxis: {
    type: "datetime",
    categories: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    axisTicks: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
    labels: {
      style: {
        colors: "#545353",
        fontSize: "12px",
        fontFamily: "Poppins",
      },
    },
  },
  yaxis: {
    labels: {
      style: {
        colors: "#545353",
        fontSize: "12px",
        fontFamily: "Poppins",
      },
    },
  },
  legend: {
    show: true,
    fontFamily: "Poppins",
  },
  grid: {
    strokeDashArray: 4,
  },
  fill: {
    type: "gradient",
    gradient: {
      shade: "light",
      type: "vertical",
      shadeIntensity: 0.5,
      inverseColors: true,
      opacityFrom: 0.5,
      opacityTo: 0,
      stops: [],
    },
    colors: ["#00a76f", "#ffab00", "#fb6584"],
  },
  colors: ["#00a76f", "#ffab00", "#fb6584"],
  // colors: ["#fff", "#3182CE"],
};

// export const pieChartData = {
//   labels: [
//     "Jan",
//     "Feb",
//     "Mar",
//     "Apr",
//     "May",
//     "Jun",
//     "Jul",
//     "Aug",
//     "Sep",
//     "Oct",
//     "Nov",
//     "Dec",
//   ],
//   datasets: [
//     {
//       label: "Orders",
//       data: [12, 19, 3, 5, 2, 3, 9, 15, 2, 10, 5, 3],
//       backgroundColor: [
//         "rgba(255, 99, 132, 1)",
//         "rgba(54, 162, 235, 1)",
//         "rgba(255, 206, 86, 1)",
//         "rgba(75, 192, 192, 1)",
//         "rgba(153, 102, 255, 1)",
//         "rgba(255, 159, 64, 1)",
//         "#29b7d7",
//         "#20a772",
//         "#fcac2c",
//         "#fb5937",
//         "#8e2df9",
//         "#1063bc",
//       ],
//       // borderColor: [
//       //   "rgba(255, 99, 132, 1)",
//       //   "rgba(54, 162, 235, 1)",
//       //   "rgba(255, 206, 86, 1)",
//       //   "rgba(75, 192, 192, 1)",
//       //   "rgba(153, 102, 255, 1)",
//       //   "rgba(255, 159, 64, 1)",
//       // ],
//       // borderWidth: 1,
//     },
//   ],
// };
