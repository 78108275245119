import * as React from "react";
import { Box, FormControlLabel, Switch } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { TableHead } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { Container } from "reactstrap";
import { MdOutlineEdit } from "react-icons/md";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator() {
  return (a, b) => -descendingComparator(a, b);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "version_id",
    numeric: false,
    disablePadding: true,
    label: "Version Id",
  },
  {
    id: "android_version",
    numeric: true,
    disablePadding: false,
    label: "Android Version",
  },
  {
    id: "ios_version",
    numeric: true,
    disablePadding: false,
    label: "iOS Version",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    label: "Actions",
  },
];

function EnhancedTableHead() {
  return (
    <TableHead sx={{ backgroundColor: "#f7e1a3" }}>
      <TableRow>
        <TableCell padding="checkbox" />
        {headCells.map((headCell) => (
          <TableCell
            sx={{
              fontFamily: "poppins",
            }}
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            <TableSortLabel>{headCell.label}</TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function EnhancedTable(props) {
  const [order] = React.useState("asc");
  const [orderBy] = React.useState("id");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ width: "100%" }}>
      {props.isTableData.length >= 1 ? (
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer>
            <Container className="py-5 px-5 flex justify-center items-center bg-[#f7e1a3] font-semibold text-slate-800">
              <FormControlLabel
                control={
                  <Switch
                    focusVisibleClassName=".Mui-focusVisible"
                    checked={
                      props.updateVersionData?.maintenance_mode === 1
                        ? true
                        : false
                    }
                    onChange={props.handleDataChange}
                    sx={{
                      width: 50,
                      height: 26,
                      padding: 0,
                      marginRight: 1,
                      "& .MuiSwitch-switchBase": {
                        padding: 0,
                        margin: 0.2,
                        transitionDuration: "300ms",
                        "&.Mui-checked": {
                          transform: "translateX(24px)",
                          color: "#fff",
                          "& + .MuiSwitch-track": {
                            backgroundColor: "#ff3f56",
                            opacity: 1,
                            border: 0,
                          },
                          "&.Mui-disabled + .MuiSwitch-track": {
                            opacity: 0.5,
                          },
                        },
                        "&.Mui-focusVisible .MuiSwitch-thumb": {
                          color: "#33cf4d",
                          border: "6px solid #fff",
                        },
                        "&.Mui-disabled + .MuiSwitch-track": {
                          opacity: 0.7,
                        },
                      },
                      "& .MuiSwitch-thumb": {
                        boxSizing: "border-box",
                        width: 22,
                        height: 22,
                      },
                      "& .MuiSwitch-track": {
                        borderRadius: 26 / 2,
                        backgroundColor: "#E9E9EA",
                        opacity: 1,
                        duration: 500,
                      },
                    }}
                  />
                }
                sx={{
                  fontFamily: "Poppins",
                  ".css-ahj2mt-MuiTypography-root": { fontFamily: "Poppins" },
                }}
                label="Maintenance Mode"
              />
            </Container>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <EnhancedTableHead />
              <TableBody>
                {props.isTableData &&
                stableSort(props.isTableData, getComparator(order, orderBy)) &&
                Array.isArray(
                  stableSort(props.isTableData, getComparator(order, orderBy))
                )
                  ? stableSort(props.isTableData, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.version_id}
                            sx={{ cursor: "pointer" }}
                          >
                            <TableCell padding="checkbox" />
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                              sx={{
                                fontFamily: "poppins",
                              }}
                            >
                              {row.version_id}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontFamily: "poppins",
                              }}
                              align="center"
                            >
                              {Number(row.android_version)}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontFamily: "poppins",
                              }}
                              align="right"
                            >
                              {Number(row.ios_version)}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontFamily: "poppins",
                              }}
                              align="right"
                            >
                              <FormControlLabel
                                control={
                                  <Switch
                                    focusVisibleClassName=".Mui-focusVisible"
                                    checked={row.status === 1 ? true : false}
                                    onChange={(e) =>
                                      props.handleVersionStatusChange(e, row)
                                    }
                                    sx={{
                                      width: 50,
                                      height: 26,
                                      padding: 0,
                                      "& .MuiSwitch-switchBase": {
                                        padding: 0,
                                        margin: 0.2,
                                        transitionDuration: "300ms",
                                        "&.Mui-checked": {
                                          transform: "translateX(24px)",
                                          color: "#fff",
                                          "& + .MuiSwitch-track": {
                                            backgroundColor: "#ff3f56",
                                            opacity: 1,
                                            border: 0,
                                          },
                                          "&.Mui-disabled + .MuiSwitch-track": {
                                            opacity: 0.5,
                                          },
                                        },
                                        "&.Mui-focusVisible .MuiSwitch-thumb": {
                                          color: "#33cf4d",
                                          border: "6px solid #fff",
                                        },
                                        "&.Mui-disabled + .MuiSwitch-track": {
                                          opacity: 0.7,
                                        },
                                      },
                                      "& .MuiSwitch-thumb": {
                                        boxSizing: "border-box",
                                        width: 22,
                                        height: 22,
                                      },
                                      "& .MuiSwitch-track": {
                                        borderRadius: 26 / 2,
                                        backgroundColor: "#E9E9EA",
                                        opacity: 1,
                                        duration: 500,
                                      },
                                    }}
                                  />
                                }
                                sx={{
                                  fontFamily: "Poppins",
                                  ".css-ahj2mt-MuiTypography-root": {
                                    fontFamily: "Poppins",
                                  },
                                }}
                              />
                            </TableCell>
                            <TableCell
                              sx={{
                                fontFamily: "poppins",
                              }}
                              align="right"
                            >
                              <Container className="flex justify-end items-center">
                                <MdOutlineEdit
                                  className="p-2 h-9 w-9 rounded-full"
                                  style={{ backgroundColor: "#ffe1a1" }}
                                  onClick={() => props.handleVesionEdit(row)}
                                />
                              </Container>
                            </TableCell>
                          </TableRow>
                        );
                      })
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={props.isTableData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              "& .MuiTablePagination-selectLabel": {
                fontFamily: "Poppins",
              },
              "& .MuiTablePagination-select": {
                fontFamily: "Poppins",
              },
              "& .MuiTablePagination-displayedRows": {
                fontFamily: "Poppins",
              },
            }}
          />
        </Paper>
      ) : (
        <Container className="flex justify-center font-semibold">
          <p>No versions found...</p>
        </Container>
      )}
    </Box>
  );
}
