import React, { useState, useEffect } from "react";
import { MdOutlineEdit } from "react-icons/md";
import { Button, Container } from "reactstrap";
import "./style.css";
// import { BsCaretDownFill, BsTranslate } from "react-icons/bs";
import CustomBottomSheet from "../../components/common/Admin/common/CustomBottomSheet";
import EditUserProfileModal from "../../components/common/Admin/Modal/UserProfile/EditUserProfileModal";
import { Link, useNavigate } from "react-router-dom";
import { getServices } from "../../apiServices/apiServices";
import toast from "react-hot-toast";
import NoInternetComp from "../../components/common/InternetConnetction/NoInternetComp";

const language = [
  "English",
  "हिंदी",
  "ಕನ್ನಡ",
  "ತುಳು",
  "മലയാളം",
  "తెలుగు",
  "தமிழ்",
  "ગુજરાતી",
];

function Profile() {
  const [isBottomNav, setIsBottomNav] = useState(false);
  const access_token = localStorage.getItem("user-access-token");
  const [data, setData] = useState();
  const [editModal, setEditModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (access_token && navigator.onLine) {
      fetchUserDetails();
    }

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [navigator]);

  const fetchUserDetails = async () => {
    try {
      const res = await getServices(`/api/user/user_details`, access_token);
      if (res.responseCode === 200) {
        setData(res.responseData.rows[0]);
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("user-access-token");
    localStorage.removeItem("user-shiprocket-access-token");
    navigate("/login");
  };

  const handleClick = () => {
    setEditModal(true);
  };

  const handleLoginPage = () => {
    navigate("/login");
  };

  return (
    <>
      <Container className="pt-2">
        {navigator.onLine ? (
          <Container>
            <Container className="flex justify-center flex-col gap-3 items-center px-2 sm:px-0 text-sm sm:text-base">
              <Container className="w-full sm:w-3/4 px-4 py-2 rounded-md">
                {access_token ? (
                  <Container className="flex gap-2 items-center justify-between">
                    <Container>
                      <p>
                        Name:{" "}
                        <span className="font-semibold">{data?.name}</span>
                      </p>
                      <p>
                        Phone:{" "}
                        <span className="font-semibold">{data?.phone}</span>
                      </p>
                      <p>
                        Email:{" "}
                        <span className="font-semibold">{data?.email}</span>
                      </p>
                    </Container>
                    <Container className="cursor-pointer" onClick={handleClick}>
                      <MdOutlineEdit className="text-[#FFFAEF] bg-[#352210] text-[34px] sm:text-[44px] p-2 rounded-full" />
                    </Container>
                  </Container>
                ) : (
                  <Container className="flex justify-center">
                    <Button
                      className="bg-[#352210] px-20 py-3 text-white font-semibold rounded-lg active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out"
                      onClick={handleLoginPage}
                    >
                      Login/SignUp
                    </Button>
                  </Container>
                )}
                <hr className="border border-[#352210] mt-5 mb-7 sm:mb-6" />
                {access_token && (
                  <Container className="profile-btns">
                    <Link
                      to="/gallery"
                      className="border-2 border-[#352210] outline-none text-center"
                    >
                      <Button className="text-xs sm:text-sm outline-none py-2">
                        Gallery
                      </Button>
                    </Link>
                    <Link
                      to="/manage_orders"
                      className="border-2 border-[#352210] outline-none text-center"
                    >
                      <Button className="text-xs sm:text-sm outline-none py-2">
                        Orders
                      </Button>
                    </Link>
                    <Link
                      to="/manage_address"
                      className="border-2 border-[#352210] outline-none text-center"
                    >
                      <Button className="text-xs sm:text-sm outline-none py-2">
                        Delivery Location
                      </Button>
                    </Link>
                    <Link
                      to="/profile/change_password"
                      className="border-2 border-[#352210] outline-none text-center"
                    >
                      <Button className="text-xs sm:text-sm outline-none py-2">
                        Change Password
                      </Button>
                    </Link>
                  </Container>
                )}
                {/* <Container
                    className="mt-4 flex w-full justify-between items-center bg-[#FFFAEF] cursor-pointer"
                    onClick={() => setIsBottomNav(true)}
                  >
                    <Container className="p-4 flex gap-3 items-center">
                      <BsTranslate className="text-xl sm:text-3xl text-[#352210]" />
                      <p className="font-medium text-xs sm:text-sm">Change Language</p>
                    </Container>
                    <Container className="p-4">
                      <BsCaretDownFill className="text-[#352210]" />
                    </Container>
                </Container> */}
                <Container className="mt-4 flex flex-col gap-4 w-full p-4 font-semibold text-xs sm:text-sm bg-[#FFFAEF] uppercase">
                  {/* <p className="cursor-pointer">FAQs</p> */}
                  {/* <p className="cursor-pointer">Help Center</p> */}
                  {!access_token && (
                    <Link to={"/gallery"} className="cursor-pointer">
                      Gallery
                    </Link>
                  )}
                  <Link to={"/terms_and_conditions"} className="cursor-pointer">
                    Terms and Conditions
                  </Link>
                  <Link to={"/privacy_policy"} className="cursor-pointer">
                    Privacy Policy
                  </Link>
                  <Link
                    to={"/cancellation_and_refund"}
                    className="cursor-pointer"
                  >
                    Cancellation and Refund Policy
                  </Link>
                  <Link
                    to={"/shipping_and_delivery"}
                    className="cursor-pointer"
                  >
                    Shipping & Delivery Policy
                  </Link>
                  <Link to={"/about_us"} className="cursor-pointer">
                    About us
                  </Link>
                  <Link to={"/contact_us"} className="cursor-pointer">
                    Contact us
                  </Link>
                </Container>
                {access_token && (
                  <Button
                    className="uppercase text-xs sm:text-sm border-2 outline-none border-[#352210] mt-4 w-full text-[#352210] py-3 font-semibold active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out"
                    onClick={handleLogout}
                  >
                    Logout
                  </Button>
                )}
              </Container>
            </Container>

            {/* bottom sheet for Change language  */}
            <CustomBottomSheet
              isBottomNav={isBottomNav}
              onClose={() => setIsBottomNav(false)}
            >
              <Container className="p-4">
                {language.map((item, i) => (
                  <Container
                    className="py-3 font-semibold text-xs sm:text-sm"
                    key={i}
                  >
                    <p>{item}</p>
                    <hr className="border-1 border-[#352210]" />
                  </Container>
                ))}
              </Container>
            </CustomBottomSheet>

            <EditUserProfileModal
              show={editModal}
              data={data}
              onClose={() => setEditModal(false)}
              fetchUserDetails={fetchUserDetails}
            />
          </Container>
        ) : (
          <Container className="min-h-[65vh] flex flex-col justify-center items-center">
            <NoInternetComp />
          </Container>
        )}
      </Container>
    </>
  );
}

export default Profile;
