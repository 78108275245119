import React, { useEffect, useState } from "react";
import { HiPlus } from "react-icons/hi";
import { Button, Container } from "reactstrap";
import NoInternetComp from "../../InternetConnetction/NoInternetComp";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { LuRotateCw, LuZoomIn, LuZoomOut } from "react-icons/lu";
import ReactPlayer from "react-player";
import { MdOutlineDelete } from "react-icons/md";
import DeleteModal from "../common/DeleteModal";
import ManageGalleryModal from "../Modal/Gallery/ManageGalleryModal";
import toast from "react-hot-toast";
import {
  getServices,
  postServices,
  removeServices,
} from "../../../../apiServices/apiServices";
import config from "../../../../config.json";

function ManageGalleryView() {
  const [value, setValue] = useState("1");
  const [modal, setModal] = useState({
    add_album: false,
    add_video: false,
  });
  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");
  const access_token = sessionStorage.getItem("access-token");
  const [imageData, setImageData] = useState([]);
  const [videoData, setVideoData] = useState([]);
  const url = config.url;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (navigator.onLine) {
      //   fetchOrders();
      fetchGalleryData();
    }
  }, [navigator]);

  const fetchGalleryData = async () => {
    try {
      const res = await getServices(`/api/gallery/file/view`);
      if (res.responseCode === 200) {
        setImageData(
          res.responseData.filter((item) =>
            item.gallery_img.match(/\.(jpeg|jpg|gif|png)$/i)
          )
        );
        setVideoData(
          res.responseData.filter((item) =>
            item.gallery_img.match(/\.(mp4|webm|ogg)$/i)
          )
        );
      } else {
        setImageData([]);
        setVideoData([]);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDeleteAlbumModal = async (id) => {
    if (window.confirm("Do you want to delete this album?")) {
      try {
        const res = await removeServices(
          `/api/gallery/file/delete/${id}`,
          access_token
        );
        if (res.responseCode === 200) {
          fetchGalleryData();
          toast.success("Image deleted...");
        } else {
          toast.error(res.responseMessage);
        }
      } catch (error) {
        toast.error(error);
      }
    }
  };

  const handleDeleteVideoModal = async (id) => {
    if (window.confirm("Do you want to delete this video?")) {
      try {
        const res = await removeServices(
          `/api/gallery/file/delete/${id}`,
          access_token
        );
        if (res.responseCode === 200) {
          fetchGalleryData();
          toast.success("Video deleted...");
        } else {
          toast.error(res.responseMessage);
        }
      } catch (error) {
        toast.error(error);
      }
    }
  };

  const handleAddAlbumModal = () => {
    setModal({ ...modal, add_album: true });
    setPreviewUrl("");
  };

  const handleAddVideoModal = () => {
    setModal({ ...modal, add_video: true });
    setPreviewUrl("");
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      setFile(selectedFile);

      // Generate a preview URL for the selected file
      const fileUrl = URL.createObjectURL(selectedFile);
      setPreviewUrl(fileUrl);
    }
  };

  const handleMediaSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const isImage = file.type.startsWith("image/");
    const maxSize = isImage ? 2 * 1024 * 1024 : 5 * 1024 * 1024; // 2MB for images, 5MB for videos

    if (file.size > maxSize) {
      alert(`File size should not exceed ${isImage ? "2MB" : "5MB"}`);
    } else {
      try {
        const formData = new FormData();
        formData.append("gallery", file);

        const res = await postServices(
          `/api/gallery/file/add`,
          access_token,
          formData
        );
        setLoading(false);
        if (res.responseCode === 200) {
          setModal({ ...modal, add_album: false, add_video: false });
          fetchGalleryData();
        } else {
          toast.error(res.responseMessage);
        }
      } catch (error) {
        toast.error(error);
      }
    }
  };

  return (
    <>
      {navigator.onLine ? (
        <Container>
          <Container className="flex justify-between items-center mx-0 px-8 pt-8 pb-3">
            <p className="text-xl sm:text-2xl font-semibold">Manage Gallery</p>
          </Container>

          <Container className="flex justify-center flex-col gap-3 items-center px-2 sm:px-0 mb-4">
            <Container className="w-full px-4">
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                    sx={{
                      "& .MuiTab-root": {
                        fontFamily: "Poppins",
                      },
                      "& .MuiTab-root.Mui-selected": {
                        color: "#000",
                      },
                      "& .MuiTabs-indicator": {
                        backgroundColor: "#000",
                      },
                    }}
                  >
                    <Tab label="Albums" value="1" />
                    <Tab label="Videos" value="2" />
                  </TabList>
                </Box>
                <TabPanel value="1" sx={{ padding: "14px 0" }}>
                  <Container className="flex justify-end pb-3">
                    <Button
                      onClick={() => handleAddAlbumModal()}
                      className="bg-slate-800 flex items-center px-2 sm:px-7 py-2 text-white font-semibold rounded-xl outline-none border-none active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out"
                    >
                      <HiPlus className="mr-2" /> Add Album
                    </Button>
                  </Container>
                  <PhotoProvider
                    toolbarRender={({ onScale, scale, rotate, onRotate }) => {
                      return (
                        <div className="flex gap-4">
                          <LuZoomIn
                            onClick={() => onScale(scale + 1)}
                            className="cursor-pointer"
                          />
                          <LuZoomOut
                            onClick={() => onScale(scale - 1)}
                            className="cursor-pointer"
                          />
                          <LuRotateCw
                            onClick={() => onRotate(rotate + 90)}
                            className="cursor-pointer"
                          />
                        </div>
                      );
                    }}
                    maskOpacity={0.8}
                  >
                    {imageData.length > 0 ? (
                      <div className="image-gallery">
                        {imageData.map((image, i) => (
                          <div className="image-box relative" key={i}>
                            <PhotoView src={url + image.gallery_img}>
                              <img src={url + image.gallery_img} alt="" />
                            </PhotoView>

                            <Container className="absolute top-3 right-3">
                              <MdOutlineDelete
                                onClick={() =>
                                  handleDeleteAlbumModal(image.gallery_id)
                                }
                                className="text-3xl bg-[#ffe1a1] p-1 rounded-full cursor-pointer hover:drop-shadow"
                              />
                            </Container>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div>
                        <p className="text-center font-semibold">
                          No Images Found...
                        </p>
                      </div>
                    )}
                  </PhotoProvider>
                </TabPanel>
                <TabPanel value="2" sx={{ padding: "14px 0" }}>
                  <Container className="flex justify-end pb-3">
                    <Button
                      onClick={() => handleAddVideoModal()}
                      className="bg-slate-800 flex items-center px-2 sm:px-7 py-2 text-white font-semibold rounded-xl outline-none border-none active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out"
                    >
                      <HiPlus className="mr-2" /> Add Video
                    </Button>
                  </Container>
                  {videoData.length > 0 ? (
                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5">
                      {videoData.map((video, i) => (
                        <div key={i} className="relative">
                          <ReactPlayer
                            url={url + video.gallery_img}
                            controls
                            width={"100%"}
                            height="100%"
                          />
                          <Container className="absolute top-3 right-3">
                            <MdOutlineDelete
                              onClick={() =>
                                handleDeleteVideoModal(video.gallery_id)
                              }
                              className="text-3xl bg-[#ffe1a1] p-1 rounded-full cursor-pointer hover:drop-shadow"
                            />
                          </Container>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div>
                      <p className="text-center font-semibold">
                        No Videos Found...
                      </p>
                    </div>
                  )}
                </TabPanel>
              </TabContext>
            </Container>
          </Container>
        </Container>
      ) : (
        <Container className="min-h-[65vh] flex flex-col justify-center items-center">
          <NoInternetComp />
        </Container>
      )}

      <ManageGalleryModal
        open={modal.add_album}
        onClose={() => setModal({ ...modal, add_album: false })}
        modal={modal}
        handleMediaSubmit={handleMediaSubmit}
        handleFileChange={handleFileChange}
        previewUrl={previewUrl}
        file={file}
        loading={loading}
      />
      <ManageGalleryModal
        open={modal.add_video}
        onClose={() => setModal({ ...modal, add_video: false })}
        modal={modal}
        handleMediaSubmit={handleMediaSubmit}
        handleFileChange={handleFileChange}
        previewUrl={previewUrl}
        file={file}
        loading={loading}
      />
    </>
  );
}

export default ManageGalleryView;
