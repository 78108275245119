import React from "react";
import { Button, Container, Form } from "reactstrap";
import classes from "./style.module.css";
import OTPInput from "react-otp-input";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { phoneVerify, signupIntegration } from "../../apiServices/authServices";
import toast from "react-hot-toast";

function OTPScreen() {
  const [countDown, setCountDown] = useState(0);
  const [runTimer, setRunTimer] = useState(true);
  const location = useLocation();
  const [data, setData] = useState({
    name: location.state?.data?.name,
    email: location.state?.data?.email,
    password: location.state?.data?.password,
    phone: location.state?.data?.phone,
    confirmPassword: location.state?.data?.confirmPassword,
    otp: "",
    session_id: location.state?.session_id,
  });
  const navigate = useNavigate();

  useEffect(() => {
    let timerId;
    if (runTimer) {
      setCountDown(60 * 1);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }
    return () => clearInterval(timerId);
  }, [runTimer]);

  useEffect(() => {
    if (countDown < 0 && runTimer) {
      setRunTimer(false);
      setCountDown(0);
    }
  }, [countDown, runTimer]);

  const toggleTimer = () => setRunTimer((t) => !t);

  //   minutes and seconds
  const seconds = String(countDown % 60).padStart(2, 0);
  const minutes = String(Math.floor(countDown / 60)).padStart(2, 0);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await signupIntegration(data);
      if (res.responseCode === 200) {
        navigate("/login");
        Notification.requestPermission().then((permission) => {
          const notification = new Notification("Registration successful", {
            body: "Thank you for registering with us. Please visit our branch for more biggest deals waiting for you.",
          });

          notification.addEventListener("click", () => {
            navigate("/login");
          });
        });
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleResend = async (e) => {
    try {
      const res = await phoneVerify(data.phone);
      if (res.responseCode === 200) {
        setData({ ...data, session_id: res.responseData.session_id });
        toggleTimer();
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <Container className={`${classes.otpForm} overflow-hidden`}>
      <h2 className="text-4xl font-bold mb-2" style={{ color: "#1E2219" }}>
        Enter a OTP.
      </h2>
      <p className="mb-4 font-[500]">OTP sent to your registered Phone no.</p>
      <Form className="flex flex-col" onSubmit={handleSubmit}>
        <Container className="mb-3">
          <OTPInput
            inputType="number"
            inputStyle={{
              width: "3rem",
              height: "3rem",
              borderRadius: "12px",
              border: "1px solid #352210",
            }}
            value={data.otp}
            onChange={(e) => {
              setData({ ...data, otp: e });
            }}
            numInputs={6}
            renderSeparator={<span style={{ visibility: "hidden" }}>-</span>}
            renderInput={(props) => <input {...props} />}
          />
        </Container>
        <Container className="flex justify-between items-center text-sm font-medium mb-6">
          <p className="">
            Don't receive the OTP?{" "}
            <Button
              type="button"
              className="text-[#352210] disabled:text-gray-500 outline-none border-none active:scale-[.98] active:duration-75 transition-all ease-in-out disabled:cursor-not-allowed"
              disabled={countDown >= 1 ? true : false}
              onClick={handleResend}
            >
              Resend
            </Button>
          </p>
          <Container>
            Time: {minutes}:{seconds}
          </Container>
        </Container>
        <Container className="w-full">
          <input
            type="submit"
            value="Verify"
            disabled={data.otp.length !== 6 ? true : false}
            className="active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out w-full px-6 py-4 rounded-xl"
          />
        </Container>
      </Form>
    </Container>
  );
}

export default OTPScreen;
