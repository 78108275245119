import React from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import { FaUser, FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";
import { BiCategory, BiHomeCircle } from "react-icons/bi";
import { Link, NavLink, useLocation } from "react-router-dom";
import { LuUsers } from "react-icons/lu";
import {
  MdOutlineCategory,
  MdOutlineLocalShipping,
  MdOutlineNotificationsActive,
  MdOutlineShoppingCart,
} from "react-icons/md";
import { RiLuggageCartLine } from "react-icons/ri";
import classes from "../style.module.css";
import { Container } from "reactstrap";
import { TbVersions } from "react-icons/tb";
import { GiKnightBanner } from "react-icons/gi";
import SiteLogo from "../../../assets/site_logo.png";
import { IoSettingsOutline } from "react-icons/io5";
import { TbBrandAppgallery } from "react-icons/tb";

function Sidebar({
  collapsed,
  toggled,
  handleToggleSidebar,
  handleCollapsedChange,
  activeMenuItem,
}) {
  const sidebarMenu = [
    { title: "Dashboard", icon: <BiHomeCircle />, link: "/admin/dashboard" },
    { title: "Users", icon: <LuUsers />, link: "/admin/users" },
    { title: "Products", icon: <RiLuggageCartLine />, link: "/admin/products" },
    { title: "Orders", icon: <MdOutlineShoppingCart />, link: "/admin/orders" },
    {
      title: "ShipRocket Orders",
      icon: <MdOutlineLocalShipping />,
      link: "/admin/ship_rocket/orders",
    },
    // {
    //   title: "Manage HSN Code",
    //   icon: <MdOutlineCategory />,
    //   link: "/admin/manage_hsn_code",
    // },
    {
      title: "Manage Category",
      icon: <MdOutlineCategory />,
      link: "/admin/manage_category",
    },
    {
      title: "Manage Sub-Category",
      icon: <BiCategory />,
      link: "/admin/manage_sub_category",
    },
    {
      title: "Manage Banner",
      icon: <GiKnightBanner />,
      link: "/admin/manage_banner",
    },
    {
      title: "Manage Gallery",
      icon: <TbBrandAppgallery />,
      link: "/admin/manage_gallery",
    },
    {
      title: "Version Control",
      icon: <TbVersions />,
      link: "/admin/version_control",
    },
    {
      title: "Settings",
      icon: <IoSettingsOutline />,
      link: "/admin/settings",
    },
  ];
  const location = useLocation();

  return (
    <ProSidebar
      collapsed={collapsed}
      toggled={toggled}
      onToggle={handleToggleSidebar}
      breakPoint="lg"
      className={classes.scrollBarStyle}
    >
      {/* Header */}
      <SidebarHeader>
        <Menu iconShape="circle">
          {collapsed ? (
            <MenuItem
              icon={<FaAngleDoubleRight />}
              onClick={handleCollapsedChange}
            ></MenuItem>
          ) : (
            <MenuItem
              suffix={<FaAngleDoubleLeft />}
              onClick={handleCollapsedChange}
            >
              <Container
                style={{
                  // padding: "9px",
                  textTransform: "uppercase",
                  fontWeight: "bold",
                  fontSize: 15,
                  letterSpacing: "1px",
                }}
              >
                {/* Navarag Smaranika */}
                <img
                  src={SiteLogo}
                  alt="Site logo"
                  width={"100%"}
                  className="h-20"
                />
              </Container>
            </MenuItem>
          )}
        </Menu>
      </SidebarHeader>
      {/* Content */}
      <SidebarContent>
        <Menu iconShape="circle">
          {sidebarMenu.map((item, i) => (
            <MenuItem
              icon={item.icon}
              // className={activeMenuItem === i ? "bg-[#ffe1a1] text-black" : ""}
              className={
                location.pathname === item.link ? "bg-[#ffe1a1] text-black" : ""
              }
              key={i}
              onClick={() => handleToggleSidebar(false, i)}
            >
              {item.title}
              <NavLink to={item.link} />
            </MenuItem>
          ))}
        </Menu>
      </SidebarContent>
      {/* <SidebarFooter
        style={{
          textAlign: "center",
          // backgroundColor: "#1d1d1d",
          // width: "253px",
        }}
        // className="fixed bottom-0 h-20"
      >
        <Container className="sidebar-btn-wrapper" style={{ padding: "16px" }}>
          <Link className="sidebar-btn" style={{ cursor: "pointer" }}>
            <FaUser className="profile-icon" />
            <span>My Account</span>
          </Link>
        </Container>
      </SidebarFooter> */}
    </ProSidebar>
  );
}

export default Sidebar;
