import React, { useState, useEffect } from "react";
import { MdOutlineDeleteOutline, MdOutlineEdit } from "react-icons/md";
import { Button, Container } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingSpinner from "../../components/common/Admin/common/LoadingSpinner";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import {
  getServices,
  postServices,
  removeServices,
} from "../../apiServices/apiServices";
import toast from "react-hot-toast";
import NoInternetComp from "../../components/common/InternetConnetction/NoInternetComp";

function ManageAddress() {
  const access_token = localStorage.getItem("user-access-token");
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [selectedAddress, setSelectedAddress] = useState("");

  useEffect(() => {
    if (navigator.onLine) {
      fetchDeliveryAddress();
    }
  }, [navigator]);

  const fetchDeliveryAddress = async () => {
    setLoading(true);
    try {
      const res = await getServices(`/api/address/list_address`, access_token);
      setLoading(false);
      if (res.responseCode === 200) {
        setData(res.responseData);
        setSelectedAddress(res.responseData[0].address_id);
      } else if (res.responseCode === 206) {
        return;
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  const handleAddAddress = () => {
    navigate("/manage_address/add_address", {
      state: { is_cart: location.state?.is_cart },
    });
  };

  const handleDeleteAddress = async (data) => {
    if (window.confirm("Are you sure you want to remove this address?")) {
      try {
        const res = await removeServices(
          `/api/address/remove_address/${data.address_id}`,
          access_token
        );
        if (res.responseCode === 200) {
          fetchDeliveryAddress();
        } else {
          toast.error(res.responseMessage);
        }
      } catch (error) {
        toast.error(error);
      }
    }
  };

  const handleUpdateAddress = (data) => {
    navigate("/manage_address/update_address", {
      state: { editData: data, is_cart: location.state?.is_cart },
    });
  };

  const handleContinueAddress = async () => {
    setLoading(true);
    try {
      const res = await postServices(`/api/shiprocket/login`, access_token);
      setLoading(false);
      if (res.responseCode === 200) {
        checkPincodeServiceability(res.responseData.token);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const checkPincodeServiceability = async (shipRocketAccessToken) => {
    setLoading(true);
    const selected = data.find((obj) => obj.address_id === selectedAddress);

    try {
      const res = await getServices(
        `/api/shiprocket/check_service?pincode=${selected.pincode}`,
        access_token,
        shipRocketAccessToken
      );
      setLoading(false);
      if (res.responseCode === 220) {
        navigate("/manage_cart/make_payment", {
          state: { address_id: selectedAddress },
        });
      } else if (res.responseCode === 219) {
        toast.error("This product not deliverable to your pincode.");
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <>
      <Container className="pt-2">
        {navigator.onLine ? (
          <Container>
            {loading ? (
              <Container className="flex items-center justify-center min-h-[66vh]">
                <LoadingSpinner />
              </Container>
            ) : (
              <Container className="min-h-[60vh]">
                <Container className="w-full sm:w-3/4 flex justify-end mx-auto mb-4 px-2 sm:px-0">
                  <Button
                    className="bg-[#352210] text-white py-2 px-4 font-medium rounded active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out"
                    onClick={handleAddAddress}
                  >
                    Add new Address
                  </Button>
                </Container>

                {data.length <= 0 ? (
                  <Container className="flex justify-center items-center h-[60vh] flex-col">
                    <p className="font-semibold mb-4">No Address Found...</p>
                  </Container>
                ) : (
                  <Container className="w-full sm:w-3/4 mx-auto mb-4 px-2 sm:px-0 flex flex-col gap-3">
                    {data.map((item, i) => (
                      <Container
                        className="flex justify-between flex-col gap-2 bg-[#fffaef] p-2 sm:p-4"
                        key={i}
                      >
                        <Container className="flex justify-between items-center">
                          {location.state?.is_cart !== 1 && (
                            <p className="text-base sm:text-lg font-semibold capitalize">
                              {item.name}
                            </p>
                          )}
                          {location.state?.is_cart === 1 && (
                            <FormControl>
                              <RadioGroup
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={selectedAddress}
                                onChange={(e) =>
                                  setSelectedAddress(e.target.value)
                                }
                              >
                                <FormControlLabel
                                  value={item.address_id}
                                  control={
                                    <Radio
                                      sx={{
                                        "&.Mui-checked": {
                                          color: "#352210",
                                        },
                                      }}
                                    />
                                  }
                                  label={
                                    <p className="text-base sm:text-lg font-semibold capitalize">
                                      {item.name}
                                    </p>
                                  }
                                  sx={{
                                    ".MuiFormControlLabel-root .MuiFormControlLabel-label, .MuiTypography-root":
                                      { fontFamily: "Poppins" },
                                  }}
                                />
                              </RadioGroup>
                            </FormControl>
                          )}
                          <p className="text-sm px-3 py-1 border-2 border-[#352210] rounded-full uppercase">
                            {item.save_address_as}
                          </p>
                        </Container>
                        <Container className="capitalize text-slate-500 font-medium text-base sm:text-sm">
                          <p>{item.area}</p>
                          <p>{item.town}</p>
                          <p>
                            {item.city}, {item.state} - {item.pincode}
                          </p>
                          <Container className="flex justify-between items-center">
                            <p>
                              Mobile:{" "}
                              <span className="text-black">
                                +91 {item.phone_no}
                              </span>
                            </p>
                            <Container className="flex gap-2">
                              <MdOutlineEdit
                                className="text-[#FFFAEF] bg-[#352210] text-[34px] sm:text-[44px] p-2 rounded-full cursor-pointer"
                                onClick={() => handleUpdateAddress(item)}
                              />
                              <MdOutlineDeleteOutline
                                className="text-[#FFFAEF] bg-[#352210] text-[34px] sm:text-[44px] p-2 rounded-full cursor-pointer"
                                onClick={() => handleDeleteAddress(item)}
                              />
                            </Container>
                          </Container>
                        </Container>
                      </Container>
                    ))}

                    {location.state?.is_cart === 1 && (
                      <Container className="flex justify-center">
                        <Button
                          className="bg-[#352210] outline-none w-full sm:w-1/2 text-white font-semibold py-3 active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out rounded-xl"
                          onClick={handleContinueAddress}
                        >
                          Continue
                        </Button>
                      </Container>
                    )}
                  </Container>
                )}
              </Container>
            )}
          </Container>
        ) : (
          <Container className="min-h-[65vh] flex flex-col justify-center items-center">
            <NoInternetComp />
          </Container>
        )}
      </Container>
    </>
  );
}

export default ManageAddress;
