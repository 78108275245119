import * as React from "react";
import { Box } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { TableHead } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import { MdOutlineEdit } from "react-icons/md";
// import { Container } from "reactstrap";
// import { useState } from "react";
// import OrderStatusModal from "../Admin/Modal/Orderstatus/OrderStatusModal";
import { Link } from "react-router-dom";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator() {
  return (a, b) => -descendingComparator(a, b);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "track_id",
    numeric: false,
    disablePadding: true,
    label: "Order No.",
  },
  {
    id: "order",
    numeric: true,
    disablePadding: false,
    label: "Order Date",
  },
  {
    id: "product_qty",
    numeric: true,
    disablePadding: false,
    label: "Payment method",
  },
  {
    id: "total_amount",
    numeric: true,
    disablePadding: false,
    label: "Total Amount",
  },
  {
    id: "order_status",
    numeric: true,
    disablePadding: false,
    label: "Order Status",
  },
  // {
  //   id: "actions",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Actions",
  // },
];

function EnhancedTableHead() {
  return (
    <TableHead sx={{ backgroundColor: "#080808de" }}>
      <TableRow>
        <TableCell padding="checkbox" />
        {headCells.map((headCell) => (
          <TableCell
            sx={{
              fontFamily: "poppins",
            }}
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            <p style={{ color: "white" }}>{headCell.label}</p>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function EnhancedTable(props) {
  const [order] = React.useState("asc");
  const [orderBy] = React.useState("id");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  // const [isModalShow, setIsModalShow] = useState(false);
  // const [editData, setEditData] = useState({ id: "", orderStatus: "" });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleEditModal = (id, orderStatus) => {
  //   setIsModalShow(true);
  //   setEditData({ ...editData, id: id, orderStatus: orderStatus });
  // };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead />
            <TableBody>
              {props.data &&
              stableSort(props.data, getComparator(order, orderBy)) &&
              Array.isArray(
                stableSort(props.data, getComparator(order, orderBy))
              )
                ? stableSort(props.data, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell padding="checkbox" />
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            sx={{
                              fontFamily: "poppins",
                            }}
                          >
                            <Link
                              to={"/admin/order/details"}
                              state={{ id: row.track_id }}
                            >
                              <p className="cursor-pointer hover:underline">
                                {row.track_id}
                              </p>
                            </Link>
                          </TableCell>

                          <TableCell
                            sx={{
                              fontFamily: "poppins",
                            }}
                            align="right"
                          >
                            {row.orderd_date + " " + row.orderd_time}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontFamily: "poppins",
                            }}
                            align="right"
                          >
                            {row.payment_method === 1
                              ? "COD"
                              : "Online payment"}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontFamily: "poppins",
                            }}
                            align="right"
                          >
                            ₹ {row.totalAmount}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontFamily: "poppins",
                            }}
                            align="center"
                          >
                            <div
                              className="w-24 my-0 ml-auto mr-0 rounded-lg py-1"
                              style={{
                                backgroundColor:
                                  row.orderStatus === 2
                                    ? "#fda693d6"
                                    : row.orderStatus === 8
                                    ? "#6149cd"
                                    : row.orderStatus === 6
                                    ? "#ffab0029"
                                    : "",
                                color:
                                  row.orderStatus === 8
                                    ? "#fff"
                                    : row.orderStatus === 2
                                    ? "#000"
                                    : row.orderStatus === 6
                                    ? "#b76e00"
                                    : "",
                              }}
                            >
                              {row.orderStatus === 2
                                ? "Processing"
                                : row.orderStatus === 8
                                ? "Initiated"
                                : row.orderStatus === 6
                                ? "Cancelled"
                                : ""}
                            </div>
                          </TableCell>
                          {/* <TableCell
                            sx={{
                              fontFamily: "poppins",
                            }}
                            align="right"
                          >
                            <Container className="flex justify-end items-center">
                              <MdOutlineEdit
                                className="p-2 h-9 w-9 rounded-full"
                                style={{ backgroundColor: "#ffe1a1" }}
                                onClick={() =>
                                  handleEditModal(row.track_id, row.orderStatus)
                                }
                              />
                            </Container>
                          </TableCell> */}
                        </TableRow>
                      );
                    })
                : null}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={props.data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            "& .MuiTablePagination-selectLabel": {
              fontFamily: "Poppins",
            },
            "& .MuiTablePagination-select": {
              fontFamily: "Poppins",
            },
            "& .MuiTablePagination-displayedRows": {
              fontFamily: "Poppins",
            },
          }}
        />
      </Paper>
      {/* <OrderStatusModal
        isModalShow={isModalShow}
        onClose={() => setIsModalShow(false)}
        editData={editData}
        fetchOrdersData={props.fetchOrdersData}
        fetchProcessingOrdersData={props.fetchProcessingOrdersData}
        fetchShipRocketOrdersData={props.fetchShipRocketOrdersData}
      /> */}
    </Box>
  );
}
