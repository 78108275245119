import { Box, CircularProgress } from "@mui/material";
import React from "react";

function LoadingSpinner() {
  return (
    <div>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          gap: "10px",
          color: "#352210",
        }}
      >
        <CircularProgress
          variant="determinate"
          sx={{
            color: "transparent",
          }}
          size={40}
          thickness={4}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          sx={{
            color: "#352210",
            animationDuration: "550ms",
            position: "absolute",
            left: 0,
          }}
          size={40}
          thickness={4}
        />
        Loading...
      </Box>
    </div>
  );
}

export default LoadingSpinner;
