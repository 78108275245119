import { Box, TextField } from "@mui/material";
import React from "react";
import { useState } from "react";
import { Button, Container, Form } from "reactstrap";
import { updateServices } from "../../../../../apiServices/apiServices";
import toast from "react-hot-toast";

function SubCategoryEditForm({ handleViewSubCategory, onClose, isEditData }) {
  const [data, setData] = useState({
    subcat_name: isEditData.name,
  });
  const access_token = sessionStorage.getItem("access-token");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await updateServices(
        `/api/subcat/update_subcat/${isEditData.id}`,
        access_token,
        data
      );
      if (res.responseCode === 200) {
        onClose();
        toast.success("Sub-Category updated successfully...");
        handleViewSubCategory();
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Box
          sx={{
            maxWidth: "100%",
          }}
          className="mx-8"
        >
          <TextField
            fullWidth
            name="sub_category_name"
            label="Sub-Category Name"
            id="fullWidth"
            sx={{
              "& .MuiOutlinedInput-root": {
                fontFamily: "Poppins",
                borderRadius: "12px",
              },
              "& .MuiInputLabel-root": {
                zIndex: "0",
                fontFamily: "Poppins",
              },
              "& .MuiInputLabel-root": {
                zIndex: "0",
                fontFamily: "Poppins",
              },
              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#352210",
                },
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#352210",
                },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #352210",
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "#352210",
              },
              marginBottom: "20px",
            }}
            value={data.subcat_name}
            onChange={(e) => setData({ ...data, subcat_name: e.target.value })}
          />
          <Container className="flex justify-end items-center mb-5">
            <Button
              type="submit"
              className="px-8 py-2 bg-slate-800 text-white font-semibold rounded-lg active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out disabled:cursor-not-allowed disabled:bg-slate-500 disabled:text-white"
              disabled={data.subcat_name === "" ? true : false}
            >
              Save Changes
            </Button>
          </Container>
        </Box>
      </Form>
    </Container>
  );
}

export default SubCategoryEditForm;
