import React from "react";
import NoInternetImg from "../../assets/no_internet.jpg";
import { Container } from "reactstrap";

function NoInternetComp() {
  return (
    <Container className="flex flex-col justify-center items-center gap-4">
      <img src={NoInternetImg} alt="Navarang smaranika" className="w-1/4" />
      <p className="font-semibold text-xl text-center">Whoops!!</p>
      <p className="text-center mb-5">
        No Internet connection was found. Check <br />
        your connection or try again.
      </p>
    </Container>
  );
}

export default NoInternetComp;
