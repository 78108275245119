import React, { useState, useEffect } from "react";
import { Container, Button } from "reactstrap";
// import { RiErrorWarningFill } from "react-icons/ri";
// import { BiSolidBox } from "react-icons/bi";
// import { FaTruck } from "react-icons/fa";
// import { FaRegMoneyBill1 } from "react-icons/fa6";
// import { BsFillGiftFill, BsFillPatchCheckFill } from "react-icons/bs";
// import { MdCancel } from "react-icons/md";
import { Link } from "react-router-dom";
import LoadingSpinner from "../../components/common/Admin/common/LoadingSpinner";
import { getServices } from "../../apiServices/apiServices";
import toast from "react-hot-toast";
import NoInternetComp from "../../components/common/InternetConnetction/NoInternetComp";
import { Typography, Breadcrumbs } from "@mui/material";
import { FaFilter } from "react-icons/fa";
import CustomBottomSheet from "../../components/common/Admin/common/CustomBottomSheet";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import moment from "moment/moment";

function ManageOrders() {
  const access_token = localStorage.getItem("user-access-token");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isBottomNav, setIsBottomNav] = useState(false);
  const [filterData, setFilterData] = useState({
    order_status: "",
    order_time: "",
  });
  const [isFiltered, setIsFiltered] = useState(false);

  useEffect(() => {
    if (navigator.onLine) {
      fetchOrders();
    }
  }, [navigator]);

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const res = await getServices(
        `/api/orders/list_all_orders?filter_type=`,
        access_token
      );
      setLoading(false);
      if (res.responseCode === 200) {
        setData(res.responseData.rows);
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  const handleApplyFilter = async () => {
    if (filterData.order_status !== "" || filterData.order_time !== "") {
      try {
        const res = await getServices(
          `/api/orders/list_all_orders?filter_type=${filterData.order_status}&order_filter=${filterData.order_time}`,
          access_token
        );
        setIsFiltered(true);
        if (res.responseCode === 200) {
          setData(res.responseData.rows);
          setIsBottomNav(false);
        } else {
          toast.error(res.responseMessage);
        }
      } catch (error) {
        toast.error(error);
      }
    } else {
      alert("Please click anyone to proceed.");
    }
  };

  return (
    <>
      {navigator.onLine ? (
        <Container>
          {loading ? (
            <Container className="flex items-center justify-center min-h-[65vh]">
              <LoadingSpinner />
            </Container>
          ) : (
            <Container className="min-h-[65vh]">
              <Container className="flex justify-center">
                <Container className="w-full sm:w-3/4 py-2 flex justify-between items-center px-3 sm:px-0">
                  <Breadcrumbs
                    separator=">"
                    aria-label="breadcrumb"
                    sx={{
                      fontFamily: "Poppins",
                    }}
                  >
                    <Link underline="hover" color="inherit" to="/profile">
                      Profile
                    </Link>
                    <Typography
                      color="text.primary"
                      sx={{
                        fontFamily: "Poppins",
                      }}
                    >
                      My Order
                    </Typography>
                  </Breadcrumbs>
                  <Container className="flex items-center gap-3">
                    <FaFilter
                      className="cursor-pointer"
                      onClick={() => {
                        setIsBottomNav(true);
                        // setFilterData({
                        //   ...filterData,
                        //   order_status: "",
                        //   order_time: "",
                        // });
                      }}
                    />
                    {isFiltered && (
                      <Button
                        onClick={() => {
                          setFilterData({
                            ...filterData,
                            order_status: "",
                            order_time: "",
                          });
                          fetchOrders();
                        }}
                        className="bg-red-200 text-red-500 px-2 py-1 rounded-md font-semibold"
                      >
                        Clear Filter
                      </Button>
                    )}
                  </Container>
                </Container>
              </Container>
              {data.length > 0 ? (
                <Container className="flex justify-center flex-col gap-3 items-center px-2 sm:px-0 mb-4">
                  {data.map((item, i) => (
                    <Container
                      className="bg-[#FFFAEF] w-full sm:w-3/4 px-4 py-2 flex flex-col gap-2 items-start relative rounded-md"
                      key={i}
                    >
                      <Link
                        to="/manage_orders/order_details"
                        state={{ track_id: item.track_id }}
                        className="w-full"
                      >
                        {/* <Container className="flex items-center gap-2 sm:gap-4">
                      {item.orderStatus === 1 ? (
                        <RiErrorWarningFill className="text-xl" />
                      ) : item.orderStatus === 2 ? (
                        <BiSolidBox className="text-xl" />
                      ) : item.orderStatus === 3 ? (
                        <FaTruck className="text-xl" />
                      ) : item.orderStatus === 4 ? (
                        <BsFillGiftFill className="text-xl" />
                      ) : item.orderStatus === 5 ? (
                        <BsFillPatchCheckFill className="text-xl" />
                      ) : item.orderStatus === 6 ? (
                        <MdCancel className="text-xl" />
                      ) : (
                        <FaRegMoneyBill1 />
                      )}
                      <p className="text-sm sm:text-base font-semibold">
                        {item.orderStatus === 1
                          ? "Pending"
                          : item.orderStatus === 2
                          ? "Processing"
                          : item.orderStatus === 3
                          ? "Shipped"
                          : item.orderStatus === 4
                          ? "Dispatched"
                          : item.orderStatus === 5
                          ? "Delivered"
                          : item.orderStatus === 6
                          ? "Cancelled"
                          : "Refunded"}
                      </p>
                    </Container> */}
                        <Container className="sm:mx-5 bg-white sm:w-[95%] p-2">
                          <p className="font-semibold">
                            Order Id - {item.track_id}
                          </p>
                          <p className="text-[#757575]">
                            Ordered Date -
                            {moment(item.date).format("DD/MM/YYYY")}
                          </p>
                          <p className="text-[#757575]">
                            Total Amount -{" "}
                            <span className="text-black font-semibold">
                              ₹{item.totalAmount?.toLocaleString("en-in")}
                            </span>
                          </p>
                        </Container>
                      </Link>
                    </Container>
                  ))}
                </Container>
              ) : (
                <Container className="flex justify-center items-center h-[65vh]">
                  <p className="font-semibold text-lg">No Orders found...</p>
                </Container>
              )}
            </Container>
          )}
        </Container>
      ) : (
        <Container className="min-h-[65vh] flex flex-col justify-center items-center">
          <NoInternetComp />
        </Container>
      )}

      {/* bottom sheet for forgot password  */}
      <CustomBottomSheet
        isBottomNav={isBottomNav}
        onClose={() => setIsBottomNav(false)}
      >
        <Container className="mx-10 my-10">
          <h2 className="font-bold text-xl lg:text-2xl mb-2">Filter Orders</h2>
          <p className="font-semibold text-lg">Order Time</p>

          <Container>
            <FormControl className="w-full">
              <RadioGroup
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                className="grid-cols-2 sm:grid-cols-3 md:grid-cols-4"
                sx={{ display: "grid !important" }}
                value={filterData.order_time ?? ""}
                onChange={(e) =>
                  setFilterData({
                    ...filterData,
                    order_time: e.target.value,
                  })
                }
              >
                <FormControlLabel
                  value="1"
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "#352210",
                        },
                      }}
                    />
                  }
                  label="One month"
                  sx={{
                    ".MuiFormControlLabel-root .MuiFormControlLabel-label, .MuiTypography-root":
                      { fontFamily: "Poppins" },
                  }}
                />
                <FormControlLabel
                  value="2"
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "#352210",
                        },
                      }}
                    />
                  }
                  label="Three month"
                  sx={{
                    ".MuiFormControlLabel-root .MuiFormControlLabel-label, .MuiTypography-root":
                      { fontFamily: "Poppins" },
                  }}
                />
                <FormControlLabel
                  value="3"
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "#352210",
                        },
                      }}
                    />
                  }
                  label="Six month"
                  sx={{
                    ".MuiFormControlLabel-root .MuiFormControlLabel-label, .MuiTypography-root":
                      { fontFamily: "Poppins" },
                  }}
                />
                <FormControlLabel
                  value="4"
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "#352210",
                        },
                      }}
                    />
                  }
                  label="One Year"
                  sx={{
                    ".MuiFormControlLabel-root .MuiFormControlLabel-label, .MuiTypography-root":
                      { fontFamily: "Poppins" },
                  }}
                />
              </RadioGroup>
            </FormControl>
          </Container>

          <p className="font-semibold text-lg mt-4">Order Status</p>
          <Container>
            <FormControl className="w-full">
              <RadioGroup
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                className="grid-cols-1 sm:grid-cols-2 md:grid-cols-3"
                sx={{ display: "grid !important" }}
                value={filterData.order_status}
                onChange={(e) =>
                  setFilterData({
                    ...filterData,
                    order_status: e.target.value,
                  })
                }
              >
                <FormControlLabel
                  value="2"
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "#352210",
                        },
                      }}
                    />
                  }
                  label="Processing"
                  sx={{
                    ".MuiFormControlLabel-root .MuiFormControlLabel-label, .MuiTypography-root":
                      { fontFamily: "Poppins" },
                  }}
                />
                <FormControlLabel
                  value="8"
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "#352210",
                        },
                      }}
                    />
                  }
                  label="Initialised to Delivery partners"
                  sx={{
                    ".MuiFormControlLabel-root .MuiFormControlLabel-label, .MuiTypography-root":
                      { fontFamily: "Poppins" },
                  }}
                />
                <FormControlLabel
                  value="6"
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "#352210",
                        },
                      }}
                    />
                  }
                  label="Cancelled"
                  sx={{
                    ".MuiFormControlLabel-root .MuiFormControlLabel-label, .MuiTypography-root":
                      { fontFamily: "Poppins" },
                  }}
                />
              </RadioGroup>
            </FormControl>
          </Container>

          <Container className="flex justify-end items-center">
            <Button
              onClick={handleApplyFilter}
              className="bg-[#352210] mt-2 text-white px-4 py-2 rounded-lg outline-none font-semibold active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out"
            >
              Apply Filter
            </Button>
          </Container>
        </Container>
      </CustomBottomSheet>
    </>
  );
}

export default ManageOrders;
