import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./style.css";

function TextEdior({ handleChange, value, className }) {
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      [{ align: [] }],
      ["link", "image"],
      ["clean"],
    ],
  };

  return (
    <div className="rounded-2xl">
      <ReactQuill
        onChange={handleChange}
        value={value}
        placeholder="Write a description..."
        modules={modules}
        className={className}
      />
    </div>
  );
}

export default TextEdior;
