import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "reactstrap";
import Sidebar from "../common/Admin/common/Sidebar";
import Navbar from "../common/Admin/common/Navbar";
import { FaBars } from "react-icons/fa";

function AdminLayout(props) {
  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);
  const navigate = useNavigate();
  const [activeMenuItem, setActiveMenuItem] = useState(0);

  const handleCollapsedChange = () => {
    setCollapsed(!collapsed);
  };

  const handleToggleSidebar = (value, i) => {
    setToggled(value);
    setActiveMenuItem(i);
    sessionStorage.setItem("activeMenuItem", i);
  };

  useEffect(() => {
    const storedActiveIndex = localStorage.getItem("activeMenuItem");

    if (storedActiveIndex) {
      setActiveMenuItem(parseInt(storedActiveIndex));
    }

    if (!sessionStorage.getItem("access-token")) {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <Container className={`app ${toggled ? "toggled" : ""} bg-zinc-100`}>
      <Sidebar
        collapsed={collapsed}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
        handleCollapsedChange={handleCollapsedChange}
        activeMenuItem={activeMenuItem}
      />
      <main>
        <Navbar>
          <FaBars
            className="btn-toggle"
            onClick={() => handleToggleSidebar(true)}
          />
        </Navbar>
        {props.children}
      </main>
    </Container>
  );
}

export default AdminLayout;
