import { Box, CircularProgress, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Container, Form } from "reactstrap";
import TextEdior from "../common/TextEdior";
import ReactSelect from "react-select";
import { useNavigate } from "react-router-dom";
import { MdClear, MdDeleteForever, MdDeleteOutline } from "react-icons/md";
import CustomInputField from "../common/CustomInputField";
import { AiOutlineCloseCircle, AiOutlineEdit } from "react-icons/ai";
import UpdateColor from "../Modal/Colors/UpdateColor";
import {
  getServices,
  postServices,
  removeServices,
  updateServices,
} from "../../../../apiServices/apiServices";
import toast from "react-hot-toast";

function AddProductView() {
  const [isShow, setIsShow] = useState({
    basic: true,
    advanced: false,
    add_size: false,
  });
  const [selectedOption, setSelectedOption] = useState({
    category: [],
    subcategory: [],
  });
  const [isCategoryList, setIsCategoryList] = useState([]);
  const [isSubcategoryList, setIsSubcategoryList] = useState([]);
  const [data, setData] = useState({
    product_name: "",
    sub_desc: "",
    desc: "",
  });
  const navigate = useNavigate();
  const access_token = sessionStorage.getItem("access-token");
  const [isLoading, setIsLoading] = useState(false);
  const [variationInputFields, setVariationInputFields] = useState([
    { color: "" },
  ]);
  const [characterLimit] = useState(225);
  const productModelsStatus = localStorage.getItem("product-modules");
  const draftProductData = localStorage.getItem("draft-product-data");
  const productColors = localStorage.getItem("product-colors");
  const [colorsData, setColorsData] = useState([]);
  const [colorInputFields, setColorInputFields] = useState(
    Array.from({ length: colorsData.length }, () => ({
      fields: [
        {
          size: "",
          price: "",
          sales_price: "",
          length: "",
          breadth: "",
          weight: "",
          image: [],
          preview_image: [],
          product_hsn: "",
          product_sku: "",
          quantity: "",
        },
      ],
    }))
  );
  const [colorModal, setColorModal] = useState({ edit: false, delete: false });
  const [colorUpdateData, setColorUpdateData] = useState({
    color_id: "",
    color_name: "",
  });
  const [isHsnData, setIsHsnData] = useState([]);

  const colorStyles = {
    control: (styles) => ({
      ...styles,
      //   backgroundColor: "#c5d3e380",
      border: "1px solid #352210",
      marginBottom: "0",
      marginTop: "0",
      outline: "none",
      padding: "0.5rem",
      boxShadow: "none",
      borderRadius: "10px",
      "&:hover": {
        border: "1px solid #352210",
      },
    }),
  };

  useEffect(() => {
    if (navigator.onLine) {
      fetchCategoryList();
      fetchHsnCodeList();
    }

    if (
      JSON.parse(productModelsStatus) &&
      JSON.parse(draftProductData) &&
      JSON.parse(productColors)
    ) {
      const productPageStatus = JSON.parse(productModelsStatus);
      const localProductData = JSON.parse(draftProductData);
      const localColorsData = JSON.parse(productColors);
      setIsShow({
        ...isShow,
        basic: productPageStatus.basic,
        advanced: productPageStatus.advanced,
        add_size: productPageStatus.add_size,
      });
      setData({
        ...data,
        product_name: localProductData.product_name,
        sub_desc: localProductData.sub_desc,
        desc: localProductData.desc,
        product_code: localProductData.product_code,
        product_sku: localProductData.product_sku,
        quantity: localProductData.quantity,
        color: localProductData.variation_title,
      });
      setColorsData(localColorsData);
    } else {
      localStorage.setItem("product-modules", JSON.stringify(isShow));
      localStorage.setItem("draft-product-data", JSON.stringify(data));
      localStorage.setItem("product-colors", JSON.stringify(colorsData));
    }

    setColorInputFields(
      Array.from({ length: colorsData.length }, () => ({
        fields: [
          {
            size: "",
            price: "",
            sales_price: "",
            length: "",
            breadth: "",
            weight: "",
            image: [],
            preview_image: [],
          },
        ],
      }))
    );
  }, [colorsData.length]);

  const fetchCategoryList = async () => {
    try {
      const res = await getServices(
        `/api/category/list_category`,
        access_token
      );
      setIsCategoryList(res.responseData);
    } catch (error) {
      toast.error(error);
    }
  };

  const fetchHsnCodeList = async () => {
    try {
      const res = await getServices(`/api/manage/hsn/list`, access_token);
      if (res.responseCode === 200) {
        setIsHsnData(res.responseData);
      } else {
        setIsHsnData([]);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleNextModule = () => {
    setIsShow({ basic: false, advanced: true, add_size: false });
    localStorage.setItem(
      "product-modules",
      JSON.stringify({ basic: false, advanced: true, add_size: false })
    );
  };
  const handleBackModule = () => {
    setIsShow({ basic: true, advanced: false });
    localStorage.setItem(
      "product-modules",
      JSON.stringify({ basic: true, advanced: false })
    );
  };

  const handleColorChange = (evnt, index) => {
    const { name, value } = evnt.target;
    const list = [...variationInputFields];
    list[index][name] = value;
    setVariationInputFields(list);
  };

  const handleCategoryChange = async (e) => {
    setSelectedOption({ ...selectedOption, category: e, subcategory: [] });

    try {
      const res = await getServices(`/api/subcat/list_subcat/${e.value}`);
      setIsSubcategoryList(res?.responseData[0].sub_categories || []);
    } catch (error) {
      toast.error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const urlencoded = new URLSearchParams();

    urlencoded.append("product_name", data.product_name);
    urlencoded.append("description", data.sub_desc);
    urlencoded.append("product_feature", data.desc);
    urlencoded.append("cat_id", selectedOption.category.value);
    urlencoded.append(
      "subcat_id",
      selectedOption.subcategory.length === 0
        ? ""
        : selectedOption.subcategory.value
    );
    for (let i = 0; i < variationInputFields.length; i++) {
      urlencoded.append("colors", variationInputFields[i].color);
    }

    try {
      const res = await postServices(
        `/api/products/add_product`,
        access_token,
        urlencoded
      );
      setIsLoading(false);
      if (res.responseCode === 200) {
        setIsLoading(false);
        setIsShow({ basic: false, advanced: false, add_size: true });
        localStorage.setItem(
          "product-modules",
          JSON.stringify({ basic: false, advanced: false, add_size: true })
        );
        localStorage.setItem("product-id", res.responseData.productId);
        fetchColorData(res.responseData.productId);
      } else {
        toast.error(res.responseMessage);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error);
    }
  };

  const fetchColorData = async (productId) => {
    try {
      const res2 = await getServices(
        `/api/products/list_colors/${productId}`,
        access_token
      );
      if (res2.responseCode === 200) {
        setColorsData(res2.responseData);
        localStorage.setItem(
          "product-colors",
          JSON.stringify(res2.responseData)
        );
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const addInputField = () => {
    if (
      variationInputFields[0].color.trim() !== "" &&
      variationInputFields[variationInputFields.length - 1].color.trim() !== ""
    ) {
      setVariationInputFields([...variationInputFields, { color: "" }]);
    } else {
      toast.error("Please fill the details...");
    }
  };
  const removeInputFields = (index) => {
    const rows = [...variationInputFields];
    rows.splice(index, 1);
    setVariationInputFields(rows);
  };

  const handleAddMoreSizeBtn = (index) => {
    const newSizeFields = [...colorInputFields];
    newSizeFields[index].fields.push({
      size: "",
      price: "",
      sales_price: "",
      length: "",
      breadth: "",
      weight: "",
      image: [],
      preview_image: [],
    });
    setColorInputFields(newSizeFields);
  };

  const handleRemoveSizeFields = (colorIndex, fieldIndex) => {
    const rows = [...colorInputFields];
    rows[colorIndex].fields.splice(fieldIndex, 1);
    setColorInputFields(rows);
  };

  const handleSizeChange = (colorIndex, fieldIndex, e) => {
    const newSizeFieldsData = [...colorInputFields];
    newSizeFieldsData[colorIndex].fields[fieldIndex][e.target.name] =
      e.target.value;
    setColorInputFields(newSizeFieldsData);
  };

  // const handleProductSKUChange = (colorIndex, fieldIndex, e) => {
  //   const newSizeFieldsData = [...colorInputFields];
  //   newSizeFieldsData[colorIndex].fields[fieldIndex][e.target.name] =
  //     e.target.value;
  //   setColorInputFields(newSizeFieldsData);
  // };

  // const handleQuantityChange = (colorIndex, fieldIndex, e) => {
  //   const newSizeFieldsData = [...colorInputFields];
  //   newSizeFieldsData[colorIndex].fields[fieldIndex][e.target.name] =
  //     e.target.value;
  //   setColorInputFields(newSizeFieldsData);
  // };

  // const handleProductHSNhange = (colorIndex, fieldIndex, e) => {
  //   const newSizeFieldsData = [...colorInputFields];
  //   newSizeFieldsData[colorIndex].fields[fieldIndex]["product_hsn"] = e;
  //   setColorInputFields(newSizeFieldsData);
  // };

  const handleColorImageChange = (e, colorIndex, fieldIndex) => {
    const images = Array.from(e.target.files);
    const newSizeFieldsImage = [...colorInputFields];

    for (const image of images) {
      if (image.size > 2 * 1024 * 1024) {
        toast.error("Image size must be less than 2MB.");
        // setPreview([]);
        return;
      }
      newSizeFieldsImage[colorIndex].fields[fieldIndex]["image"].push(image);
      newSizeFieldsImage[colorIndex].fields[fieldIndex]["preview_image"].push(
        URL.createObjectURL(image)
      );
    }
    setColorInputFields(newSizeFieldsImage);
  };

  const handleRemoveParticularImage = (colorIndex, fieldIndex, imageIndex) => {
    const rows = [...colorInputFields];
    rows[colorIndex].fields[fieldIndex].image.splice(imageIndex, 1);
    rows[colorIndex].fields[fieldIndex].preview_image.splice(imageIndex, 1);
    setColorInputFields(rows);
  };

  const handleUpdateColors = (color) => {
    setColorModal({ ...colorModal, edit: true });
    setColorUpdateData({
      ...colorUpdateData,
      color_id: color.product_color_id,
      color_name: color.colors,
    });
  };

  const handleColorSubmit = async (e) => {
    e.preventDefault();
    const product_id = localStorage.getItem("product-id");
    const value = { colors: colorUpdateData.color_name };

    try {
      const res = await updateServices(
        `/api/products/update_color/${colorUpdateData.color_id}`,
        access_token,
        value
      );
      if (res.responseCode === 200) {
        fetchColorData(product_id);
        setColorModal({ ...colorStyles, edit: false });
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleDeleteColor = async (data) => {
    const product_id = localStorage.getItem("product-id");
    if (window.confirm(`Do you want delete this color?`)) {
      try {
        const res = await removeServices(
          `/api/products/delete_color/${data.product_color_id}`,
          access_token
        );
        if (res.responseCode === 200) {
          fetchColorData(product_id);
        } else {
          toast.error(res.responseMessage);
        }
      } catch (error) {
        toast.error(error);
      }
    }
  };

  const handleCompleteVariation = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const requests = [];

    for (let i = 0; i < colorsData.length; i++) {
      for (let j = 0; j < colorInputFields[i].fields.length; j++) {
        const formData = new FormData();

        formData.append(
          "variation",
          parseFloat(colorInputFields[i].fields[j].size)
        );
        formData.append(
          "price",
          parseFloat(colorInputFields[i].fields[j].price)
        );
        formData.append(
          "sales_price",
          parseFloat(colorInputFields[i].fields[j].sales_price) > 0
            ? parseFloat(colorInputFields[i].fields[j].sales_price)
            : 0
        );
        formData.append(
          "weight",
          parseFloat(colorInputFields[i].fields[j].weight)
        );
        formData.append(
          "length",
          parseFloat(colorInputFields[i].fields[j].length)
        );
        formData.append(
          "breadth",
          parseFloat(colorInputFields[i].fields[j].breadth)
        );
        // formData.append(
        //   "product_sku",
        //   colorInputFields[i].fields[j].product_sku
        // );
        // formData.append(
        //   "product_hsn",
        //   colorInputFields[i].fields[j].product_hsn?.label
        // );
        // formData.append("quantity", colorInputFields[i].fields[j].quantity);

        for (
          let img = 0;
          img < colorInputFields[i].fields[j].image.length;
          img++
        ) {
          formData.append("image", colorInputFields[i].fields[j].image[img]);
        }

        requests.push(
          postServices(
            `/api/products/add_variation/${colorsData[i].product_color_id}`,
            access_token,
            formData
          )
        );
      }
    }

    try {
      const responses = await Promise.all(requests);
      setIsLoading(false);
      for (let suc = 0; suc < responses.length; suc++) {
        if (responses[suc].responseCode === 200) {
          toast.success("Product created...");
          navigate("/admin/products");
          localStorage.removeItem("product-modules");
          localStorage.removeItem("draft-product-data");
          localStorage.removeItem("product-id");
          localStorage.removeItem("product-colors");
        } else {
          toast.error(responses[suc].responseMessage);
        }
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleDiscardProduct = () => {
    localStorage.removeItem("product-modules");
    localStorage.removeItem("draft-product-data");
    localStorage.removeItem("product-id");
    localStorage.removeItem("product-colors");
    navigate("/admin/products");
  };

  return (
    <Container>
      <p className="p-8 text-2xl font-semibold">Create a new product</p>
      <Container className="bg-white shadow mx-4 md:mx-8 rounded-xl mb-8">
        <p className="px-8 py-6 font-bold text-xl">
          {isShow.basic ? "Details" : isShow.advanced ? "Properties" : "Sizes"}
        </p>
        <Container className="px-4 md:px-8 w-full">
          <Form onSubmit={handleSubmit}>
            {isShow.basic && (
              <Container>
                <Box
                  sx={{
                    maxWidth: "100%",
                  }}
                  // className="mr-8 md:mr-16"
                >
                  <TextField
                    fullWidth
                    autoFocus
                    name="product_name"
                    label="Product Name"
                    id="fullWidth"
                    sx={{
                      "& .MuiInputLabel-root": {
                        fontFamily: "Poppins",
                      },
                      "& .MuiOutlinedInput-root": {
                        fontFamily: "Poppins",
                        borderRadius: "12px",
                      },
                      "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#352210",
                        },
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#352210",
                        },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #352210",
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "#352210",
                      },
                    }}
                    value={data.product_name}
                    onChange={(e) => {
                      setData({ ...data, product_name: e.target.value });
                      localStorage.setItem(
                        "draft-product-data",
                        JSON.stringify({
                          ...data,
                          product_name: e.target.value,
                        })
                      );
                    }}
                    required
                  />
                  <TextField
                    fullWidth
                    label="Sub Description"
                    id="fullWidth"
                    multiline
                    minRows={3}
                    maxRows={3}
                    sx={{
                      "& .MuiInputLabel-root": {
                        fontFamily: "Poppins",
                      },
                      "& .MuiOutlinedInput-root": {
                        fontFamily: "Poppins",
                        borderRadius: "12px",
                      },
                      "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#352210",
                        },
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#352210",
                        },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #352210",
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "#352210",
                      },
                      marginTop: "30px",
                    }}
                    value={data.sub_desc}
                    onChange={(e) => {
                      setData({ ...data, sub_desc: e.target.value });
                      localStorage.setItem(
                        "draft-product-data",
                        JSON.stringify({
                          ...data,
                          sub_desc: e.target.value,
                        })
                      );
                    }}
                    inputProps={{ maxLength: characterLimit }}
                    required={false}
                  />
                  <Container className="flex justify-end mb-8 mt-2">
                    <p className="text-sm">
                      {data.sub_desc.length} / {characterLimit}
                    </p>
                  </Container>
                  <TextEdior
                    className="mb-8"
                    handleChange={(e) => {
                      setData({ ...data, desc: e });
                      localStorage.setItem(
                        "draft-product-data",
                        JSON.stringify({
                          ...data,
                          desc: e,
                        })
                      );
                    }}
                    value={data.desc}
                  />
                </Box>
                <Container className="flex justify-between items-center">
                  <Button
                    onClick={handleDiscardProduct}
                    className="bg-red-100 text-red-500 border-none outline-none p-3 rounded-xl mb-6 font-semibold disabled:cursor-not-allowed disabled:bg-slate-500"
                  >
                    Discard Product
                  </Button>
                  <Button
                    type="button"
                    onClick={handleNextModule}
                    className="bg-slate-800 text-white border-none outline-none p-3 rounded-xl mb-6 font-semibold disabled:cursor-not-allowed disabled:bg-slate-500"
                    disabled={
                      data.product_name === ""
                        ? // || data.sub_desc === "" ||
                          // data.desc === ""
                          // selectedImages.length < 1 ||
                          // selectedImages.length > 8
                          true
                        : false
                    }
                  >
                    Next
                  </Button>
                </Container>
              </Container>
            )}

            {isShow.advanced && (
              <Container>
                <Box
                  sx={{
                    maxWidth: "100%",
                  }}
                  // className="mr-8 md:mr-16"
                >
                  {/* <Container className="flex flex-col sm:flex-row">
                    <TextField
                      fullWidth
                      name="product_code"
                      label="Product Code"
                      id="fullWidth"
                      sx={{
                        "& .MuiInputLabel-root": {
                          fontFamily: "Poppins",
                        },
                        "& .MuiOutlinedInput-root": {
                          fontFamily: "Poppins",
                          borderRadius: "12px",
                        },
                        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#352210",
                          },
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#352210",
                          },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #352210",
                        },
                        "& .MuiInputLabel-root.Mui-focused": {
                          color: "#352210",
                        },
                        marginRight: "1rem",
                        marginBottom: "15px",
                      }}
                      onChange={(e) => {
                        setData({ ...data, product_code: e.target.value });
                        localStorage.setItem(
                          "draft-product-data",
                          JSON.stringify({
                            ...data,
                            product_code: e.target.value,
                          })
                        );
                      }}
                      value={data.product_code}
                      required
                    />
                    <TextField
                      fullWidth
                      name="product_sku"
                      label="Product SKU"
                      id="fullWidth"
                      sx={{
                        "& .MuiInputLabel-root": {
                          fontFamily: "Poppins",
                        },
                        "& .MuiOutlinedInput-root": {
                          fontFamily: "Poppins",
                          borderRadius: "12px",
                        },
                        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#352210",
                          },
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#352210",
                          },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #352210",
                        },
                        "& .MuiInputLabel-root.Mui-focused": {
                          color: "#352210",
                        },
                        marginRight: "1rem",
                        marginBottom: "15px",
                      }}
                      value={data.product_sku}
                      onChange={(e) => {
                        setData({ ...data, product_sku: e.target.value });
                        localStorage.setItem(
                          "draft-product-data",
                          JSON.stringify({
                            ...data,
                            product_sku: e.target.value,
                          })
                        );
                      }}
                      required
                    />
                    <TextField
                      fullWidth
                      name="quantity"
                      label="Quantity"
                      type="number"
                      id="fullWidth"
                      onInput={(e) => {
                        e.target.value = Math.max(1, parseInt(e.target.value))
                          .toString()
                          .slice(0);
                      }}
                      sx={{
                        "& .MuiInputLabel-root": {
                          fontFamily: "Poppins",
                        },
                        "& .MuiOutlinedInput-root": {
                          fontFamily: "Poppins",
                          borderRadius: "12px",
                        },
                        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#352210",
                          },
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#352210",
                          },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #352210",
                        },
                        "& .MuiInputLabel-root.Mui-focused": {
                          color: "#352210",
                        },
                      }}
                      onChange={(e) => {
                        setData({ ...data, quantity: e.target.value });
                        localStorage.setItem(
                          "draft-product-data",
                          JSON.stringify({
                            ...data,
                            quantity: e.target.value,
                          })
                        );
                      }}
                      value={data.quantity}
                      required
                    />
                  </Container> */}
                  <Container className="flex flex-col sm:flex-row mb-4 mt-3 sm:mt-0 sm:mb-0">
                    <ReactSelect
                      options={isCategoryList.map((category) => {
                        return {
                          value: category.category_id,
                          label: category.category_name,
                        };
                      })}
                      onChange={handleCategoryChange}
                      placeholder="Select the Category"
                      styles={colorStyles}
                      className="w-full mr-4"
                      required
                    />
                    {isSubcategoryList.length > 0 && (
                      <ReactSelect
                        options={isSubcategoryList.map((subcategory) => {
                          return {
                            value: subcategory.subcat_id,
                            label: subcategory.subcat_name,
                          };
                        })}
                        onChange={(e) =>
                          setSelectedOption({
                            ...selectedOption,
                            subcategory: e,
                          })
                        }
                        placeholder="Select the sub-category"
                        styles={colorStyles}
                        className="w-full"
                        // required
                      />
                    )}
                  </Container>
                  <Container className="flex justify-between items-center flex-row mt-3 mb-4">
                    <p className="text-lg font-medium">Insert color</p>
                    <Button
                      type="button"
                      onClick={addInputField}
                      className="bg-slate-800 whitespace-nowrap flex items-center px-2 sm:px-7 py-2 text-white font-semibold rounded-xl outline-none border-none active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out"
                    >
                      Add Color
                    </Button>
                  </Container>
                  <Container className="flex flex-wrap">
                    {variationInputFields.map((data, i) => {
                      const { color } = data;
                      return (
                        <Container
                          className="flex flex-row sm:flex-row w-full sm:w-1/2"
                          key={i}
                        >
                          <TextField
                            fullWidth
                            name="color"
                            label="Color"
                            id="fullWidth"
                            sx={{
                              "& .MuiInputLabel-root": {
                                fontFamily: "Poppins",
                                zIndex: 0,
                              },
                              "& .MuiOutlinedInput-root": {
                                fontFamily: "Poppins",
                                borderRadius: "12px",
                              },
                              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#352210",
                                },
                              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#352210",
                                },
                              "& .MuiOutlinedInput-notchedOutline": {
                                border: "1px solid #352210",
                              },
                              "& .MuiInputLabel-root.Mui-focused": {
                                color: "#352210",
                              },
                              marginRight:
                                variationInputFields.length !== 1 ? "1rem" : "",
                              marginBottom: "15px",
                            }}
                            onChange={(e) => handleColorChange(e, i)}
                            value={color}
                            required
                          />
                          {variationInputFields.length !== 1 ? (
                            <Button
                              type="button"
                              className={`mb-5 ml-0 p-[12px] bg-red-600 text-white rounded-full w-[15%] sm:w-[unset] outline-none ${
                                variationInputFields.length !== 1 ? "mr-2" : ""
                              }`}
                              onClick={() => removeInputFields(i)}
                            >
                              <MdDeleteOutline className="text-2xl" />
                            </Button>
                          ) : (
                            ""
                          )}
                        </Container>
                      );
                    })}
                  </Container>
                </Box>
                <Container className="flex justify-between">
                  <div className="flex items-center gap-2">
                    <Button
                      type="button"
                      onClick={handleBackModule}
                      className="bg-slate-800 text-white border-none outline-none p-3 rounded-xl mb-6 font-semibold"
                    >
                      Back
                    </Button>
                    <Button
                      onClick={handleDiscardProduct}
                      className="bg-red-100 text-red-500 border-none outline-none p-3 rounded-xl mb-6 font-semibold disabled:cursor-not-allowed disabled:bg-slate-500"
                    >
                      Discard Product
                    </Button>
                  </div>
                  {isLoading ? (
                    <Button
                      type="button"
                      className={`bg-slate-800 text-white border-none outline-none p-3 rounded-xl mb-6 font-semibold ${
                        isLoading ? "w-20" : ""
                      }`}
                    >
                      <CircularProgress color="secondary" size={25} />
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      className={`bg-slate-800 text-white border-none outline-none p-3 rounded-xl mb-6 font-semibold ${
                        isLoading ? "w-20" : ""
                      }`}
                    >
                      Create Product
                    </Button>
                  )}
                </Container>
              </Container>
            )}
          </Form>

          {isShow.add_size && (
            <Container className="px-0 pb-6">
              <Form onSubmit={handleCompleteVariation}>
                {colorInputFields.map((colorFields, colorIndex) => (
                  <Container key={colorIndex} className="mb-4">
                    <Container className="flex justify-between items-center">
                      <p className="font-semibold mb-1">
                        {colorsData[colorIndex]?.colors}
                      </p>
                      <Container className="flex items-center gap-3">
                        <AiOutlineEdit
                          className="text-3xl bg-[#7fae9c] text-white p-1 rounded-full cursor-pointer"
                          onClick={() =>
                            handleUpdateColors(colorsData[colorIndex])
                          }
                        />
                        {colorsData.length > 1 && (
                          <MdDeleteForever
                            className="text-3xl bg-[#73c1de] text-white p-1 rounded-full cursor-pointer"
                            onClick={() =>
                              handleDeleteColor(colorsData[colorIndex])
                            }
                          />
                        )}
                        <Button
                          type="button"
                          onClick={() => handleAddMoreSizeBtn(colorIndex)}
                          className="bg-slate-800 text-white px-4 py-1 rounded-lg"
                        >
                          Add more sizes
                        </Button>
                      </Container>
                    </Container>
                    {colorFields.fields.map((inputField, fieldIndex) => (
                      <Container key={fieldIndex} className="mt-2 w-full">
                        {/* <Container className="flex flex-col sm:flex-row items-center gap-4">
                          <ReactSelect
                            options={isHsnData.map((hsn) => {
                              return {
                                value: hsn.hsn_id,
                                label: hsn.hsn_number,
                              };
                            })}
                            onChange={(e) =>
                              handleProductHSNhange(colorIndex, fieldIndex, e)
                            }
                            placeholder="Select the HSN Code"
                            styles={colorStyles}
                            className="w-full"
                            required
                          />
                          <CustomInputField
                            name="product_sku"
                            label="Product SKU"
                            type="text"
                            required={true}
                            value={inputField.product_sku}
                            onChange={(e) =>
                              handleProductSKUChange(colorIndex, fieldIndex, e)
                            }
                          />
                          <CustomInputField
                            name="quantity"
                            label="Quantity"
                            type="number"
                            required={true}
                            value={inputField.quantity}
                            onChange={(e) =>
                              handleQuantityChange(colorIndex, fieldIndex, e)
                            }
                            min={1}
                          />
                          {colorInputFields[colorIndex].fields.length > 1 && (
                            <Button
                              onClick={() =>
                                handleRemoveSizeFields(colorIndex, fieldIndex)
                              }
                            >
                              <AiOutlineCloseCircle className="text-2xl" />
                            </Button>
                          )}
                        </Container> */}
                        <Container className="flex flex-col sm:flex-row items-center gap-4 mt-3">
                          <CustomInputField
                            name="size"
                            label="Size in inches"
                            type="number"
                            required={true}
                            value={inputField.size}
                            onChange={(e) =>
                              handleSizeChange(colorIndex, fieldIndex, e)
                            }
                          />
                          <CustomInputField
                            name="price"
                            label="Price"
                            type="number"
                            required={true}
                            value={inputField.price}
                            onChange={(e) =>
                              handleSizeChange(colorIndex, fieldIndex, e)
                            }
                          />
                          <CustomInputField
                            name="sales_price"
                            label="Sales Price"
                            type="number"
                            required={false}
                            value={inputField.sales_price}
                            onChange={(e) =>
                              handleSizeChange(colorIndex, fieldIndex, e)
                            }
                          />
                          {colorInputFields[colorIndex].fields.length > 1 && (
                            <Button
                              onClick={() =>
                                handleRemoveSizeFields(colorIndex, fieldIndex)
                              }
                            >
                              <AiOutlineCloseCircle className="text-2xl" />
                            </Button>
                          )}
                        </Container>
                        <Container className="flex flex-col sm:flex-row items-center gap-4 mt-3">
                          <CustomInputField
                            name="weight"
                            label="Weight in Kgs"
                            type="number"
                            required={true}
                            value={inputField.weight}
                            onChange={(e) =>
                              handleSizeChange(colorIndex, fieldIndex, e)
                            }
                          />
                          <CustomInputField
                            name="length"
                            label="Length in cms"
                            type="number"
                            required={true}
                            value={inputField.length}
                            onChange={(e) =>
                              handleSizeChange(colorIndex, fieldIndex, e)
                            }
                          />
                          <CustomInputField
                            name="breadth"
                            label="Breadth in cms"
                            type="number"
                            required={true}
                            value={inputField.breadth}
                            onChange={(e) =>
                              handleSizeChange(colorIndex, fieldIndex, e)
                            }
                          />
                          {/* {colorInputFields[colorIndex].fields.length > 1 && (
                            <Button
                              onClick={() =>
                                handleRemoveSizeFields(colorIndex, fieldIndex)
                              }
                            >
                              <AiOutlineCloseCircle className="text-2xl" />
                            </Button>
                          )} */}
                        </Container>
                        <Container className="flex flex-col mt-2">
                          <input
                            type="file"
                            accept="image/png, image/jpg, image/jpeg, image/heic"
                            multiple
                            className="border border-slate-400 p-2 rounded-md"
                            onChange={(e) =>
                              handleColorImageChange(e, colorIndex, fieldIndex)
                            }
                            required
                          />
                          <Container className="flex gap-4 mt-2">
                            {colorInputFields[colorIndex].fields[fieldIndex]
                              .image.length > 0
                              ? colorInputFields[colorIndex].fields[
                                  fieldIndex
                                ].preview_image.map((item, i) => (
                                  <Container key={i} className="relative">
                                    <img
                                      src={item}
                                      alt="navarang smaranika"
                                      className="w-20 h-20 object-cover rounded-lg"
                                    />
                                    {colorInputFields[colorIndex].fields[
                                      fieldIndex
                                    ].preview_image.length > 1 && (
                                      <Button
                                        onClick={() =>
                                          handleRemoveParticularImage(
                                            colorIndex,
                                            fieldIndex,
                                            i
                                          )
                                        }
                                        className="absolute right-0 top-0 bg-[#0000006b] rounded-full"
                                        type="button"
                                      >
                                        <MdClear className="text-white p-1 text-2xl" />
                                      </Button>
                                    )}
                                  </Container>
                                ))
                              : null}
                          </Container>
                        </Container>
                      </Container>
                    ))}
                    <hr className="border-slate-500 border-solid border-2 my-4 rounded-full" />
                  </Container>
                ))}
                <Container className="flex justify-between items-center">
                  <Button
                    onClick={handleDiscardProduct}
                    className="bg-red-100 text-red-500 border-none outline-none p-3 rounded-xl mb-6 font-semibold disabled:cursor-not-allowed disabled:bg-slate-500"
                  >
                    Discard Product
                  </Button>
                  {isLoading ? (
                    <Button
                      type="button"
                      className="bg-slate-700 text-white cursor-not-allowed px-4 py-2 rounded-lg font-semibold text-lg"
                    >
                      Loading...
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      className="bg-slate-800 text-white font-semibold text-lg px-4 py-2 rounded-lg active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out"
                    >
                      Save Product
                    </Button>
                  )}
                </Container>
              </Form>
            </Container>
          )}
        </Container>
        {/* </Form> */}
      </Container>

      <UpdateColor
        open={colorModal.edit}
        onClose={() => setColorModal({ ...colorModal, edit: false })}
        colorUpdateData={colorUpdateData}
        setColorUpdateData={setColorUpdateData}
        isLoading={isLoading}
        handleColorSubmit={handleColorSubmit}
      />
    </Container>
  );
}

export default AddProductView;
