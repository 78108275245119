import React from "react";
import { Container } from "reactstrap";

function Login(props) {
  return (
    <Container
      className={`flex justify-center items-center h-screen w-screen bg-[#fcecc9]`}
    >
      {props.children}
    </Container>
  );
}

export default Login;
