import { Box, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Button, Container, Form } from "reactstrap";
import ReactSelect from "react-select";
import {
  getServices,
  postServices,
} from "../../../../../apiServices/apiServices";
import toast from "react-hot-toast";

function SubCategoryAddForm({ onClose }) {
  const [statusSelectedOption, setStatusSelectedOption] = useState();
  const [isCategoryList, setIsCategoryList] = useState([]);
  const [data, setData] = useState({ category_id: "", subcat_name: "" });
  const access_token = sessionStorage.getItem("access-token");

  useEffect(() => {
    fetchCategoryList();
    setData({ ...data, category_id: statusSelectedOption?.value });
  }, []);

  const fetchCategoryList = async () => {
    try {
      const res = await getServices(
        `/api/category/list_category`,
        access_token
      );
      setIsCategoryList(res.responseData);
    } catch (error) {
      toast.error(error);
    }
  };

  const colorStyles = {
    control: (styles) => ({
      ...styles,
      //   backgroundColor: "#c5d3e380",
      border: "1px solid #352210",
      marginBottom: "0.75rem",
      marginTop: "0.25rem",
      outline: "none",
      padding: "0.5rem",
      borderRadius: "10px",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #352210",
      },
    }),
    menu: (styles) => ({
      ...styles,
      maxHeight: "130px",
      overflow: "hidden",
    }),
    menuList: (styles) => ({
      ...styles,
      overflow: "auto",
      maxHeight: "130px",
    }),
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await postServices(
        `/api/subcat/add_subcat`,
        access_token,
        data
      );
      if (res.responseCode === 200) {
        toast.success("Sub-Category added successfully...");
        onClose();
        // handleViewSubCategory();
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Box
          sx={{
            maxWidth: "100%",
          }}
          className="mx-8"
        >
          <ReactSelect
            options={isCategoryList.map((category) => {
              return {
                value: category.category_id,
                label: category.category_name,
              };
            })}
            onChange={(e) => {
              // setStatusSelectedOption(e);
              setData({ ...data, category_id: e.value });
            }}
            placeholder="Select the category"
            styles={colorStyles}
          />
          <TextField
            fullWidth
            name="sub_category_name"
            label="Sub-Category Name"
            id="fullWidth"
            sx={{
              "& .MuiOutlinedInput-root": {
                fontFamily: "Poppins",
                borderRadius: "12px",
              },
              "& .MuiInputLabel-root": {
                zIndex: "0",
                fontFamily: "Poppins",
              },
              "& .MuiInputLabel-root": {
                zIndex: "0",
                fontFamily: "Poppins",
              },
              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#352210",
                },
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#352210",
                },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #352210",
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "#352210",
              },
              marginBottom: "20px",
            }}
            onChange={(e) => setData({ ...data, subcat_name: e.target.value })}
            // value={data.subcat_name}
            required
          />
          <Container className="flex justify-end items-center mb-5">
            <Button
              type="submit"
              disabled={data.category_id?.length === 0 ? true : false}
              className="px-8 py-2 bg-slate-800 text-white font-semibold rounded-lg active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out disabled:cursor-not-allowed disabled:bg-slate-500"
            >
              Save
            </Button>
          </Container>
        </Box>
      </Form>
    </Container>
  );
}

export default SubCategoryAddForm;
