import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Container } from "reactstrap";
import NoInternetComp from "../../InternetConnetction/NoInternetComp";
import { IoChevronBackOutline } from "react-icons/io5";
import toast from "react-hot-toast";
import { getServices } from "../../../../apiServices/apiServices";
import profileAvatar from "../../../assets/avatar_25.jpg";
import LoadingSpinner from "../common/LoadingSpinner";

function UserProfileDetailsView() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const access_token = sessionStorage.getItem("access-token");
  const location = useLocation().state;
  const navigate = useNavigate();

  useEffect(() => {
    if (navigator.onLine) {
      fetchUserProfileDetailsData();
    }
  }, [navigator]);

  const fetchUserProfileDetailsData = async () => {
    setLoading(true);
    try {
      const res = await getServices(
        `/api/user/overview?user_id=${location.user_id}`,
        access_token
      );
      setLoading(false);
      if (res.responseCode === 200) {
        setData(res.responseData);
      } else {
        setData({});
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  return (
    <>
      {navigator.onLine ? (
        <>
          {loading ? (
            <Container className="flex items-center justify-center mt-52">
              <LoadingSpinner />
            </Container>
          ) : (
            <Container>
              <Container className="flex items-center gap-2 px-5">
                <IoChevronBackOutline
                  className="text-2xl cursor-pointer"
                  onClick={() => navigate(-1)}
                />
                <p className="py-4 sm:py-8 text-2xl font-semibold">
                  Profile Details
                </p>
              </Container>
              <Container className="grid sm:grid-cols-2 md:grid-cols-3 gap-3 md:items-center mx-4 sm:mx-8 mt-5 sm:mt-0">
                <Container
                  className="bg-white rounded-2xl flex items-start p-6 gap-3 flex-col"
                  style={{
                    transition:
                      "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    boxShadow:
                      "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                  }}
                >
                  <Container className="flex flex-col items-center justify-center w-full">
                    <img
                      src={profileAvatar}
                      alt="Navarang Smaranika"
                      className="rounded-full w-20"
                    />
                  </Container>
                  {data.userData && (
                    <p>
                      Name:{" "}
                      <span className="font-semibold">
                        {data.userData[0]?.name}
                      </span>
                    </p>
                  )}
                  {data.userData && (
                    <p>
                      Email:{" "}
                      <span className="font-semibold">
                        {data.userData[0]?.email}
                      </span>
                    </p>
                  )}
                  {data.userData && (
                    <p>
                      Phone No.:{" "}
                      <span className="font-semibold">
                        {data.userData[0]?.phone}
                      </span>
                    </p>
                  )}
                </Container>
                <Container className="md:col-span-2">
                  <Container className="grid grid-cols-2 md:grid-cols-3 gap-3">
                    <Container
                      className="bg-white rounded-2xl flex items-start p-6 gap-3 flex-col"
                      style={{
                        transition:
                          "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                        boxShadow:
                          "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                      }}
                    >
                      <p className="text-xs">Total Orders Amount</p>
                      <p className="text-xl font-semibold">
                        ₹{data.ordersTotalAmount?.toLocaleString("en-in")}
                      </p>
                    </Container>
                    <Container
                      className="bg-white rounded-2xl flex items-start p-6 gap-3 flex-col"
                      style={{
                        transition:
                          "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                        boxShadow:
                          "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                      }}
                    >
                      <p className="text-xs">Total Shipping Fee</p>
                      <p className="text-xl font-semibold">
                        ₹{data.ordersToatalShippingFee?.toLocaleString("en-in")}
                      </p>
                    </Container>
                    <Container
                      className="bg-white rounded-2xl flex items-start p-6 gap-3 flex-col"
                      style={{
                        transition:
                          "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                        boxShadow:
                          "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                      }}
                    >
                      <p className="text-xs">Total Discount Amount</p>
                      <p className="text-xl font-semibold">
                        ₹{data.ordersTotalDiscountAmt?.toLocaleString("en-in")}
                      </p>
                    </Container>
                    <Container
                      className="bg-white rounded-2xl flex items-start p-6 gap-3 flex-col"
                      style={{
                        transition:
                          "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                        boxShadow:
                          "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                      }}
                    >
                      <p className="text-xs">Total Orders MRP</p>
                      <p className="text-xl font-semibold">
                        ₹{data.ordersTotalMRP?.toLocaleString("en-in")}
                      </p>
                    </Container>
                    <Container
                      className="bg-white rounded-2xl flex items-start p-6 gap-3 flex-col"
                      style={{
                        transition:
                          "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                        boxShadow:
                          "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                      }}
                    >
                      <p className="text-xs">Total Address Count</p>
                      <p className="text-xl font-semibold">
                        {data.totalAddressCount}
                      </p>
                    </Container>
                    <Container
                      className="bg-white rounded-2xl flex items-start p-6 gap-3 flex-col"
                      style={{
                        transition:
                          "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                        boxShadow:
                          "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                      }}
                    >
                      <p className="text-xs">Total Orders Count</p>
                      <p className="text-xl font-semibold">
                        {data.totalOrdersCount}
                      </p>
                    </Container>
                  </Container>
                </Container>
              </Container>

              {/* manage address */}
              <Container className="mx-4 sm:mx-8 mt-4">
                <p className="font-semibold text-2xl mb-2">Address Details</p>
                {data.userAddress?.length > 0 ? (
                  <Container className="mb-4 px-2 sm:px-0 grid sm:grid-cols-2 gap-3">
                    {data.userAddress?.map((item, i) => (
                      <Container
                        className="flex justify-between flex-col gap-2 bg-white p-2 sm:p-4 rounded-2xl"
                        style={{
                          transition:
                            "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                          boxShadow:
                            "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                        }}
                        key={i}
                      >
                        <Container className="flex justify-between items-center">
                          <p className="text-base sm:text-lg font-semibold capitalize">
                            {item.name}
                          </p>
                          <p className="text-sm px-3 py-1 border-2 border-[#352210] rounded-full uppercase">
                            {item.save_address}
                          </p>
                        </Container>
                        <Container className="capitalize text-slate-500 font-medium text-base sm:text-sm">
                          <p>{item.area}</p>
                          <p>{item.town}</p>
                          <p>
                            {item.city}, {item.state} - {item.pincode}
                          </p>
                          <Container className="flex justify-between items-center">
                            <p>
                              Mobile:{" "}
                              <span className="text-black">
                                +91 {item.phone_no}
                              </span>
                            </p>
                          </Container>
                        </Container>
                      </Container>
                    ))}
                  </Container>
                ) : (
                  <Container className="flex justify-center items-center h-[15vh] flex-col">
                    <p className="font-semibold">No Address Found...</p>
                  </Container>
                )}
              </Container>

              {/* manage orders */}
              <Container className="mx-4 sm:mx-8 mt-4">
                <p className="font-semibold text-2xl mb-2">Order Details</p>
                {data.userOrders?.length > 0 ? (
                  <Container className="mb-4 px-2 sm:px-0 grid sm:grid-cols-2 gap-3">
                    {data.userOrders?.map((item, i) => (
                      <Container
                        className="flex justify-between flex-col gap-2 bg-white p-2 sm:p-4 rounded-2xl"
                        style={{
                          transition:
                            "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                          boxShadow:
                            "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                        }}
                        key={i}
                      >
                        {item.orderStatus && (
                          <Container className="flex items-center justify-end">
                            <p
                              className={`text-xs px-3 py-1 rounded ${
                                item.orderStatus === 2
                                  ? "bg-[#fda693d6] text-black"
                                  : item.orderStatus === 8
                                  ? "bg-[#6149cd] text-white"
                                  : ""
                              }`}
                            >
                              {item.orderStatus === 2
                                ? "Processing"
                                : item.orderStatus === 8
                                ? "Initiated to ShipRocket"
                                : ""}
                            </p>
                          </Container>
                        )}
                        <Container className="flex justify-between items-center">
                          <Link
                            to={"/admin/order/details"}
                            state={{ id: item.track_id }}
                            className="text-base sm:text-lg font-semibold"
                          >
                            #{item.track_id}
                          </Link>
                          <p className="text-sm text-right">
                            {item.orderd_date + " " + item.orderd_time}
                          </p>
                        </Container>
                        <p>
                          Total Weight:{" "}
                          <span className="font-semibold">
                            {item.total_weight}kg
                          </span>
                        </p>
                        <p>
                          Total MRP:{" "}
                          <span className="font-semibold">
                            ₹{item.totalMrp?.toLocaleString("en-in")}
                          </span>
                        </p>
                        <p>
                          Shipping Fee:{" "}
                          <span className="font-semibold">
                            ₹{item.shippingFee?.toLocaleString("en-in")}
                          </span>
                        </p>
                        <p>
                          Discount Amount:{" "}
                          <span className="font-semibold">
                            ₹{item.discountAmt?.toLocaleString("en-in")}
                          </span>
                        </p>
                        <p>
                          Total Amount:{" "}
                          <span className="font-semibold">
                            ₹{item.totalAmount?.toLocaleString("en-in")}
                          </span>
                        </p>
                        <p>
                          Payment method:{" "}
                          <span className="font-semibold">
                            {item.payment_method === 2 ? "Online" : "COD"}
                          </span>
                        </p>
                      </Container>
                    ))}
                  </Container>
                ) : (
                  <Container className="flex justify-center items-center h-[15vh] flex-col">
                    <p className="font-semibold">No Orders Found...</p>
                  </Container>
                )}
              </Container>
            </Container>
          )}
        </>
      ) : (
        <Container className="min-h-[65vh] flex flex-col justify-center items-center">
          <NoInternetComp />
        </Container>
      )}
    </>
  );
}

export default UserProfileDetailsView;
