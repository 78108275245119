import React from "react";
import { Button, Container } from "reactstrap";
import { useEffect } from "react";
import { useState } from "react";
import LoadingSpinner from "../../components/common/Admin/common/LoadingSpinner";
import { Link } from "react-router-dom";
import { getServices } from "../../apiServices/apiServices";
import { nNumber } from "../../variables/constants";
import toast from "react-hot-toast";
import NoInternetComp from "../../components/common/InternetConnetction/NoInternetComp";

function NotificationPage() {
  const access_token = localStorage.getItem("user-access-token");
  const [notificationData, setNotificationData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (access_token && navigator.onLine) {
      fetchNotificationData();
    }

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [navigator]);

  const fetchNotificationData = async () => {
    setIsLoading(true);
    try {
      const res = await getServices(
        `/api/notifications/list_notification?limit=${nNumber}`,
        access_token
      );
      setIsLoading(false);
      if (res.responseCode === 200) {
        setNotificationData(res.responseData);
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <>
      <Container className="pt-2">
        {navigator.onLine ? (
          <Container>
            {access_token ? (
              <Container>
                {isLoading ? (
                  <Container className="flex justify-center items-center min-h-[65vh]">
                    <LoadingSpinner />
                  </Container>
                ) : (
                  <Container className="min-h-[65vh]">
                    {notificationData.length <= 0 ? (
                      <Container className="flex justify-center items-center h-[65vh]">
                        <p className="font-semibold text-lg">
                          No notification found...
                        </p>
                      </Container>
                    ) : (
                      <Container className="flex justify-center flex-col gap-3 items-center px-2 sm:px-0 mb-4">
                        {notificationData.map((item, i) => (
                          <Link
                            to="/manage_orders/order_details"
                            state={{ track_id: item.track_id }}
                            className="bg-[#FFFAEF] w-full sm:w-3/4 px-4 py-2 flex gap-2 items-start relative rounded-md"
                            key={i}
                          >
                            <Container>
                              <p className="text-sm sm:text-base font-semibold">
                                {item.title}
                              </p>
                              <p className="text-sm sm:text-base">
                                {item.message}
                              </p>
                            </Container>
                            <p className="absolute top-1 right-2 text-xs">
                              {item.time}
                            </p>
                          </Link>
                        ))}
                      </Container>
                    )}
                  </Container>
                )}
              </Container>
            ) : (
              <Container className="flex justify-center items-center h-[65vh] flex-col">
                <p className="font-semibold mb-4">Please login to continue.</p>
                <Link to="/login">
                  <Button className="bg-[#352210] font-semibold text-white py-2 px-10 rounded active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out">
                    Login
                  </Button>
                </Link>
              </Container>
            )}
          </Container>
        ) : (
          <Container className="min-h-[65vh] flex flex-col justify-center items-center">
            <NoInternetComp />
          </Container>
        )}
      </Container>
    </>
  );
}

export default NotificationPage;
