import { Carousel } from "@mantine/carousel";
import React, { useState } from "react";
import { IoMdHeart } from "react-icons/io";
import { Button, Container } from "reactstrap";
import config from "../../../config.json";
import { Link, useNavigate } from "react-router-dom";
import VariationCustomBottomSheet from "./common/VariationCustomBottomSheet";
import { Box, Modal } from "@mui/material";

function UserProductSliderCard(props) {
  const url = config.url;
  const [isBottomSheet, setIsBottomSheet] = useState(false);
  const [data, setData] = useState();
  const access_token = localStorage.getItem("user-access-token");
  const navigate = useNavigate();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    padding: "20px 14px",
    outline: "none",
    borderRadius: "10px",
  };

  const handleAddWishiList = async (data) => {
    setIsBottomSheet(true);
    setData(data);
  };

  const handleLoginPage = () => {
    navigate("/login");
  };

  return (
    <div>
      <Carousel
        slideSize="25%"
        // height={200}
        // slideGap={"md"}
        dragFree
        withControls={false}
        align="start"
        loop={true}
        breakpoints={[
          { maxWidth: "md", slideSize: "33.33%" },
          { maxWidth: "sm", slideSize: "50%" },
        ]}
      >
        {props.data.map((item, i) => (
          <Carousel.Slide
            key={i}
            className="mx-2 bg-[#FFFAEF] flex flex-col justify-center rounded relative"
          >
            <Link to="/product_details" state={{ product_id: item.product_id }}>
              <img
                src={url + item.productColorInfo[0].productDataInfo[0].image[0]}
                className="w-full p-4 object-contain"
                style={{ aspectRatio: "3/2" }}
              />
              <Container className="flex items-center justify-between px-4 pb-4">
                <Container>
                  <p className="font-semibold text-sm capitalize">
                    {item.product_name.length >= 13
                      ? item.product_name.substring(0, 12) + "..."
                      : item.product_name}
                  </p>
                  <Container className="flex gap-4">
                    <p className="text-[#757575] text-sm">
                      {item.productColorInfo[0].productDataInfo[0].variation}{" "}
                      inch
                    </p>
                    <p className="font-semibold text-sm">
                      ₹{" "}
                      {item.productColorInfo[0].productDataInfo[0]
                        .sales_price <= 0
                        ? item.productColorInfo[0].productDataInfo[0].price
                        : item.productColorInfo[0].productDataInfo[0]
                            .sales_price}
                    </p>
                  </Container>
                </Container>
                {item.productColorInfo[0].productDataInfo[0].offer_value >
                  0 && (
                  <p className="absolute top-5 left-5 text-white font-semibold text-xs sm:text-sm px-2 py-1 bg-green-600 rounded-lg shadow-2xl">
                    {item.productColorInfo[0].productDataInfo[0].offer_value > 0
                      ? item.productColorInfo[0].productDataInfo[0]
                          .offer_value + "% off"
                      : 0}
                  </p>
                )}
              </Container>
            </Link>
            <Container
              className="cursor-pointer absolute bottom-6 right-3 sm:right-5"
              onClick={() => handleAddWishiList(item)}
            >
              <IoMdHeart className="text-white p-2 text-4xl bg-[#352210] rounded-full" />
            </Container>
          </Carousel.Slide>
        ))}
      </Carousel>

      {access_token ? (
        <VariationCustomBottomSheet
          isBottomNav={isBottomSheet}
          setIsBottomNav={() => setIsBottomSheet(false)}
          data={data}
        />
      ) : (
        <Modal
          open={isBottomSheet}
          onClose={() => setIsBottomSheet(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <p id="modal-modal-description">Please login to continue.</p>
            <Container className="flex justify-end">
              <Button
                onClick={handleLoginPage}
                className="bg-[#352210] px-6 py-1 rounded-lg text-white font-semibold active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out"
              >
                Login
              </Button>
            </Container>
          </Box>
        </Modal>
      )}
    </div>
  );
}

export default UserProductSliderCard;
