import React, { useEffect, useState } from "react";
import CustomBottomSheet from "./CustomBottomSheet";
import { Button, Container } from "reactstrap";
import {
  Box,
  FormControl,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
} from "@mui/material";
import config from "../../../../config.json";
import { useNavigate } from "react-router-dom";
import { postServices } from "../../../../apiServices/apiServices";
import toast from "react-hot-toast";

function VariationCustomBottomSheet(props) {
  const url = config.url;
  const access_token = localStorage.getItem("user-access-token");
  const navigate = useNavigate();
  const [colorId, setColorId] = useState();
  const [variationData, setVariationData] = useState();
  const [sizeSelection, setSizeSelection] = useState();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    padding: "20px 14px",
    outline: "none",
    borderRadius: "10px",
  };

  useEffect(() => {
    setColorId(props.data?.productColorInfo[0].product_color_id);
    setVariationData(props.data?.productColorInfo[0]);
    setSizeSelection(props.data?.productColorInfo[0].productDataInfo[0]);
  }, [props.data]);

  const handleAddToCart = async () => {
    try {
      const res = await postServices(
        `/api/wishlist/add_wishlist`,
        access_token,
        {
          product_id: props.data?.product_id,
          color_id: colorId,
          variation_id: sizeSelection.product_data_id,
        }
      );
      props.setIsBottomNav(false);
      if (res.responseCode === 200) {
        toast.success("Product added to wishlist successfully...");
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleLoginPage = () => {
    navigate("/login");
  };

  const handleVariationClick = (data) => {
    setVariationData(data);
    setSizeSelection(data.productDataInfo[0]);
    setColorId(data.product_color_id);
  };

  const handleSizeClick = (e, size) => {
    setSizeSelection(size);
  };

  return (
    <>
      {access_token ? (
        <CustomBottomSheet
          isBottomNav={props.isBottomNav}
          onClose={() => props.setIsBottomNav(false)}
        >
          <Container className="mx-5 sm:mx-10 my-3 sm:my-5">
            <Container className="flex items-center gap-5">
              <Container className="capitalize">
                <p className="text-slate-600 font-medium text-sm sm:text-base">
                  {props.data?.categoryInfo?.category_name}
                </p>
                <p className="text-base sm:text-lg text-black font-semibold">
                  {props.data?.product_name}
                </p>
                <Container className="flex items-end mb-3">
                  {props.data && (
                    <h1
                      className="text-lg font-semibold"
                      style={{
                        marginRight:
                          sizeSelection?.sales_price == 0 ? "0" : "1rem",
                      }}
                    >
                      {sizeSelection?.sales_price == 0
                        ? null
                        : "₹ " + sizeSelection?.sales_price}
                    </h1>
                  )}
                  {props.data && (
                    <h1
                      className="text-md font-semibold"
                      style={{
                        color:
                          sizeSelection?.sales_price == 0
                            ? "#353535"
                            : "#64748B",
                        lineHeight: sizeSelection?.sales_price
                          ? "1.75rem"
                          : "2.25rem",
                      }}
                    >
                      {sizeSelection?.sales_price == 0 ? (
                        "₹ " + sizeSelection?.price
                      ) : (
                        <del>{"₹ " + sizeSelection?.price}</del>
                      )}
                    </h1>
                  )}
                  {props.data && (
                    <p className="ml-3 text-md font-semibold text-green-600">
                      {sizeSelection?.offer_value > 0 &&
                        sizeSelection?.offer_value + "% OFF"}
                    </p>
                  )}
                </Container>
              </Container>
            </Container>
            <Container>
              <p className="my-2 sm:my-2 text-base font-semibold">
                Select Size
              </p>
              <Container className="flex gap-2 flex-wrap items-center text-center">
                {props.data?.productColorInfo?.map((item, i) => (
                  <Container
                    key={i}
                    onClick={
                      colorId === item.product_color_id
                        ? null
                        : () => handleVariationClick(item)
                    }
                    className={`text-center cursor-pointer capitalize rounded-md ${
                      colorId === item.product_color_id
                        ? "border-2 border-slate-500 opacity-100"
                        : "opacity-50"
                    }`}
                  >
                    <img
                      src={url + item.productDataInfo[0]?.image[0]}
                      alt={item.productDataInfo[0].variation}
                      className="w-16 rounded-md h-10 object-cover"
                    />
                    <p className="text-xs">{item.colors}</p>
                  </Container>
                ))}
              </Container>
              <FormControl>
                {variationData?.productDataInfo?.map((varia, i) => (
                  <RadioGroup
                    key={i}
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={sizeSelection.product_data_id}
                    onChange={(e) => handleSizeClick(e, varia)}
                  >
                    <FormControlLabel
                      value={varia.product_data_id}
                      control={
                        <Radio
                          sx={{
                            "&, &.Mui-checked": {
                              color: "#352210",
                            },
                          }}
                        />
                      }
                      sx={{
                        fontFamily: "Poppins",
                        ".css-pi3i9q-MuiFormControlLabel-root .MuiFormControlLabel-label":
                          { fontFamily: "Poppins" },
                      }}
                      label={
                        <span
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                          }}
                        >
                          {varia.variation} inch
                        </span>
                      }
                    />
                  </RadioGroup>
                ))}
              </FormControl>
              <Button
                type="button"
                onClick={handleAddToCart}
                className="bg-[#352210] w-full text-white font-semibold outline-none py-3 mt-2 rounded-lg active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out"
              >
                Add To Wishlist
              </Button>
            </Container>
          </Container>
        </CustomBottomSheet>
      ) : (
        <Modal
          open={props.isBottomNav}
          onClose={() => props.setIsBottomNav(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <p id="modal-modal-description">Please login to continue.</p>
            <Container className="flex justify-end">
              <Button
                onClick={handleLoginPage}
                className="bg-[#352210] px-6 py-1 rounded-lg text-white font-semibold active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out"
              >
                Login
              </Button>
            </Container>
          </Box>
        </Modal>
      )}
    </>
  );
}

export default VariationCustomBottomSheet;
