import { Box, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Container, Form } from "reactstrap";
import TextEdior from "../common/TextEdior";
import ReactSelect from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getServices,
  removeServices,
  updateServices,
} from "../../../../apiServices/apiServices";
import CustomInputField from "../common/CustomInputField";
import { MdDeleteForever } from "react-icons/md";
import { AiOutlineCloseCircle, AiOutlineEdit } from "react-icons/ai";
import UpdateColor from "../Modal/Colors/UpdateColor";
import toast from "react-hot-toast";
import config from "../../../../config.json";
import VariationSizes from "../Modal/Variations/VariationSizes";
import UpdateImages from "../Modal/Variations/UpdateImages";

const options = [
  { value: "1", label: "In Stock" },
  { value: "0", label: "Out of Stock" },
];

function EditProductView() {
  const [isShow, setIsShow] = useState({
    basic: true,
    advanced: false,
  });
  const location = useLocation().state;
  const [isCategoryList, setIsCategoryList] = useState([]);
  const [isSubcategoryList, setIsSubcategoryList] = useState([]);
  const [data, setData] = useState({
    product_name: location.data.product_name,
    description: location.data.description,
    product_feature: location.data.product_feature,
    // product_code: location.data.product_code,
    // product_sku: location.data.product_sku,
    // quantity: location.data.quantity,
    cat_id: location.data.categoryInfo.category_id,
    subcat_id: location.data.subcatInfo.subcat_id,
    // is_stock: location.data.is_stock?.toString(),
  });
  const [selectedOption, setSelectedOption] = useState({
    category: [],
    subcategory: [],
    stockStatus: [],
  });
  const navigate = useNavigate();
  const access_token = sessionStorage.getItem("access-token");
  const [colorsData, setColorsData] = useState([]);
  const [colorModal, setColorModal] = useState({ edit: false, delete: false });
  const [colorUpdateData, setColorUpdateData] = useState({
    color_id: "",
    color_name: "",
  });
  const [modal, setModal] = useState({ add_size: false, update_image: false });
  const [sizeColors, setSizeColors] = useState([]);
  const [isHsnData, setIsHsnData] = useState([]);
  const product_id = localStorage.getItem("product-id");
  const url = config.url;

  const colorStyles = {
    control: (styles) => ({
      ...styles,
      border: "1px solid #352210",
      marginBottom: "0rem",
      marginTop: "0rem",
      outline: "none",
      padding: "0.5rem",
      borderRadius: "10px",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #352210",
      },
    }),
  };

  useEffect(() => {
    if (navigator.onLine) {
      fetchCategoryList();
      fetchHsnCodeList();
      fetchColorData();
      fetchParticularProductDetails();
    }
    localStorage.setItem("product-id", location.data.product_id);
  }, [location.data.productColorInfo, product_id]);

  const fetchParticularProductDetails = async () => {
    try {
      const res = await getServices(
        `/api/products/details/${product_id}`,
        access_token
      );
      setSizeColors(res.responseData.productColorInfo);
    } catch (error) {
      toast.error(error);
    }
  };

  const fetchColorData = async () => {
    try {
      const res2 = await getServices(
        `/api/products/list_colors/${location.data.product_id}`,
        access_token
      );
      if (res2.responseCode === 200) {
        setColorsData(res2.responseData);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const fetchCategoryList = async () => {
    try {
      const res = await getServices(`/api/category/list_category`);
      setIsCategoryList(res.responseData);
    } catch (error) {
      toast.error(error);
    }
  };

  const fetchHsnCodeList = async () => {
    try {
      const res = await getServices(`/api/manage/hsn/list`, access_token);
      if (res.responseCode === 200) {
        setIsHsnData(res.responseData);
      } else {
        setIsHsnData([]);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleCategoryChange = async (e) => {
    setSelectedOption({ ...selectedOption, category: e, subcategory: "" });
    setData({ ...data, cat_id: e.value, subcat_id: "" });

    try {
      const res = await getServices(`/api/subcat/list_subcat/${e.value}`);
      setIsSubcategoryList(res?.responseData[0].sub_categories || []);
    } catch (error) {
      toast.error(error);
    }
  };

  const handleNextModule = () => {
    setIsShow({ basic: false, advanced: true });
  };
  const handleBackModule = () => {
    setIsShow({ basic: true, advanced: false });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await updateServices(
        `/api/products/update_products/${location.data.product_id}`,
        access_token,
        data
      );
      if (res.responseCode === 200) {
        toast.success("Product updated successfully...");
        navigate("/admin/products");
        localStorage.removeItem("product-id");
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleUpdateColors = (color) => {
    setColorModal({ ...colorModal, edit: true });
    setColorUpdateData({
      ...colorUpdateData,
      color_id: color.product_color_id,
      color_name: color.colors,
    });
  };

  const handleColorSubmit = async (e) => {
    e.preventDefault();
    const value = { colors: colorUpdateData.color_name };

    try {
      const res = await updateServices(
        `/api/products/update_color/${colorUpdateData.color_id}`,
        access_token,
        value
      );
      if (res.responseCode === 200) {
        fetchColorData();
        setColorModal({ ...colorStyles, edit: false });
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleDeleteColor = async (data) => {
    if (window.confirm(`Do you want delete this color?`)) {
      try {
        const res = await removeServices(
          `/api/products/delete_color/${data.product_color_id}`,
          access_token
        );
        if (res.responseCode === 200) {
          fetchColorData();
        } else {
          toast.error(res.responseMessage);
        }
      } catch (error) {
        toast.error(error);
      }
    }
  };

  const handleUpdateHSNChange = (colorIndex, sizeIndex, newHSN) => {
    setSizeColors((prevColors) => {
      const updatedColors = [...prevColors];
      updatedColors[colorIndex].productDataInfo[sizeIndex] = {
        ...updatedColors[colorIndex].productDataInfo[sizeIndex],
        product_hsn: newHSN,
      };
      return updatedColors;
    });
  };
  const handleSKUChange = (colorIndex, sizeIndex, newSKU) => {
    setSizeColors((prevColors) => {
      const updatedColors = [...prevColors];
      updatedColors[colorIndex].productDataInfo[sizeIndex] = {
        ...updatedColors[colorIndex].productDataInfo[sizeIndex],
        product_sku: newSKU,
      };
      return updatedColors;
    });
  };
  const handleQuantityChange = (colorIndex, sizeIndex, newQuantity) => {
    setSizeColors((prevColors) => {
      const updatedColors = [...prevColors];
      updatedColors[colorIndex].productDataInfo[sizeIndex] = {
        ...updatedColors[colorIndex].productDataInfo[sizeIndex],
        quantity: newQuantity,
      };
      return updatedColors;
    });
  };
  const handleUpdateSizeChange = (colorIndex, sizeIndex, newSize) => {
    setSizeColors((prevColors) => {
      const updatedColors = [...prevColors];
      updatedColors[colorIndex].productDataInfo[sizeIndex] = {
        ...updatedColors[colorIndex].productDataInfo[sizeIndex],
        variation: newSize,
      };
      return updatedColors;
    });
  };
  const handlePriceChange = (colorIndex, sizeIndex, newPrice) => {
    setSizeColors((prevColors) => {
      const updatedColors = [...prevColors];
      updatedColors[colorIndex].productDataInfo[sizeIndex] = {
        ...updatedColors[colorIndex].productDataInfo[sizeIndex],
        price: newPrice || 0,
      };
      return updatedColors;
    });
  };
  const handleSalesPriceChange = (colorIndex, sizeIndex, newPrice) => {
    setSizeColors((prevColors) => {
      const updatedColors = [...prevColors];
      updatedColors[colorIndex].productDataInfo[sizeIndex] = {
        ...updatedColors[colorIndex].productDataInfo[sizeIndex],
        sales_price: newPrice || 0,
      };
      return updatedColors;
    });
  };
  const handleWeightChange = (colorIndex, sizeIndex, newPrice) => {
    setSizeColors((prevColors) => {
      const updatedColors = [...prevColors];
      updatedColors[colorIndex].productDataInfo[sizeIndex] = {
        ...updatedColors[colorIndex].productDataInfo[sizeIndex],
        weight: newPrice || 0,
      };
      return updatedColors;
    });
  };
  const handleLengthtChange = (colorIndex, sizeIndex, newPrice) => {
    setSizeColors((prevColors) => {
      const updatedColors = [...prevColors];
      updatedColors[colorIndex].productDataInfo[sizeIndex] = {
        ...updatedColors[colorIndex].productDataInfo[sizeIndex],
        length: newPrice || 0,
      };
      return updatedColors;
    });
  };
  const handleBreadthChange = (colorIndex, sizeIndex, newPrice) => {
    setSizeColors((prevColors) => {
      const updatedColors = [...prevColors];
      updatedColors[colorIndex].productDataInfo[sizeIndex] = {
        ...updatedColors[colorIndex].productDataInfo[sizeIndex],
        breadth: newPrice || 0,
      };
      return updatedColors;
    });
  };

  const handleUpdateSizes = async () => {
    const requests = [];

    for (let i = 0; i < sizeColors.length; i++) {
      for (let j = 0; j < sizeColors[i].productDataInfo.length; j++) {
        if (sizeColors[i].productDataInfo[j].product_hsn === null) {
          alert("Please select HSN code.");
        } else if (sizeColors[i].productDataInfo[j].product_sku === null) {
          alert("Please enter the Product SKU.");
        } else if (sizeColors[i].productDataInfo[j].quantity === null) {
          alert(
            "Please enter a quantity greater than zero in the field provided."
          );
        } else {
          const urlencoded = new URLSearchParams();

          urlencoded.append(
            "variation",
            parseFloat(sizeColors[i].productDataInfo[j].variation)
          );
          urlencoded.append(
            "price",
            parseFloat(sizeColors[i].productDataInfo[j].price)
          );
          urlencoded.append(
            "sales_price",
            parseFloat(sizeColors[i].productDataInfo[j].sales_price)
          );
          urlencoded.append(
            "breadth",
            parseFloat(sizeColors[i].productDataInfo[j].breadth)
          );
          urlencoded.append(
            "length",
            parseFloat(sizeColors[i].productDataInfo[j].length)
          );
          urlencoded.append(
            "weight",
            parseFloat(sizeColors[i].productDataInfo[j].weight)
          );
          // urlencoded.append(
          //   "product_sku",
          //   sizeColors[i].productDataInfo[j].product_sku?.toString()
          // );
          // urlencoded.append(
          //   "product_hsn",
          //   sizeColors[i].productDataInfo[j].product_hsn?.label ??
          //     sizeColors[i].productDataInfo[j].product_hsn
          // );
          // urlencoded.append(
          //   "quantity",
          //   Number(sizeColors[i].productDataInfo[j].quantity)
          // );

          requests.push(
            updateServices(
              `/api/products/update_variation/${sizeColors[i].productDataInfo[j].product_data_id}`,
              access_token,
              urlencoded
            )
          );

          try {
            const responses = await Promise.all(requests);
            for (let suc = 0; suc < responses.length; suc++) {
              if (responses[suc].responseCode === 200) {
                toast.success("Product updated...");
                navigate("/admin/products");
                localStorage.removeItem("product-id");
              } else {
                toast.error(responses[suc].responseMessage);
              }
            }
          } catch (error) {
            toast.error(error);
          }
        }
      }
    }
  };

  const handleRemoveVariation = async (colorIndex, sizeIndex) => {
    if (window.confirm("Do you want delete this variation?")) {
      try {
        const res = await updateServices(
          `/api/products/remove_variation/${sizeColors[colorIndex].productDataInfo[sizeIndex].product_data_id}`,
          access_token
        );
        if (res.responseCode === 200) {
          fetchParticularProductDetails();
        } else {
          toast.error(res.responseMessage);
        }
      } catch (error) {
        toast.error(error);
      }
    }
  };

  const handleAddMoreSize = (id) => {
    setModal({ ...modal, add_size: true, color_id: id });
  };
  const handleUpdateImage = (data) => {
    setModal({ ...modal, update_image: true, image_data: data });
  };

  return (
    <Container>
      {location.is_update_colors === 0 && (
        <Container>
          <p className="p-8 text-2xl font-semibold">Update product</p>
          <Container className="bg-white shadow mx-4 md:mx-8 rounded-xl mb-8">
            <p className="px-8 py-6 font-bold text-xl">
              {isShow.basic ? "Details" : "Properties"}
            </p>
            <Form onSubmit={handleSubmit}>
              <Container className="mx-4 md:mx-8 w-full">
                {isShow.basic && (
                  <Container>
                    <Box
                      sx={{
                        maxWidth: "100%",
                      }}
                      className="mr-8 md:mr-16"
                    >
                      <CustomInputField
                        name="product_name"
                        label="Product Name"
                        type="text"
                        value={data.product_name}
                        onChange={(e) =>
                          setData({ ...data, product_name: e.target.value })
                        }
                        required={true}
                      />
                      <TextField
                        fullWidth
                        label="Sub Description"
                        id="fullWidth"
                        multiline
                        minRows={3}
                        maxRows={3}
                        sx={{
                          "& .MuiInputLabel-root": {
                            fontFamily: "Poppins",
                          },
                          "& .MuiOutlinedInput-root": {
                            fontFamily: "Poppins",
                            borderRadius: "12px",
                          },
                          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#352210",
                            },
                          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#352210",
                            },
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "1px solid #352210",
                          },
                          "& .MuiInputLabel-root.Mui-focused": {
                            color: "#352210",
                          },
                          marginTop: "30px",
                          marginBottom: "30px",
                        }}
                        value={data.description}
                        onChange={(e) =>
                          setData({ ...data, description: e.target.value })
                        }
                        required={false}
                      />
                      <TextEdior
                        className="mb-8"
                        value={data.product_feature}
                        handleChange={(e) => {
                          setData({ ...data, product_feature: e });
                        }}
                      />
                    </Box>
                    <Container className="flex justify-end mr-8 md:mx-16">
                      <Button
                        type="button"
                        onClick={handleNextModule}
                        className="bg-slate-800 text-white border-none outline-none p-3 rounded-xl mb-6 font-semibold"
                      >
                        Next
                      </Button>
                    </Container>
                  </Container>
                )}

                {isShow.advanced && (
                  <Container>
                    <Box
                      sx={{
                        maxWidth: "100%",
                      }}
                      className="mr-8 md:mr-16"
                    >
                      {/* <Container className="flex flex-col sm:flex-row">
                        <TextField
                          fullWidth
                          name="product_code"
                          label="Product Code"
                          id="fullWidth"
                          sx={{
                            "& .MuiInputLabel-root": {
                              fontFamily: "Poppins",
                            },
                            "& .MuiOutlinedInput-root": {
                              fontFamily: "Poppins",
                              borderRadius: "12px",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#352210",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#352210",
                              },
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "1px solid #352210",
                            },
                            "& .MuiInputLabel-root.Mui-focused": {
                              color: "#352210",
                            },
                            marginRight: "1rem",
                            marginBottom: "15px",
                          }}
                          onChange={(e) => {
                            setData({ ...data, product_code: e.target.value });
                          }}
                          value={data.product_code}
                          required
                        />
                        <TextField
                          fullWidth
                          name="product_sku"
                          label="Product SKU"
                          id="fullWidth"
                          sx={{
                            "& .MuiInputLabel-root": {
                              fontFamily: "Poppins",
                            },
                            "& .MuiOutlinedInput-root": {
                              fontFamily: "Poppins",
                              borderRadius: "12px",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#352210",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#352210",
                              },
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "1px solid #352210",
                            },
                            "& .MuiInputLabel-root.Mui-focused": {
                              color: "#352210",
                            },
                            marginRight: "1rem",
                            marginBottom: "15px",
                          }}
                          onChange={(e) => {
                            setData({ ...data, product_sku: e.target.value });
                          }}
                          value={data.product_sku}
                          required
                        />
                        <TextField
                          fullWidth
                          name="quantity"
                          label="Quantity"
                          type="number"
                          id="fullWidth"
                          onInput={(e) => {
                            e.target.value = Math.max(
                              1,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0);
                          }}
                          sx={{
                            "& .MuiInputLabel-root": {
                              fontFamily: "Poppins",
                            },
                            "& .MuiOutlinedInput-root": {
                              fontFamily: "Poppins",
                              borderRadius: "12px",
                            },
                            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#352210",
                              },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#352210",
                              },
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "1px solid #352210",
                            },
                            "& .MuiInputLabel-root.Mui-focused": {
                              color: "#352210",
                            },
                          }}
                          onChange={(e) => {
                            setData({ ...data, quantity: e.target.value });
                          }}
                          value={data.quantity}
                          required
                        />
                      </Container> */}
                      <Container className="flex flex-col sm:flex-row mt-3 sm:mt-0 sm:mb-0">
                        <ReactSelect
                          options={isCategoryList.map((category) => {
                            return {
                              value: category.category_id,
                              label: category.category_name,
                            };
                          })}
                          onChange={handleCategoryChange}
                          placeholder="Select the Category"
                          styles={colorStyles}
                          className="w-full mr-4"
                          defaultInputValue={
                            location.data.categoryInfo?.category_name
                          }
                          // required
                        />
                        {isSubcategoryList.length > 0 && (
                          <ReactSelect
                            options={isSubcategoryList.map((subcategory) => {
                              return {
                                value: subcategory.subcat_id,
                                label: subcategory.subcat_name,
                              };
                            })}
                            onChange={(e) => {
                              setSelectedOption({
                                ...selectedOption,
                                subcategory: e,
                              });
                              setData({
                                ...data,
                                subcat_id: e.value,
                              });
                            }}
                            placeholder="Select the sub-category"
                            styles={colorStyles}
                            className="w-full"
                            // defaultInputValue={
                            //   location.data.subcatInfo?.subcat_name
                            // }
                            // required
                          />
                        )}
                      </Container>
                      {/* <Container className="flex flex-col sm:flex-row mb-4 mt-3 mr-3 sm:mb-0">
                        <ReactSelect
                          options={options}
                          onChange={(e) => {
                            setSelectedOption({
                              ...selectedOption,
                              stockStatus: e,
                            });
                            setData({ ...data, is_stock: e.value.toString() });
                          }}
                          placeholder="Select the stock status"
                          styles={colorStyles}
                          className="w-full md:w-1/2 sm:mr-4"
                          defaultInputValue={
                            location.data.is_stock === 1
                              ? "In Stock"
                              : "Out of Stock"
                          }
                        />
                      </Container> */}
                    </Box>
                    <Container className="flex justify-between mr-8 mt-3 md:mr-16">
                      <Button
                        type="button"
                        onClick={handleBackModule}
                        className="bg-slate-800 text-white border-none outline-none p-3 rounded-xl mb-6 font-semibold"
                      >
                        Back
                      </Button>
                      <Button
                        type="submit"
                        className="bg-slate-800 text-white border-none outline-none p-3 rounded-xl mb-6 font-semibold"
                      >
                        Update Changes
                      </Button>
                    </Container>
                  </Container>
                )}
              </Container>
            </Form>
          </Container>
        </Container>
      )}
      {location.is_update_colors === 1 && (
        <Container>
          <p className="p-8 text-2xl font-semibold">Update product</p>
          <Container className="bg-white shadow mx-4 md:mx-8 rounded-xl mb-8">
            <p className="px-8 py-6 font-bold text-xl">Update Colors</p>
            <Container className="px-8 pb-4">
              {colorsData.map((colorFields, colorIndex) => (
                <Container key={colorIndex} className="mb-4">
                  <Container className="flex justify-between items-center">
                    <p className="font-semibold mb-1">{colorFields.colors}</p>
                    <Container className="flex items-center gap-3">
                      <AiOutlineEdit
                        className="text-3xl bg-[#7fae9c] text-white p-1 rounded-full cursor-pointer"
                        onClick={() => handleUpdateColors(colorFields)}
                      />
                      {colorsData.length > 1 && (
                        <MdDeleteForever
                          className="text-3xl bg-[#73c1de] text-white p-1 rounded-full cursor-pointer"
                          onClick={() => handleDeleteColor(colorFields)}
                        />
                      )}
                    </Container>
                  </Container>
                </Container>
              ))}
            </Container>
          </Container>
        </Container>
      )}
      {location.is_update_colors === 2 && (
        <Container>
          <p className="p-8 text-2xl font-semibold">Update product</p>
          <Container className="bg-white shadow mx-4 md:mx-8 rounded-xl mb-8">
            <p className="px-8 py-6 font-bold text-xl">Update Sizes</p>
            <Container className="px-8 pb-4">
              <Container className="flex flex-col gap-4">
                {sizeColors?.map((colors, i) => (
                  <Container key={i}>
                    <Container className="flex justify-between items-center">
                      <p className="font-semibold">{colors.colors}</p>
                      <Button
                        type="button"
                        onClick={() =>
                          handleAddMoreSize(colors.product_color_id)
                        }
                        className="bg-slate-800 text-white px-4 py-1 rounded-lg"
                      >
                        Add more sizes
                      </Button>
                    </Container>
                    {colors.productDataInfo.map((sizes, j) => (
                      <Container key={j}>
                        <Container className="flex justify-end items-center mt-2">
                          <Button
                            type="button"
                            className="bg-red-500 px-2 text-white rounded-md"
                            onClick={() =>
                              handleUpdateImage(colors.productDataInfo[j])
                            }
                          >
                            Update Images
                          </Button>
                        </Container>
                        {/* <Container className="flex gap-3 flex-col sm:flex-row mt-2">
                          <ReactSelect
                            options={isHsnData.map((hsn) => {
                              return {
                                value: hsn.hsn_id,
                                label: hsn.hsn_number,
                              };
                            })}
                            onChange={(e) => handleUpdateHSNChange(i, j, e)}
                            placeholder="Select the HSN Code"
                            styles={colorStyles}
                            className="w-full"
                            defaultInputValue={sizes.product_hsn?.toString()}
                            // required
                          />
                          <CustomInputField
                            name="product_sku"
                            label="Product SKU"
                            type="text"
                            required={true}
                            value={sizes.product_sku ?? ""}
                            onChange={(e) =>
                              handleSKUChange(i, j, e.target.value)
                            }
                          />
                          <CustomInputField
                            name="quantity"
                            label="Quantity"
                            type="number"
                            required={true}
                            value={sizes.quantity ?? ""}
                            onChange={(e) =>
                              handleQuantityChange(i, j, e.target.value)
                            }
                            min={1}
                          />
                          {colors.productDataInfo.length > 1 && (
                            <Button
                              title="Remove variation"
                              className="bg-red-600 sm:bg-transparent w-fit px-2 sm:px-0 py-1 sm:py-0 text-white sm:text-black rounded-lg sm:rounded-none"
                              onClick={() => handleRemoveVariation(i, j)}
                            >
                              <AiOutlineCloseCircle className="text-2xl hidden sm:flex" />
                              <p className="flex sm:hidden">Remove Variation</p>
                            </Button>
                          )}
                        </Container> */}
                        <Container className="flex gap-3 flex-col sm:flex-row mt-3">
                          <CustomInputField
                            name="size"
                            label="Size"
                            type="tel"
                            required={true}
                            value={sizes.variation}
                            onChange={(e) =>
                              handleUpdateSizeChange(i, j, e.target.value)
                            }
                          />
                          <CustomInputField
                            name="price"
                            label="Price"
                            type="tel"
                            required={true}
                            value={sizes.price}
                            onChange={(e) =>
                              handlePriceChange(
                                i,
                                j,
                                parseFloat(e.target.value)
                              )
                            }
                          />
                          <CustomInputField
                            name="sales_price"
                            label="Sales Price"
                            type="tel"
                            required={false}
                            value={
                              sizes.sales_price === null ? 0 : sizes.sales_price
                            }
                            onChange={(e) =>
                              handleSalesPriceChange(
                                i,
                                j,
                                parseFloat(e.target.value)
                              )
                            }
                          />
                          {colors.productDataInfo.length > 1 && (
                            <Button
                              title="Remove variation"
                              className="bg-red-600 sm:bg-transparent w-fit px-2 sm:px-0 py-1 sm:py-0 text-white sm:text-black rounded-lg sm:rounded-none"
                              onClick={() => handleRemoveVariation(i, j)}
                            >
                              <AiOutlineCloseCircle className="text-2xl hidden sm:flex" />
                              <p className="flex sm:hidden">Remove Variation</p>
                            </Button>
                          )}
                        </Container>
                        <Container className="flex gap-3 flex-col sm:flex-row mt-3">
                          <CustomInputField
                            name="weight"
                            label="Weight in Kgs"
                            type="tel"
                            required={true}
                            value={sizes.weight}
                            onChange={(e) =>
                              handleWeightChange(
                                i,
                                j,
                                parseFloat(e.target.value)
                              )
                            }
                          />
                          <CustomInputField
                            name="length"
                            label="Length in cms"
                            type="tel"
                            required={true}
                            value={sizes.length}
                            onChange={(e) =>
                              handleLengthtChange(
                                i,
                                j,
                                parseFloat(e.target.value)
                              )
                            }
                          />
                          <CustomInputField
                            name="breadth"
                            label="Breadth in cms"
                            type="tel"
                            required={true}
                            value={sizes.breadth}
                            onChange={(e) =>
                              handleBreadthChange(
                                i,
                                j,
                                parseFloat(e.target.value)
                              )
                            }
                          />
                        </Container>
                      </Container>
                    ))}
                  </Container>
                ))}
              </Container>
              <Container className="flex justify-end items-center mt-3">
                <Button
                  onClick={handleUpdateSizes}
                  className="bg-slate-800 text-white border-none outline-none p-3 rounded-xl font-semibold disabled:cursor-not-allowed disabled:bg-slate-500 active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out"
                >
                  Update
                </Button>
              </Container>
            </Container>
          </Container>
        </Container>
      )}

      <UpdateColor
        open={colorModal.edit}
        onClose={() => setColorModal({ ...colorModal, edit: false })}
        colorUpdateData={colorUpdateData}
        setColorUpdateData={setColorUpdateData}
        handleColorSubmit={handleColorSubmit}
      />
      <VariationSizes
        open={modal.add_size}
        onClose={() => setModal({ ...modal, add_size: false })}
        colorId={modal.color_id}
        fetchParticularProductDetails={fetchParticularProductDetails}
        isHsnData={isHsnData}
      />
      <UpdateImages
        open={modal.update_image}
        onClose={() => setModal({ ...modal, update_image: false })}
        imageData={modal.image_data}
      />
    </Container>
  );
}

export default EditProductView;
