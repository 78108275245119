import {
  Backdrop,
  Box,
  Fade,
  FormControl,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React from "react";
import { IoClose } from "react-icons/io5";
import { Button, Container, Form } from "reactstrap";
import CustomInputField from "../../common/CustomInputField";

function AdminPaymentGatewayModal(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    padding: "15px 20px",
    outline: "none",
    borderRadius: "15px",
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        // onClose={props.onClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={props.open}>
          <Box sx={style}>
            <div className="flex justify-between items-center">
              <p className="font-semibold">
                {props.modal?.rp_add_modal || props.modal?.pp_add_modal
                  ? "Add"
                  : props.modal?.rp_update_modal || props.modal?.pp_update_modal
                  ? "Update"
                  : ""}{" "}
                Payment Gateway Key
              </p>
              <IoClose
                className="cursor-pointer border border-[#000] rounded-full"
                onClick={props.onClose}
              />
            </div>
            <Form
              onSubmit={
                props.modal?.rp_add_modal
                  ? props.handleRazorpayAddSubmit
                  : props.modal?.rp_update_modal
                  ? props.handleRazorpayUpdateSubmit
                  : props.modal?.pp_add_modal
                  ? props.handlePhonePeAddSubmit
                  : props.modal?.pp_update_modal
                  ? props.handlePhonePeUpdateSubmit
                  : null
              }
              className="mt-3 w-[300px] sm:w-[400px] flex flex-col gap-3"
            >
              <Container className="flex flex-col gap-3">
                {props.modal?.rp_add_modal && (
                  <CustomInputField
                    name="razorpaykey_id"
                    label="Key Id"
                    type="text"
                    required={true}
                    value={props.fieldsValue?.razorpaykey_id}
                    onChange={(e) =>
                      props.setFieldsValue({
                        ...props.fieldsValue,
                        razorpaykey_id: e.target.value,
                      })
                    }
                  />
                )}
                {props.modal?.rp_add_modal && (
                  <CustomInputField
                    name="razorpay_secret"
                    label="Key Secret"
                    type="text"
                    required={true}
                    value={props.fieldsValue?.razorpay_secret}
                    onChange={(e) =>
                      props.setFieldsValue({
                        ...props.fieldsValue,
                        razorpay_secret: e.target.value,
                      })
                    }
                  />
                )}
                {(props.modal?.rp_update_modal ||
                  props.modal?.pp_update_modal) && (
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={props.fieldsValue?.isdisabled ?? ""}
                      onChange={(e) =>
                        props.setFieldsValue({
                          ...props.fieldsValue,
                          isdisabled: e.target.value,
                        })
                      }
                    >
                      <FormControlLabel
                        value="1"
                        control={
                          <Radio
                            sx={{
                              "&.Mui-checked": {
                                color: "#352210",
                              },
                            }}
                          />
                        }
                        label="Active"
                        sx={{
                          ".MuiFormControlLabel-root .MuiFormControlLabel-label, .MuiTypography-root":
                            { fontFamily: "Poppins" },
                        }}
                      />
                      {/* <FormControlLabel
                        value="0"
                        control={
                          <Radio
                            sx={{
                              "&, &.Mui-checked": {
                                color: "#352210",
                              },
                            }}
                          />
                        }
                        label="Inactive"
                        sx={{
                          ".MuiFormControlLabel-root .MuiFormControlLabel-label, .MuiTypography-root":
                            { fontFamily: "Poppins" },
                        }}
                      /> */}
                    </RadioGroup>
                  </FormControl>
                )}

                {props.modal?.pp_add_modal && (
                  <Container className="flex flex-col gap-3">
                    <CustomInputField
                      name="merchant_id"
                      label="Merchant Id"
                      type="text"
                      required={true}
                      value={props.fieldsValue?.merchant_id}
                      onChange={(e) =>
                        props.setFieldsValue({
                          ...props.fieldsValue,
                          merchant_id: e.target.value,
                        })
                      }
                    />
                    <CustomInputField
                      name="merchant_userid"
                      label="Merchant User Id"
                      type="text"
                      required={true}
                      value={props.fieldsValue?.merchant_userid}
                      onChange={(e) =>
                        props.setFieldsValue({
                          ...props.fieldsValue,
                          merchant_userid: e.target.value,
                        })
                      }
                    />
                    <TextField
                      fullWidth
                      // autoFocus
                      name="merchant_mobno"
                      label="Merchant Mobile No."
                      type="number"
                      id="fullWidth"
                      sx={{
                        "& .MuiInputLabel-root": {
                          fontFamily: "Poppins",
                          zIndex: 0,
                        },
                        "& .MuiOutlinedInput-root": {
                          fontFamily: "Poppins",
                          borderRadius: "12px",
                        },
                        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#352210",
                          },
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#352210",
                          },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #352210",
                        },
                        "& .MuiInputLabel-root.Mui-focused": {
                          color: "#352210",
                        },
                      }}
                      value={props.fieldsValue?.merchant_mobno}
                      onChange={(e) =>
                        props.setFieldsValue({
                          ...props.fieldsValue,
                          merchant_mobno: e.target.value,
                        })
                      }
                      required={true}
                      InputProps={{ inputProps: { min: 1 } }}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 10);
                      }}
                    />
                    <CustomInputField
                      name="saltkey"
                      label="Salt Key"
                      type="text"
                      required={true}
                      value={props.fieldsValue?.saltkey}
                      onChange={(e) =>
                        props.setFieldsValue({
                          ...props.fieldsValue,
                          saltkey: e.target.value,
                        })
                      }
                    />
                  </Container>
                )}
              </Container>
              <Container className="flex justify-end items-center">
                <Button
                  type="submit"
                  className="active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out bg-slate-800 text-white text-lg font-semibold py-2 px-6 rounded-xl cursor-pointer"
                >
                  {props.modal?.rp_add_modal || props.modal?.pp_add_modal
                    ? "Save"
                    : props.modal?.rp_update_modal ||
                      props.modal?.pp_update_modal
                    ? "Update"
                    : ""}
                </Button>
              </Container>
            </Form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default AdminPaymentGatewayModal;
