import React from "react";
import { MdMoreVert } from "react-icons/md";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";

function BannerMoreButton(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <MdMoreVert
        className="p-2 h-10 w-10 rounded-full mx-2 text-black bg-[#ffe1a1] cursor-pointer"
        onClick={handleClick}
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      />
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              //   right: 60,
              left: 15,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        // anchorOrigin={{
        //   vertical: "right",
        //   horizontal: "top",
        // }}
        // transformOrigin={{
        //   vertical: "right",
        //   horizontal: "bottom",
        // }}
      >
        {/* <MenuItem
          onClick={props.handleUpdate}
          sx={{
            fontFamily: "Poppins",
          }}
        >
          Edit Image
        </MenuItem> */}
        <MenuItem
          onClick={props.handleDelete}
          sx={{
            fontFamily: "Poppins",
            color: "red",
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </div>
  );
}

export default BannerMoreButton;
