import React from "react";
import "./style.css";
import imageUploader from "../../../assets/imageUploader.svg";
import { MdClear } from "react-icons/md";

function ImageUploader({
  handleDrop,
  handleDragOver,
  handleFileSelect,
  selectedImages,
  handleRemoveImage,
  multiple,
}) {
  return (
    <div>
      <div
        className="image-uploader"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <div className="upload-prompt">
          <img
            src={imageUploader}
            alt="uploader"
            style={{ height: "180px", margin: "8px auto" }}
          />
          <p className="text-lg font-bold">Drop or Select images</p>
          <div className="flex mt-2">
            <label className="file-input-label mb-3">
              {"Drop images here or click"}{" "}
              <input
                className="file-input"
                type="file"
                accept="image/png, image/jpg, image/jpeg, image/heic"
                multiple={multiple}
                onChange={handleFileSelect}
                // required
              />
              <span className="text-amber-600 cursor-pointer underline">
                browse
              </span>{" "}
              {"through your machine"}
            </label>
          </div>
        </div>
      </div>
      <div className="flex text-red-600 justify-center my-1 text-sm">
        <p className="text-center">
          .jpg, .png, .jpeg, .heic image formats are supported and max size is
          2MB *
        </p>
      </div>
      <div className="flex flex-wrap">
        {selectedImages?.map((image, index) => (
          <div key={index} className="m-1 flex">
            <img
              src={image.preview || image}
              alt="Preview"
              width={80}
              className="rounded-xl h-20 object-cover"
              style={{ border: "1px solid #919eab" }}
            />
            <button
              type="button"
              onClick={() => handleRemoveImage(index, image)}
              className="inline-flex items-center justify-center outline-none w-7 h-7 -ml-8 mt-1 border-none m-0 cursor-pointer text-center rounded-full overflow-visible text-lg p-1 text-white"
              style={{
                transition:
                  "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                backgroundColor: "#161c247a",
              }}
            >
              <MdClear />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ImageUploader;
