import React, { useState, useEffect } from "react";
import { Button, Container } from "reactstrap";
import { IoWalletOutline } from "react-icons/io5";
import toast from "react-hot-toast";
import {
  getServices,
  postServices,
} from "../../../../../apiServices/apiServices";
import ShipRocketOrderTable from "../../../tables/ShipRocketOrderTable";
import LoadingSpinner from "../../common/LoadingSpinner";
import NoInternetComp from "../../../InternetConnetction/NoInternetComp";

function ShipRocketDashboard() {
  const [wallet, setWallet] = useState("0");
  const [shipRocketData, setShipRocketData] = useState({ data: [], meta: {} });
  const access_token = sessionStorage.getItem("access-token");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [shipRocketAccessToken, setShipRocketAccessToken] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (navigator.onLine) {
      authShipRocket();
    }
  }, [page, navigator]);

  const authShipRocket = async () => {
    setLoading(true);
    try {
      const res = await postServices(`/api/shiprocket/login`, access_token);
      setLoading(false);
      if (res.responseCode === 200) {
        fetchWalletData(res.responseData.token);
        fetchShipRocketOrdersData(res.responseData.token);
        setShipRocketAccessToken(res.responseData.token);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const fetchWalletData = async (shipRcketAccessToken) => {
    setLoading(true);
    try {
      const res = await getServices(
        `/api/shiprocket/check_balance`,
        null,
        shipRcketAccessToken
      );
      setLoading(false);
      if (res.responseCode === 200) {
        setWallet(res.responseData.balance_amount);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const fetchShipRocketOrdersData = async (shipRcketAccessToken) => {
    setLoading(true);
    try {
      const res = await getServices(
        `/api/shiprocket/all_orders?page=${page + 1}&limit=500`,
        null,
        shipRcketAccessToken
      );
      setLoading(false);
      if (res.responseCode === 200) {
        if (res.responseData.meta.pagination.total > 500) {
          setShipRocketData({
            ...shipRocketData,
            data: [...shipRocketData.data, ...res.responseData.data],
            meta: res.responseData.meta,
          });
        } else {
          setShipRocketData({
            ...shipRocketData,
            data: res.responseData.data,
            meta: res.responseData.meta,
          });
        }
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleChangePage = (event, newPage) => {
    fetchShipRocketOrdersData(shipRocketAccessToken);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      {navigator.onLine ? (
        <Container>
          {loading ? (
            <Container className="flex justify-center items-center my-80">
              <LoadingSpinner />
            </Container>
          ) : (
            <Container>
              <Container className="flex justify-between items-center mx-0 px-4 md:px-8 py-8">
                <p className="text-xl sm:text-2xl font-semibold">
                  ShipRocket Orders
                </p>
                <Container className="flex gap-4">
                  <p className="flex items-center gap-3">
                    <IoWalletOutline className="text-xl text-slate-700" /> ₹{" "}
                    {wallet}
                  </p>
                  <Button
                    onClick={() =>
                      window.open("https://app.shiprocket.in/seller/home")
                    }
                    className="bg-[#6149cd] flex items-center outline-none gap-3 text-white font-semibold py-2 px-4 rounded-lg  active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out"
                  >
                    Recharge
                  </Button>
                </Container>
              </Container>
              {/* <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            // onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={page}
            previousLabel="< previous"
            renderOnZeroPageCount={null}
          /> */}
              {!shipRocketData.data.length > 0 ? (
                <Container className="flex justify-center items-center my-64">
                  {/* <LoadingSpinner /> */}
                  <p className="font-semibold">There is no orders found...</p>
                </Container>
              ) : (
                <Container className="mx-0 px-4 md:px-8">
                  {/* {shipRocketData.data.length > 0 ? ( */}
                  <ShipRocketOrderTable
                    shipRocketData={shipRocketData}
                    handleChangePage={handleChangePage}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    shipRocketAccessToken={shipRocketAccessToken}
                  />
                  {/* ) : (
                ""
              )} */}
                </Container>
              )}
            </Container>
          )}
        </Container>
      ) : (
        <Container className="min-h-[65vh] flex flex-col justify-center items-center">
          <NoInternetComp />
        </Container>
      )}
    </>
  );
}

export default ShipRocketDashboard;
