import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Rating, Tab } from "@mui/material";
import React from "react";
import { Container } from "reactstrap";
import StarIcon from "@mui/icons-material/Star";
import LinearProgress from "@mui/material/LinearProgress";

function ProductDetailTabs({ data, ratingsData }) {
  const [value, setValue] = React.useState("1");
  const maxValue = ratingsData ? ratingsData.noOfRatings : 0;
  const current5Value = ratingsData ? ratingsData.ratingCounts[5] : 0;
  const current4Value = ratingsData ? ratingsData.ratingCounts[4] : 0;
  const current3Value = ratingsData ? ratingsData.ratingCounts[3] : 0;
  const current2Value = ratingsData ? ratingsData.ratingCounts[2] : 0;
  const current1Value = ratingsData ? ratingsData.ratingCounts[1] : 0;
  const progress5Percentage = (current5Value / maxValue) * 100;
  const progress4Percentage = (current4Value / maxValue) * 100;
  const progress3Percentage = (current3Value / maxValue) * 100;
  const progress2Percentage = (current2Value / maxValue) * 100;
  const progress1Percentage = (current1Value / maxValue) * 100;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container className="border border-solid border-slate-200 rounded-2xl">
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            variant="scrollable"
            scrollButtons="auto"
            onChange={handleChange}
            aria-label="lab API tabs example"
            sx={{
              "& .MuiTab-root": {
                // margin: "0 0 0 20px",
                fontFamily: "Poppins",
              },
              "& .MuiTab-root.Mui-selected": {
                color: "#000",
              },
              "& .MuiTabs-indicator": {
                backgroundColor: "#eea300",
              },
            }}
          >
            <Tab label="Description" value="1" className="ml-4" />
            <Tab
              label={`Reviews ${
                ratingsData?.noOfRatings >= 1
                  ? "(" + ratingsData.noOfRatings + ")"
                  : ""
              }`}
              value="2"
            />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Container
            dangerouslySetInnerHTML={{
              __html: data ? data.product_feature : null,
            }}
          />
        </TabPanel>
        {ratingsData?.noOfRatings >= 1 ? (
          <TabPanel value="2">
            <Container className="grid grid-cols-1 sm:grid-cols-2">
              <Container className="flex flex-col items-center justify-center gap-4">
                <h6 className="font-semibold">Average rating</h6>
                <h2 className="text-5xl font-bold">
                  {ratingsData ? ratingsData.totalRatings : ""}/5
                </h2>
                <Rating
                  name="half-rating-read"
                  // defaultValue={4.4}
                  value={ratingsData ? ratingsData.totalRatings : 0}
                  precision={0.1}
                  readOnly
                  sx={{ color: "#faaf00" }}
                  emptyIcon={<StarIcon style={{ opacity: 0.55 }} />}
                />
                <p className="text-zinc-400">
                  ({ratingsData ? ratingsData.noOfRatings : 0} reviews)
                </p>
              </Container>
              <Container className="flex flex-col gap-5 px-0 md:px-10 py-5 md:border-dashed md:border-slate-300 md:border-l">
                <Container className="flex items-center flex-row">
                  <span className="m-0 font-semibold w-24 md:w-16">5 Star</span>
                  <LinearProgress
                    variant="determinate"
                    value={progress5Percentage}
                    sx={{
                      width: "100%",
                      maxWidth: "500px",
                      borderRadius: "4px",
                      backgroundColor: "#388e3c4a",
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: "#388e3c",
                      },
                    }}
                    className="mx-2"
                  />
                  <span className="m-0 text-zinc-500">
                    {ratingsData ? ratingsData.ratingCounts[5] : 0}
                  </span>
                </Container>
                <Container className="flex items-center flex-row">
                  <span className="m-0 font-semibold w-24 md:w-16">4 Star</span>
                  <LinearProgress
                    variant="determinate"
                    value={progress4Percentage}
                    sx={{
                      width: "100%",
                      maxWidth: "500px",
                      borderRadius: "4px",
                      backgroundColor: "#388e3c4a",
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: "#388e3c",
                      },
                    }}
                    className="mx-2"
                  />
                  <span className="m-0 text-zinc-500">
                    {ratingsData ? ratingsData.ratingCounts[4] : 0}
                  </span>
                </Container>
                <Container className="flex items-center flex-row">
                  <span className="m-0 font-semibold w-24 md:w-16">3 Star</span>
                  <LinearProgress
                    variant="determinate"
                    value={progress3Percentage}
                    sx={{
                      width: "100%",
                      maxWidth: "500px",
                      borderRadius: "4px",
                      backgroundColor: "#eea3004a",
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: "#eea300",
                      },
                    }}
                    className="mx-2"
                  />
                  <span className="m-0 text-zinc-500">
                    {ratingsData ? ratingsData.ratingCounts[3] : 0}
                  </span>
                </Container>
                <Container className="flex items-center flex-row">
                  <span className="m-0 font-semibold w-24 md:w-16">2 Star</span>
                  <LinearProgress
                    variant="determinate"
                    value={progress2Percentage}
                    sx={{
                      width: "100%",
                      maxWidth: "500px",
                      borderRadius: "4px",
                      backgroundColor: "#ff61614a",
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: "#ff6161",
                      },
                    }}
                    className="mx-2"
                  />
                  <span className="m-0 text-zinc-500">
                    {ratingsData ? ratingsData.ratingCounts[2] : 0}
                  </span>
                </Container>
                <Container className="flex items-center flex-row">
                  <span className="m-0 font-semibold w-24 md:w-16">1 Star</span>
                  <LinearProgress
                    variant="determinate"
                    value={progress1Percentage}
                    sx={{
                      width: "100%",
                      maxWidth: "500px",
                      borderRadius: "4px",
                      backgroundColor: "#ff00004a",
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: "#ff0000",
                      },
                    }}
                    className="mx-2"
                  />
                  <span className="m-0 text-zinc-500">
                    {ratingsData ? ratingsData.ratingCounts[1] : 0}
                  </span>
                </Container>
              </Container>
            </Container>
            <hr className="border-dashed border-stone-300" />
            {ratingsData
              ? ratingsData.reviews.map((item) => (
                  <Container key={item.review_id}>
                    <Container className="mt-5 md:mt-6 flex flex-col md:flex-row gap-4">
                      <Container className="flex items-center justify-center flex-col md:flex-row gap-4 text-center w-60">
                        <Container>
                          <p className="font-semibold text-md">
                            {item?.userInfo?.email}
                          </p>
                          <p className="text-sm font-normal">{item.date}</p>
                        </Container>
                      </Container>
                      <Container className="flex flex-col gap-2">
                        <Rating
                          name="half-rating-read"
                          defaultValue={item.star}
                          precision={1}
                          readOnly
                          sx={{ color: "#faaf00" }}
                          emptyIcon={<StarIcon style={{ opacity: 0.55 }} />}
                        />
                        <p className="text-justify font-normal">
                          {item.review}
                        </p>
                      </Container>
                    </Container>
                    <hr className="border-dashed mt-6" />
                  </Container>
                ))
              : null}
          </TabPanel>
        ) : (
          <TabPanel value="2">
            <Container className="text-center">No Ratings Found!!!</Container>
          </TabPanel>
        )}
      </TabContext>
    </Container>
  );
}

export default ProductDetailTabs;
