import React from "react";
import OTPInput from "react-otp-input";
import { Button, Container, Form, Input } from "reactstrap";
import classes from "./style.module.css";
import { useEffect } from "react";
import { useState } from "react";
import { LuEye, LuEyeOff } from "react-icons/lu";
import { nonstrongPwd } from "../../variables/constants";
import { useLocation, useNavigate } from "react-router-dom";
import {
  forgotPasswordService,
  resetPasswordService,
} from "../../apiServices/authServices";
import toast from "react-hot-toast";

function ForgotPassword() {
  const [countDown, setCountDown] = useState(0);
  const [runTimer, setRunTimer] = useState(true);
  const [isPasswordVisible, setIsPasswordVisible] = useState({
    new_password: false,
    confirm_password: false,
  });
  const location = useLocation();
  const [data, setData] = useState({
    otp: "",
    password: "",
    confirm_password: "",
    session_id: location.state.session_id,
    phone: location.state.forgot_phone,
  });
  const [isValid, setIsValid] = useState({
    isNewPasswordValid: false,
    isConfirmPasswordValid: false,
  });
  const navigate = useNavigate();

  useEffect(() => {
    let timerId;
    if (runTimer) {
      setCountDown(60 * 1);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }
    return () => clearInterval(timerId);
  }, [runTimer]);

  useEffect(() => {
    if (countDown < 0 && runTimer) {
      setRunTimer(false);
      setCountDown(0);
    }
  }, [countDown, runTimer]);

  const toggleTimer = () => setRunTimer((t) => !t);

  //   minutes and seconds
  const seconds = String(countDown % 60).padStart(2, 0);
  const minutes = String(Math.floor(countDown / 60)).padStart(2, 0);

  const handleChange = (e) => {
    if (e.target.name === "password") {
      if (nonstrongPwd.test(e.target.value)) {
        setIsValid({ ...isValid, isNewPasswordValid: true });
      } else {
        setIsValid({ ...isValid, isNewPasswordValid: false });
      }
    } else if (e.target.name === "confirm_password") {
      if (
        data.password === e.target.value &&
        e.target.value === data.password
      ) {
        setIsValid({ ...isValid, isConfirmPasswordValid: true });
      } else {
        setIsValid({ ...isValid, isConfirmPasswordValid: false });
      }
    }
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (data.password !== data.confirm_password) {
      toast.error("Passwords must be same.");
    } else {
      try {
        const res = await resetPasswordService(data);
        if (res.responseCode === 200) {
          alert("Your password has been updated successfully.");
          navigate("/login");
        } else {
          toast.error(res.responseMessage);
        }
      } catch (error) {
        toast.error(error);
      }
    }
  };

  const handleResend = async (e) => {
    toggleTimer();

    try {
      const res = await forgotPasswordService(location.state);
      if (res.responseCode === 200) {
        setData({ ...data, session_id: res.responseData.session_id });
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible({
      ...isPasswordVisible,
      new_password: !isPasswordVisible.new_password,
    });
  };
  const toggleConfirmPasswordVisibility = () => {
    setIsPasswordVisible({
      ...isPasswordVisible,
      confirm_password: !isPasswordVisible.confirm_password,
    });
  };

  return (
    <Container className={`${classes.otpForm} overflow-hidden`}>
      <h2 className="text-4xl font-bold mb-2" style={{ color: "#1E2219" }}>
        Reset Password
      </h2>
      <Form className="flex flex-col" onSubmit={handleSubmit}>
        <Container className="mb-3">
          <OTPInput
            inputType="number"
            inputStyle={{
              width: "3rem",
              height: "3rem",
              borderRadius: "12px",
              border: "1px solid #352210",
            }}
            value={data.otp}
            onChange={(e) => {
              setData({ ...data, otp: e });
            }}
            numInputs={6}
            renderSeparator={<span style={{ visibility: "hidden" }}>-</span>}
            renderInput={(props) => <input {...props} />}
          />
        </Container>
        <Container className="flex justify-between items-center text-sm font-medium mb-4">
          <p className="">
            Don't receive the OTP?{" "}
            <Button
              type="button"
              className="text-[#352210] disabled:text-gray-500 outline-none border-none active:scale-[.98] active:duration-75 transition-all ease-in-out disabled:cursor-not-allowed"
              disabled={countDown >= 1 ? true : false}
              onClick={handleResend}
            >
              Resend
            </Button>
          </p>
          <Container>
            Time: {minutes}:{seconds}
          </Container>
        </Container>
        <Container className="mb-4">
          <Container className="flex items-center">
            <Input
              type={isPasswordVisible.new_password ? "text" : "password"}
              name="password"
              placeholder="Password *"
              className={classes.forgotpasswordField}
              onChange={handleChange}
              value={data.password}
              required
            />
            {isPasswordVisible.new_password ? (
              <LuEye className=" -ml-7" onClick={togglePasswordVisibility} />
            ) : (
              <LuEyeOff className=" -ml-7" onClick={togglePasswordVisibility} />
            )}
          </Container>
          {!isValid.isNewPasswordValid && data.password && (
            <p className={`${classes.errorMsg} mt-2 ml-4 text-rose-600`}>
              Password must have atleast 1 uppercase,
              <br /> lowercase, number, special characters
              <br /> and minimum 8 characters.
            </p>
          )}
        </Container>
        <Container className="mb-4">
          <Container className="flex items-center">
            <Input
              type={isPasswordVisible.confirm_password ? "text" : "password"}
              name="confirm_password"
              placeholder="Confirm Password *"
              className={classes.forgotpasswordField}
              onChange={handleChange}
              value={data.confirm_password}
              required
            />
            {isPasswordVisible.confirm_password ? (
              <LuEye
                className=" -ml-7"
                onClick={toggleConfirmPasswordVisibility}
              />
            ) : (
              <LuEyeOff
                className=" -ml-7"
                onClick={toggleConfirmPasswordVisibility}
              />
            )}
          </Container>
          {!isValid.isConfirmPasswordValid && data.confirm_password && (
            <p className={`${classes.errorMsg} mt-2 ml-4 text-rose-600`}>
              Passwords must be same.
            </p>
          )}
        </Container>
        <Container className="w-full">
          <input
            type="submit"
            value="Reset Password"
            disabled={
              data.otp.length !== 6 ||
              !isValid.isConfirmPasswordValid ||
              !isValid.isNewPasswordValid
                ? true
                : false
            }
            className="active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out w-full px-6 py-4 rounded-xl"
          />
        </Container>
      </Form>
    </Container>
  );
}

export default ForgotPassword;
