import { Box, TextField } from "@mui/material";
import React, { useState } from "react";
import { Button, Container, Form } from "reactstrap";
import { updateServices } from "../../../../../apiServices/apiServices";
import toast from "react-hot-toast";

function EditUserProfileForm(props) {
  const [editData, setEditData] = useState({
    name: props.data?.name,
    phone: props.data?.phone,
    email: props.data?.email,
  });
  const access_token = localStorage.getItem("user-access-token");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await updateServices(
        `/api/authenticate/update_profile`,
        access_token,
        editData
      );
      if (res.responseCode === 200) {
        props.onClose();
        props.fetchUserDetails();
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Box
          sx={{
            maxWidth: "100%",
          }}
          className="mx-8"
        >
          <TextField
            fullWidth
            name="name"
            label="Enter a Name"
            id="fullWidth"
            type="text"
            sx={{
              "& .MuiInputLabel-root": {
                fontFamily: "Poppins",
              },
              "& .MuiInputLabel-root": {
                fontFamily: "Poppins",
              },
              "& .MuiOutlinedInput-root": {
                fontFamily: "Poppins",
                borderRadius: "12px",
              },
              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#352210",
                },
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#352210",
                },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #352210",
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "#352210",
              },
              marginBottom: "20px",
            }}
            required
            value={editData.name}
            onChange={(e) => setEditData({ ...editData, name: e.target.value })}
          />
          <TextField
            fullWidth
            name="phone"
            label="Enter a Phone No."
            id="fullWidth"
            type="tel"
            sx={{
              "& .MuiInputLabel-root": {
                fontFamily: "Poppins",
              },
              "& .MuiInputLabel-root": {
                fontFamily: "Poppins",
              },
              "& .MuiOutlinedInput-root": {
                fontFamily: "Poppins",
                borderRadius: "12px",
              },
              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#352210",
                },
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#352210",
                },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #352210",
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "#352210",
              },
              marginBottom: "20px",
            }}
            required
            onChange={(e) => {
              setEditData({ ...editData, phone: e.target.value });
            }}
            value={editData.phone}
          />
          <TextField
            fullWidth
            name="email"
            label="Enter a Email"
            id="fullWidth"
            type="email"
            sx={{
              "& .MuiInputLabel-root": {
                fontFamily: "Poppins",
              },
              "& .MuiInputLabel-root": {
                fontFamily: "Poppins",
              },
              "& .MuiOutlinedInput-root": {
                fontFamily: "Poppins",
                borderRadius: "12px",
              },
              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#352210",
                },
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#352210",
                },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #352210",
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "#352210",
              },
              marginBottom: "20px",
            }}
            required
            value={editData.email}
            onChange={(e) =>
              setEditData({ ...editData, email: e.target.value })
            }
          />

          <Container className="flex justify-end items-center mb-5">
            <Button
              type="submit"
              className="px-8 py-2 bg-[#352210] text-white font-semibold rounded-lg active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out"
            >
              Update Profile
            </Button>
          </Container>
        </Box>
      </Form>
    </Container>
  );
}

export default EditUserProfileForm;
