import axios from "axios";
import config from "../config.json";
import toast from "react-hot-toast";

const url = config.url;

const login = async (data) => {
  try {
    const response = await axios.post(`${url}/api/authenticate/sign_in`, data);
    return response.data;
  } catch (error) {
    toast.error(error.message);
  }
};

const phoneVerify = async (data) => {
  try {
    const response = await axios.post(`${url}/api/authenticate/verify`, {
      phone: data,
    });
    return response.data;
  } catch (error) {
    toast.error(error.message);
  }
};

const signupIntegration = async (data) => {
  try {
    const response = await axios.post(`${url}/api/authenticate/sign_up`, data);
    return response.data;
  } catch (error) {
    toast.error(error.message);
  }
};

const forgotPasswordService = async (data) => {
  try {
    const response = await axios.post(
      `${url}/api/authenticate/forgot_password`,
      { phone: data.forgot_phone }
    );
    return response.data;
  } catch (error) {
    toast.error(error.message);
  }
};

const resetPasswordService = async (data) => {
  try {
    const response = await axios.post(
      `${url}/api/authenticate/reset_password`,
      data
    );
    return response.data;
  } catch (error) {
    toast.error(error.message);
  }
};

const changePasswordService = async (access_token, data) => {
  try {
    const response = await axios.post(
      `${url}/api/authenticate/change_password`,
      data,
      { headers: { authorization: access_token } }
    );
    return response.data;
  } catch (error) {
    toast.error(error);
  }
};

export {
  login,
  phoneVerify,
  signupIntegration,
  forgotPasswordService,
  resetPasswordService,
  changePasswordService,
};
