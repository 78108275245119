import { Box, TextField } from "@mui/material";
import React, {useState} from "react";
import { Button, Container, Form } from "reactstrap";
import { updateServices } from "../../../../../apiServices/apiServices";
import toast from "react-hot-toast";

function VersionEditForm({ editData, onClose, fetchVersionsList }) {
  const [data, setData] = useState({
    android_version: editData.android_version,
    ios_version: editData.ios_version,
  });
  const access_token = sessionStorage.getItem("access-token");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await updateServices(
        `/api/version/update_version/${editData?.version_id}`,
        access_token,
        data
      );
      if (res.responseCode === 200) {
        toast.success("Version updated...");
        onClose();
        fetchVersionsList();
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Box
          sx={{
            maxWidth: "100%",
          }}
          className="mx-8"
        >
          <TextField
            fullWidth
            name="android_version"
            label="Enter Android Version"
            id="fullWidth"
            type="tel"
            sx={{
              "& .MuiInputLabel-root": {
                fontFamily: "Poppins",
              },
              "& .MuiInputLabel-root": {
                fontFamily: "Poppins",
              },
              "& .MuiOutlinedInput-root": {
                fontFamily: "Poppins",
                borderRadius: "12px",
              },
              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#352210",
                },
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#352210",
                },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #352210",
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "#352210",
              },
              marginBottom: "20px",
            }}
            onChange={(e) => {
              setData({ ...data, android_version: e.target.value });
            }}
            value={data.android_version}
          />
          <TextField
            fullWidth
            name="ios_version"
            label="Enter iOS Version"
            id="fullWidth"
            type="tel"
            sx={{
              "& .MuiInputLabel-root": {
                fontFamily: "Poppins",
              },
              "& .MuiInputLabel-root": {
                fontFamily: "Poppins",
              },
              "& .MuiOutlinedInput-root": {
                fontFamily: "Poppins",
                borderRadius: "12px",
              },
              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#352210",
                },
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#352210",
                },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #352210",
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "#352210",
              },
              marginBottom: "20px",
            }}
            onChange={(e) => setData({ ...data, ios_version: e.target.value })}
            value={data.ios_version}
          />

          <Container className="flex justify-end items-center mb-5">
            <Button
              type="submit"
              className="px-8 py-2 bg-slate-800 text-white font-semibold rounded-lg active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out"
            >
              Save Changes
            </Button>
          </Container>
        </Box>
      </Form>
    </Container>
  );
}

export default VersionEditForm;
