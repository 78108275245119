import React from "react";
import { Container } from "reactstrap";
import SiteLogo from "../../../assets/site_logo.png";
import { MdOutlineMail, MdPhoneInTalk } from "react-icons/md";
import { Link } from "react-router-dom";
import { IoLocationOutline } from "react-icons/io5";

function UserFooter() {
  return (
    <Container className="bg-[#352210] text-white px-2 sm:px-20 py-4">
      <Container className="grid lg:grid-cols-4 gap-4 w-full">
        <Container className="w-full col-span-1 lg:col-span-2">
          <img
            src={SiteLogo}
            alt="site logo"
            className="w-[50%] sm:w-[30%] md:w-[50%]"
          />
          <p className="lg:px-4 text-base sm:text-lg text-justify">
            We are committed to deliver quality products to our customers with
            our creative approach in design and with the help of our dedicated
            and experienced team.
          </p>
        </Container>
        <Container className="w-full">
          <p className="text-lg sm:text-xl font-semibold">Consumer Policy</p>
          <Container className="flex flex-col gap-2 mt-4">
            <Link
              to={"/terms_and_conditions"}
              className="cursor-pointer"
              onClick={() => window.scrollTo(0, 0)}
            >
              Terms and Conditions
            </Link>
            <Link
              to={"/privacy_policy"}
              className="cursor-pointer"
              onClick={() => window.scrollTo(0, 0)}
            >
              Privacy Policy
            </Link>
            <Link
              to={"/cancellation_and_refund"}
              className="cursor-pointer"
              onClick={() => window.scrollTo(0, 0)}
            >
              Cancellation and Refund Policy
            </Link>
            <Link
              to={"/shipping_and_delivery"}
              className="cursor-pointer"
              onClick={() => window.scrollTo(0, 0)}
            >
              Shipping & Delivery Policy
            </Link>
            <Link
              to={"/about_us"}
              className="cursor-pointer"
              onClick={() => window.scrollTo(0, 0)}
            >
              About us
            </Link>
            <Link
              to={"/contact_us"}
              className="cursor-pointer"
              onClick={() => window.scrollTo(0, 0)}
            >
              Contact us
            </Link>
          </Container>
        </Container>
        <Container className="w-full">
          <p className="text-lg sm:text-xl font-semibold">Contact Details</p>
          <Container className="flex flex-col gap-2 mt-4">
            <Container className="flex items-start gap-4">
              <IoLocationOutline />
              <p>
                3-4/5, NAVARANG SMARANIKA <br /> Panemangaluru <br /> KARNATAKA
                574231
              </p>
            </Container>
            <Container className="flex items-center gap-4">
              <MdPhoneInTalk />
              <a href="tel:+91 9343899767">+91 9343899767</a>
            </Container>
            <Container className="flex items-center gap-4">
              <MdOutlineMail />
              <a href="mailto:navarangdigital767@gmail.com">
                navarangdigital767@gmail.com
              </a>
            </Container>
          </Container>
        </Container>
      </Container>
      <hr className="border-[#e4b262] mt-4" />
      <Container className="mt-4">
        <p className="text-center">
          © {new Date().getFullYear()}. All rights reserved. Designed by{" "}
          <a
            href="https://www.accelerlabsolutions.com/"
            target="_blank"
            className="hover:text-[#e4b626]"
          >
            Accelerlab Solutions Pvt. Ltd.
          </a>
        </p>
      </Container>
    </Container>
  );
}

export default UserFooter;
