import React, { useState } from "react";
import { Button, Container, Form } from "reactstrap";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { LuEye, LuEyeOff } from "react-icons/lu";
import { changePasswordService } from "../../apiServices/authServices";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

function ChangePasssword() {
  const [data, setData] = useState({
    old_pass: "",
    new_pass: "",
    cnfm_pass: "",
  });
  const [showPassword, setShowPasword] = useState({
    old_pass: false,
    new_pass: false,
    cnfm_pass: false,
  });
  const [loading, setLoading] = useState(false);
  const access_token = localStorage.getItem("user-access-token");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (data.new_pass === data.cnfm_pass) {
      try {
        const res = await changePasswordService(access_token, data);
        setLoading(false);
        if (res.responseCode === 200) {
          toast.success("Your password has been updated successfully...");
          navigate("/profile");
        } else {
          toast.error(res.responseMessage);
        }
      } catch (error) {
        toast.error(error);
      }
    } else {
      setLoading(false);
      toast.error("New password and Confirm password must be same.");
    }
  };

  return (
    <Container className="flex justify-center flex-col gap-3 items-center px-2 sm:px-0 text-sm sm:text-base min-h-[55vh]">
      <Container className="w-full sm:w-2/4 px-4 py-2 rounded-md">
        <Form onSubmit={handleSubmit}>
          <FormControl
            sx={{
              "& .MuiInputLabel-root": {
                fontFamily: "Poppins",
              },
              "& .MuiInputLabel-root": {
                fontFamily: "Poppins",
              },
              "& .MuiOutlinedInput-root": {
                fontFamily: "Poppins",
                borderRadius: "12px",
              },
              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#352210",
                },
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#352210",
                },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #352210",
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "#352210",
              },
            }}
            variant="outlined"
            fullWidth
          >
            <InputLabel
              htmlFor="outlined-adornment-password"
              sx={{ fontFamily: "Poppins" }}
            >
              Old Password
            </InputLabel>
            <OutlinedInput
              type={showPassword.old_pass ? "text" : "password"}
              sx={{
                fontFamily: "Poppins",
                borderRadius: "12px",
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      setShowPasword({
                        ...showPassword,
                        old_pass: !showPassword.old_pass,
                      })
                    }
                    edge="end"
                  >
                    {!showPassword.old_pass ? <LuEyeOff /> : <LuEye />}
                  </IconButton>
                </InputAdornment>
              }
              label="Old Password"
              required
              value={data.old_pass}
              onChange={(e) => setData({ ...data, old_pass: e.target.value })}
            />
          </FormControl>
          <FormControl
            sx={{
              margin: "15px 0",
              "& .MuiInputLabel-root": {
                fontFamily: "Poppins",
              },
              "& .MuiInputLabel-root": {
                fontFamily: "Poppins",
              },
              "& .MuiOutlinedInput-root": {
                fontFamily: "Poppins",
                borderRadius: "12px",
              },
              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#352210",
                },
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#352210",
                },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #352210",
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "#352210",
              },
            }}
            variant="outlined"
            fullWidth
          >
            <InputLabel
              htmlFor="outlined-adornment-password"
              sx={{ fontFamily: "Poppins" }}
            >
              New Password
            </InputLabel>
            <OutlinedInput
              type={showPassword.new_pass ? "text" : "password"}
              sx={{
                fontFamily: "Poppins",
                borderRadius: "12px",
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      setShowPasword({
                        ...showPassword,
                        new_pass: !showPassword.new_pass,
                      })
                    }
                    edge="end"
                  >
                    {!showPassword.new_pass ? <LuEyeOff /> : <LuEye />}
                  </IconButton>
                </InputAdornment>
              }
              label="New Password"
              required
              value={data.new_pass}
              onChange={(e) => setData({ ...data, new_pass: e.target.value })}
            />
            <p className="text-xs sm:text-xs text-red-600 my-2">
              * Password must have atleast 1 uppercase, lowercase, number,
              special characters and minimum 8 characters.
            </p>
          </FormControl>
          <FormControl
            sx={{
              "& .MuiInputLabel-root": {
                fontFamily: "Poppins",
              },
              "& .MuiInputLabel-root": {
                fontFamily: "Poppins",
              },
              "& .MuiOutlinedInput-root": {
                fontFamily: "Poppins",
                borderRadius: "12px",
              },
              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#352210",
                },
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#352210",
                },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #352210",
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "#352210",
              },
            }}
            variant="outlined"
            fullWidth
          >
            <InputLabel
              htmlFor="outlined-adornment-password"
              sx={{ fontFamily: "Poppins" }}
            >
              Confirm New Password
            </InputLabel>
            <OutlinedInput
              type={showPassword.cnfm_pass ? "text" : "password"}
              sx={{
                fontFamily: "Poppins",
                borderRadius: "12px",
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      setShowPasword({
                        ...showPassword,
                        cnfm_pass: !showPassword.cnfm_pass,
                      })
                    }
                    edge="end"
                  >
                    {!showPassword.cnfm_pass ? <LuEyeOff /> : <LuEye />}
                  </IconButton>
                </InputAdornment>
              }
              label="Confirm New Password"
              required
              value={data.cnfm_pass}
              onChange={(e) => setData({ ...data, cnfm_pass: e.target.value })}
            />
          </FormControl>

          <Container className="flex justify-center items-center mt-4">
            {loading ? (
              <Button
                type="button"
                className="bg-[#352210] text-white outline-none py-3 px-7 rounded-lg font-semibold active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out"
              >
                Loading...
              </Button>
            ) : (
              <Button
                type="submit"
                disabled={
                  data.old_pass.trim() === "" ||
                  data.new_pass.trim() === "" ||
                  data.cnfm_pass.trim() === ""
                    ? true
                    : false
                }
                className="bg-[#352210] text-white outline-none py-3 px-7 rounded-lg font-semibold active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out disabled:cursor-not-allowed disabled:bg-[#4d5157]"
              >
                Change Password
              </Button>
            )}
          </Container>
        </Form>
      </Container>
    </Container>
  );
}

export default ChangePasssword;
