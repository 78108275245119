import { Backdrop, Box, Fade, Modal } from "@mui/material";
import React, { useState, useEffect } from "react";
import { IoClose } from "react-icons/io5";
import { Button, Container, Form } from "reactstrap";
import config from "../../../../../config.json";
import { MdClear } from "react-icons/md";
import toast from "react-hot-toast";
import {
  removeParticularImage,
  updateServices,
} from "../../../../../apiServices/apiServices";

function UpdateImages(props) {
  const [selectedPrevImages, setSelectedPrevImages] = useState();
  const [selectedImages, setSelectedImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const access_token = sessionStorage.getItem("access-token");
  const url = config.url;

  useEffect(() => {
    const newArr = props.imageData?.image.map((item) => url + item);
    setSelectedPrevImages(newArr);
  }, [props.imageData]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    padding: "15px 20px",
    outline: "none",
    borderRadius: "15px",
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    handleImageUpload(files);
  };

  const handleImageUpload = (files) => {
    const selectedPrev = [...selectedPrevImages];
    const newSelected = [...selectedImages];

    for (const file of files) {
      selectedPrev.push(URL.createObjectURL(file));
      newSelected.push(file);
    }

    setSelectedPrevImages(selectedPrev);
    setSelectedImages(newSelected);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();

    for (let i = 0; i < selectedImages.length; i++) {
      formData.append("image", selectedImages[i]);
    }

    try {
      const res = await updateServices(
        `/api/products/update_image/${props.imageData.product_data_id}`,
        access_token,
        formData
      );
      setLoading(false);
      if (res.responseCode === 200) {
        props.onClose();
        toast.success("Image updated...");
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleRemoveParticularImage = async (index, image) => {
    if (image.file) {
      setSelectedImages((prevImages) => {
        const updatedImages = prevImages.filter((_, i) => i !== index);
        return updatedImages;
      });
      setSelectedPrevImages((prevImages) => {
        const updatedImages = prevImages.filter((_, i) => i !== index);
        return updatedImages;
      });
    } else {
      setSelectedImages((prevImages) => {
        const updatedImages = prevImages.filter((_, i) => i !== index);
        return updatedImages;
      });
      setSelectedPrevImages((prevImages) => {
        const updatedImages = prevImages.filter((_, i) => i !== index);
        return updatedImages;
      });

      const imageSplit = image.split("/");
      const imageName = imageSplit.pop();

      try {
        const res = await removeParticularImage(
          `/api/products/delete_image/${props.imageData.product_data_id}`,
          access_token,
          { remove_image: [imageName] }
        );
        if (res.responseCode !== 200) {
          toast.error(res.responseMessage)
        }
      } catch (error) {
        toast.error(error);
      }
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        // onClose={props.onClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={props.open}>
          <Box sx={style}>
            <div className="flex justify-between items-center mb-2">
              <p className="font-semibold">Add Size</p>
              <IoClose
                className="cursor-pointer border border-[#f46565] rounded-full"
                onClick={props.onClose}
              />
            </div>
            <Form
              onSubmit={handleSubmit}
              className="mt-3 w-[300px] sm:w-[400px] flex flex-col gap-3"
            >
              <Container className="flex flex-col">
                <input
                  type="file"
                  accept="image/png, image/jpg, image/jpeg, image/heic"
                  multiple
                  className="border border-slate-400 p-2 rounded-md"
                  onChange={handleImageChange}
                />
              </Container>
              <Container className="flex gap-2 flex-wrap">
                {selectedPrevImages?.map((item, i) => (
                  <Container key={i} className="relative">
                    <img
                      src={item}
                      alt="navarang smaranika"
                      className="w-20 h-20 object-cover rounded-lg"
                    />
                    {selectedPrevImages.length > 1 && (
                      <Button
                        onClick={() => handleRemoveParticularImage(i, item)}
                        className="absolute right-1 top-1 bg-[#0000006b] rounded-full"
                        type="button"
                      >
                        <MdClear className="text-white p-1 text-2xl" />
                      </Button>
                    )}
                  </Container>
                ))}
              </Container>
              <Container className="flex justify-end items-center">
                {loading ? (
                  <Button
                    type="button"
                    className="bg-slate-600 text-white text-lg font-semibold py-2 px-4 rounded-xl cursor-not-allowed"
                  >
                    Loading...
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    className="active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out bg-slate-800 text-white text-lg font-semibold py-2 px-4 rounded-xl cursor-pointer"
                  >
                    Save
                  </Button>
                )}
              </Container>
            </Form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default UpdateImages;
