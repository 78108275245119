import { Backdrop, Box, Fade, Modal } from "@mui/material";
import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import { Button, Container, Form } from "reactstrap";
import CustomInputField from "../../common/CustomInputField";
import toast from "react-hot-toast";
import { MdClear } from "react-icons/md";
import { postServices } from "../../../../../apiServices/apiServices";
import ReactSelect from "react-select";

function VariationSizes(props) {
  const [data, setData] = useState({
    variation: "",
    price: "",
    sales_price: "",
    weight: "",
    breadth: "",
    length: "",
    product_sku: "",
    quantity: "",
    product_hsn: "",
    image: [],
  });
  const [imagePreview, setImagePreview] = useState([]);
  const access_token = sessionStorage.getItem("access-token");
  const [loading, setLoading] = useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    padding: "15px 20px",
    outline: "none",
    borderRadius: "15px",
  };

  const colorStyles = {
    control: (styles) => ({
      ...styles,
      border: "1px solid #352210",
      marginBottom: "0rem",
      marginTop: "0rem",
      outline: "none",
      padding: "0.5rem",
      borderRadius: "10px",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #352210",
      },
    }),
  };

  const handleImageChange = (e) => {
    const images = Array.from(e.target.files);
    const preview = [...imagePreview];
    for (const image of images) {
      if (image.size > 2 * 1024 * 1024) {
        toast.error("Image size must be less than 2MB.");
        return;
      }
      preview.push(URL.createObjectURL(image));
      setData({ ...data, image: images });
      setImagePreview(preview);
    }
  };

  const handleRemoveParticularImage = (index) => {
    const updateImages = [...data.image];
    const updatedPreview = [...imagePreview];
    updateImages.splice(index, 1);
    updatedPreview.splice(index, 1);

    setData({ ...data, image: updateImages });
    setImagePreview(updatedPreview);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();

    formData.append("variation", parseFloat(data.variation));
    formData.append("price", parseFloat(data.price));
    formData.append("breadth", parseFloat(data.breadth));
    formData.append("length", parseFloat(data.length));
    formData.append("weight", parseFloat(data.weight));
    formData.append("sales_price", parseFloat(data.sales_price));
    formData.append("product_sku", data.product_sku);
    formData.append("product_hsn", data.product_hsn?.label);
    formData.append("quantity", Number(data.quantity));
    for (let i = 0; i < data.image.length; i++) {
      formData.append("image", data.image[i]);
    }

    try {
      const res = await postServices(
        `/api/products/add_variation/${props.colorId}`,
        access_token,
        formData
      );
      setLoading(false);
      if (res.responseCode === 200) {
        props.onClose();
        props.fetchParticularProductDetails();
        toast.success("Size added...");
        setData({
          ...data,
          variation: "",
          sales_price: "",
          price: "",
          image: [],
        });
        setImagePreview([]);
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        // onClose={props.onClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={props.open}>
          <Box sx={style}>
            <div className="flex justify-between items-center mb-2">
              <p className="font-semibold">Add Size</p>
              <IoClose
                className="cursor-pointer border border-[#000] rounded-full"
                onClick={props.onClose}
              />
            </div>
            <Form
              onSubmit={handleSubmit}
              className="mt-3 w-[300px] sm:w-[450px] md:w-[550px] flex flex-col gap-3"
            >
              <Container className="flex flex-col items-center gap-3">
                <ReactSelect
                  options={props.isHsnData.map((hsn) => {
                    return {
                      value: hsn.hsn_id,
                      label: hsn.hsn_number,
                    };
                  })}
                  onChange={(e) => setData({ ...data, product_hsn: e })}
                  placeholder="Select the HSN Code"
                  styles={colorStyles}
                  className="w-full"
                  // value={sizes.product_hsn}
                  // defaultInputValue={sizes.product_hsn?.toString()}
                  required
                />
                <Container className="flex flex-col sm:flex-row gap-3 w-full">
                  <CustomInputField
                    name="product_sku"
                    label="Product SKU"
                    type="text"
                    required={true}
                    value={data.product_sku}
                    onChange={(e) =>
                      setData({ ...data, product_sku: e.target.value })
                    }
                  />
                  <CustomInputField
                    name="quantity"
                    label="Quantity"
                    type="number"
                    required={true}
                    value={data.quantity}
                    onChange={(e) =>
                      setData({ ...data, quantity: e.target.value })
                    }
                    min={1}
                  />
                </Container>
                <Container className="flex flex-col sm:flex-row gap-3 w-full">
                  <CustomInputField
                    name="size"
                    label="Size"
                    type="text"
                    required={true}
                    value={data.variation}
                    onChange={(e) =>
                      setData({ ...data, variation: e.target.value })
                    }
                  />
                  <CustomInputField
                    name="price"
                    label="Price"
                    type="number"
                    required={true}
                    value={data.price}
                    onChange={(e) =>
                      setData({ ...data, price: e.target.value })
                    }
                    min={1}
                  />
                </Container>
                <Container className="flex flex-col sm:flex-row gap-3 w-full">
                  <CustomInputField
                    name="sales_price"
                    label="Sales Price"
                    type="number"
                    required={false}
                    value={data.sales_price}
                    onChange={(e) =>
                      setData({ ...data, sales_price: e.target.value })
                    }
                    min={0}
                  />
                  <CustomInputField
                    name="weight"
                    label="Weight in Kgs"
                    type="number"
                    required={true}
                    value={data.weight}
                    onChange={(e) =>
                      setData({ ...data, weight: e.target.value })
                    }
                  />
                </Container>

                <Container className="flex flex-col sm:flex-row gap-3 w-full">
                  <CustomInputField
                    name="breadth"
                    label="Breadth in cms"
                    type="number"
                    required={true}
                    value={data.breadth}
                    onChange={(e) =>
                      setData({ ...data, breadth: e.target.value })
                    }
                  />
                  <CustomInputField
                    name="length"
                    label="Length in cms"
                    type="number"
                    required={true}
                    value={data.length}
                    onChange={(e) =>
                      setData({ ...data, length: e.target.value })
                    }
                  />
                </Container>
              </Container>
              <Container className="flex flex-col">
                <input
                  type="file"
                  accept="image/png, image/jpg, image/jpeg, image/heic"
                  multiple
                  className="border border-slate-400 p-2 rounded-md"
                  onChange={handleImageChange}
                  required
                />
              </Container>
              <Container className="flex gap-2 flex-wrap">
                {imagePreview.map((item, i) => (
                  <Container key={i} className="relative">
                    <img
                      src={item}
                      alt="navarang smaranika"
                      className="w-20 h-20 object-cover rounded-lg"
                    />
                    {imagePreview.length > 1 && (
                      <Button
                        onClick={() => handleRemoveParticularImage(i)}
                        className="absolute right-1 top-1 bg-[#0000006b] rounded-full"
                        type="button"
                      >
                        <MdClear className="text-white p-1 text-2xl" />
                      </Button>
                    )}
                  </Container>
                ))}
              </Container>
              <Container className="flex justify-end items-center">
                {loading ? (
                  <Button
                    type="button"
                    className="bg-slate-600 text-white text-lg font-semibold py-2 px-4 rounded-xl cursor-not-allowed"
                  >
                    Loading...
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    className="active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out bg-slate-800 text-white text-lg font-semibold py-2 px-4 rounded-xl cursor-pointer"
                  >
                    Save
                  </Button>
                )}
              </Container>
            </Form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default VariationSizes;
