import React, { useState, useEffect } from "react";
import { HiPlus } from "react-icons/hi";
import { Button, Container } from "reactstrap";
import VersionsTable from "../../tables/VersionsTable";
import VersionAddModal from "../Modal/VersionControl/VersionAddModal";
import {
  getServices,
  postServices,
  updateServices,
} from "../../../../apiServices/apiServices";
import VersionEditModal from "../Modal/VersionControl/VersionEditModal";
import toast from "react-hot-toast";
import NoInternetComp from "../../InternetConnetction/NoInternetComp";

function VersionControlView() {
  const [addModal, setAddModal] = useState(false);
  const [data, setData] = useState({ android_version: "", ios_version: "" });
  const access_token = sessionStorage.getItem("access-token");
  const [isTableData, setIsTableData] = useState([]);
  const [updateVersionData, setUpdateVersionData] = useState({
    maintenance_mode: false,
  });
  const [editModal, setEditModal] = useState(false);
  const [editData, setEditData] = useState();

  useEffect(() => {
    if (navigator.onLine) {
      fetchVersionsList();
    }
  }, [navigator]);

  const fetchVersionsList = async () => {
    try {
      const res = await getServices(`/api/version/get_version`, access_token);
      setIsTableData(res.responseData?.version_list);
      setUpdateVersionData({
        ...updateVersionData,
        maintenance_mode: res.responseData?.maintenance_mode,
      });
    } catch (error) {
      toast.error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await postServices(
        `/api/version/add_version`,
        access_token,
        data
      );
      if (res.responseCode === 200) {
        toast.success("Version added...");
        fetchVersionsList();
        setAddModal(false);
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleAddModal = () => {
    setAddModal(true);
  };

  const handleDataChange = async (e) => {
    setUpdateVersionData({
      ...updateVersionData,
      maintenance_mode: e.target.checked,
    });
    var checked = e.target.checked;

    try {
      const res = await updateServices(
        `/api/version/maintenance`,
        access_token,
        { maintenance_mode: e.target.checked ? 1 : 0 }
      );
      if (res.responseCode === 200) {
        fetchVersionsList();
        if (checked) {
          toast.success("Maintenance mode is enabled...");
        } else {
          toast.success("Maintenance mode is disabled...");
        }
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleVersionStatusChange = async (e, data) => {
    let statusFlag = e.target.checked === true ? 1 : 0;

    try {
      const res = await updateServices(
        `/api/version/update_version/${data.version_id}`,
        access_token,
        {
          status: statusFlag,
        }
      );
      if (res.responseCode === 200) {
        fetchVersionsList();
        if (e.target.checked) {
          toast.success("Version status disabled...");
        } else {
          toast.success("Version status enabled...");
        }
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleVesionEdit = (data) => {
    setEditModal(true);
    setEditData(data);
  };

  return (
    <>
      {navigator.onLine ? (
        <Container>
          <Container className="flex justify-between items-center mx-0 md:mx-0 p-8">
            <p className="text-xl sm:text-2xl font-semibold">Version Control</p>
            <Button
              onClick={() => handleAddModal()}
              className="bg-slate-800 flex items-center px-2 sm:px-7 py-2 text-white font-semibold rounded-xl outline-none border-none active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out"
            >
              <HiPlus className="mr-2" /> Add Version
            </Button>
          </Container>

          <Container className="mx-4 md:mx-8">
            <VersionsTable
              isTableData={isTableData}
              access_token={access_token}
              setUpdateVersionData={setUpdateVersionData}
              updateVersionData={updateVersionData}
              fetchVersionsList={fetchVersionsList}
              handleDataChange={handleDataChange}
              handleVersionStatusChange={handleVersionStatusChange}
              handleVesionEdit={handleVesionEdit}
            />
          </Container>

          <VersionAddModal
            isModalShow={addModal}
            onClose={() => setAddModal(false)}
            handleSubmit={handleSubmit}
            data={data}
            setData={setData}
          />

          <VersionEditModal
            isModalShow={editModal}
            onClose={() => setEditModal(false)}
            editData={editData}
            fetchVersionsList={fetchVersionsList}
          />
        </Container>
      ) : (
        <Container className="min-h-[65vh] flex flex-col justify-center items-center">
          <NoInternetComp />
        </Container>
      )}
    </>
  );
}

export default VersionControlView;
