import { Box, Modal, Typography } from "@mui/material";
import React from "react";
import { IoWarning } from "react-icons/io5";
import { Button, Container } from "reactstrap";

function DeleteModal(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
    outline: "none",
    "@media (max-width: 500px)": {
      width: 350,
    },
  };

  const handleBackdropClick = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <Modal
        open={props?.isOpen?.isOpen}
        onClose={props?.handleDeleteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{ onClick: handleBackdropClick }}
      >
        <Box sx={style}>
          <Container className="flex justify-center">
            <IoWarning
              style={{
                width: "50px",
                height: "50px",
                padding: "8px",
                backgroundColor: "#fff5f6",
                borderRadius: "50%",
                color: "#ff3f56",
              }}
            />
          </Container>
          <Container className="text-center">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ mt: 2, fontFamily: "Poppins" }}
            >
              Delete {props.title}
            </Typography>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 1, fontFamily: "Poppins" }}
            >
              You're going to delete the "{props?.name}" {props.title}. Are you
              sure?
            </Typography>
          </Container>
          <Container className="mt-5 flex justify-between gap-5 items-center">
            <Button
              className="bg-[#f5f5f7] text-[#394650] font-semibold py-3 px-5 rounded-full active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out"
              onClick={props?.handleDeleteModal}
            >
              No, Keep It.
            </Button>
            <Button
              className="bg-[#ff3f56] text-white font-semibold py-3 px-5 rounded-full active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out"
              onClick={() => props?.handleDelete(props?.id)}
            >
              Yes, Delete!
            </Button>
          </Container>
        </Box>
      </Modal>
    </div>
  );
}

export default DeleteModal;
