import React, { useEffect } from "react";
import { Container } from "reactstrap";
import UsersTable from "../../tables/UsersTable";
import NoInternetComp from "../../InternetConnetction/NoInternetComp";

function UsersView() {
  useEffect(() => {}, [navigator]);

  return (
    <>
      {navigator.onLine ? (
        <Container>
          <Container className="flex flex-col sm:flex-row justify-between">
            <p className="p-8 text-2xl font-semibold">Manage Users</p>
          </Container>
          <Container className="mx-8 mt-5 sm:mt-0">
            <UsersTable />
          </Container>
        </Container>
      ) : (
        <Container className="min-h-[65vh] flex flex-col justify-center items-center">
          <NoInternetComp />
        </Container>
      )}
    </>
  );
}

export default UsersView;
