import * as React from "react";
import { Box } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { TableHead } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { useState, useEffect } from "react";
import { Container } from "reactstrap";
import { getServices } from "../../../apiServices/apiServices";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator() {
  return (a, b) => -descendingComparator(a, b);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: "Email",
  },
  {
    id: "phone_number",
    numeric: false,
    disablePadding: false,
    label: "Phone Number",
  },
  {
    id: "created_on",
    numeric: true,
    disablePadding: false,
    label: "Created on",
  },
];

function EnhancedTableHead() {
  return (
    <TableHead sx={{ backgroundColor: "#f7e1a3" }}>
      <TableRow>
        <TableCell padding="checkbox" />
        {headCells.map((headCell) => (
          <TableCell
            sx={{
              fontFamily: "poppins",
            }}
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            <TableSortLabel>{headCell.label}</TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function EnhancedTable() {
  const [order] = React.useState("asc");
  const [orderBy] = React.useState("id");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isData, setIsData] = useState([]);
  const access_token = sessionStorage.getItem("access-token");
  const navigate = useNavigate();

  useEffect(() => {
    if (navigator.onLine) {
      fetchUsersList();
    }
  }, [navigator]);

  const fetchUsersList = async () => {
    try {
      const res = await getServices(`/api/user/user_details`, access_token);
      setIsData(res.responseData.rows);
    } catch (error) {
      toast.error(error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ width: "100%" }}>
      {isData ? (
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <EnhancedTableHead />
              <TableBody>
                {isData &&
                stableSort(isData, getComparator(order, orderBy)) &&
                Array.isArray(stableSort(isData, getComparator(order, orderBy)))
                  ? stableSort(isData, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.user_id}
                            sx={{ cursor: "pointer" }}
                            onClick={() =>
                              navigate("/admin/user/profile", {
                                state: { user_id: row.user_id },
                              })
                            }
                          >
                            <TableCell padding="checkbox" />
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                              sx={{
                                fontFamily: "poppins",
                              }}
                            >
                              {row.name}
                            </TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                              sx={{
                                fontFamily: "poppins",
                              }}
                            >
                              {row.email}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontFamily: "poppins",
                              }}
                            >
                              {row.phone}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontFamily: "poppins",
                              }}
                              align="right"
                            >
                              {row.registered_on}
                            </TableCell>
                          </TableRow>
                        );
                      })
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={isData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              "& .MuiTablePagination-selectLabel": {
                fontFamily: "Poppins",
              },
              "& .MuiTablePagination-select": {
                fontFamily: "Poppins",
              },
              "& .MuiTablePagination-displayedRows": {
                fontFamily: "Poppins",
              },
            }}
          />
        </Paper>
      ) : (
        <Container className="flex justify-center font-semibold">
          <p>No users found...</p>
        </Container>
      )}
    </Box>
  );
}
