import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Login from "./pages/Admin/Login";
import "./styles.scss";
import ImageEditor from "./pages/Admin/ImageEditor";
import LoginForm from "./components/Auth/LoginForm";
import RegisterForm from "./components/Auth/RegisterForm";
import OTPScreen from "./components/Auth/OTPScreen";
import ForgotPassword from "./components/Auth/ForgotPassword";
import HomePage from "./pages/User/HomePage";
import UserLayout from "./components/layouts/UserLayout";
import FavoritesPage from "./pages/User/FavoritesPage";
import NotificationPage from "./pages/User/NotificationPage";
import CartPage from "./pages/User/CartPage";
import SearchPage from "./pages/User/SearchPage";
import Profile from "./pages/User/Profile";
import ProductsCategory from "./pages/User/ProductsCategory";
import ProductDetails from "./pages/User/ProductDetails";
import ManageAddress from "./pages/User/ManageAddress";
import AddAddressPage from "./pages/User/AddAddressPage";
import EditAddressPage from "./pages/User/EditAddressPage";
import PaymentSelectionPage from "./pages/User/PaymentSelectionPage";
import OrderPlacedSuccessful from "./pages/User/OrderPlacedSuccessful";
import ManageOrders from "./pages/User/ManageOrders";
import ManageOrderDetails from "./pages/User/ManageOrderDetails";
import ChangePasssword from "./pages/User/ChangePasssword";
import AdminLayout from "./components/layouts/AdminLayout";
import DashboardView from "./components/common/Admin/views/DashboardView";
import UsersView from "./components/common/Admin/views/UsersView";
import AddProductView from "./components/common/Admin/views/AddProductView";
import ProductsView from "./components/common/Admin/views/ProductsView";
import OrdersView from "./components/common/Admin/views/OrdersView";
import ManageCategoryView from "./components/common/Admin/views/ManageCategoryView";
import ManageSubCategoryView from "./components/common/Admin/views/ManageSubCategoryView";
import EditProductView from "./components/common/Admin/views/EditProductView";
import ProductDetailView from "./components/common/Admin/views/ProductDetailView";
import OrderDetailsView from "./components/common/Admin/views/OrderDetailsView";
import VersionControlView from "./components/common/Admin/views/VersionControlView";
import ManageBannerView from "./components/common/Admin/views/ManageBannerView";
import { Toaster } from "react-hot-toast";
import ShipRocketDashboard from "./components/common/Admin/views/ShipRocket/ShipRocketDashboard";
import ShipRocketOrderDetails from "./components/common/Admin/views/ShipRocket/ShipRocketOrderDetails";
import CheckConnection from "./components/common/Admin/common/CheckConnection";
import PrivacyPolicyPage from "./pages/User/PrivacyPolicyPage";
import TermsAndConditions from "./pages/User/TermsAndConditions";
import CancellationAndRefund from "./pages/User/CancellationAndRefund";
import ShippingAndDelivery from "./pages/User/ShippingAndDelivery";
import ContactUsPage from "./pages/User/ContactUsPage";
import UserProfileDetailsView from "./components/common/Admin/views/UserProfileDetailsView";
import SettingsView from "./components/common/Admin/views/SettingsView";
import ManageHSNView from "./components/common/Admin/views/ManageHSNView";
import ManagePaymentsView from "./components/common/Admin/views/ManagePaymentGateways/ManagePaymentsView";
import GalleryPage from "./pages/User/GalleryPage";
import ManageGalleryView from "./components/common/Admin/views/ManageGalleryView";
import AboutUsPage from "./pages/User/AboutUsPage";

function App() {
  return (
    <CheckConnection>
      <BrowserRouter>
        <Routes>
          {/* login and signup page route  */}
          <Route
            path="/login"
            element={
              <Login>
                <LoginForm />
              </Login>
            }
          />
          <Route
            path="/register"
            element={
              <Login>
                <RegisterForm />
              </Login>
            }
          />
          <Route
            path="/verification"
            element={
              <Login>
                <OTPScreen />
              </Login>
            }
          />
          <Route
            path="/forgot_password"
            element={
              <Login>
                <ForgotPassword />
              </Login>
            }
          />
          {/* admin module routes  */}
          <Route
            path="/admin/dashboard"
            element={
              <AdminLayout>
                <DashboardView />
              </AdminLayout>
            }
          />
          <Route
            path="/admin/users"
            element={
              <AdminLayout>
                <UsersView />
              </AdminLayout>
            }
          />
          <Route
            path="/admin/add_product"
            element={
              <AdminLayout>
                <AddProductView />
              </AdminLayout>
            }
          />
          <Route
            path="/admin/products"
            element={
              <AdminLayout>
                <ProductsView />
              </AdminLayout>
            }
          />
          <Route
            path="/admin/orders"
            element={
              <AdminLayout>
                <OrdersView />
              </AdminLayout>
            }
          />
          <Route
            path="/admin/manage_category"
            element={
              <AdminLayout>
                <ManageCategoryView />
              </AdminLayout>
            }
          />
          <Route
            path="/admin/manage_sub_category"
            element={
              <AdminLayout>
                <ManageSubCategoryView />
              </AdminLayout>
            }
          />
          <Route
            path="/admin/product/edit"
            element={
              <AdminLayout>
                <EditProductView />
              </AdminLayout>
            }
          />
          <Route
            path="/admin/product/detail"
            element={
              <AdminLayout>
                <ProductDetailView />
              </AdminLayout>
            }
          />
          <Route
            path="/admin/order/details"
            element={
              <AdminLayout>
                <OrderDetailsView />
              </AdminLayout>
            }
          />
          <Route
            path="/admin/version_control"
            element={
              <AdminLayout>
                <VersionControlView />
              </AdminLayout>
            }
          />
          <Route
            path="/admin/manage_banner"
            element={
              <AdminLayout>
                <ManageBannerView />
              </AdminLayout>
            }
          />
          <Route
            path="/admin/manage_banner/image/editor"
            element={<ImageEditor />}
          />
          <Route
            path="/admin/ship_rocket/orders"
            element={
              <AdminLayout>
                <ShipRocketDashboard />
              </AdminLayout>
            }
          />
          <Route
            path="/admin/ship_rocket/order_details"
            element={
              <AdminLayout>
                <ShipRocketOrderDetails />
              </AdminLayout>
            }
          />
          <Route
            path="/admin/settings"
            element={
              <AdminLayout>
                <SettingsView />
              </AdminLayout>
            }
          />
          <Route
            path="/admin/user/profile"
            element={
              <AdminLayout>
                <UserProfileDetailsView />
              </AdminLayout>
            }
          />
          <Route
            path="/admin/manage_hsn_code"
            element={
              <AdminLayout>
                <ManageHSNView />
              </AdminLayout>
            }
          />
          <Route
            path="/admin/settings/manage_payment_gateways"
            element={
              <AdminLayout>
                <ManagePaymentsView />
              </AdminLayout>
            }
          />
          <Route
            path="/admin/manage_gallery"
            element={
              <AdminLayout>
                <ManageGalleryView />
              </AdminLayout>
            }
          />

          {/* user module routes  */}
          <Route
            path="/"
            element={
              <UserLayout>
                <HomePage />
              </UserLayout>
              // <Navigate to="/login" />
            }
          />
          <Route
            path="/gallery"
            element={
              <UserLayout>
                <GalleryPage />
              </UserLayout>
            }
          />
          <Route
            path="/favorite_products"
            element={
              <UserLayout>
                <FavoritesPage />
              </UserLayout>
            }
          />
          <Route
            path="/notification"
            element={
              <UserLayout>
                <NotificationPage />
              </UserLayout>
            }
          />
          <Route
            path="/cart"
            element={
              <UserLayout>
                <CartPage />
              </UserLayout>
            }
          />
          <Route
            path="/search"
            element={
              <UserLayout>
                <SearchPage />
              </UserLayout>
            }
          />
          <Route
            path="/profile"
            element={
              <UserLayout>
                <Profile />
              </UserLayout>
            }
          />
          <Route
            path="/products_category"
            element={
              <UserLayout>
                <ProductsCategory />
              </UserLayout>
            }
          />
          <Route
            path="/product_details"
            element={
              <UserLayout>
                <ProductDetails />
              </UserLayout>
            }
          />
          <Route
            path="/manage_address"
            element={
              <UserLayout>
                <ManageAddress />
              </UserLayout>
            }
          />
          <Route
            path="/manage_address/add_address"
            element={
              <UserLayout>
                <AddAddressPage />
              </UserLayout>
            }
          />
          <Route
            path="/manage_address/update_address"
            element={
              <UserLayout>
                <EditAddressPage />
              </UserLayout>
            }
          />
          <Route
            path="/manage_cart/make_payment"
            element={
              <UserLayout>
                <PaymentSelectionPage />
              </UserLayout>
            }
          />
          <Route
            path="/manage_cart/order_placed_successful"
            element={<OrderPlacedSuccessful />}
          />
          <Route
            path="/manage_orders"
            element={
              <UserLayout>
                <ManageOrders />
              </UserLayout>
            }
          />
          <Route
            path="/manage_orders/order_details"
            element={
              <UserLayout>
                <ManageOrderDetails />
              </UserLayout>
            }
          />
          <Route
            path="/profile/change_password"
            element={
              <UserLayout>
                <ChangePasssword />
              </UserLayout>
            }
          />
          <Route
            path="/privacy_policy"
            element={
              <UserLayout>
                <PrivacyPolicyPage />
              </UserLayout>
            }
          />
          <Route
            path="/terms_and_conditions"
            element={
              <UserLayout>
                <TermsAndConditions />
              </UserLayout>
            }
          />
          <Route
            path="/cancellation_and_refund"
            element={
              <UserLayout>
                <CancellationAndRefund />
              </UserLayout>
            }
          />
          <Route
            path="/shipping_and_delivery"
            element={
              <UserLayout>
                <ShippingAndDelivery />
              </UserLayout>
            }
          />
          <Route
            path="/contact_us"
            element={
              <UserLayout>
                <ContactUsPage />
              </UserLayout>
            }
          />
          <Route
            path="/about_us"
            element={
              <UserLayout>
                <AboutUsPage />
              </UserLayout>
            }
          />

          {/* not found page link */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <Toaster position="bottom-right" />
      </BrowserRouter>
    </CheckConnection>
  );
}

export default App;
