import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Button, Container } from "reactstrap";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import "./style.css";
import config from "../../../../config.json";
import { getServices, postServices } from "../../../../apiServices/apiServices";
import toast from "react-hot-toast";
import { MdOutlineLocalShipping } from "react-icons/md";
import MakeOrders from "../Modal/ShipRocket/MakeOrders";
import NoInternetComp from "../../InternetConnetction/NoInternetComp";

function OrderDetailsView() {
  const location = useLocation();
  const { id } = location.state;
  const [data, setData] = useState({
    address: [],
    orderData: [],
    order_details: [],
    user_details: [],
    paymentData: [],
  });
  const access_token = sessionStorage.getItem("access-token");
  const url = config.url;
  const [modal, setModal] = useState({
    make_order: false,
    shipRocketAccessToken: "",
  });

  useEffect(() => {
    if (navigator.onLine) {
      fetchOrderDetails();
      // authShipRocket();
    }
  }, [navigator]);

  const fetchOrderDetails = async () => {
    try {
      const res = await getServices(
        `/api/orders/order_details/${id}`,
        access_token
      );
      if (res.responseCode === 200) {
        setData({
          ...data,
          address: res.responseData[0]?.order_details?.addressInfo,
          user_details: res.responseData[0]?.order_details?.userInfo,
          order_details: res.responseData[0].order_details,
          orderData: res.responseData[0]?.data,
          paymentData: res.responseData[0].paymentData,
        });
      } else {
        setData({
          ...data,
          address: [],
          user_details: [],
          order_details: [],
          orderData: [],
          paymentData: [],
        });
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleProceedToShip = async () => {
    try {
      const res = await postServices(`/api/shiprocket/login`, access_token);
      if (res.responseCode === 200) {
        setModal({
          ...modal,
          make_order: true,
          shipRocketAccessToken: res.responseData.token,
        });
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <>
      {navigator.onLine ? (
        <Container className="p-8">
          <Container className="flex flex-col gap-1">
            <Container className="flex flex-row items-center justify-between">
              <Container className="flex flex-col sm:flex-row gap-4 items-center">
                <p className="font-semibold text-xl sm:text-2xl">
                  Order Details
                </p>
                <p
                  className="bg-[#22c55e] text-white rounded-md px-2 text-sm sm:text-base"
                  style={{
                    backgroundColor:
                      data?.order_details.orderStatus === 2
                        ? "#fda693d6"
                        : data?.order_details.orderStatus === 8
                        ? "#6149cd"
                        : data?.order_details.orderStatus === 6
                        ? "#ffab0029"
                        : "",
                    color:
                      data?.order_details.orderStatus === 8
                        ? "#fff"
                        : data?.order_details.orderStatus === 2
                        ? "#000"
                        : data?.order_details.orderStatus === 6
                        ? "#b76e00"
                        : "",
                  }}
                >
                  {data?.order_details.orderStatus === 2
                    ? "Processing"
                    : data?.order_details.orderStatus === 8
                    ? "Initiated to ShipRocket"
                    : data?.order_details.orderStatus === 6
                    ? "Cancelled"
                    : ""}
                </p>
              </Container>
              {data?.order_details.orderStatus === 2 && (
                <Button
                  onClick={handleProceedToShip}
                  className="bg-[#6149cd] flex items-center outline-none gap-3 text-white font-semibold py-2 px-4 rounded-lg  active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out"
                >
                  Proceed to Ship <MdOutlineLocalShipping className="text-xl" />
                </Button>
              )}
            </Container>
            <p className="text-base font-semibold">
              Track id: {data.order_details.track_id}
            </p>
            <p className="text-[#919eab]">
              {/* 23 Jun 2023 9:15 AM */}
              {data.order_details
                ? data.order_details.orderd_date +
                  " " +
                  data.order_details.orderd_time
                : null}
            </p>
          </Container>
          <Container className="order-cards">
            <Container className="order-first-card">
              <Container className="mt-6 md:mr-2 p-5 bg-white rounded-xl shadow-lg">
                <Container className="font-semibold">Details</Container>
                <SimpleBar className="mt-5">
                  <Container className="grid grid-cols-5 items-center min-w-[640px] mt-4 mb-4"></Container>
                  {data.orderData.map((product, i) => (
                    <Container key={i}>
                      <Container className="grid grid-cols-5 items-center min-w-[640px] mt-4 mb-4">
                        <Container className="col-span-3 flex items-center gap-4">
                          <img
                            src={
                              url +
                              product?.cartInfo?.productInfo?.variationInfo
                                ?.image[0]
                            }
                            alt="product"
                            className="rounded-lg h-[50px] w-[70px] object-cover"
                          />
                          <Container>
                            <Link
                              to="/admin/product/detail"
                              state={{
                                product_id:
                                  product?.cartInfo?.productInfo?.product_id,
                              }}
                            >
                              <p className="font-semibold text-[0.9]">
                                {product?.cartInfo?.productInfo?.product_name}
                              </p>
                            </Link>
                            <p className="text-[#919eab] text-[0.9] flex items-center gap-2">
                              <span>
                                {
                                  product.cartInfo?.productInfo?.colorInfo
                                    ?.color
                                }
                              </span>
                              <span>
                                {
                                  product?.cartInfo?.productInfo?.variationInfo
                                    ?.variation
                                }{" "}
                                inch
                              </span>
                            </p>
                          </Container>
                        </Container>
                        <Container className="text-right">
                          x{product?.cartInfo?.product_qty}
                        </Container>
                        <Container className="col-span-1 text-right">
                          ₹ {product?.cartInfo?.totalAmount}.00
                        </Container>
                      </Container>
                      <hr className="border-dashed" />
                    </Container>
                  ))}
                </SimpleBar>
                <Container className="flex flex-col gap-4 items-end my-6 text-right">
                  <Container className="flex flex-row">
                    <p className="text-[#637381]">Total MRP</p>
                    <p className="w-40 font-semibold">
                      ₹{" "}
                      {data?.order_details?.totalMrp -
                        data?.order_details?.discountAmt}
                    </p>
                  </Container>
                  <Container className="flex flex-row">
                    <p className="text-[#637381]">Shipping Fees</p>
                    <p className="w-40 font-semibold">
                      ₹ {data?.order_details?.shippingFee}
                    </p>
                  </Container>
                  <Container className="flex flex-row">
                    <p className="text-[#637381]">Total</p>
                    <p className="w-40 font-semibold">
                      ₹ {data?.order_details?.totalAmount}
                    </p>
                  </Container>
                </Container>
              </Container>
              <Container className="mt-6 md:mr-2 p-5 bg-white rounded-xl shadow-lg">
                <Container className="font-semibold">Courier Details</Container>
                <SimpleBar className="mt-5">
                  <Container className="flex gap-3 flex-col">
                    <p className="flex justify-between items-center">
                      Courier company id:{" "}
                      <span className="font-semibold text-right">
                        {data?.order_details?.courier_company_id}
                      </span>
                    </p>
                    <p className="flex justify-between">
                      Courier company name:{" "}
                      <span className="font-semibold text-right">
                        {data?.order_details?.courier_name}
                      </span>
                    </p>
                    <p className="flex justify-between">
                      Product total weight:{" "}
                      <span className="font-semibold text-right">
                        {data?.order_details?.total_weight} kg
                      </span>
                    </p>
                  </Container>
                </SimpleBar>
              </Container>
            </Container>
            <Container className="order-second-card">
              <Container className=" mt-6 md:mx-2 p-5 bg-white rounded-xl shadow-lg">
                <Container>
                  <p className="font-semibold">Customer Info</p>
                  <Container>
                    <Container className="flex flex-col my-4 gap-1">
                      <p className="text-sm font-semibold">
                        {data.address && data.address
                          ? data.address.name
                          : null}
                      </p>
                      <p className="text-sm text-[#637381]">
                        {data.user_details && data.user_details
                          ? data.user_details.email
                          : null}
                      </p>
                      <p className="text-sm text-[#637381]">
                        {data.user_details && data.user_details
                          ? data.user_details.phone
                          : null}
                      </p>
                      {data.order_details.cust_gstin && (
                        <p className="text-sm text-[#637381]">
                          GSTIN:{" "}
                          {data.order_details && data.order_details
                            ? data.order_details.cust_gstin
                            : null}
                        </p>
                      )}
                      {data.order_details.cust_business_name && (
                        <p className="text-sm text-[#637381]">
                          Business Name:{" "}
                          {data.order_details && data.order_details
                            ? data.order_details.cust_business_name
                            : null}
                        </p>
                      )}
                    </Container>
                    <hr className="border-dashed" />
                  </Container>
                  <Container>
                    <p className="font-semibold mt-4">Shipping</p>
                    <Container className="flex flex-col my-4 gap-1">
                      <Container className="flex flex-row">
                        <p className="min-w-[160px] text-[#637381e3]">
                          Phone Number
                        </p>
                        <p>
                          {data.address && data.address
                            ? data.address.phone_no
                            : null}
                        </p>
                      </Container>
                      <Container className="flex flex-row">
                        <p className="min-w-[160px] text-[#637381e3]">Area</p>
                        <p>
                          {data.address && data.address
                            ? data.address.area
                            : null}
                        </p>
                      </Container>
                      <Container className="flex flex-row">
                        <p className="min-w-[160px] text-[#637381e3]">Town</p>
                        <p>
                          {data.address && data.address
                            ? data.address.town
                            : null}
                        </p>
                      </Container>
                      <Container className="flex flex-row">
                        <p className="min-w-[160px] text-[#637381e3]">City</p>
                        <p>
                          {data.address && data.address
                            ? data.address.city
                            : null}
                        </p>
                      </Container>
                      <Container className="flex flex-row">
                        <p className="min-w-[160px] text-[#637381e3]">State</p>
                        <p>
                          {data.address && data.address
                            ? data.address.state
                            : null}
                        </p>
                      </Container>
                      <Container className="flex flex-row">
                        <p className="min-w-[160px] text-[#637381e3]">
                          Address
                        </p>
                        <p>
                          {data.address && data.address
                            ? data.address.save_address
                            : null}
                        </p>
                      </Container>
                      <Container className="flex flex-row">
                        <p className="min-w-[160px] text-[#637381e3]">
                          Pin Code
                        </p>
                        <p>
                          {data.address && data.address
                            ? data.address.pincode
                            : null}
                        </p>
                      </Container>
                    </Container>
                    <hr className="border-dashed" />
                  </Container>
                  <Container>
                    <p className="font-semibold mt-4">Payment</p>
                    <Container className="flex flex-col my-4 gap-1">
                      <Container className="flex flex-row justify-between items-center">
                        <p className="min-w-[160px] text-[#637381e3]">
                          Payment Method
                        </p>
                        <p>
                          {data.order_details &&
                          data.order_details.payment_method === 1
                            ? "COD"
                            : "Online Payment"}
                        </p>
                      </Container>
                      {data.paymentData[0]?.transaction_at && (
                        <Container className="flex flex-row justify-between items-center">
                          <p className="min-w-[160px] text-[#637381e3]">
                            Payment at
                          </p>
                          <p>{data.paymentData[0]?.transaction_at}</p>
                        </Container>
                      )}
                      {data.paymentData[0]?.razorpay_payment_id && (
                        <Container className="flex flex-row justify-between items-center">
                          <p className="min-w-[160px] text-[#637381e3]">
                            Payment Id
                          </p>
                          <p>{data.paymentData[0]?.razorpay_payment_id}</p>
                        </Container>
                      )}
                      {data.paymentData[0]?.gateway_type && (
                        <Container className="flex flex-row justify-between items-center">
                          <p className="min-w-[160px] text-[#637381e3]">
                            Payment Gateway type
                          </p>
                          <p>
                            {data.paymentData[0]?.gateway_type === "rp"
                              ? "Razorpay"
                              : data.paymentData[0]?.gateway_type === "pp"
                              ? "PhonePe"
                              : ""}
                          </p>
                        </Container>
                      )}
                      {data.paymentData[0]?.state && (
                        <Container className="flex flex-row justify-between items-center">
                          <p className="min-w-[160px] text-[#637381e3]">
                            Payment Status
                          </p>
                          <p>{data.paymentData[0]?.state}</p>
                        </Container>
                      )}
                    </Container>
                    <hr className="border-dashed" />
                  </Container>
                  {/* <Container>
                <p className="font-semibold mt-4">Delivery Date/Time</p>
                <Container className="flex flex-col my-4 gap-1">
                  <Container className="flex flex-row justify-between items-center">
                    <p className="min-w-[160px] text-[#637381e3]">
                      Delivery Date
                    </p>
                    <p>
                      {data.order_details && data.order_details.delivery_date}
                    </p>
                  </Container>
                  <Container className="flex flex-row justify-between items-center">
                    <p className="min-w-[160px] text-[#637381e3]">
                      Delivery Time
                    </p>
                    <p>
                      {data.order_details && data.order_details.delivery_time}
                    </p>
                  </Container>
                </Container>
                <hr className="border-dashed" />
              </Container> */}
                </Container>
              </Container>
            </Container>
          </Container>

          <MakeOrders
            open={modal.make_order}
            onClose={() => setModal({ ...modal, make_order: false })}
            shipRocketAccessToken={modal.shipRocketAccessToken}
            orderData={data.order_details}
            fetchOrderDetails={fetchOrderDetails}
          />
        </Container>
      ) : (
        <Container className="min-h-[65vh] flex flex-col justify-center items-center">
          <NoInternetComp />
        </Container>
      )}
    </>
  );
}

export default OrderDetailsView;
