import { Backdrop, Box, Fade, Modal } from "@mui/material";
import React from "react";
import { IoClose } from "react-icons/io5";
import CustomInputField from "../../common/CustomInputField";
import { Container, Form, Input } from "reactstrap";

function UpdateColor(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    padding: "15px 20px",
    outline: "none",
    borderRadius: "15px",
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        // onClose={props.onClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={props.open}>
          <Box sx={style}>
            <div className="flex justify-end items-center mb-2">
              <IoClose
                className="cursor-pointer border border-[#f46565] rounded-full"
                onClick={props.onClose}
              />
            </div>
            <Form
              onSubmit={props.handleColorSubmit}
              className="mt-3 w-[300px] sm:w-[400px] flex flex-col gap-3"
            >
              <CustomInputField
                name="color"
                label="Color"
                type="text"
                value={props.colorUpdateData.color_name}
                onChange={(e) =>
                  props.setColorUpdateData({
                    ...props.colorUpdateData,
                    color_name: e.target.value,
                  })
                }
                required={true}
              />
              <Container className="flex items-center justify-end">
                {props.isLoading ? (
                  <Input
                    type="button"
                    className="bg-[#ff7580] text-white rounded-lg font-semibold py-2 px-4 cursor-not-allowed"
                    value="Loading..."
                  />
                ) : (
                  <Input
                    type="Submit"
                    value="Update"
                    readOnly
                    className="active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out bg-slate-800 text-white text-lg font-semibold py-2 px-4 rounded-xl cursor-pointer"
                  />
                )}
              </Container>
            </Form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default UpdateColor;
