import { Rating, TextField } from "@mui/material";
import React, { useState } from "react";
import { Button, Container, Form } from "reactstrap";
import StarIcon from "@mui/icons-material/Star";
import toast from "react-hot-toast";
import {
  postServices,
  updateServices,
} from "../../../../../apiServices/apiServices";

function RatingsForm(props) {
  const [editData, setEditData] = useState({
    star: props.data.data.length > 0 ? props.data.data[0].star : null,
    review: props.data.data.length > 0 ? props.data.data[0].review : null,
  });
  const access_token = localStorage.getItem("user-access-token");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (props.data.data.length > 0) {
      if (editData.star >= 1) {
        try {
          const res = await updateServices(
            `/api/review/update_review/${props.data.review_id}`,
            access_token,
            editData
          );
          if (res.responseCode === 200) {
            props.onClose();
          } else {
            toast.error(res.responseMessage);
          }
        } catch (error) {
          toast.error(error);
        }
      } else {
        alert("Please select a star rating before submitting your review.");
      }
    } else {
      if (editData.star >= 1) {
        try {
          const res = await postServices(
            `/api/review/add_review/${props.data.productInfo.product_id}`,
            access_token,
            editData
          );
          if (res.responseCode === 200) {
            props.onClose();
          } else {
            toast.error(res.responseMessage);
          }
        } catch (error) {
          toast.error(error);
        }
      } else {
        alert("Please select a star rating before submitting your review.");
      }
    }
  };

  return (
    <Container className="mx-4 mb-4">
      <Form onSubmit={handleSubmit}>
        <Container className="flex justify-center items-center">
          <Rating
            name="half-rating-read"
            value={editData.star}
            precision={1}
            sx={{ color: "#faaf00" }}
            emptyIcon={
              <StarIcon
                style={{ opacity: 0.55, width: "52px", height: "52px" }}
              />
            }
            icon={<StarIcon style={{ width: "52px", height: "52px" }} />}
            className="mr-3"
            size="large"
            onChange={(e, newValue) =>
              setEditData({ ...editData, star: newValue })
            }
          />
        </Container>
        <Container>
          <TextField
            fullWidth
            label="Enter your Review"
            id="fullWidth"
            multiline
            minRows={3}
            maxRows={3}
            sx={{
              "& .MuiInputLabel-root": {
                fontFamily: "Poppins",
              },
              "& .MuiInputLabel-root": {
                fontFamily: "Poppins",
              },
              "& .MuiOutlinedInput-root": {
                fontFamily: "Poppins",
                borderRadius: "12px",
              },
              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#352210",
                },
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#352210",
                },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #352210",
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "#352210",
              },
              backgroundColor: "white",
              marginTop: "15px",
            }}
            value={editData.review === null ? "" : editData.review}
            onChange={(e) =>
              setEditData({ ...editData, review: e.target.value })
            }
            required
          />
          <Container className="flex justify-end mt-4">
            <Button
              type="submit"
              className="bg-black text-white font-semibold py-2 px-4 rounded-lg active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out"
            >
              {props.data.data.length > 0 ? "Update Review" : "Submit"}
            </Button>
          </Container>
        </Container>
      </Form>
    </Container>
  );
}

export default RatingsForm;
