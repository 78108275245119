import React, { useEffect, useState } from "react";
import { Container, Button } from "reactstrap";
import NoInternetComp from "../../InternetConnetction/NoInternetComp";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Backdrop,
  Box,
  Fade,
  Modal,
} from "@mui/material";
import toast from "react-hot-toast";
import {
  getServices,
  updateServices,
} from "../../../../apiServices/apiServices";
import { IoClose } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

function SettingsView() {
  const access_token = sessionStorage.getItem("access-token");
  const [gatewayType, setGatewayType] = useState({});
  const [modal, setModal] = useState({ updatePaymentGateway: false });
  const [remainingSeconds, setRemainingSeconds] = useState(15);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const navigate = useNavigate();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    padding: "15px 20px",
    outline: "none",
    borderRadius: "15px",
  };

  useEffect(() => {
    let timer;

    if (modal.updatePaymentGateway) {
      if (remainingSeconds > 0 && isButtonDisabled) {
        timer = setTimeout(() => {
          setRemainingSeconds(remainingSeconds - 1);
        }, 1000);
      } else {
        setIsButtonDisabled(false);
      }
    }

    return () => {
      clearTimeout(timer);
    };
  }, [
    navigator,
    remainingSeconds,
    isButtonDisabled,
    modal.updatePaymentGateway,
  ]);

  useEffect(() => {
    if (navigator.onLine) {
      fetchGatewayData();
    }
  }, []);

  const fetchGatewayData = async () => {
    try {
      const res = await getServices(`/api/manage/gateway/view`, access_token);
      if (res.responseCode === 200) {
        setGatewayType(res.responseData);
      } else {
        setGatewayType({});
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleUpdateModal = () => {
    setModal({ ...modal, updatePaymentGateway: true });
    setRemainingSeconds(15);
    setIsButtonDisabled(true);
  };

  const handleUpdatePaymentGateway = async () => {
    try {
      const res = await updateServices(
        `/api/manage/gateway/change/${gatewayType.gateway_id}`,
        access_token,
        { gateway_type: gatewayType.gateway_type }
      );
      if (res.responseCode === 200) {
        fetchGatewayData();
        setModal({ ...modal, updatePaymentGateway: false });
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <>
      {navigator.onLine ? (
        <Container>
          <Container className="flex flex-col sm:flex-row justify-between mx-8 py-5">
            <p className="text-lg sm:text-2xl font-semibold">
              Switch Payment Gateways
            </p>
            <Button
              onClick={() =>
                navigate("/admin/settings/manage_payment_gateways")
              }
              className="bg-slate-800 flex items-center px-7 py-2 text-white font-semibold rounded-xl outline-none border-none active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out"
            >
              Manage Gateways
            </Button>
          </Container>
          <Container className="mx-10 mt-5 sm:mt-0">
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={gatewayType.gateway_type ?? ""}
                onChange={(e) =>
                  setGatewayType({
                    ...gatewayType,
                    gateway_type: e.target.value,
                  })
                }
              >
                <FormControlLabel
                  value="pp"
                  control={
                    <Radio
                      sx={{
                        "&, &.Mui-checked": {
                          color: "#352210",
                        },
                      }}
                    />
                  }
                  label="PhonePe"
                  sx={{
                    ".MuiFormControlLabel-root .MuiFormControlLabel-label, .MuiTypography-root":
                      { fontFamily: "Poppins" },
                  }}
                />
                <FormControlLabel
                  value="rp"
                  control={
                    <Radio
                      sx={{
                        "&, &.Mui-checked": {
                          color: "#352210",
                        },
                      }}
                    />
                  }
                  label="Razorpay"
                  sx={{
                    ".MuiFormControlLabel-root .MuiFormControlLabel-label, .MuiTypography-root":
                      { fontFamily: "Poppins" },
                  }}
                />
              </RadioGroup>
            </FormControl>
          </Container>
          <Container className="mx-10 mt-5 sm:mt-0 flex justify-end items-center">
            <Button
              onClick={() => handleUpdateModal()}
              className="bg-slate-800 flex items-center px-7 py-2 text-white font-semibold rounded-xl outline-none border-none active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out"
            >
              Update
            </Button>
          </Container>
        </Container>
      ) : (
        <Container className="min-h-[65vh] flex flex-col justify-center items-center">
          <NoInternetComp />
        </Container>
      )}

      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={modal.updatePaymentGateway}
          // onClose={props.onClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={modal.updatePaymentGateway}>
            <Box sx={style}>
              <div className="flex justify-between items-center mb-2">
                <p className="font-semibold">Update Payment Gateway</p>
                <IoClose
                  className="cursor-pointer border border-[#f46565] rounded-full"
                  onClick={() =>
                    setModal({ ...modal, updatePaymentGateway: false })
                  }
                />
              </div>
              <p className="text-center p-2">
                Are you sure you want to update the <br /> payment gateway?
              </p>
              <Container className="flex items-center justify-between mt-3">
                <Button
                  onClick={() =>
                    setModal({ ...modal, updatePaymentGateway: false })
                  }
                  className="bg-[#f5f5f7] font-semibold text-[#394650] px-4 rounded-full py-2 active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out"
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleUpdatePaymentGateway}
                  disabled={isButtonDisabled}
                  className="bg-[#ff3f56] font-semibold text-white px-4 rounded-full py-2 active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out disabled:bg-slate-500 disabled:cursor-not-allowed"
                >
                  {remainingSeconds > 0
                    ? `Wait (${remainingSeconds})`
                    : "Update"}
                </Button>
              </Container>
            </Box>
          </Fade>
        </Modal>
      </div>
    </>
  );
}

export default SettingsView;
