import * as React from "react";
import { Box } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { TableHead } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { Container } from "reactstrap";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import config from "../../../config.json";
import MenuButton from "../Admin/common/MenuButton";
import DeleteModal from "../Admin/common/DeleteModal";
import { removeServices } from "../../../apiServices/apiServices";
import toast from "react-hot-toast";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator() {
  return (a, b) => -descendingComparator(a, b);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "product_name",
    numeric: false,
    disablePadding: true,
    label: "Product Name",
  },
  {
    id: "quantity",
    numeric: true,
    disablePadding: false,
    label: "Quantity",
  },
  {
    id: "price",
    numeric: true,
    disablePadding: false,
    label: "Price",
  },
  // {
  //   id: "stock",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Stock",
  // },
  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    label: "Actions",
  },
];

function EnhancedTableHead() {
  return (
    <TableHead sx={{ backgroundColor: "#f7e1a3" }}>
      <TableRow>
        <TableCell padding="checkbox" />
        {headCells.map((headCell) => (
          <TableCell
            sx={{
              fontFamily: "poppins",
            }}
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            <TableSortLabel>{headCell.label}</TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function EnhancedTable(props) {
  const [order] = React.useState("asc");
  const [orderBy] = React.useState("id");
  const [page, setPage] = React.useState(Number(sessionStorage.getItem("activeProductPage")) || 0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const navigate = useNavigate();
  const url = config.url;
  const [deleteModal, setDeleteModal] = useState({ isOpen: false, data: null });
  const access_token = sessionStorage.getItem("access-token");

  // React.useEffect(() => {
  //   sessionStorage.setItem("activeProductPage", page);
  // }, [page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleImageEditModal = (data) => {
    navigate("/admin/product/edit", {
      state: {
        data: data,
        is_update_colors: 1,
      },
    });
  };

  const handleDeleteModal = (data) => {
    setDeleteModal({ ...deleteModal, isOpen: !deleteModal.isOpen, data: data });
  };

  const handleDelete = async (id) => {
    try {
      const res = await removeServices(
        `/api/products/delete_products/${id}`,
        access_token
      );
      if (res.responseCode === 200) {
        props.fetchProductData();
        toast.success("Product deleted...");
        setDeleteModal({ ...deleteModal, isOpen: !deleteModal.isOpen });
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleEditDetails = (data) => {
    navigate("/admin/product/edit", {
      state: {
        data: data,
        is_update_colors: 0,
      },
    });
  };

  const handleSizeUpdate = (data) => {
    navigate("/admin/product/edit", {
      state: {
        data: data,
        is_update_colors: 2,
      },
    });
  };

  return (
    <Box sx={{ width: "100%" }}>
      {props.data ? (
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <EnhancedTableHead />
              <TableBody>
                {props.data &&
                stableSort(props.data, getComparator(order, orderBy)) &&
                Array.isArray(
                  stableSort(props.data, getComparator(order, orderBy))
                )
                  ? stableSort(props.data, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.product_id}
                            // sx={{ cursor: "pointer" }}
                          >
                            <TableCell padding="checkbox" />
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                              sx={{
                                fontFamily: "poppins",
                              }}
                            >
                              <Container className="flex items-center my-4">
                                <img
                                  src={
                                    url +
                                    row.productColorInfo[0]?.productDataInfo[0]
                                      ?.image[0]
                                  }
                                  alt="products"
                                  className="h-16 w-16 rounded-lg object-cover"
                                />{" "}
                                <Link
                                  to={"/admin/product/detail"}
                                  state={{ product_id: row.product_id }}
                                >
                                  <p className="ml-2 font-semibold text-slate-600 cursor-pointer hover:underline capitalize">
                                    {row.product_name.length >= 20
                                      ? row.product_name.substring(0, 17) +
                                        "..."
                                      : row.product_name}
                                  </p>
                                </Link>
                              </Container>
                            </TableCell>
                            <TableCell
                              align="right"
                              sx={{
                                fontFamily: "poppins",
                              }}
                            >
                              x{" "}
                              {row.productColorInfo[0]?.productDataInfo[0]
                                ?.quantity ?? 50}
                            </TableCell>
                            <TableCell
                              align="right"
                              sx={{
                                fontFamily: "poppins",
                              }}
                            >
                              {row.productColorInfo[0]?.productDataInfo[0]
                                ?.sales_price > 0
                                ? "₹" +
                                  row.productColorInfo[0]?.productDataInfo[0]
                                    ?.sales_price +
                                  ".00"
                                : "₹" +
                                  row.productColorInfo[0]?.productDataInfo[0]
                                    ?.price +
                                  ".00"}
                            </TableCell>
                            {/* <TableCell
                              align="center"
                              sx={{
                                fontFamily: "poppins",
                              }}
                            >
                              <div
                                className="w-[110px] my-0 ml-auto mr-0 rounded-lg py-1 font-[500]"
                                style={{
                                  backgroundColor:
                                    row.is_stock === 1 ? "#22c55e" : "#ff5630",
                                  color: "#fff",
                                }}
                              >
                                {row.is_stock === 1
                                  ? "In Stock"
                                  : "Out of Stock"}
                              </div>
                            </TableCell> */}
                            <TableCell
                              sx={{
                                fontFamily: "poppins",
                              }}
                              align="right"
                            >
                              <Container className="flex justify-end items-center">
                                <MenuButton
                                  editDetails={() => handleEditDetails(row)}
                                  // deleteClick={() => handleDelete(row.product_id)}
                                  deleteClick={() => handleDeleteModal(row)}
                                  imageEditModal={() =>
                                    handleImageEditModal(row)
                                  }
                                  sizeUpdate={() => handleSizeUpdate(row)}
                                />
                              </Container>
                            </TableCell>
                          </TableRow>
                        );
                      })
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={props.data?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              "& .MuiTablePagination-selectLabel": {
                fontFamily: "Poppins",
              },
              "& .MuiTablePagination-select": {
                fontFamily: "Poppins",
              },
              "& .MuiTablePagination-displayedRows": {
                fontFamily: "Poppins",
              },
            }}
          />
        </Paper>
      ) : (
        <Container className="flex justify-center font-semibold">
          <p>No products found...</p>
        </Container>
      )}

      <DeleteModal
        isOpen={deleteModal}
        name={deleteModal?.data?.product_name}
        id={deleteModal?.data?.product_id}
        handleDeleteModal={handleDeleteModal}
        title={"Product"}
        handleDelete={handleDelete}
      />
    </Box>
  );
}
