import { Backdrop, Box, Fade, Modal, TextField } from "@mui/material";
import React from "react";
import { IoClose } from "react-icons/io5";
import { Button, Container, Form } from "reactstrap";

function AdminHSNModal(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    padding: "15px 20px",
    outline: "none",
    borderRadius: "15px",
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        // onClose={props.onClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={props.open}>
          <Box sx={style}>
            <div className="flex justify-between items-center">
              <p className="font-semibold">
                {props.modal?.edit ? "Update" : "Add"} HSN Code
              </p>
              <IoClose
                className="cursor-pointer border border-[#000] rounded-full"
                onClick={props.onClose}
              />
            </div>
            <Form
              onSubmit={
                props.modal?.edit
                  ? props.handleUpdateHsnCodeSubmit
                  : props.handleAddHsnCodeSubmit
              }
              className="mt-3 w-[300px] sm:w-[400px] flex flex-col gap-3"
            >
              <Container className="flex flex-col gap-3">
                <TextField
                  fullWidth
                  name="hsn_code"
                  label="HSN Code"
                  required={true}
                  value={props.hsnData.hsn_no}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  }}
                  onChange={(e) =>
                    props.setHsnData({
                      ...props.hsnData,
                      hsn_no: e.target.value,
                    })
                  }
                  inputProps={{ maxLength: 10 }}
                  sx={{
                    "& .MuiInputLabel-root": {
                      fontFamily: "Poppins",
                    },
                    "& .MuiOutlinedInput-root": {
                      fontFamily: "Poppins",
                      borderRadius: "12px",
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#352210",
                      },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#352210",
                      },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #352210",
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#352210",
                    },
                  }}
                />
              </Container>
              <Container className="flex justify-end items-center">
                <Button
                  type="submit"
                  className="active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out bg-slate-800 text-white text-lg font-semibold py-2 px-6 rounded-xl cursor-pointer"
                >
                  {props.modal?.edit ? "Update" : "Save"}
                </Button>
              </Container>
            </Form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default AdminHSNModal;
