import React, { useState } from "react";
import { Button, Container } from "reactstrap";
import CategoryTable from "../../tables/CategoryTable";
import CategoryModal from "../Modal/Category/CategoryAddModal";
import { HiPlus } from "react-icons/hi";
import { useEffect } from "react";
import { getServices, postServices } from "../../../../apiServices/apiServices";
import toast from "react-hot-toast";
import NoInternetComp from "../../InternetConnetction/NoInternetComp";

function ManageCategoryView() {
  const [isModalShow, setIsModalShow] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [data, setData] = useState({ name: "", image: selectedImages });
  const access_token = sessionStorage.getItem("access-token");
  const [isData, setIsData] = useState([]);

  useEffect(() => {
    if (navigator.onLine) {
      fetchCategoryList();
    }
  }, [navigator]);

  const fetchCategoryList = async () => {
    try {
      const res = await getServices(`/api/category/list_category`);
      setIsData(res.responseData);
    } catch (error) {
      toast.error(error);
    }
  };

  const handleAddModal = () => {
    setIsModalShow(true);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    handleImageUpload(files);

    setData({ ...data, image: files[0] });
  };

  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files);
    handleImageUpload(files);
    setData({ ...data, image: event.target.files[0] });
  };

  const handleImageUpload = (files) => {
    // Perform any necessary image upload logic here
    const selected = [...selectedImages];

    for (const file of files) {
      selected.push(URL.createObjectURL(file));
    }

    setSelectedImages(selected);
  };

  const handleRemoveImage = (index) => {
    setSelectedImages((prevImages) => {
      const updatedImages = prevImages.filter((_, i) => i !== index);
      return updatedImages;
    });
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleChange = (e) => {
    setData({ ...data, name: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("category_name", data.name);
    formData.append("image", data.image);

    try {
      const res = await postServices(
        `/api/category/add_category`,
        access_token,
        formData
      );
      if (res.responseCode === 200) {
        toast.success("Category added successfully...");
        setIsModalShow(false);
        fetchCategoryList();
        setData({ ...data, name: "", image: [] });
        setSelectedImages([]);
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <>
      {navigator.onLine ? (
        <Container>
          <Container className="flex justify-between items-center mx-0 p-8">
            <p className="text-2xl font-semibold">Manage Category</p>
            <Button
              onClick={handleAddModal}
              className="bg-slate-800 flex items-center px-7 py-2 text-white font-semibold rounded-xl outline-none border-none active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out"
            >
              <HiPlus className="mr-2" />
              Add Category
            </Button>
          </Container>
          <Container className="mx-8 mt-5 sm:mt-0">
            {isData.length > 0 ? (
              <CategoryTable
                isData={isData}
                fetchCategoryList={fetchCategoryList}
              />
            ) : (
              <Container className="flex justify-center items-center">
                <p className="font-semibold">No Category Found...</p>
              </Container>
            )}
          </Container>

          <CategoryModal
            isModalShow={isModalShow}
            onClose={() => setIsModalShow(false)}
            data={data}
            handleDrop={handleDrop}
            handleDragOver={handleDragOver}
            handleSubmit={handleSubmit}
            handleChange={handleChange}
            handleFileSelect={handleFileSelect}
            handleRemoveImage={handleRemoveImage}
            selectedImages={selectedImages}
          />
        </Container>
      ) : (
        <Container className="min-h-[65vh] flex flex-col justify-center items-center">
          <NoInternetComp />
        </Container>
      )}
    </>
  );
}

export default ManageCategoryView;
