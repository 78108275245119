import axios from "axios";
import config from "../config.json";
import toast from "react-hot-toast";

const baseurl = config.url;

const updateServices = async (url, access_token, data) => {
  try {
    const response = await axios.put(`${baseurl}${url}`, data, {
      headers: { authorization: access_token },
    });
    return response.data;
  } catch (error) {
    toast.error(error.message);
  }
};

const removeServices = async (url, access_token) => {
  try {
    const response = await axios.delete(`${baseurl}${url}`, {
      headers: { authorization: access_token },
    });
    return response.data;
  } catch (error) {
    toast.error(error.message);
  }
};

const postServices = async (
  url,
  access_token,
  data,
  shipRocket_access_token
) => {
  try {
    const response = await axios.post(`${baseurl}${url}`, data, {
      headers: {
        authorization: access_token,
        shipauthorization: shipRocket_access_token,
      },
    });
    return response.data;
  } catch (error) {
    toast.error(error.message);
  }
};

const getServices = async (url, access_token, shipRocket_access_token) => {
  try {
    const response = await axios.get(`${baseurl}${url}`, {
      headers: {
        authorization: access_token,
        shipauthorization: shipRocket_access_token,
      },
    });
    return response.data;
  } catch (error) {
    toast.error(error.message);
  }
};

const removeParticularImage = async (url, access_token, data) => {
  try {
    const response = await axios.put(`${baseurl}${url}`, data, {
      headers: { authorization: access_token },
    });
    return response.data;
  } catch (error) {
    toast.error(error.message);
  }
};

export {
  updateServices,
  removeServices,
  postServices,
  getServices,
  removeParticularImage,
};
