import React, { useEffect, useState } from "react";
import { Button, Container } from "reactstrap";
import LoadingSpinner from "../common/LoadingSpinner";
import NoInternetComp from "../../InternetConnetction/NoInternetComp";
import { HiPlus } from "react-icons/hi";
import toast from "react-hot-toast";
import {
  getServices,
  postServices,
  removeServices,
  updateServices,
} from "../../../../apiServices/apiServices";
import { MdOutlineDelete, MdOutlineEdit } from "react-icons/md";
import AdminHSNModal from "../Modal/HSNCode/AdminHSNModal";

function ManageHSNView() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const access_token = sessionStorage.getItem("access-token");
  const [modal, setModal] = useState({ add: false, edit: false });
  const [hsnData, setHsnData] = useState({ hsn_no: "" });

  useEffect(() => {
    if (navigator.onLine) {
      fetchHSNCodeData();
    }
  }, [navigator]);

  const fetchHSNCodeData = async () => {
    setLoading(true);
    try {
      const res = await getServices(`/api/manage/hsn/list`, access_token);
      setLoading(false);
      if (res.responseCode === 200) {
        setData(res.responseData);
      } else {
        setData([]);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  const handleAddHSNModal = () => {
    setModal({ ...modal, add: true });
    setHsnData({ ...hsnData, hsn_no: "" });
  };

  const handleAddHsnCodeSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await postServices(
        `/api/manage/hsn/add`,
        access_token,
        hsnData
      );
      if (res.responseCode === 200) {
        setModal({ ...modal, add: false });
        fetchHSNCodeData();
        toast.success("HSN Code added...");
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleUpdateHSNModal = (hsn_data) => {
    setModal({ ...modal, edit: true });
    setHsnData({
      ...hsnData,
      hsn_no: hsn_data.hsn_number,
      hsn_id: hsn_data.hsn_id,
    });
  };

  const handleUpdateHsnCodeSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await updateServices(
        `/api/manage/hsn/update/${hsnData.hsn_id}`,
        access_token,
        { hsn_no: hsnData.hsn_no }
      );
      if (res.responseCode === 200) {
        toast.success("HSN Code updated...");
        setModal({ ...modal, edit: false });
        fetchHSNCodeData();
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleDeleteHSN = async (hsn_data) => {
    if (window.confirm("Do you want delete this Code?")) {
      try {
        const res = await removeServices(
          `/api/manage/hsn/delete/${hsn_data.hsn_id}`,
          access_token
        );
        if (res.responseCode === 200) {
          toast.success("HSN Code deleted...");
          fetchHSNCodeData();
        } else {
          toast.error(res.responseMessage);
        }
      } catch (error) {
        toast.error(error);
      }
    }
  };

  return (
    <>
      {navigator.onLine ? (
        <Container>
          {loading ? (
            <Container className="flex items-center justify-center mt-52">
              <LoadingSpinner />
            </Container>
          ) : (
            <Container>
              <Container className="flex justify-between items-center mx-0 md:mx-0 p-4 sm:p-8">
                <p className="text-xl sm:text-2xl font-semibold">
                  Manage HSN Code
                </p>
                <Button
                  onClick={handleAddHSNModal}
                  className="bg-slate-800 flex items-center px-2 sm:px-7 py-2 text-white font-semibold rounded-xl outline-none border-none active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out"
                >
                  <HiPlus className="mr-2" /> Add HSN Code
                </Button>
              </Container>

              {data?.length > 0 ? (
                <Container className="mx-4 sm:mx-8">
                  <Container className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 gap-3">
                    {data.map((item, i) => (
                      <Container
                        key={i}
                        className="flex justify-between items-center px-3 py-2 bg-white rounded-lg drop-shadow-sm hover:drop-shadow-lg transition-all"
                      >
                        <p className="font-semibold">{item.hsn_number}</p>
                        <Container className="flex items-center gap-3">
                          <MdOutlineEdit
                            onClick={() => handleUpdateHSNModal(item)}
                            className="rounded-full w-5 h-5 cursor-pointer"
                          />
                          <MdOutlineDelete
                            onClick={() => handleDeleteHSN(item)}
                            className="h-5 w-5 rounded-full cursor-pointer"
                          />
                        </Container>
                      </Container>
                    ))}
                  </Container>
                </Container>
              ) : (
                <Container className="text-center mx-8 mt-5 sm:mt-5">
                  <p className="font-semibold">No data found!!!</p>
                </Container>
              )}
            </Container>
          )}
        </Container>
      ) : (
        <Container className="min-h-[65vh] flex flex-col justify-center items-center">
          <NoInternetComp />
        </Container>
      )}

      <AdminHSNModal
        open={modal.add}
        onClose={() => setModal({ ...modal, add: false })}
        hsnData={hsnData}
        setHsnData={setHsnData}
        handleAddHsnCodeSubmit={handleAddHsnCodeSubmit}
      />
      <AdminHSNModal
        open={modal.edit}
        onClose={() => setModal({ ...modal, edit: false })}
        hsnData={hsnData}
        setHsnData={setHsnData}
        modal={modal}
        handleUpdateHsnCodeSubmit={handleUpdateHsnCodeSubmit}
      />
    </>
  );
}

export default ManageHSNView;
