import * as React from "react";
import { Box } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { TableHead } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { Container } from "reactstrap";
import { HiOutlineShoppingCart } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator() {
  return (a, b) => -descendingComparator(a, b);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "order_details",
    numeric: false,
    disablePadding: true,
    label: "Order Details",
  },
  {
    id: "customer_details",
    numeric: false,
    disablePadding: false,
    label: "Customer Details",
  },
  {
    id: "package_details",
    numeric: false,
    disablePadding: false,
    label: "Package Details",
  },
  {
    id: "payment",
    numeric: false,
    disablePadding: false,
    label: "Payment",
  },
  {
    id: "pickup_address",
    numeric: false,
    disablePadding: false,
    label: "Pickup Address",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: true,
    label: "Status",
  },
  // {
  //   id: "action",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Action",
  // },
];

function EnhancedTableHead() {
  return (
    <TableHead sx={{ backgroundColor: "#f7e1a3" }}>
      <TableRow>
        <TableCell padding="checkbox" />
        {headCells.map((headCell) => (
          <TableCell
            sx={{
              fontFamily: "poppins",
            }}
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            <TableSortLabel>{headCell.label}</TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function EnhancedTable(props) {
  const [order] = React.useState("asc");
  const [orderBy] = React.useState("id");
  const navigate = useNavigate();

  const handleGoToDetailsPage = (data) => {
    navigate("/admin/ship_rocket/order_details", {
      state: {
        shipRocketAccessToken: props.shipRocketAccessToken,
        orderDetail: data,
      },
    });
  };

  return (
    <Box sx={{ width: "100%" }}>
      {props.shipRocketData.data ? (
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <EnhancedTableHead />
              <TableBody>
                {props.shipRocketData.data &&
                stableSort(
                  props.shipRocketData.data,
                  getComparator(order, orderBy)
                ) &&
                Array.isArray(
                  stableSort(
                    props.shipRocketData.data,
                    getComparator(order, orderBy)
                  )
                )
                  ? stableSort(
                      props.shipRocketData.data,
                      getComparator(order, orderBy)
                    )
                      .slice(
                        props.page * props.rowsPerPage,
                        props.page * props.rowsPerPage + props.rowsPerPage
                      )
                      .map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.id}
                            // sx={{ cursor: "pointer" }}
                          >
                            <TableCell padding="checkbox" />
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                              sx={{
                                fontFamily: "poppins",
                                padding: "10px 0",
                              }}
                            >
                              <Container className="flex flex-col gap-2">
                                <p
                                  className="text-[#6149cd] cursor-pointer w-fit hover:underline"
                                  onClick={() => handleGoToDetailsPage(row)}
                                >
                                  {row.channel_order_id}
                                </p>
                                <p className="text-[11px] text-[#555]">
                                  {row.channel_created_at.split(",").join(" |")}
                                </p>
                                <p className="flex gap-3 items-center text-[11px]">
                                  <HiOutlineShoppingCart /> {row.channel_name}
                                </p>
                              </Container>
                            </TableCell>
                            <TableCell
                              sx={{
                                fontFamily: "poppins",
                              }}
                            >
                              <Container className="flex flex-col gap-2 text-xs">
                                <p>{row.customer_name}</p>
                                <p>{row.customer_email}</p>
                                <p>{row.customer_phone}</p>
                              </Container>
                            </TableCell>
                            <TableCell
                              sx={{
                                fontFamily: "poppins",
                              }}
                            >
                              <Container className="flex flex-col gap-2 text-[12px]">
                                <p>Dead wt.: {row.shipments[0].weight} kg</p>
                                <p>
                                  {row.shipments[0].dimensions
                                    .split("x")
                                    .join(".00 x ")}
                                  .00 (cm)
                                </p>
                                <p>
                                  Volumetric wt.:{" "}
                                  {row.shipments[0].volumetric_weight} kg
                                </p>
                              </Container>
                            </TableCell>
                            <TableCell
                              sx={{
                                fontFamily: "poppins",
                              }}
                            >
                              <Container className="flex flex-col gap-2">
                                <p className="text-[13px]">
                                  ₹ {row.shipments[0].total}
                                </p>
                                <p className="bg-[#e5e5e5] w-fit text-xs px-2 py-1 rounded-md text-[#008e27]">
                                  {row.payment_method}
                                </p>
                              </Container>
                            </TableCell>
                            <TableCell
                              sx={{
                                fontFamily: "poppins",
                              }}
                            >
                              <p className="text-xs border-b border-dashed w-fit border-gray-600">
                                {row.pickup_location}
                              </p>
                            </TableCell>
                            <TableCell
                              sx={{
                                fontFamily: "poppins",
                              }}
                            >
                              <p className="bg-[#ebf7e8] w-fit text-xs text-[#008e27] px-2 py-1 rounded-md">
                                {row.status}
                              </p>
                            </TableCell>
                            {/* <TableCell
                              sx={{
                                fontFamily: "poppins",
                              }}
                              align="right"
                            >
                              {row.phone}
                            </TableCell> */}
                          </TableRow>
                        );
                      })
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={props.shipRocketData.meta.pagination?.total || 0}
            rowsPerPage={props.rowsPerPage}
            page={props.page}
            onPageChange={props.handleChangePage}
            onRowsPerPageChange={props.handleChangeRowsPerPage}
            sx={{
              "& .MuiTablePagination-selectLabel": {
                fontFamily: "Poppins",
              },
              "& .MuiTablePagination-select": {
                fontFamily: "Poppins",
              },
              "& .MuiTablePagination-displayedRows": {
                fontFamily: "Poppins",
              },
            }}
          />
        </Paper>
      ) : (
        <Container className="flex justify-center font-semibold">
          <p>No users found...</p>
        </Container>
      )}
    </Box>
  );
}
