import { Backdrop, Box, Fade, Modal } from "@mui/material";
import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import { Button, Container, Form } from "reactstrap";
import CustomInputField from "../../common/CustomInputField";
import toast from "react-hot-toast";
import { postServices } from "../../../../../apiServices/apiServices";

function MakeOrders(props) {
  const [data, setData] = useState({ length: "", breadth: "", height: "" });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    padding: "15px 20px",
    outline: "none",
    borderRadius: "15px",
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await postServices(
        `/api/shiprocket/create_order/${props.orderData.track_id}`,
        null,
        {
          length: parseFloat(data.length),
          breadth: parseFloat(data.breadth),
          height: parseFloat(data.height),
        },
        props.shipRocketAccessToken
      );
      if (res.responseCode === 200) {
        props.onClose();
        props.fetchOrderDetails();
      } else {
        toast.error(res.responseMessage);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        // onClose={props.onClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={props.open}>
          <Box sx={style}>
            <div className="flex justify-between items-center mb-2">
              <p className="font-semibold">Initialize to ShipRocket Order</p>
              <IoClose
                className="cursor-pointer border border-[#f46565] rounded-full"
                onClick={props.onClose}
              />
            </div>
            <Form
              onSubmit={handleSubmit}
              className="w-[300px] md:w-[400px] flex flex-col gap-4"
            >
              <CustomInputField
                name="length"
                type="number"
                label="Box Length in cms"
                onChange={(e) => setData({ ...data, length: e.target.value })}
                value={data.length}
                required={true}
              />
              <CustomInputField
                name="breadth"
                type="number"
                label="Box Breadth in cms"
                onChange={(e) => setData({ ...data, breadth: e.target.value })}
                value={data.breadth}
                required={true}
              />
              <CustomInputField
                name="height"
                type="number"
                label="Box Height in cms"
                onChange={(e) => setData({ ...data, height: e.target.value })}
                value={data.height}
                required={true}
              />
              <Container className="flex justify-end items-center">
                <Button
                  type="submit"
                  className="active:scale-[.98] active:duration-75 transition-all hover:scale-[1.01] ease-in-out bg-slate-800 text-white text-lg font-semibold py-2 px-4 rounded-xl cursor-pointer"
                >
                  Make Order
                </Button>
              </Container>
            </Form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default MakeOrders;
